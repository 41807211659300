<p-dialog header="Rejeter le projet" [(visible)]="visible" [modal]=true [draggable]=false (onHide)="closeDialog()">
    <div class="p-d-flex p-flex-column p-jc-center" [formGroup]="form">
        <div class="p-mb-2 p-ai-end">
            <app-form-field class="app-form-field app-form-field--column"
                [control]="form.controls.justificatif" label="Justificatif :">
                <input pInputText type="text" [required]="true" formControlName="justificatif">
            </app-form-field>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
            (click)="closeDialog()"></button>
        <button [disabled]="!form.controls.justificatif.valid" class="p-button-text" pButton pRipple icon="pi pi-check" label="Confirmer"
            (click)="onRejeter()"></button>
    </ng-template>
</p-dialog>
