export enum RechercheConfig {
    RECHERCHE_CODE_BARRES = 'Code à barres',
    RECHERCHE_LIGNE = 'Ligne',
    RECHERCHE_LCLCL = 'LCLCL',
}

export enum RechercheBackendValue {
    RECHERCHE_CODE_BARRES = 'cab',
    RECHERCHE_LIGNE = 'ligne',
    RECHERCHE_LCLCL = 'lclcl',
}

export const rechercheBackendUrlValue = new Map<RechercheConfig, RechercheBackendValue>([
    [RechercheConfig.RECHERCHE_CODE_BARRES, RechercheBackendValue.RECHERCHE_CODE_BARRES],
    [RechercheConfig.RECHERCHE_LIGNE, RechercheBackendValue.RECHERCHE_LIGNE],
    [RechercheConfig.RECHERCHE_LCLCL, RechercheBackendValue.RECHERCHE_LCLCL],
]);

export enum RechercheSource {
    CAPTURE = 'Capture',
    SIG = 'SIG',
}
