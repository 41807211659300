import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { flattenDeep } from 'lodash';
import { of } from 'rxjs';
import { switchMap, map, debounceTime } from 'rxjs/operators';
import { AnomalieActionTypes, forbiddenGetAnomaliesBbox, getAnomaliesBboxSuccess } from './anomalie.action';
import { ApiService } from 'src/app/services/api.service';
import { StoreName } from '../../../offline/models/indexed-db-store-name.enum';
import { Bounds } from '../../../../shared/models/bounds.model';
import { Projet } from '../../../projet/models/projet.model';
import { mapStyleConfig } from '../../../../map/models/map-style-config.const';
import { IndexedDbService } from '../../../offline/services/indexed-db.service';
import { UtilisateurService } from '../../../../services/utilisateur.service';
import { UserRole } from '../../../../shared/models/user-roles.model';
import { AnomalieBaseDto } from '../../../../core/api/client/models';

@Injectable()
export class AnomalieEffects {

    public getAnomaliesWithBbox$ = createEffect(() => this.actions$.pipe(
        ofType(AnomalieActionTypes.GET_ANOMALIES_BBOX),
        debounceTime(200),
        switchMap((action: { bbox: Bounds, zoomLevel: number }) => {
            if (this.utilisateurService.isOneOfRoles([
                UserRole.INSPECTEUR_EXTERNE,
                UserRole.INSPECTEUR_HQD,
                UserRole.CONTROLEUR_QUALITE_EXTERNE
            ])) {
                return of(null);
            }
            if (!navigator.onLine) {
                return this.dbService.getAll(StoreName.PROJETS).pipe(
                    map((p: any) => (p[0] as Projet)?.pointInspections || []),
                    map(pis => {
                        const anomalies = pis.map(pi => pi.anomalies || []);
                        return flattenDeep(anomalies);
                    }),
                );
            }
            if (action.zoomLevel >= mapStyleConfig.poteau.minZoom) {
                const bboxParams = `xmin=${action.bbox.xmin}&ymin=${action.bbox.ymin}&xmax=${action.bbox.xmax}&ymax=${action.bbox.ymax}`;
                return this.apiService.get<AnomalieBaseDto[]>(`/anomalies?pageSize=5000&${bboxParams}`).pipe(
                    map((x: any) => x.items)
                );
            }
            return of([]);
        }),
        map((anomalies) => {
            if (!anomalies) {
                return forbiddenGetAnomaliesBbox();
            }
            return getAnomaliesBboxSuccess({ anomalies });
        })
    ));

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private utilisateurService: UtilisateurService,
        private readonly dbService: IndexedDbService,
    ) { }
}
