/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { ProjetAuditDto } from '../models/projet-audit-dto';
import { PatchProjetAuditDto } from '../models/patch-projet-audit-dto';
import { ProjetAuditDtoSearchResult } from '../models/projet-audit-dto-search-result';
import { PhotoEntitySearchResult } from '../models/photo-entity-search-result';
import { IncludePointAuditDto } from '../models/include-point-audit-dto';
@Injectable({
    providedIn: 'root',
})
class ProjetAuditsService extends __BaseService {
    static readonly getApiV1AuditsIdPath = '/api/v1/audits/{id}';
    static readonly deleteApiV1AuditsIdPath = '/api/v1/audits/{id}';
    static readonly patchApiV1AuditsIdPath = '/api/v1/audits/{id}';
    static readonly getApiV1AuditsIdStatutPath = '/api/v1/audits/{id}/statut';
    static readonly getApiV1AuditsPath = '/api/v1/audits';
    static readonly getApiV1AuditsIdPhotosPath = '/api/v1/audits/{id}/photos';
    static readonly postApiV1AuditsIdInspectionsPointInspectionIdPath = '/api/v1/audits/{id}/inspections/{pointInspectionId}';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * @param id undefined
     * @return Success
     */
    getApiV1AuditsIdResponse(id: string): __Observable<__StrictHttpResponse<ProjetAuditDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/audits/${encodeURIComponent(String(id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetAuditDto>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    getApiV1AuditsId(id: string): __Observable<ProjetAuditDto> {
        return this.getApiV1AuditsIdResponse(id).pipe(
            __map(_r => _r.body as ProjetAuditDto)
        );
    }

    /**
     * @param id undefined
     * @return Success
     */
    deleteApiV1AuditsIdResponse(id: string): __Observable<__StrictHttpResponse<ProjetAuditDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/api/v1/audits/${encodeURIComponent(String(id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetAuditDto>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    deleteApiV1AuditsId(id: string): __Observable<ProjetAuditDto> {
        return this.deleteApiV1AuditsIdResponse(id).pipe(
            __map(_r => _r.body as ProjetAuditDto)
        );
    }

    /**
     * @param params The `ProjetAuditsService.PatchApiV1AuditsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    patchApiV1AuditsIdResponse(params: ProjetAuditsService.PatchApiV1AuditsIdParams): __Observable<__StrictHttpResponse<ProjetAuditDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/api/v1/audits/${encodeURIComponent(String(params.id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetAuditDto>;
            })
        );
    }
    /**
     * @param params The `ProjetAuditsService.PatchApiV1AuditsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    patchApiV1AuditsId(params: ProjetAuditsService.PatchApiV1AuditsIdParams): __Observable<ProjetAuditDto> {
        return this.patchApiV1AuditsIdResponse(params).pipe(
            __map(_r => _r.body as ProjetAuditDto)
        );
    }

    /**
     * @param id undefined
     * @return Success
     */
    getApiV1AuditsIdStatutResponse(id: string): __Observable<__StrictHttpResponse<string>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/audits/${encodeURIComponent(String(id))}/statut`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'text'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<string>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    getApiV1AuditsIdStatut(id: string): __Observable<string> {
        return this.getApiV1AuditsIdStatutResponse(id).pipe(
            __map(_r => _r.body as string)
        );
    }

    /**
     * @param params The `ProjetAuditsService.GetApiV1AuditsParams` containing the following parameters:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * @return Success
     */
    getApiV1AuditsResponse(params: ProjetAuditsService.GetApiV1AuditsParams): __Observable<__StrictHttpResponse<ProjetAuditDtoSearchResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/audits`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetAuditDtoSearchResult>;
            })
        );
    }
    /**
     * @param params The `ProjetAuditsService.GetApiV1AuditsParams` containing the following parameters:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * @return Success
     */
    getApiV1Audits(params: ProjetAuditsService.GetApiV1AuditsParams): __Observable<ProjetAuditDtoSearchResult> {
        return this.getApiV1AuditsResponse(params).pipe(
            __map(_r => _r.body as ProjetAuditDtoSearchResult)
        );
    }

    /**
     * @param params The `ProjetAuditsService.GetApiV1AuditsIdPhotosParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `type`:
     *
     * - `taille`:
     *
     * - `PageSize`:
     *
     * - `PageNumber`:
     *
     * @return Success
     */
    getApiV1AuditsIdPhotosResponse(params: ProjetAuditsService.GetApiV1AuditsIdPhotosParams): __Observable<__StrictHttpResponse<PhotoEntitySearchResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        if (params.type != null) __params = __params.set('type', params.type.toString());
        if (params.taille != null) __params = __params.set('taille', params.taille.toString());
        if (params.PageSize != null) __params = __params.set('PageSize', params.PageSize.toString());
        if (params.PageNumber != null) __params = __params.set('PageNumber', params.PageNumber.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/audits/${encodeURIComponent(String(params.id))}/photos`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PhotoEntitySearchResult>;
            })
        );
    }
    /**
     * @param params The `ProjetAuditsService.GetApiV1AuditsIdPhotosParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `type`:
     *
     * - `taille`:
     *
     * - `PageSize`:
     *
     * - `PageNumber`:
     *
     * @return Success
     */
    getApiV1AuditsIdPhotos(params: ProjetAuditsService.GetApiV1AuditsIdPhotosParams): __Observable<PhotoEntitySearchResult> {
        return this.getApiV1AuditsIdPhotosResponse(params).pipe(
            __map(_r => _r.body as PhotoEntitySearchResult)
        );
    }

    /**
     * @param params The `ProjetAuditsService.PostApiV1AuditsIdInspectionsPointInspectionIdParams` containing the following parameters:
     *
     * - `pointInspectionId`:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    postApiV1AuditsIdInspectionsPointInspectionIdResponse(params: ProjetAuditsService.PostApiV1AuditsIdInspectionsPointInspectionIdParams): __Observable<__StrictHttpResponse<ProjetAuditDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        __body = params.body;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/audits/${encodeURIComponent(String(params.id))}/inspections/${encodeURIComponent(String(params.pointInspectionId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ProjetAuditDto>;
            })
        );
    }
    /**
     * @param params The `ProjetAuditsService.PostApiV1AuditsIdInspectionsPointInspectionIdParams` containing the following parameters:
     *
     * - `pointInspectionId`:
     *
     * - `id`:
     *
     * - `body`:
     *
     * @return Success
     */
    postApiV1AuditsIdInspectionsPointInspectionId(params: ProjetAuditsService.PostApiV1AuditsIdInspectionsPointInspectionIdParams): __Observable<ProjetAuditDto> {
        return this.postApiV1AuditsIdInspectionsPointInspectionIdResponse(params).pipe(
            __map(_r => _r.body as ProjetAuditDto)
        );
    }
}

module ProjetAuditsService {

    /**
     * Parameters for patchApiV1AuditsId
     */
    export interface PatchApiV1AuditsIdParams {
        id: string;
        body?: PatchProjetAuditDto;
    }

    /**
     * Parameters for getApiV1Audits
     */
    export interface GetApiV1AuditsParams {
        pageSize?: number;
        pageNumber?: number;
    }

    /**
     * Parameters for getApiV1AuditsIdPhotos
     */
    export interface GetApiV1AuditsIdPhotosParams {
        id: string;
        type?: any;
        taille?: any;
        PageSize?: number;
        PageNumber?: number;
    }

    /**
     * Parameters for postApiV1AuditsIdInspectionsPointInspectionId
     */
    export interface PostApiV1AuditsIdInspectionsPointInspectionIdParams {
        pointInspectionId: string;
        id: string;
        body?: IncludePointAuditDto;
    }
}

export { ProjetAuditsService }
