import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../../shared/shared.module';
import { OfflineEffects } from './state/offline.effects';
import { offlineReducer } from './state/offline.reducer';

@NgModule({
    declarations: [],
    imports: [
        SharedModule,
        StoreModule.forFeature('offline', offlineReducer),
        EffectsModule.forFeature([OfflineEffects])
    ],
    exports: [],
})
export class OfflineModule { }
