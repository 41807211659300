import { Component, OnDestroy, OnInit } from '@angular/core';
import { DynamicDialogRef, DynamicDialogConfig } from 'primeng/dynamicdialog';
import packageJson from '../../../package.json';
import { UiService } from '../services/ui.service';
import { Subscription } from 'rxjs';
import { tap } from 'rxjs/operators';

@Component({
    selector: 'app-version',
    templateUrl: './version.component.html',
    styleUrls: ['./version.component.scss']
})

export class VersionComponent implements OnInit, OnDestroy {
    public copyright: string = ' © Hydro-Québec, ' + new Date().getFullYear();
    public frontendVersion = packageJson.version;
    public backendVersion: string = '';
    private subscriptions: Subscription[] = [];

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        public readonly uiService: UiService,
    ) { }

    public ngOnInit(): void {
        this.subscriptions.push(
            this.uiService.backendVersion$.pipe(
                tap((version: string) => {
                    this.backendVersion = version;
                })
            ).subscribe()
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
    }
}
