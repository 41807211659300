import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';

export const bufferLigneProjet: AnyLayer = {
    id: MapLayersSources.BUFFER_LIGNE_PROJET,
    type: 'fill',
    source: MapLayersSources.BUFFER_LIGNE_PROJET,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'fill-color': mapStyleConfig.colors.transparent,
        'fill-opacity': mapStyleConfig.projetHighlight.opacity,
        /* eslint-enable @typescript-eslint/naming-convention */
    },
    filter: ['==', ['geometry-type'], 'Polygon']
};
