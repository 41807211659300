import { ProjetAuditDto } from '../../../core/api/client/models';
import { StatutProjetAudit } from '../models/statut-projet-audit.enum';
import { Projet, StatutProjet } from '../../projet/models';

export function getCreateProjetAuditPermission(projet: Projet): boolean {
    return projet.statut === StatutProjet.attenteApprobationHQ;
}

export function getAssignProjetAuditPermission(projetAudit: ProjetAuditDto): boolean {
    return projetAudit.statut === StatutProjetAudit.cree ||
        projetAudit.statut === StatutProjetAudit.assigne ||
        projetAudit.statut === StatutProjetAudit.enCours ||
        projetAudit.statut === StatutProjetAudit.nonApprouve;
}

export function getCancelProjetAuditPermission(projetAudit: ProjetAuditDto): boolean {
    return projetAudit.statut === StatutProjetAudit.cree ||
        projetAudit.statut === StatutProjetAudit.assigne;
}

export function getRejectProjetAuditPermission(projetAudit: ProjetAuditDto): boolean {
    return projetAudit.statut === StatutProjetAudit.auditComplete;
}

export function getCompleteProjetAuditPermission(projetAudit: ProjetAuditDto, projetAuditDownloaded: boolean, isOffline: boolean): boolean {
    return !(projetAudit.statut === StatutProjetAudit.enCours) || isOffline || !projetAuditDownloaded;
}

export function getApproveProjetAuditPermission(projetAudit: ProjetAuditDto, isOffline: boolean): boolean {
    return projetAudit.statut === StatutProjetAudit.auditComplete && !isOffline;
}
