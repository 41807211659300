import { createAction, props } from '@ngrx/store';
import { Bounds } from '../../../../shared/models/bounds.model';
import { AnomalieBaseDto } from '../../../../core/api/client/models';

export enum AnomalieActionTypes {
    GET_ANOMALIES_BBOX = '[PointsInspection] Get anomalies with bbox from backend',
    GET_ANOMALIES_BBOX_SUCCESS = '[PointsInspection] Get anomalies with bbox from backend Success',
    GET_ANOMALIES_BBOX_FORBIDDEN = '[PointsInspection] Get anomalies with bbox from backend forbidden',
}

export const getAnomaliesBbox = createAction(AnomalieActionTypes.GET_ANOMALIES_BBOX, props<{ bbox: Bounds, zoomLevel: number }>());

export const getAnomaliesBboxSuccess = createAction(AnomalieActionTypes.GET_ANOMALIES_BBOX_SUCCESS, props<{ anomalies: AnomalieBaseDto[] }>());

export const forbiddenGetAnomaliesBbox = createAction(AnomalieActionTypes.GET_ANOMALIES_BBOX_FORBIDDEN,);
