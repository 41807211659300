<div class="point-audit-pop-up">
    <app-pop-up-info-header [mainTitle]="pointInspection?.poteau?.codeABarres || 'Code à barres inconnu'"
        [subTitle]="subTitle" [logoHighQuantity]="nbPhotos" [logoLowQuantity]="anomaliesNonConformes?.length || 0"
        (closed)="close()">
    </app-pop-up-info-header>
    <div class="point-audit-pop-up__content">
        <div class="content__scrolable">
            <div *ngIf="projetAudit" class="scrolable__single">
                <span class="single__value">
                    {{projetAudit?.nom}}
                </span>
            </div>
            <app-pop-up-info-properties [datas]="data" [verticalProperties]="verticalProperties"
                [keepOriginalOrder]="true" />
            <div *ngIf="pointAudit?.photos?.length > 0 || pointAudit?.anomaliesAudit?.length > 0"
                class="scrolable__accordion">
                <ng-container *ngIf="pointAudit?.photos?.length > 0">
                    <pop-up-info-accordion-component [title]="pointAudit?.photos?.length > 1 ? 'Photos' : 'Photo'"
                        [logo]="'fa-solid fa-camera'" [isAlwaysOpen]="true" [quantity]="pointAudit?.photos?.length || 0"
                        [minHeight]="true">
                        <app-photo-container [photos]="pointAuditHtmlPhotos"></app-photo-container>
                    </pop-up-info-accordion-component>
                </ng-container>
                <ng-container *ngIf="anomaliesNonConformes?.length > 0">
                    <pop-up-info-accordion-component
                        [title]="anomaliesNonConformes?.length > 1 ? 'Anomalies non conformes' : 'Anomalie non conforme'"
                        [logo]="'fa-solid fa-bug'" [logoColor]="'#ff0000'" [isAlwaysOpen]="true"
                        [quantity]="anomaliesNonConformes?.length || 0">
                        <app-pop-up-info-anomalie-audit [anomalies]="pointInspection?.anomalies || []"
                            [anomaliesAudit]="anomaliesNonConformes || []">
                        </app-pop-up-info-anomalie-audit>
                    </pop-up-info-accordion-component>
                </ng-container>
            </div>
        </div>
        <div class="content__static" *ngIf="showEditButton">
            <button class="p-element p-button-primary p-button p-component" (click)="edit()" [disabled]="isOffline">
                <i class="fa-solid fa-pen"></i>
                <span>Modifier Audit</span>
            </button>
            <div class="offlineMessage" *ngIf="isOffline">
                {{offlineMessage}}
            </div>
        </div>
    </div>
</div>
