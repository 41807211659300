import { ChangeDetectorRef, Component, ElementRef, Input, OnChanges, SimpleChanges, ViewChild } from '@angular/core';

@Component({
    selector: 'app-photo-container',
    templateUrl: './photo-container.component.html',
    styleUrls: ['./photo-container.component.scss']
})
export class PhotoContainerComponent implements OnChanges {
    @Input() photos: HTMLImageElement[];

    @ViewChild('photoContainer', { static: false }) photosContainer: ElementRef<HTMLElement>;

    constructor(
        private cdr: ChangeDetectorRef
    ) { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.photos.currentValue) {
            this.cdr.detectChanges();
            this.addPhotos();
        }
    }

    private clearPhotoContainer() {
        if (this.photosContainer && this.photosContainer.nativeElement.hasChildNodes()) {
            Array.from(this.photosContainer.nativeElement.childNodes).forEach(nodeChild => {
                this.photosContainer.nativeElement.removeChild(nodeChild);
            });
        }
    }

    private addPhotos() {
        this.clearPhotoContainer();
        this.photos.forEach(photo => this.photosContainer.nativeElement.appendChild(photo));
    }
}
