import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createOneTaxonomieSuccess, deleteOneTaxonomieSuccess, getAllTaxonomiesSuccess, updateOneTaxonomieSuccess } from '../actions/taxonomie.action';
import { Taxonomie } from '../../../features/pilotage/models/taxonomie.model';


export interface TaxonomieState extends EntityState<Taxonomie> {
    fetched: boolean,
}

export const taxonomieAdapter: EntityAdapter<Taxonomie> = createEntityAdapter<Taxonomie>({
    selectId: (taxonomie: Taxonomie) => taxonomie.id as string
});

export const taxonomieAdapterInitialState = taxonomieAdapter.getInitialState({
    fetched: false,
});

export const taxonomieSelectors = taxonomieAdapter.getSelectors();

export const taxonomieReducer = createReducer(taxonomieAdapterInitialState,
    on(getAllTaxonomiesSuccess, (state, { taxonomies }) => taxonomieAdapter.upsertMany(taxonomies, {
        ...state,
        fetched: true,
    })),
    on(createOneTaxonomieSuccess, (state, { taxonomie }) => taxonomieAdapter.upsertOne(taxonomie, state)),
    on(updateOneTaxonomieSuccess, (state, { taxonomie }) => taxonomieAdapter.upsertOne(taxonomie, state)),
    on(deleteOneTaxonomieSuccess, (state, { taxonomie }) => taxonomieAdapter.removeOne(taxonomie.id!, state)),
);
