import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';

export const rechercheCodeBarres: AnyLayer = {
    id: MapLayersSources.RECHERCHE_CODE_BARRES,
    type: 'circle',
    source: MapLayersSources.RECHERCHE_CODE_BARRES,
    minzoom: mapStyleConfig.rechercheCodeBarres.minZoom,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'circle-radius': 6,
        'circle-color': mapStyleConfig.colors.rechercheColor,
        'circle-opacity': mapStyleConfig.rechercheCodeBarres.opacity
        /* eslint-enable @typescript-eslint/naming-convention */
    }
};
