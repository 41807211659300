export enum ServiceFeature {
POSTE = 0,
POTEAU = 1,
LCLCL_AERIEN = 2,
LCLCL_SOUTERRAIN = 3,
CONDUCTEUR_MT_SOUTERRAIN = 5,
CONDUCTEUR_MT_AERIEN = 4,
PARC_DE_POTEAU_HQ = 6,
PARC_DE_POTEAU_AUTRE = 7
}
