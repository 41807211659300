import { StatutAudit } from '../../../core/api/client/models';

export enum StatutPointAudit {
    conforme = 'conforme',
    nonConforme = 'nonConforme',
    aAuditer = 'aAuditer',
    ignore = 'ignore',
    exclu = 'exclu'
}

export enum StatutPointAuditValue {
    conforme = 'Audit conforme',
    nonConforme = 'Audit non conforme',
    aAuditer = 'À auditer',
    ignore = 'Audit ignoré',
    exclu = 'Audit exclu'
}

export const statutPointAuditName = new Map<StatutPointAudit, string>([
    [StatutPointAudit.conforme, StatutPointAuditValue.conforme],
    [StatutPointAudit.nonConforme, StatutPointAuditValue.nonConforme],
    [StatutPointAudit.aAuditer, StatutPointAuditValue.aAuditer],
    [StatutPointAudit.ignore, StatutPointAuditValue.ignore],
    [StatutPointAudit.exclu, StatutPointAuditValue.exclu]
]);

export const statutAuditName = (statut: StatutAudit): string | null => {

    let retour: string = null;
    statutPointAuditName.forEach((value, key) => {
        if (statut.toString() === key.toString()) {
            retour = value;
        }
    });

    return retour;
};
