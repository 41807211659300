import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { ComponentBase } from '@ngxhq/common-ui';
import { takeUntil } from 'rxjs';
import {
    getNonCachedAnomaliesAuditPhotos,
    getNonCachedAnomaliesPhotos,
    getNonCachedPointAuditPhotos,
    getCacheAnomaliesAuditLoading,
    getCachingAnomaliesAuditProgression,
    getCacheAnomaliesPhotosLoading,
    getCachingAnomaliesPhotosProgression,
    getCachePointAuditPhotosLoading,
    getCachingPointAuditPhotosProgression,
    getCachePointInspectionPhotosLoading,
    getCachingPointInspectionPhotosProgression,
    getNonCachedPointInspectionPhotos,
    isCachingPhotosLoading
} from '../state/photos.selectors';

@Component({
    selector: 'app-photo-status',
    templateUrl: './photos-status.component.html',
    styleUrls: ['./photos-status.component.scss']
})
export class PhotosStatusComponent extends ComponentBase implements OnInit {
    public photosInErrorList$ = this.store.select(getNonCachedPointInspectionPhotos);
    public anomaliesPhotosInErrorList$ = this.store.select(getNonCachedAnomaliesPhotos);
    public auditPhotosInErrorList$ = this.store.select(getNonCachedPointAuditPhotos);
    public anomaliesAuditPhotosInErrorList$ = this.store.select(getNonCachedAnomaliesAuditPhotos);

    public showPanel = false;

    public showInspectionPhotosProgression = false;
    public showAnomaliesPhotosProgression = false;
    public showAuditPhotosProgression = false;
    public showAnomaliesAuditPhotosProgression = false;

    public inspectionPhotosProgression = 0;
    public anomaliesPhotosProgression = 0;
    public auditPhotosProgression = 0;
    public anomaliesAuditPhotosProgression = 0;

    constructor(
        private store: Store
    ) {
        super();
    }

    ngOnInit(): void {
        this.subscribeToCachingPhotosLoading();
        this.subscribeToInspectionPhotos();
        this.subscribeToAnomaliesPhotos();
        this.subscribeToAuditPhotos();
        this.subscribeToAnomaliesAuditPhotos();
    }

    private subscribeToCachingPhotosLoading() {
        this.store.select(isCachingPhotosLoading).pipe(
            takeUntil(this.destroyed)
        ).subscribe((loading) => {
            if (loading) {
                this.showPanel = true;
            }
        });
    }

    private subscribeToInspectionPhotos() {
        this.store.select(getCachePointInspectionPhotosLoading).pipe(
            takeUntil(this.destroyed)
        ).subscribe((loading) => {
            if (loading) {
                this.showInspectionPhotosProgression = true;
            }
        });

        this.store.select(getCachingPointInspectionPhotosProgression).pipe(
            takeUntil(this.destroyed)
        ).subscribe((progression) => {
            this.inspectionPhotosProgression = progression;
        });
    }

    private subscribeToAnomaliesPhotos() {
        this.store.select(getCacheAnomaliesPhotosLoading).pipe(
            takeUntil(this.destroyed)
        ).subscribe((loading) => {
            if (loading) {
                this.showAnomaliesPhotosProgression = true;
            }
        });

        this.store.select(getCachingAnomaliesPhotosProgression).pipe(
            takeUntil(this.destroyed)
        ).subscribe((progression) => {
            this.anomaliesPhotosProgression = progression;
        });
    }

    private subscribeToAuditPhotos() {
        this.store.select(getCachePointAuditPhotosLoading).pipe(
            takeUntil(this.destroyed)
        ).subscribe((loading) => {
            if (loading) {
                this.showAuditPhotosProgression = true;
            }
        });

        this.store.select(getCachingPointAuditPhotosProgression).pipe(
            takeUntil(this.destroyed)
        ).subscribe((progression) => {
            this.auditPhotosProgression = progression;
        });
    }

    private subscribeToAnomaliesAuditPhotos() {
        this.store.select(getCacheAnomaliesAuditLoading).pipe(
            takeUntil(this.destroyed)
        ).subscribe((loading) => {
            if (loading) {
                this.showAnomaliesAuditPhotosProgression = true;
            }
        });

        this.store.select(getCachingAnomaliesAuditProgression).pipe(
            takeUntil(this.destroyed)
        ).subscribe((progression) => {
            this.anomaliesAuditPhotosProgression = progression;
        });
    }

    public closePanel() {
        this.showPanel = false;
    }
}
