/* eslint-disable @typescript-eslint/naming-convention */

import { AnomalieLayers } from '../enums/anomalie-layers.enum';
import { LayerGroupe } from '../enums/layerGroupe';
import { ServiceFeature } from '../enums/serviceFeature';
import { StyleLayer } from '../map/models/style-layer.model';
import { LayerType } from '../map/models/layer-type.enum';
import { mapStyleConfig } from '../map/models/map-style-config.const';
import { StatutPointInspectionValue, StatutPointInspection } from '../features/inspection/models/statut-point-inspection.enum';
import { StatutPointAudit, StatutPointAuditValue } from '../features/audit/models/statut-point-audit.enum';
import { StatutProjetValue } from '../features/projet/models/statut-projet.enum';
import { StatutProjetAudit, StatutProjetAuditValue } from '../features/audit/models/statut-projet-audit.enum';
import { PointAuditProperties } from '../features/audit/models/point-audit-properties.enum';
import { FeatureSource } from '../map/models/feature-source.enum';

export const styles: StyleLayer[] = [
    {
        'urlimage': ['assets/images/general/Poteau.png'],
        'value': {
            'id': 'Poteau',
            'type': LayerType.SYMBOL,
            'source': 'Poteau',
            'source-layer': 'Poteau',
            'minzoom': 16,
            'layout': { 'icon-image': 'Poteau', 'icon-allow-overlap': true },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    {
        'value': {
            'id': 'Cables (souterrain)/Biphasé',
            'type': LayerType.LINE,
            'source': 'Cables',
            'source-layer': 'Cables (souterrain)',
            'filter': ['==', '_symbol', 0], 'minzoom': 13,
            'layout': { 'line-join': 'round' },
            'paint': { 'line-color': '#BEE8FF', 'line-dasharray': [3, 2], 'line-width': 1.33333 }
        },
    },
    {
        'value': {
            'id': 'Cables (souterrain)/Monophasé',
            'type': LayerType.LINE,
            'source': 'Cables',
            'source-layer': 'Cables (souterrain)',
            'filter': ['==', '_symbol', 1], 'minzoom': 13,
            'layout': { 'line-join': 'round' },
            'paint': { 'line-color': '#73DFFF', 'line-dasharray': [3, 2], 'line-width': 1.33333 }
        },
    },
    {
        'value': {
            'id': 'Cables (souterrain)/Triphasé',
            'type': LayerType.LINE,
            'source': 'Cables',
            'source-layer': 'Cables (souterrain)',
            'filter': ['==', '_symbol', 2], 'minzoom': 13,
            'layout': { 'line-join': 'round' },
            'paint': { 'line-color': '#73DFFF', 'line-width': 1.33333 }
        },
    },
    {
        'value': {
            'id': 'Conducteur Moyenne tension Aérien/Monophasé',
            'type': LayerType.LINE,
            'source': 'Conducteur_MT',
            'source-layer': 'Conducteur Moyenne tension Aérien',
            'filter': ['==', '_symbol', 0],
            'minzoom': 10,
            'layout': {
                'line-join': 'round'
            },
            'paint': {
                'line-color':
                    '#DC7300', 'line-dasharray': [3, 2],
                'line-width': 1.66667
            }
        }
    },
    {
        'value': {
            'id': 'Conducteur Moyenne tension Aérien/Biphasé',
            'type': LayerType.LINE,
            'source': 'Conducteur_MT',
            'source-layer': 'Conducteur Moyenne tension Aérien',
            'filter': ['==', '_symbol', 1],
            'minzoom': 10,
            'layout': { 'line-join': 'round' },
            'paint': {
                'line-color': '#DC7300',
                'line-dasharray': [3, 2],
                'line-width': 2.33333
            }
        }
    },
    {
        'value': {
            'id': 'Conducteur Moyenne tension Aérien/Triphasé',
            'type': LayerType.LINE,
            'source': 'Conducteur_MT',
            'source-layer': 'Conducteur Moyenne tension Aérien',
            'filter': ['==', '_symbol', 2],
            'minzoom': 10,
            'layout': { 'line-join': 'round' },
            'paint': {
                'line-color': '#DC7300',
                'line-width': 2.33333
            }
        }
    },
    {
        'value': {
            'id': 'Conducteur Moyenne tension Aérien/label/Classe d’étiquettes 1',
            'type': LayerType.SYMBOL,
            'source': 'Conducteur_MT',
            'source-layer': 'Conducteur Moyenne tension Aérien/label',
            'minzoom': 16,
            'layout': {
                'symbol-placement': LayerType.LINE,
                'symbol-spacing': 1000,
                'text-allow-overlap': true,
                'text-font': ['Arial Unicode MS Regular'],
                'text-size': 12,
                'text-anchor': 'bottom',
                'text-offset': [0, -0.111111],
                'text-field': '{_name}',
                'text-optional': true
            },
            'paint': {
                'text-color': '#DC7300',
                'text-halo-color': '#FFFFFF',
                'text-halo-width': 1
            }
        }
    },
    {
        'urlimage': ['assets/images/general/Poste.png'],
        'value': {
            'id': 'Poste',
            'type': LayerType.SYMBOL,
            'source': 'Poste',
            'source-layer': 'Poste',
            'minzoom': 9,
            'layout': {
                'icon-image': 'Poste', 'icon-allow-overlap': true,
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    /*{
        'urlimage': ['assets/images/general/LCLCL.png'],
        'value': {
            'id': 'LCLCL',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL',
            'source-layer': 'LCLCL',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [0.470711, -0.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },*/
    {
        'urlimage': ['assets/images/general/LCLCL-SO.png'],
        'value': {
            'id': 'LCLCL aérien/SO',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/SO',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/SO', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-D.png'],
        'value': {
            'id': 'LCLCL aérien/DL',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/DL',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/DL', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-D.png'],
        'value': {
            'id': 'LCLCL aérien/DF',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/DF',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/DF', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-D.png'],
        'value': {
            'id': 'LCLCL aérien/DJ',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/DJ',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/DJ', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-D.png'],
        'value': {
            'id': 'LCLCL aérien/DK',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/DK',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/DK', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-CC.png'],
        'value': {
            'id': 'LCLCL aérien/CC',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/CC',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/CC',
                'icon-allow-overlap': true,
                'text-font': ['Arial Unicode MS Regular'],
                'text-size': 13.3333,
                'text-anchor': 'bottom-left',
                'text-justify': 'left',
                'text-offset': [10.470711, -10.170711],
                'text-field': '{_name}',
                'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/LCLCL-autre.png'],
        'value': {
            'id': 'LCLCL aérien/Autres',
            'type': LayerType.SYMBOL,
            'source': 'LCLCL aérien/Autres',
            'source-layer': 'LCLCL aérien',
            'minzoom': 16,
            'layout': {
                'icon-image': 'LCLCL aérien/Autres', 'icon-allow-overlap': true, 'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333,
                'text-anchor': 'bottom-left', 'text-justify': 'left', 'text-offset': [10.470711, -10.170711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#D97300', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/Vegetation.png'],
        'value': {
            'id': 'Vegetation',
            'type': LayerType.SYMBOL,
            'source': 'Vegetation',
            'source-layer': 'Vegetation',
            'minzoom': 9,
            'layout': {
                'icon-image': 'Vegetation', 'icon-allow-overlap': true, 'visibility': 'none',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/selected.png'],
        'value': {
            'id': 'selected',
            'type': LayerType.SYMBOL,
            'source': 'selected',
            'source-layer': 'selected',
            'minzoom': 9,
            'layout': {
                'icon-image': 'a-auditer', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/a-auditer.png'],
        'value': {
            'id': 'a-auditer',
            'type': LayerType.SYMBOL,
            'source': 'a-auditer',
            'source-layer': 'a-auditer',
            'minzoom': 9,
            'layout': {
                'icon-image': 'a-auditer', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/ignore.png'],
        'value': {
            'id': 'ignore',
            'type': LayerType.SYMBOL,
            'source': 'ignore',
            'source-layer': 'ignore',
            'minzoom': 9,
            'layout': {
                'icon-image': 'ignore', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/conforme.png'],
        'value': {
            'id': 'conforme',
            'type': LayerType.SYMBOL,
            'source': 'conforme',
            'source-layer': 'conforme',
            'minzoom': 9,
            'layout': {
                'icon-image': 'conforme', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
    {
        'urlimage': ['assets/images/general/audit/non-conforme.png'],
        'value': {
            'id': 'non-conforme',
            'type': LayerType.SYMBOL,
            'source': 'non-conforme',
            'source-layer': 'non-conforme',
            'minzoom': 9,
            'layout': {
                'icon-image': 'non-conforme', 'icon-allow-overlap': true, 'visibility': 'visible',
                'text-font': ['Arial Unicode MS Regular'], 'text-size': 13.3333, 'text-anchor': 'bottom-left', 'text-justify': 'left',
                'text-offset': [0.807553, -0.470711], 'text-field': '{_name}', 'text-optional': true
            },
            'paint': { 'icon-color': '#FFFFFF', 'text-color': '#BF5900', 'text-halo-color': '#FFFFFF', 'text-halo-width': 1 }
        }
    },
];

export const styleLegende: StyleLayer[] = [
    {
        'nomGroupe': LayerGroupe.PARC_DE_POTEAUX,
        'numeroService': ServiceFeature.PARC_DE_POTEAU_AUTRE,
        'value': {
            'id': 'AREQ',
            'type': LayerType.FILL,
            'source': 'Parcs de poteaux',
            'filter': ['==', ['get', 'CIE_Tel_desservie'], 'AREQ'],
            'minzoom': 5,
            'layout': { 'visibility': 'none' },
            'paint': {
                'fill-color': 'rgba(245, 189, 252, 0.5)',
                'fill-outline-color': '#000000'
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PARC_DE_POTEAUX,
        'numeroService': ServiceFeature.PARC_DE_POTEAU_AUTRE,
        'value': {
            'id': 'Bell',
            'type': LayerType.FILL,
            'source': 'Parcs de poteaux',
            'filter': ['==', ['get', 'CIE_Tel_desservie'], 'Bell'],
            'minzoom': 5,
            'layout': { 'visibility': 'none' },
            'paint': {
                'fill-color': 'rgba(190, 232, 255,0.5)',
                'fill-outline-color': '#000000'
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PARC_DE_POTEAUX,
        'numeroService': ServiceFeature.PARC_DE_POTEAU_AUTRE,
        'value': {
            'id': 'Bell Aliant',
            'type': LayerType.FILL,
            'source': 'Parcs de poteaux',
            'filter': ['==', ['get', 'CIE_Tel_desservie'], 'Bell Aliant'],
            'minzoom': 5,
            'layout': { 'visibility': 'none' },
            'paint': {
                'fill-color': 'rgba(115, 178, 255,0.5)',
                'fill-outline-color': '#000000'
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PARC_DE_POTEAUX,
        'numeroService': ServiceFeature.PARC_DE_POTEAU_AUTRE,
        'value': {
            'id': 'Privé',
            'type': LayerType.FILL,
            'source': 'Parcs de poteaux',
            'filter': ['==', ['get', 'CIE_Tel_desservie'], 'Privé'],
            'minzoom': 5,
            'layout': { 'visibility': 'none' },
            'paint': {
                'fill-color': 'rgba(255, 235, 175,0.5)',
                'fill-outline-color': '#000000'
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PARC_DE_POTEAUX,
        'numeroService': ServiceFeature.PARC_DE_POTEAU_AUTRE,
        'value': {
            'id': 'Telus',
            'type': LayerType.FILL,
            'source': 'Parcs de poteaux',
            'filter': ['==', ['get', 'CIE_Tel_desservie'], 'Telus'],
            'minzoom': 5,
            'layout': { 'visibility': 'none' },
            'paint': {
                'fill-color': 'rgba(205, 245, 122,0.5)',
                'fill-outline-color': '#000000'
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PARC_DE_POTEAUX,
        'numeroService': ServiceFeature.PARC_DE_POTEAU_AUTRE,
        'value': {
            'id': 'Télébec',
            'type': LayerType.FILL,
            'source': 'Parcs de poteaux',
            'filter': ['==', ['get', 'CIE_Tel_desservie'], 'Télébec'],
            'minzoom': 5,
            'layout': { 'visibility': 'none' },
            'paint': {
                'fill-color': 'rgba(255, 211, 127,0.5)',
                'fill-outline-color': '#000000'
            }
        },
    },
    //Projets d'audit
    {
        'nomGroupe': LayerGroupe.PROJETS_AUDIT,
        'value': {
            'id': StatutProjetAuditValue.cree,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_AUDIT,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetAudit.cree],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.auditCree,
                'fill-outline-color': mapStyleConfig.colors.auditCree,
            }
        }
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_AUDIT,
        'value': {
            'id': StatutProjetAuditValue.assigne,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_AUDIT,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetAudit.assigne],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.auditAssigne,
                'fill-outline-color': mapStyleConfig.colors.auditAssigne,
            }
        }
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_AUDIT,
        'value': {
            'id': StatutProjetAuditValue.enCours,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_AUDIT,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetAudit.enCours],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.auditEnCours,
                'fill-outline-color': mapStyleConfig.colors.auditEnCours,
            }
        }
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_AUDIT,
        'value': {
            'id': StatutProjetAuditValue.annule,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_AUDIT,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetAudit.annule],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.auditAnnule,
                'fill-outline-color': mapStyleConfig.colors.auditAnnule,
            }
        }
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_AUDIT,
        'value': {
            'id': StatutProjetAuditValue.auditComplete,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_AUDIT,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetAudit.auditComplete],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.auditComplete,
                'fill-outline-color': mapStyleConfig.colors.auditComplete,
            }
        }
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_AUDIT,
        'value': {
            'id': StatutProjetAuditValue.nonApprouve,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_AUDIT,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetAudit.nonApprouve],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.auditNonApprouve,
                'fill-outline-color': mapStyleConfig.colors.auditNonApprouve,
            }
        }
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_AUDIT,
        'value': {
            'id': StatutProjetAuditValue.approuve,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_AUDIT,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetAudit.approuve],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.auditApprouve,
                'fill-outline-color': mapStyleConfig.colors.auditApprouve,
            }
        }
    },
    // Projets
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.enCreation,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.enCreation],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.enCreation,
                'fill-outline-color': mapStyleConfig.colors.enCreation,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.nouveau,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.nouveau],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.nouveau,
                'fill-outline-color': mapStyleConfig.colors.nouveau,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.inspectionAssigne,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.inspectionAssigne],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.assigne,
                'fill-outline-color': mapStyleConfig.colors.assigne,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.inspectionEnCours,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.inspectionEnCours],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.inspectionEnCours,
                'fill-outline-color': mapStyleConfig.colors.inspectionEnCours,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.inspectionCompletee,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.inspectionCompletee],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.inspectionCompletee,
                'fill-outline-color': mapStyleConfig.colors.inspectionCompletee,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.aqAssigne,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.aqAssigne],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.aqAssigne,
                'fill-outline-color': mapStyleConfig.colors.aqAssigne,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.aqEnCours,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.aqEnCours],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.aqEnCours,
                'fill-outline-color': mapStyleConfig.colors.aqEnCours,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.aqCompletee,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.aqCompletee],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.aqCompletee,
                'fill-outline-color': mapStyleConfig.colors.aqCompletee,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.attenteApprobationHQ,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.attenteApprobationHQ],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.attenteApprobationHQ,
                'fill-outline-color': mapStyleConfig.colors.attenteApprobationHQ,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.approbationFinaleHQ,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.approbationFinaleHQ],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.approbationFinaleHQ,
                'fill-outline-color': mapStyleConfig.colors.approbationFinaleHQ,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.conforme,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.conforme],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.conforme,
                'fill-outline-color': mapStyleConfig.colors.conforme,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.nonConforme,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.nonConforme],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.nonConforme,
                'fill-outline-color': mapStyleConfig.colors.nonConforme,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.correctionAssignee,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.correctionAssignee],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.correctionAssignee,
                'fill-outline-color': mapStyleConfig.colors.correctionAssignee,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.correctionEnCours,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.correctionEnCours],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.correctionEnCours,
                'fill-outline-color': mapStyleConfig.colors.correctionEnCours,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.correctionCompletee,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.correctionCompletee],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.correctionCompletee,
                'fill-outline-color': mapStyleConfig.colors.correctionCompletee,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': 'insp_' + StatutProjetValue.auditEnCours,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.auditEnCours],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.auditEnCours,
                'fill-outline-color': mapStyleConfig.colors.auditEnCours,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.audite,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.audite],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': mapStyleConfig.colors.audite,
                'fill-outline-color': mapStyleConfig.colors.audite,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.annule,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.annule],
            'layout': { 'visibility': 'none' },
            'paint': {
                'fill-color': mapStyleConfig.colors.annule,
                'fill-outline-color': mapStyleConfig.colors.annule,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.PROJETS_INSPECTION,
        'value': {
            'id': StatutProjetValue.erreur,
            'type': LayerType.FILL,
            'source': FeatureSource.PROJETS_INSPECTION,
            'minzoom': 5,
            'filter': ['==', ['get', 'Statut'], StatutProjetValue.erreur],
            'layout': { 'visibility': 'none' },
            'paint': {
                'fill-color': mapStyleConfig.colors.erreur,
                'fill-outline-color': mapStyleConfig.colors.erreur,
            }
        },
    },
    // Points d'audit
    {
        'nomGroupe': LayerGroupe.POINTS_AUDIT,
        'urlimage': ['assets/images/general/audit/a-auditer.png'],
        'value': {
            'id': StatutPointAuditValue.aAuditer,
            'type': LayerType.SYMBOL,
            'source': FeatureSource.POINTS_AUDIT,
            'minzoom': mapStyleConfig.pointAudit.minZoom,
            'filter': ['==', ['get', 'StatutGlobal'], StatutPointAudit.aAuditer],
            'layout': { 'icon-image': 'a-auditer', 'icon-allow-overlap': true, 'visibility': 'visible' },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    {
        'nomGroupe': LayerGroupe.POINTS_AUDIT,
        'urlimage': ['assets/images/general/audit/ignore.png'],
        'value': {
            'id': StatutPointAuditValue.ignore,
            'type': LayerType.SYMBOL,
            'source': FeatureSource.POINTS_AUDIT,
            'minzoom': mapStyleConfig.pointAudit.minZoom,
            'filter': ['==', ['get', 'StatutGlobal'], StatutPointAudit.ignore],
            'layout': { 'icon-image': 'ignore', 'icon-allow-overlap': true, 'visibility': 'visible' },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    {
        'nomGroupe': LayerGroupe.POINTS_AUDIT,
        'urlimage': ['assets/images/general/audit/conforme.png'],
        'value': {
            'id': StatutPointAuditValue.conforme,
            'type': LayerType.SYMBOL,
            'source': FeatureSource.POINTS_AUDIT,
            'minzoom': mapStyleConfig.pointAudit.minZoom,
            'filter': ['==', ['get', 'StatutGlobal'], StatutPointAudit.conforme],
            'layout': { 'icon-image': 'conforme', 'icon-allow-overlap': true, 'visibility': 'visible' },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    {
        'nomGroupe': LayerGroupe.POINTS_AUDIT,
        'urlimage': ['assets/images/general/audit/non-conforme.png'],
        'value': {
            'id': StatutPointAuditValue.nonConforme,
            'type': LayerType.SYMBOL,
            'source': FeatureSource.POINTS_AUDIT,
            'minzoom': mapStyleConfig.pointAudit.minZoom,
            'filter': ['==', ['get', 'StatutGlobal'], StatutPointAudit.nonConforme],
            'layout': { 'icon-image': 'non-conforme', 'icon-allow-overlap': true, 'visibility': 'visible' },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    {
        'nomGroupe': LayerGroupe.POINTS_AUDIT,
        'urlimage': ['assets/images/general/audit/a-auditer.png', 'assets/images/general/audit/non-conforme.png'],
        'value': {
            'id': `${StatutPointAuditValue.nonConforme} (Poteau)`,
            'type': LayerType.SYMBOL,
            'source': FeatureSource.POINTS_AUDIT,
            'minzoom': mapStyleConfig.pointAudit.minZoom,
            'filter': ['==', PointAuditProperties.statut, StatutPointAudit.nonConforme],
            'layout': {
                'icon-image': ['case',
                    ['==', ['get', PointAuditProperties.statutGlobal], StatutPointAudit.nonConforme], 'non-conforme',
                    ['==', ['get', PointAuditProperties.statutGlobal], StatutPointAudit.aAuditer], 'a-auditer',
                    ''
                ],
                'icon-allow-overlap': true,
                'visibility': 'visible'
            },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    {
        'nomGroupe': LayerGroupe.POINTS_AUDIT,
        'urlimage': ['assets/images/general/audit/a-auditer.png', 'assets/images/general/audit/non-conforme.png'],
        'value': {
            'id': `${StatutPointAuditValue.nonConforme} (Anomalie)`,
            'type': LayerType.SYMBOL,
            'source': FeatureSource.POINTS_AUDIT,
            'minzoom': mapStyleConfig.pointAudit.minZoom,
            'filter': ['==', PointAuditProperties.containsAnomalieNonConforme, true],
            'layout': {
                'icon-image': ['case',
                    ['==', ['get', PointAuditProperties.statutGlobal], StatutPointAudit.nonConforme], 'non-conforme',
                    ['==', ['get', PointAuditProperties.statutGlobal], StatutPointAudit.aAuditer], 'a-auditer',
                    ''
                ],
                'icon-allow-overlap': true,
                'visibility': 'visible'
            },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    // Points d'inspection
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.nonInspecte,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.nonInspecte],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.blancRgba,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.urgence,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.urgence],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.urgence,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.anomaliePrioriteC,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.anomaliePrioriteC],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.anomaliePrioriteC,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.anomaliePrioriteE,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.anomaliePrioriteE],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.anomaliePrioriteE,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.anomaliePrioriteG,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.anomaliePrioriteG],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.anomaliePrioriteG,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.anomaliePrioriteK,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.anomaliePrioriteK],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.anomaliePrioriteK,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.anomaliePrioriteM,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.anomaliePrioriteM],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.anomaliePrioriteM,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.anomaliePrioriteN,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.anomaliePrioriteN],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.anomaliePrioriteN,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.sansAnomalie,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.sansAnomalie],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.sansAnomalie,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.ignore,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.ignore],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.gris,
                'circle-radius': mapStyleConfig.poteau.circleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    {
        'nomGroupe': LayerGroupe.POINTS_INSPECTION,
        'value': {
            'id': StatutPointInspectionValue.exclu,
            'type': LayerType.CIRCLE,
            'source': FeatureSource.POINTS_INSPECTION,
            'minzoom': mapStyleConfig.poteau.minZoom,
            'filter': ['==', ['get', 'Statut'], StatutPointInspection.exclu],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'circle-color': mapStyleConfig.colors.exclu,
                'circle-radius': mapStyleConfig.poteau.excluCircleWidth,
                'circle-stroke-color': mapStyleConfig.colors.noir,
                'circle-stroke-width': mapStyleConfig.poteau.circleStroke,
            }
        },
    },
    // Anomalie
    {
        'nomGroupe': LayerGroupe.ANOMALIES,
        'urlimage': ['assets/images/general/Vegetation.png'],
        'value': {
            'id': AnomalieLayers.vegetation,
            'type': LayerType.SYMBOL,
            'source': FeatureSource.ANOMALIES,
            'minzoom': 10,
            'filter': ['==', ['get', 'element'], 'Végétation'],
            'layout': { 'icon-image': 'Vegetation', 'icon-allow-overlap': true, 'visibility': 'none' },
            'paint': { 'icon-color': '#FFFFFF' }
        }
    },
    // Rainettes
    {
        'nomGroupe': LayerGroupe.ENVIRONNEMENT,
        'value': {
            'id': 'Zone de protection - Rainette faux-grillon',
            'type': LayerType.FILL,
            'source': 'environnement',
            'minzoom': 5,
            'filter': ['==', ['get', 'HQ_ETIQUETTE'], 'Rainette faux-grillon de l\'ouest'],
            'layout': { 'visibility': 'visible' },
            'paint': {
                'fill-color': 'rgba(0, 255, 0, 0.4)',
                'fill-outline-color': mapStyleConfig.colors.vegetation,
            }
        }
    },
];
