import { Action, createReducer, on } from '@ngrx/store';
import * as SyncActions from './synchronisation.actions';
import { SynchronisationState } from './synchronisation.state';

export const synchronisationInitialState: SynchronisationState = {
    syncErrors: null,
    syncErrorsLoading: false,
    syncErrorsSuccess: false,
    syncErrorsError: null,
    requests: null,
    getRequestsSuccess: false,
    getRequestsError: null,
    uploadRequests: null,
    uploadRequestsSuccess: false,
    uploadRequestsError: null,
    deleteRequests: false,
    deleteRequestsSuccess: false,
    deleteRequestsError: null
};

export const synchronisationReducer = createReducer<SynchronisationState>(
    synchronisationInitialState,
    on(SyncActions.getSyncErrors, (state) => {
        return {
            ...state,
            syncErrors: null,
            syncErrorsLoading: true,
            syncErrorsSuccess: false,
            syncErrorsError: null,
        };
    }),
    on(SyncActions.getSyncErrorsSuccess, (state, action) => {
        return {
            ...state,
            syncErrors: action.syncErrors,
            syncErrorsLoading: false,
            syncErrorsSuccess: true,
            syncErrorsError: null,
        };
    }),
    on(SyncActions.getSyncErrorsFailure, (state, action) => {
        return {
            ...state,
            syncErrors: null,
            syncErrorsLoading: false,
            syncErrorsSuccess: false,
            syncErrorsError: action.error,
        };
    }),
    on(SyncActions.addSyncErrors, (state, action) => {
        return {
            ...state,
            syncErrors: action.syncErrors,
            syncErrorsLoading: true,
            syncErrorsSuccess: false,
            syncErrorsError: null
        };
    }),
    on(SyncActions.addSyncErrorsSuccess, (state) => {
        return {
            ...state,
            syncErrorsLoading: false,
            syncErrorsSuccess: true,
            syncErrorsError: null
        };
    }),
    on(SyncActions.addSyncErrorsFailure, (state, action) => {
        return {
            ...state,
            syncErrors: null,
            syncErrorsLoading: false,
            syncErrorsSuccess: false,
            syncErrorsError: action.error
        };
    }),
    on(SyncActions.deleteSyncErrors, (state) => {
        return {
            ...state,
            syncErrors: null,
            syncErrorsLoading: true,
            syncErrorsSuccess: false,
            syncErrorsError: null,
        };
    }),
    on(SyncActions.deleteSyncErrorsSuccess, (state, action) => {
        return {
            ...state,
            syncErrors: null,
            syncErrorsLoading: false,
            syncErrorsSuccess: action.success,
            syncErrorsError: null,
        };
    }),
    on(SyncActions.deleteSyncErrorsFailure, (state, action) => {
        return {
            ...state,
            syncErrors: null,
            syncErrorsLoading: false,
            syncErrorsSuccess: false,
            syncErrorsError: action.error,
        };
    }),
    on(SyncActions.getRequests, (state) => {
        return {
            ...state,
            requests: null,
            getRequestsSuccess: false,
            getRequestsError: null,
        };
    }),
    on(SyncActions.getRequestsSuccess, (state, action) => {
        return {
            ...state,
            requests: action.requests,
            getRequestsSuccess: true,
            getRequestsError: null,
        };
    }),
    on(SyncActions.getRequestsFailure, (state, action) => {
        return {
            ...state,
            requests: null,
            getRequestsSuccess: false,
            getRequestsError: action.error,
        };
    }),
    on(SyncActions.uploadRequests, (state) => {
        return {
            ...state,
            uploadRequests: null,
            uploadRequestsSuccess: false,
            uploadRequestsError: null,
        };
    }),
    on(SyncActions.uploadRequestsSuccess, (state, action) => {
        return {
            ...state,
            uploadRequests: action.request,
            uploadRequestsSuccess: true,
            uploadRequestsError: null,
        };
    }),
    on(SyncActions.uploadRequestsFailure, (state, action) => {
        return {
            ...state,
            uploadRequests: null,
            uploadRequestsSuccess: false,
            uploadRequestsError: action.error,
        };
    }),
    on(SyncActions.deleteRequests, (state) => {
        return {
            ...state,
            deleteRequests: null,
            deleteRequestsSuccess: false,
            deleteRequestsError: null,
        };
    }),
    on(SyncActions.deleteRequestsSuccess, (state, action) => {
        return {
            ...state,
            deleteRequests: action.success,
            deleteRequestsSuccess: true,
            deleteRequestsError: null,
        };
    }),
    on(SyncActions.deleteRequestsFailure, (state, action) => {
        return {
            ...state,
            deleteRequests: null,
            deleteRequestsSuccess: false,
            deleteRequestsError: action.error,
        };
    }),
);

export function reducer(state: any, action: Action) {
    return synchronisationReducer(state, action);
}
