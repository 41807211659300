<div class="pointInspectionPopUp">
    <app-pop-up-info-header [mainTitle]="codeBarres" [subTitle]="subTitle" [logoHighQuantity]="numberOfPhotos"
        [logoLowQuantity]="anomalies.length" (closed)="close()">
    </app-pop-up-info-header>
    <div class="pointInspectionPopUp__content">
        <div class="content__scrolable">
            <div *ngIf="projet$ | async" class="scrolable__single">
                <span class="single__value">
                    {{currentProjet.nom}}
                </span>
            </div>
            <app-pop-up-info-properties [datas]="data" [verticalProperties]="verticalProperties">
            </app-pop-up-info-properties>
            <div *ngIf="photosPointInspection.length > 0 || anomalies.length > 0" class="scrolable__accordion">
                <ng-container *ngIf="photosPointInspection.length > 0">
                    <pop-up-info-accordion-component [title]="anomalies.length > 1 ? 'Photos' : 'Photo'"
                        [logo]="'fa-solid fa-camera'" [isAlwaysOpen]="true" [quantity]="photosPointInspection.length"
                        [minHeight]="true">
                        <app-photo-container [photos]="imagesPointInspection"></app-photo-container>
                    </pop-up-info-accordion-component>
                </ng-container>
                <ng-container *ngIf="anomalies.length > 0">
                    <pop-up-info-accordion-component [title]="anomalies.length > 1 ? 'Anomalies' : 'Anomalie'"
                        [logo]="'fa-solid fa-bug'" [logoColor]="'#ff0000'" [isAlwaysOpen]="true"
                        [quantity]="anomalies.length">
                        <app-pop-up-info-anomalie [anomalies]="anomalies"></app-pop-up-info-anomalie>
                    </pop-up-info-accordion-component>
                </ng-container>
                <ng-container *ngIf="anomalies.length > 0">
                    <pop-up-info-accordion-component [title]="pointInspectionProperties.equipementsMineurs"
                        [logo]="'fa-solid fa-screwdriver'">
                        <app-pop-up-info-equipements-mineurs [equipementsMineurs]="equipementsMineurs">
                        </app-pop-up-info-equipements-mineurs>
                    </pop-up-info-accordion-component>
                </ng-container>
            </div>
        </div>
        <div class="content__static" *ngIf="showEditInspection">
            <button class="p-element p-button-primary p-button p-component" (click)="editInspection()"
                [disabled]="isOffline">
                <i class="fa-solid fa-pen"></i>
                <span>Modifier inspection</span>
            </button>
            <div class="offlineMessage" *ngIf="isOffline">
                {{offlineMessage}}
            </div>
        </div>
    </div>
</div>