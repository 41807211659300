import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { AuditModule } from '../audit/audit.module';
import { AnomalieModule } from '../anomalie/anomalie.module';
import { ProjetListDialogComponent } from './components/projet-list-dialog/projet-list-dialog.component';
import { CompleteProjetDialogComponent } from '../inspection/components/complete-projet-dialog/complete-projet-dialog.component';
import { AssignProjetDialogComponent } from './components/assign-projet-dialog/assign-projet-dialog.component';
import { RapportCreationAvisDialogComponent } from './components/rapports/rapport-creation-avis-dialog/rapport-creation-avis-dialog.component';
import { projetReducers, projetsStoreEffects } from './store';
import { SharedModule } from '../../shared/shared.module';
import { RejeterProjetDialogComponent } from './components/rejeter-projet-dialog/rejeter-projet-dialog.component';

@NgModule({
    declarations: [
        AssignProjetDialogComponent,
        CompleteProjetDialogComponent,
        ProjetListDialogComponent,
        RapportCreationAvisDialogComponent,
        RejeterProjetDialogComponent,
    ],
    imports: [
        SharedModule,
        AuditModule,
        AnomalieModule,
        StoreModule.forFeature('projet', projetReducers),
        EffectsModule.forFeature([...projetsStoreEffects])
    ],
    exports: [
        ProjetListDialogComponent
    ]
})
export class ProjetModule { }
