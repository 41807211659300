
import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';

export const poteauSelected: AnyLayer = {
    id: MapLayersSources.POTEAU_SELECTED,
    type: 'circle',
    source: MapLayersSources.POTEAU_SELECTED,
    minzoom: mapStyleConfig.poteau.minZoom,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'circle-color': [
            'case',
            ['boolean', ['feature-state', 'click'], false],
            mapStyleConfig.colors.gris, mapStyleConfig.colors.transparent],
        'circle-stroke-color': [
            'case',
            ['boolean', ['feature-state', 'click'], false],
            mapStyleConfig.colors.gris, mapStyleConfig.colors.poteauSelected],
        'circle-radius': [
            'case',
            ['boolean', ['feature-state', 'click'], false],
            mapStyleConfig.poteau.circleWidhtClick, mapStyleConfig.poteau.circleWidth + 1],
        'circle-stroke-width': [
            'case',
            ['boolean', ['feature-state', 'click'], false],
            mapStyleConfig.poteau.circleStrokeClick, mapStyleConfig.poteau.circleStroke + 4],
        /* eslint-enable @typescript-eslint/naming-convention */
    }
};
