import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PhotosState } from './photos.state';

const getPhotosFeatureState = createFeatureSelector<PhotosState>('photos');

// Points Inspection
export const getCachePointInspectionPhotosSuccess = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachePointInspectionPhotosSuccess
);
export const getCachePointInspectionPhotosLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachePointInspectionPhotosLoading
);
export const getCachingPointInspectionPhotosProgression = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachingPointInspectionPhotosProgression
);
export const getNonCachedPointInspectionPhotos = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.nonCachedPointInspectionPhotos
);
export const getPointInspectionPhotos = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.pointInspectionPhotos
);
export const getPointInspectionPhotosLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.loadPointInspectionPhotosLoading
);

// Anomalies
export const getCacheAnomaliesPhotosSuccess = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cacheAnomaliesPhotosSuccess
);
export const getCacheAnomaliesPhotosLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cacheAnomaliesPhotosLoading
);
export const getCachingAnomaliesPhotosProgression = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachingAnomaliesPhotosProgression
);
export const getNonCachedAnomaliesPhotos = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.nonCachedAnomaliesPhotos
);
export const getAnomaliesPhotos = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.anomaliesPhotos
);
export const getAnomaliesPhotosLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.loadAnomaliesPhotosLoading
);

// Points Audit
export const getCachePointAuditPhotosSuccess = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachePointAuditPhotosSuccess
);
export const getCachePointAuditPhotosLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachePointAuditPhotosLoading
);
export const getCachingPointAuditPhotosProgression = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachingPointAuditPhotosProgression
);
export const getNonCachedPointAuditPhotos = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.nonCachedPointAuditPhotos
);
export const getPointAuditPhotos = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.pointAuditPhotos
);
export const getPointAuditPhotosLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.loadPointAuditPhotosLoading
);

// Anomalies audit
export const getCacheAnomaliesAuditSuccess = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cacheAnomaliesAuditPhotosSuccess
);
export const getCacheAnomaliesAuditLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cacheAnomaliesAuditPhotosLoading
);
export const getCachingAnomaliesAuditProgression = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachingAnomaliesAuditPhotosProgression
);
export const getNonCachedAnomaliesAuditPhotos = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.nonCachedAnomaliesAuditPhotos
);
export const getAnomaliesAuditPhotos = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.anomaliesAuditPhotos
);
export const getAnomaliesAuditPhotosLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.loadAnomaliesAuditPhotosLoading
);

// Autre
export const isCachingPhotosLoading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.cachePointInspectionPhotosLoading ||
        state.cacheAnomaliesPhotosLoading ||
        state.cachePointAuditPhotosLoading ||
        state.cacheAnomaliesAuditPhotosLoading
);

export const arePhotosUploading = createSelector(
    getPhotosFeatureState,
    (state: PhotosState) => state.loadUploadedPhotosLoading
);

