import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { BaseComponent } from '../../shared/components/abstract-base-component';
import { State } from './state/audit.state';
import * as AuditActions from './state/audit.actions';
import { getProjetAuditList, getProjetAuditListLoading } from './state/audit.selectors';

@Component({
    selector: 'app-audit',
    templateUrl: './audit.component.html',
    styleUrls: ['./audit.component.scss']
})
export class AuditComponent extends BaseComponent implements OnInit, OnDestroy {

    public projetAuditList$ = this.store.select(getProjetAuditList);
    public projetAuditListLoading$ = this.store.select(getProjetAuditListLoading);

    constructor(private store: Store<State>
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(AuditActions.loadProjetAuditList());
    }
}
