<div class="projectPopUp">
    <app-pop-up-info-header
        [mainTitle]="projetName"
        [subTitle]="subTitle"
        [displayLogos]="false"
        (closed)="close()">
    </app-pop-up-info-header>
    <div class="projetPopUp__content">
        <app-pop-up-info-properties
            [datas]="data"
            [verticalProperties]="verticalProperties">
        </app-pop-up-info-properties>
    </div>
</div>
