import { AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { combineLatest, takeUntil } from 'rxjs';
import { Terminal } from 'xterm';
import { FitAddon } from 'xterm-addon-fit';
import { BaseComponent } from '../components/abstract-base-component';
import { GpsPortConnectionState, GpsState } from './models';
import { GpsService } from './service/gps.service';

@Component({
    encapsulation: ViewEncapsulation.None,
    selector: 'app-gps',
    templateUrl: './gps.component.html',
    styleUrls: ['./gps.component.scss']
})
export class GpsComponent extends BaseComponent implements OnInit, AfterViewInit {
    @ViewChild('terminal', { static: true }) terminalElement: ElementRef;

    public gpsPortConnectionStateEnum = GpsPortConnectionState;
    public gpsStateEnum = GpsState;
    public gpsStatusSeverity: 'success' | 'warning' | 'danger' = 'danger';
    public gpsStatusMessage: string = 'Déconnecté';

    public gpsPortConnectionState = GpsPortConnectionState.DISCONNECTED;
    public gpsState = GpsState.OFFLINE;
    private terminal = new Terminal;
    private fitAddon = new FitAddon();

    constructor(
        private gpsService: GpsService
    ) {
        super();
    }

    ngOnInit(): void {
        this.gpsService.gpsData$
            .pipe(takeUntil(this.destroyed))
            .subscribe(data => this.terminal.write(data));

        combineLatest([
            this.gpsService.portconnectionState$,
            this.gpsService.state$
        ])
            .pipe(takeUntil(this.destroyed))
            .subscribe(([portConnectionState, state]) => {
                this.gpsPortConnectionState = portConnectionState;
                this.gpsState = state;
                if (this.gpsPortConnectionState === GpsPortConnectionState.DISCONNECTED && this.gpsState === GpsState.OFFLINE) {
                    this.gpsStatusSeverity = 'danger';
                    this.gpsStatusMessage = 'Déconnecté';
                } else if (this.gpsPortConnectionState === GpsPortConnectionState.CONNECTED && this.gpsState === GpsState.OFFLINE) {
                    this.gpsStatusSeverity = 'warning';
                    this.gpsStatusMessage = 'Aucune données';
                } else {
                    this.gpsStatusSeverity = 'success';
                    this.gpsStatusMessage = 'Connecté';
                }
            });

    }

    ngAfterViewInit(): void {
        this.terminal.loadAddon(this.fitAddon);
        this.terminal.open(this.terminalElement.nativeElement);
        this.fitAddon.fit();
    }

    public connectGps() {
        this.gpsService.connect();
    }

    public disconnectGps() {
        this.gpsService.disconnect();
    }
}
