/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PointInspectionDto } from '../models/point-inspection-dto';
import { Operation } from '../models/operation';
import { PointInspectionDtoSearchResult } from '../models/point-inspection-dto-search-result';
@Injectable({
    providedIn: 'root',
})
class InspectionService extends __BaseService {
    static readonly getApiV1InspectionsIdPath = '/api/v1/inspections/{id}';
    static readonly patchApiV1InspectionsIdPath = '/api/v1/inspections/{id}';
    static readonly putApiV1InspectionsIdPath = '/api/v1/inspections/{id}';
    static readonly getApiV1InspectionsPath = '/api/v1/inspections';
    static readonly getApiV1InspectionsInspectionIdPhotosNomPhotoThumbnailsPath = '/api/v1/inspections/{inspectionId}/photos/{nomPhoto}/thumbnails';
    static readonly getApiV1InspectionsInspectionIdPhotosIdentifiantPhotoPath = '/api/v1/inspections/{inspectionId}/photos/{identifiantPhoto}';
    static readonly postApiV1InspectionsInspectionIdPhotosPath = '/api/v1/inspections/{inspectionId}/photos';
    static readonly deleteApiV1InspectionsInspectionIdPhotosNomPhotoPath = '/api/v1/inspections/{inspectionId}/photos/{nomPhoto}';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * @param id undefined
     * @return Success
     */
    getApiV1InspectionsIdResponse(id: string): __Observable<__StrictHttpResponse<PointInspectionDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/inspections/${encodeURIComponent(String(id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PointInspectionDto>;
            })
        );
    }
    /**
     * @param id undefined
     * @return Success
     */
    getApiV1InspectionsId(id: string): __Observable<PointInspectionDto> {
        return this.getApiV1InspectionsIdResponse(id).pipe(
            __map(_r => _r.body as PointInspectionDto)
        );
    }

    /**
     * @param params The `InspectionService.PatchApiV1InspectionsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * - `action`:
     *
     * @return Success
     */
    patchApiV1InspectionsIdResponse(params: InspectionService.PatchApiV1InspectionsIdParams): __Observable<__StrictHttpResponse<PointInspectionDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        if (params.action != null) __params = __params.set('action', params.action.toString());
        let req = new HttpRequest<any>(
            'PATCH',
            this.rootUrl + `/api/v1/inspections/${encodeURIComponent(String(params.id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PointInspectionDto>;
            })
        );
    }
    /**
     * @param params The `InspectionService.PatchApiV1InspectionsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * - `action`:
     *
     * @return Success
     */
    patchApiV1InspectionsId(params: InspectionService.PatchApiV1InspectionsIdParams): __Observable<PointInspectionDto> {
        return this.patchApiV1InspectionsIdResponse(params).pipe(
            __map(_r => _r.body as PointInspectionDto)
        );
    }

    /**
     * @param params The `InspectionService.PutApiV1InspectionsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * - `action`:
     *
     * @return Success
     */
    putApiV1InspectionsIdResponse(params: InspectionService.PutApiV1InspectionsIdParams): __Observable<__StrictHttpResponse<PointInspectionDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        if (params.action != null) __params = __params.set('action', params.action.toString());
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/api/v1/inspections/${encodeURIComponent(String(params.id))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PointInspectionDto>;
            })
        );
    }
    /**
     * @param params The `InspectionService.PutApiV1InspectionsIdParams` containing the following parameters:
     *
     * - `id`:
     *
     * - `body`:
     *
     * - `action`:
     *
     * @return Success
     */
    putApiV1InspectionsId(params: InspectionService.PutApiV1InspectionsIdParams): __Observable<PointInspectionDto> {
        return this.putApiV1InspectionsIdResponse(params).pipe(
            __map(_r => _r.body as PointInspectionDto)
        );
    }

    /**
     * @param params The `InspectionService.GetApiV1InspectionsParams` containing the following parameters:
     *
     * - `ymin`:
     *
     * - `ymax`:
     *
     * - `xmin`:
     *
     * - `xmax`:
     *
     * - `projetId`:
     *
     * - `poteauId`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * - `fields`:
     *
     * - `expand`:
     *
     * @return Success
     */
    getApiV1InspectionsResponse(params: InspectionService.GetApiV1InspectionsParams): __Observable<__StrictHttpResponse<PointInspectionDtoSearchResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.ymin != null) __params = __params.set('ymin', params.ymin.toString());
        if (params.ymax != null) __params = __params.set('ymax', params.ymax.toString());
        if (params.xmin != null) __params = __params.set('xmin', params.xmin.toString());
        if (params.xmax != null) __params = __params.set('xmax', params.xmax.toString());
        if (params.projetId != null) __params = __params.set('projetId', params.projetId.toString());
        if (params.poteauId != null) __params = __params.set('poteauId', params.poteauId.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
        (params.fields || []).forEach(val => { if (val != null) __params = __params.append('fields', val.toString()) });
        if (params.expand != null) __params = __params.set('expand', params.expand.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/inspections`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PointInspectionDtoSearchResult>;
            })
        );
    }
    /**
     * @param params The `InspectionService.GetApiV1InspectionsParams` containing the following parameters:
     *
     * - `ymin`:
     *
     * - `ymax`:
     *
     * - `xmin`:
     *
     * - `xmax`:
     *
     * - `projetId`:
     *
     * - `poteauId`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * - `fields`:
     *
     * - `expand`:
     *
     * @return Success
     */
    getApiV1Inspections(params: InspectionService.GetApiV1InspectionsParams): __Observable<PointInspectionDtoSearchResult> {
        return this.getApiV1InspectionsResponse(params).pipe(
            __map(_r => _r.body as PointInspectionDtoSearchResult)
        );
    }

    /**
     * @param params The `InspectionService.GetApiV1InspectionsInspectionIdPhotosNomPhotoThumbnailsParams` containing the following parameters:
     *
     * - `nomPhoto`:
     *
     * - `inspectionId`:
     */
    getApiV1InspectionsInspectionIdPhotosNomPhotoThumbnailsResponse(params: InspectionService.GetApiV1InspectionsInspectionIdPhotosNomPhotoThumbnailsParams): __Observable<__StrictHttpResponse<ArrayBuffer>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/inspections/${encodeURIComponent(String(params.inspectionId))}/photos/${encodeURIComponent(String(params.nomPhoto))}/thumbnails`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'arraybuffer'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<ArrayBuffer>;
            })
        );
    }
    /**
     * @param params The `InspectionService.GetApiV1InspectionsInspectionIdPhotosNomPhotoThumbnailsParams` containing the following parameters:
     *
     * - `nomPhoto`:
     *
     * - `inspectionId`:
     */
    getApiV1InspectionsInspectionIdPhotosNomPhotoThumbnails(params: InspectionService.GetApiV1InspectionsInspectionIdPhotosNomPhotoThumbnailsParams): __Observable<ArrayBuffer> {
        return this.getApiV1InspectionsInspectionIdPhotosNomPhotoThumbnailsResponse(params).pipe(
            __map(_r => _r.body as ArrayBuffer)
        );
    }

    /**
     * @param params The `InspectionService.GetApiV1InspectionsInspectionIdPhotosIdentifiantPhotoParams` containing the following parameters:
     *
     * - `inspectionId`:
     *
     * - `identifiantPhoto`:
     *
     * - `taille`:
     */
    getApiV1InspectionsInspectionIdPhotosIdentifiantPhotoResponse(params: InspectionService.GetApiV1InspectionsInspectionIdPhotosIdentifiantPhotoParams): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        if (params.taille != null) __params = __params.set('taille', params.taille.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/inspections/${encodeURIComponent(String(params.inspectionId))}/photos/${encodeURIComponent(String(params.identifiantPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'blob'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            })
        );
    }
    /**
     * @param params The `InspectionService.GetApiV1InspectionsInspectionIdPhotosIdentifiantPhotoParams` containing the following parameters:
     *
     * - `inspectionId`:
     *
     * - `identifiantPhoto`:
     *
     * - `taille`:
     */
    getApiV1InspectionsInspectionIdPhotosIdentifiantPhoto(params: InspectionService.GetApiV1InspectionsInspectionIdPhotosIdentifiantPhotoParams): __Observable<Blob> {
        return this.getApiV1InspectionsInspectionIdPhotosIdentifiantPhotoResponse(params).pipe(
            __map(_r => _r.body as Blob)
        );
    }

    /**
     * @param params The `InspectionService.PostApiV1InspectionsInspectionIdPhotosParams` containing the following parameters:
     *
     * - `inspectionId`:
     *
     * - `Photo`:
     *
     * - `NomOriginal`:
     *
     * - `Nom`:
     *
     * - `Id`:
     */
    postApiV1InspectionsInspectionIdPhotosResponse(params: InspectionService.PostApiV1InspectionsInspectionIdPhotosParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.Photo != null) { __formData.append('Photo', params.Photo as string | Blob); }
        if (params.NomOriginal != null) { __formData.append('NomOriginal', params.NomOriginal as string | Blob); }
        if (params.Nom != null) { __formData.append('Nom', params.Nom as string | Blob); }
        if (params.Id != null) { __formData.append('Id', params.Id as string | Blob); }
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/inspections/${encodeURIComponent(String(params.inspectionId))}/photos`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `InspectionService.PostApiV1InspectionsInspectionIdPhotosParams` containing the following parameters:
     *
     * - `inspectionId`:
     *
     * - `Photo`:
     *
     * - `NomOriginal`:
     *
     * - `Nom`:
     *
     * - `Id`:
     */
    postApiV1InspectionsInspectionIdPhotos(params: InspectionService.PostApiV1InspectionsInspectionIdPhotosParams): __Observable<null> {
        return this.postApiV1InspectionsInspectionIdPhotosResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `InspectionService.DeleteApiV1InspectionsInspectionIdPhotosNomPhotoParams` containing the following parameters:
     *
     * - `nomPhoto`:
     *
     * - `inspectionId`:
     */
    deleteApiV1InspectionsInspectionIdPhotosNomPhotoResponse(params: InspectionService.DeleteApiV1InspectionsInspectionIdPhotosNomPhotoParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/api/v1/inspections/${encodeURIComponent(String(params.inspectionId))}/photos/${encodeURIComponent(String(params.nomPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `InspectionService.DeleteApiV1InspectionsInspectionIdPhotosNomPhotoParams` containing the following parameters:
     *
     * - `nomPhoto`:
     *
     * - `inspectionId`:
     */
    deleteApiV1InspectionsInspectionIdPhotosNomPhoto(params: InspectionService.DeleteApiV1InspectionsInspectionIdPhotosNomPhotoParams): __Observable<null> {
        return this.deleteApiV1InspectionsInspectionIdPhotosNomPhotoResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }
}

module InspectionService {

    /**
     * Parameters for patchApiV1InspectionsId
     */
    export interface PatchApiV1InspectionsIdParams {
        id: string;
        body?: Array<Operation>;
        action?: any;
    }

    /**
     * Parameters for putApiV1InspectionsId
     */
    export interface PutApiV1InspectionsIdParams {
        id: string;
        body?: PointInspectionDto;
        action?: any;
    }

    /**
     * Parameters for getApiV1Inspections
     */
    export interface GetApiV1InspectionsParams {
        ymin?: number;
        ymax?: number;
        xmin?: number;
        xmax?: number;
        projetId?: string;
        poteauId?: string;
        pageSize?: number;
        pageNumber?: number;
        fields?: Array<string>;
        expand?: boolean;
    }

    /**
     * Parameters for getApiV1InspectionsInspectionIdPhotosNomPhotoThumbnails
     */
    export interface GetApiV1InspectionsInspectionIdPhotosNomPhotoThumbnailsParams {
        nomPhoto: string;
        inspectionId: string;
    }

    /**
     * Parameters for getApiV1InspectionsInspectionIdPhotosIdentifiantPhoto
     */
    export interface GetApiV1InspectionsInspectionIdPhotosIdentifiantPhotoParams {
        inspectionId: string;
        identifiantPhoto: string;
        taille?: any;
    }

    /**
     * Parameters for postApiV1InspectionsInspectionIdPhotos
     */
    export interface PostApiV1InspectionsInspectionIdPhotosParams {
        inspectionId: string;
        Photo?: Blob;
        NomOriginal?: string;
        Nom?: string;
        Id?: string;
    }

    /**
     * Parameters for deleteApiV1InspectionsInspectionIdPhotosNomPhoto
     */
    export interface DeleteApiV1InspectionsInspectionIdPhotosNomPhotoParams {
        nomPhoto: string;
        inspectionId: string;
    }
}

export { InspectionService }
