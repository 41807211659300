import { SelectItem } from 'primeng/api';
import { Priorite, PrioriteValue } from '../../../shared/enums/priorite.enum';

export const prioriteOptions: SelectItem[] = [
    { label: PrioriteValue.anomaliePrioriteC, value: Priorite.anomaliePrioriteC },
    { label: PrioriteValue.anomaliePrioriteE, value: Priorite.anomaliePrioriteE },
    { label: PrioriteValue.anomaliePrioriteG, value: Priorite.anomaliePrioriteG },
    { label: PrioriteValue.anomaliePrioriteK, value: Priorite.anomaliePrioriteK },
    { label: PrioriteValue.anomaliePrioriteM, value: Priorite.anomaliePrioriteM },
    { label: PrioriteValue.anomaliePrioriteN, value: Priorite.anomaliePrioriteN }
];

