import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from './api.service';

@Injectable({
  providedIn: 'root'
})
export class ImageService {

  constructor(private readonly apiService: ApiService) { }

  public getImages(url: string): Observable<ArrayBuffer> {
    return this.apiService.getWithResponseType<ArrayBuffer, { responseType: 'arraybuffer'}>(url, { responseType: 'arraybuffer'});
  }
}
