export enum EssenceValue {
    au = 'AU',
    df = 'DF',
    ec = 'EC',
    ic = 'IC',
    jp = 'JP',
    lp = 'LP',
    rp = 'RP',
    scp = 'SCP',
    sp = 'SP',
    syp = 'SYP',
    wc = 'WC',
    wh = 'WH',
    wl = 'WL',
    wp = 'WP',
    yc = 'YC',
    inconnu = 'Inconnu',
}

export enum Essence {
    au = 'au',
    df = 'df',
    ec = 'ec',
    ic = 'ic',
    jp = 'jp',
    lp = 'lp',
    rp = 'rp',
    scp = 'scp',
    sp = 'sp',
    syp = 'syp',
    wc = 'wc',
    wh = 'wh',
    wl = 'wl',
    wp = 'wp',
    yc = 'yc',
    inconnu = 'inconnu',
}

export const essenceListe = Object.keys(Essence).map(key => ({ champ: key, valeur: EssenceValue[key] }));

export const essenceMap = new Map<Essence, string>([
    [Essence.au, EssenceValue.au],
    [Essence.df, EssenceValue.df],
    [Essence.ec, EssenceValue.ec],
    [Essence.ic, EssenceValue.ic],
    [Essence.jp, EssenceValue.jp],
    [Essence.lp, EssenceValue.lp],
    [Essence.rp, EssenceValue.rp],
    [Essence.scp, EssenceValue.scp],
    [Essence.sp, EssenceValue.sp],
    [Essence.syp, EssenceValue.syp],
    [Essence.wc, EssenceValue.wc],
    [Essence.wh, EssenceValue.wh],
    [Essence.wl, EssenceValue.wl],
    [Essence.wp, EssenceValue.wp],
    [Essence.yc, EssenceValue.yc],
    [Essence.inconnu, EssenceValue.inconnu],
]);
