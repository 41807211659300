<p-dialog header="Veuillez assigner le projet d'audit" [(visible)]="visible" [modal]=true [draggable]=false
    (onHide)="closeAssignDialog()">
    <div class="p-d-flex p-flex-column p-jc-center">
        <div class="p-mb-2 p-ai-end">Choisissez l'auditeur qui réalisera le projet :
            <p-dropdown [options]="$any(auditeursList$ | async)" placeholder="Choisir un élément"
                [(ngModel)]="auditeurSelected" appendTo="body"></p-dropdown>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
            (click)="closeAssignDialog()"></button>
        <button [disabled]="!auditeurSelected" class="p-button-text" pButton pRipple icon="pi pi-check"
            label="Enregistrer" (click)="onAssigne()"></button>
    </ng-template>
</p-dialog>
