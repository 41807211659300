<div class="accordion" [ngClass]="isOpen ? 'open' : ''">
    <div class="accordion__header" (click)="toggleHeader()" [class]="{borders: displayBorders, borderClose: !isOpen && displayBorders, isNotAlwaysOpen: displayChevrons}" >
        <div class="header__start">
            <div *ngIf="displayChevrons" class="header__chevron">
                <i *ngIf="isOpen" class="fa-solid fa-chevron-up"></i>
                <i *ngIf="!isOpen" class="fa-solid fa-chevron-down"></i>
            </div>
            <div *ngIf="logo !== ''" class="header__logo" [ngStyle]="{'color': logoColor}">
                <i class="{{logo}}"></i>
            </div>
            <div class="start__title" [ngStyle]="{'color': titleColor}">
                <span pTooltip="{{titleTooltip}}">{{title}}</span>
                <span *ngIf="quantity">({{quantity}})</span>
            </div>
        </div>
        <div *ngIf="logoEnd !== ''" class="header__end" [ngStyle]="{'color': quantityEnd > 0 ? logoEndColor : disabledColor}">
            <span *ngIf="quantityEnd && quantityEnd > 0">{{quantityEnd}}</span>
            <i class="{{logoEnd}}"></i>
        </div>
    </div>
    <div *ngIf="displayContent" class="accordion__content" [class]="{contentBorders: displayBorders, contentPadding: !displayBorders, minContent: minHeight}">
        <ng-content></ng-content>
    </div>
</div>
