import { Injectable } from '@angular/core';
import { ImageBase64 } from '../shared/models/image-base64.model';
import { MapService } from '../map/services/map.service';
import { Photo } from '../shared/models/photo.model';
import { PhotoContainer } from '../shared/models/photo-container.model';
import { Observable } from 'rxjs';
import { PhotoEntity } from '../core/api/client/models';
@Injectable()
export class PhotoService {
    constructor(private mapServices: MapService) {
    }
    public cleanImage(file: File, maxWidth = 3000, maxHeight = 3000): Promise<string> {
        if (!file || !file.type.match('image.*')) {
            return Promise.reject(`Ce type de fichier n'est pas une image`);
        }
        const imageUrl = URL.createObjectURL(file);
        return this.createImageElement(imageUrl)
            .then(image => {
                const outputSize = this.calculateOutputSize(image.width, image.height, maxWidth, maxHeight);
                const canvas: HTMLCanvasElement = this.redrawImage(image, outputSize);
                return canvas.toDataURL(file.type);
            });
    }
    private calculateOutputSize(width: number, height: number, maxWidth: number, maxHeight: number) {
        const scale = (value: number, ratio: number) => Math.floor(value * ratio);
        let newWidth;
        let newHeight;
        if (width > height) {
            newWidth = Math.min(maxWidth, width);
            const ratio = newWidth / width;
            newHeight = scale(height, ratio);
        } else {
            newHeight = Math.min(maxHeight, height);
            const ratio = newHeight / height;
            newWidth = scale(width, ratio);
        }
        return this.resizeByRatio(newWidth, newHeight);
    }
    // PDL-3916 : Correction temporaire pour redimensionner TOUTES les
    //   photos à 95% de la valeur donnée.
    private resizeByRatio(width: number, height: number) {
        const ratio = 0.95;
        return { width: (width * ratio), height: (height * ratio) };
    }
    private redrawImage(image: any, outputSize: any): HTMLCanvasElement {
        const canvas = document.createElement('canvas');
        canvas.height = outputSize.height;
        canvas.width = outputSize.width;
        const context = canvas.getContext('2d');
        context!.drawImage(image, 0, 0, outputSize.width, outputSize.height);
        return canvas;
    }
    private createImageElement(url: string): Promise<HTMLImageElement> {
        return new Promise(success => {
            const image = new Image();
            image.addEventListener('load', () => success(image));
            image.src = url;
        });
    }
    public generateImageFile(image: ImageBase64, file: File): File {
        const imageBlob = this.dataURItoBlob(image.url);
        const imageFile = new File([imageBlob], file.name, { type: file.type });
        return imageFile;
    }

    public base64ToFile(base64: string, filename: string): File {
        const type = base64?.split(';')[0].split(':')[1];
        const blob = this.dataURItoBlob(base64);
        return new File([blob], filename, { type });
    }

    private dataURItoBlob(dataURI: any): Blob {
        const splitDataURI = dataURI?.split(',');
        if (splitDataURI) {
            const byteString = splitDataURI[0].indexOf('base64') >= 0 ? window.atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
            const mimeString = splitDataURI[0].split(':')[1].split(';')[0];
            const ia = new Uint8Array(new ArrayBuffer(byteString.length));
            for (let i = 0; i < byteString.length; i++) {
                ia[i] = byteString.charCodeAt(i);
            }
            return new Blob([ia], { type: mimeString });
        } else {
            return new Blob();
        }

    }

    public convertToPhotoContainer(photos: Photo[]): PhotoContainer[] {
        const converted = photos.map((photo, index) => {
            const finalIndex = index + 1;
            const visibleTitre = photo.title + '_' + finalIndex;
            return { photo, index: finalIndex, visibleTitre };
        });
        return converted;
    }
    public setCarousselPhotos(photos: PhotoContainer[]) {
        this.mapServices.photoListe = photos;
        this.mapServices.nombrePhoto.next(photos.length);
    }
    public convertToPhotoHtml(photos: PhotoContainer[]): HTMLImageElement[] {
        const htmlPhotos = photos.map(({ photo, index, visibleTitre }) => {
            return this.mapServices.creationImage(photo, index, visibleTitre);
        });
        return htmlPhotos;
    }

    public fileToBase64(file: File): Observable<string> {
        return new Observable((observer) => {
            const reader = new FileReader();
            reader.onloadend = () => {
                observer.next(reader.result as string);
                observer.complete();
            };
            reader.onerror = (error) => observer.error(error);
            reader.readAsDataURL(file);
        });
    }

    public removeDataFromPhotoArray(photos: PhotoEntity[]): PhotoEntity[] {
        return photos.map((photo: PhotoEntity) => {
            if (photo.data) {
                const { data, ...photoWithoutData } = photo;
                return photoWithoutData;
            }
            return photo;
        });
    }

    public blobToFile(blob: Blob, name: string) {
        return new File([blob], name);
    }

    public removePhoto(photosWithData: PhotoEntity[], photoId: string): PhotoEntity[] {
        const index = photosWithData.findIndex(photo => photo.id === photoId);

        if (index > -1) {
            photosWithData.splice(index, 1);
            return [...photosWithData];
        }

        return photosWithData;
    }
}
