import { Component, Input } from '@angular/core';
import { AnomalieBaseDto } from '../../../../../core/api/client/models';
import { Photo } from '../../../../../shared/models/photo.model';
import { AnomalieProperties } from '../../../../../features/anomalie/models/anomalie-properties.enum';
import { prioriteMap } from '../../../../../shared/enums/priorite.enum';
import { PhotoService } from '../../../../../services/photo.service';
import { StatutAnomalie } from '../../../../../features/anomalie/models/statut-anomalie.enum';
import { containsSpace } from '../../../../../shared/utils';

@Component({
    selector: 'app-pop-up-info-anomalie',
    templateUrl: './pop-up-info-anomalie.component.html',
    styleUrls: ['./pop-up-info-anomalie.component.scss']
})
export class PopUpInfoAnomalieComponent {
    public imagesAnomalies: { [name: string]: HTMLImageElement[] } = {};
    public verticalProperties: string[] = [];
    public statutAnomalieLabel = StatutAnomalie;
    public get anomalieProperties() {
        return AnomalieProperties;
    }

    public data: { [name: string]: any } = [];
    public numberOfPhotos: { [name: string]: any } = [];
    public anomalieKeyHaveSpace = false;

    private _anomalies: AnomalieBaseDto[] = [];
    @Input() set anomalies(value: AnomalieBaseDto[]) {
        this.anomalieKeyHaveSpace = containsSpace(value);
        this._anomalies = value || [];
        this.setUpAnomalies();
    }

    get anomalies() {
        return this._anomalies;
    }

    constructor(private photoService: PhotoService) { }

    private getAnomaliesPhotos() {
        this.anomalies.forEach((anomalie, index) => {
            const anomeliesPhotos = this.photoService.convertToPhotoContainer(anomalie.photos as Photo[]);
            const htmlPhotos = this.photoService.convertToPhotoHtml(anomeliesPhotos);
            this.numberOfPhotos[index] = anomalie.photos.length;
            this.imagesAnomalies[this.anomalieKeyHaveSpace ? anomalie[AnomalieProperties.ANOMALIE_ID] : anomalie.id] = htmlPhotos;
        });
    }

    private getProperties(): string[] {
        this.verticalProperties = [
            AnomalieProperties.CAUSE,
        ];

        const propertiesToExclude = [
            AnomalieProperties.PHOTOS,
            AnomalieProperties.ELEMENT,
            AnomalieProperties.STATUT,
            AnomalieProperties.ANOMALIE_ID
        ];
        const properties: string[] = [];
        Object.keys(AnomalieProperties).forEach((key: string) => {
            if (!propertiesToExclude.includes(AnomalieProperties[key])) {
                properties.push(AnomalieProperties[key]);
            }
        });

        return properties;
    }

    private addToDisplay(property: string, value: any): boolean {
        if (property === AnomalieProperties.REMARQUE && (value === undefined || value === null || value === 'Inconnu' || value === 'inconnu' || value === '')) {
            return false;
        }
        return true;
    }

    private mappingData(property: any, value: any): string {
        switch (property) {
            case AnomalieProperties.PRIORITE: return prioriteMap.get(value);
            default: return value;
        }
    }

    private prepareData() {
        const anomalieData: any[] = [];
        this.anomalies.forEach((anomalie: AnomalieBaseDto, index: number) => {
            const curData: { [name: string]: any } = [];
            this.getProperties().forEach(valueKey => {
                if (this.addToDisplay(valueKey, anomalie[valueKey])) {
                    curData[valueKey] = this.mappingData(valueKey, anomalie[valueKey]);
                }
            });
            anomalieData[index] = curData;
        });
        this.data = anomalieData;
    }

    private setUpAnomalies() {
        this.prepareData();
        this.getAnomaliesPhotos();
    }

}
