import { createAction, props } from '@ngrx/store';
import { ProjetAuditHistory } from '../models/projet-audit-history.model';
import { BoundingBoxInfo } from '../../../shared/models/bounding-box-info.model';
import {
    PointAuditDto,
    ProjetAuditDto,
    PatchProjetAuditDto,
    CreatedProjetAuditDto,
    PointInspectionDto,
    PhotoEntity
} from '../../../core/api/client/models';

export enum AuditActionTypes {
    LOAD_PROJET_AUDIT_LIST = '[Audit] Load Projet Audit List',
    LOAD_PROJET_AUDIT_LIST_SUCCESS = '[Audit] Load Projet Audit List Success',
    LOAD_PROJET_AUDIT_LIST_ERROR = '[Audit] Load Projet Audit List Error',
    LOAD_PROJET_AUDIT_HISTORY = '[Audit] Load Projet Audit History',
    LOAD_PROJET_AUDIT_HISTORY_SUCCESS = '[Audit] Load Projet Audit History Success',
    LOAD_PROJET_AUDIT_HISTORY_ERROR = '[Audit] Load Projet Audit History Error',
    START_CREATE_PROJET_AUDIT = '[Audit] Start Create Projet Audit',
    START_CREATE_PROJET_AUDIT_SUCCESS = '[Audit] Start Create Projet Audit Success',
    START_CREATE_PROJET_AUDIT_ERROR = '[Audit] Start Create Projet Audit Error',
    CREATE_PROJET_AUDIT_SUCCESS = '[Audit] Create Projet Audit Success',
    CREATE_PROJET_AUDIT_ERROR = '[Audit] Create Projet Audit Error',
    ASSIGN_PROJET_AUDIT = '[Audit] Assign Projet Audit',
    ASSIGN_PROJET_AUDIT_SUCCESS = '[Audit] Assign Projet Audit Success',
    ASSIGN_PROJET_AUDIT_ERROR = '[Audit] Assign Projet Audit Error',
    ACTIVATE_PROJET_AUDIT = '[Audit] Activate Projet Audit',
    ACTIVATE_PROJET_AUDIT_SUCCESS = '[Audit] Activate Projet Audit Success',
    ACTIVATE_PROJET_AUDIT_ERROR = '[Audit] Activate Projet Audit Error',
    SET_CURRENT_ACTIVE_PROJET_AUDIT = '[Audit] Set Current Active Projet Audit',
    SET_CURRENT_ACTIVE_PROJET_AUDIT_BY_ID = '[Audit] Set Current Active Projet Audit By Id',
    CANCEL_PROJET_AUDIT = '[Audit] Cancel Projet Audit',
    CANCEL_PROJET_AUDIT_SUCCESS = '[Audit] Cancel Projet Audit Success',
    CANCEL_PROJET_AUDIT_ERROR = '[Audit] Cancel Projet Audit Error',
    REJECT_PROJET_AUDIT = '[Audit] Reject Projet Audit',
    REJECT_PROJET_AUDIT_SUCCESS = '[Audit] Reject Projet Audit Success',
    REJECT_PROJET_AUDIT_ERROR = '[Audit] Reject Projet Audit Error',
    APPROVE_PROJET_AUDIT = '[Audit] Approve Projet Audit',
    APPROVE_PROJET_AUDIT_SUCCESS = '[Audit] Approve Projet Audit Success',
    APPROVE_PROJET_AUDIT_ERROR = '[Audit] Approve Projet Audit Error',
    COMPLETE_PROJET_AUDIT = '[Audit] Complete Projet Audit',
    COMPLETE_PROJET_AUDIT_SUCCESS = '[Audit] Complete Projet Audit Success',
    COMPLETE_PROJET_AUDIT_ERROR = '[Audit] Complete Projet Audit Error',
    CLEAR_PROJET_AUDIT_DATA = '[Audit] Clear Projet Audit Data',
    UPDATE_POINT_AUDIT = '[Audit] Update Point Audit',
    UPDATE_POINT_AUDIT_SUCCESS = '[Audit] Update Point Audit Success',
    UPDATE_POINT_AUDIT_ERROR = '[Audit] Update Point Audit Error',
    UPDATE_PROJET_AUDIT_TO_INDEXEDDB = '[Audit] Update Projet Audit To IndexedDb',
    LOAD_POINTS_AUDIT_BBOX = '[Audit] Get Points Audit Bbox',
    REFRESH_POINTS_AUDIT_BBOX = '[Audit] Refresh Points Audit Bbox',
    LOAD_POINTS_AUDIT_BBOX_SUCCESS = '[Audit] Get Points Audit Bbox Success',
    LOAD_POINTS_AUDIT_BBOX_ERROR = '[Audit] Get Points Audit Bbox Error',
    GET_POINTS_ACTIVE_PROJET_AUDIT = '[Audit] Get Points From Active Projet Audit',
    GET_POINTS_ACTIVE_PROJET_AUDIT_SUCCESS = '[Audit] Get Points Active Projet Audit Success',
    GET_POINTS_ACTIVE_PROJET_AUDIT_ERROR = '[Audit] Get Points Active Projet Audit Error',
    GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID = '[Audit] Get Points Audit By Projet Audit Id',
    GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID_SUCCESS = '[Audit] Get Points Audit By Projet Audit Id Success',
    GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID_ERROR = '[Audit] Get Points Audit By Projet Audit Id Error',
    ADD_POINT_AUDIT = '[Audit] Add Point Audit',
    ADD_POINT_AUDIT_SUCCESS = '[Audit] Add Point Audit Success',
    ADD_POINT_AUDIT_ERROR = '[Audit] Add Point Audit Error',
    ADD_POINT_AUDIT_PHOTO = '[Audit] Add Point Audit Photo',
    ADD_POINT_AUDIT_PHOTO_SUCCESS = '[Audit] Add Point Audit Photo Success',
    ADD_POINT_AUDIT_PHOTO_ERROR = '[Audit] Add Point Audit Photo Error',
    ADD_ANOMALIE_AUDIT_PHOTO = '[Audit] Add Anomalie Audit Photo',
    ADD_ANOMALIE_AUDIT_PHOTO_SUCCESS = '[Audit] Add Anomalie Audit Photo Success',
    ADD_ANOMALIE_AUDIT_PHOTO_ERROR = '[Audit] Add Anomalie Audit Photo Error',
    DELETE_POINT_AUDIT_PHOTO = '[Audit] Delete Point Audit Photo',
    DELETE_POINT_AUDIT_PHOTO_SUCCESS = '[Audit] Delete Point Audit Photo Success',
    DELETE_POINT_AUDIT_PHOTO_ERROR = '[Audit] Delete Point Audit Photo Error',
    DELETE_ANOMALIE_AUDIT_PHOTO = '[Audit] Delete Anomalie Audit Photo',
    DELETE_ANOMALIE_AUDIT_PHOTO_SUCCESS = '[Audit] Delete Anomalie Audit Photo Success',
    DELETE_ANOMALIE_AUDIT_PHOTO_ERROR = '[Audit] Delete Anomalie Audit Photo Error',
    CREATE_POINT_INSPECTION_AUDIT = `[Audit] Create Point Inspection And Point Audit`,
    CREATE_POINT_INSPECTION_AUDIT_SUCCESS = `[Audit] Create Point Inspection And Point Audit Success`,
    CREATE_POINT_INSPECTION_AUDIT_ERROR = `[Audit] Create Point Inspection And Point Audit Error`,
}

/*** LOAD PROJET AUDIT LIST */
export const loadProjetAuditList = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_LIST);

export const loadProjetAuditListSuccess = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_LIST_SUCCESS, props<{ projetAuditList: ProjetAuditDto[] }>());

export const loadProjetAuditListError = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_LIST_ERROR, props<{ error: any }>());

/*** LOAD PROJET AUDIT HISTORY */
export const loadProjetAuditHistory = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_HISTORY, props<{ projetAuditId: string }>());

export const loadProjetAuditHistorySuccess = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_HISTORY_SUCCESS, props<{ auditHistory: ProjetAuditHistory[] }>());

export const loadProjetAuditHistoryError = createAction(AuditActionTypes.LOAD_PROJET_AUDIT_HISTORY_ERROR, props<{ error: any }>());

/*** CREATE PROJET AUDIT ***/
export const startCreateProjetAudit = createAction(AuditActionTypes.START_CREATE_PROJET_AUDIT, props<{ projetId: string, projetAudit: ProjetAuditDto }>());

export const startCreateProjetAuditSuccess = createAction(AuditActionTypes.START_CREATE_PROJET_AUDIT_SUCCESS, props<{ createdProjetAudit: CreatedProjetAuditDto }>());

export const startCreateProjetAuditError = createAction(AuditActionTypes.START_CREATE_PROJET_AUDIT_ERROR, props<{ error: any }>());

export const createProjetAuditSuccess = createAction(AuditActionTypes.CREATE_PROJET_AUDIT_SUCCESS, props<{ createProjetAudit: ProjetAuditDto }>());

export const createProjetAuditError = createAction(AuditActionTypes.CREATE_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** ASSIGN PROJET AUDIT ***/
export const assignProjetAudit = createAction(AuditActionTypes.ASSIGN_PROJET_AUDIT, props<{ projetAuditId: string, assignData: PatchProjetAuditDto }>());

export const assignProjetAuditSuccess = createAction(AuditActionTypes.ASSIGN_PROJET_AUDIT_SUCCESS, props<{ assignProjetAudit: ProjetAuditDto }>());

export const assignProjetAuditError = createAction(AuditActionTypes.ASSIGN_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** ACTIVATE PROJET AUDIT ***/
export const activateProjetAudit = createAction(AuditActionTypes.ACTIVATE_PROJET_AUDIT, props<{ projetAuditId: string, activateData: PatchProjetAuditDto }>());

export const activateProjetAuditSuccess = createAction(AuditActionTypes.ACTIVATE_PROJET_AUDIT_SUCCESS, props<{ activateProjetAudit: ProjetAuditDto }>());

export const activateProjetAuditError = createAction(AuditActionTypes.ACTIVATE_PROJET_AUDIT_ERROR, props<{ error: any }>());

export const setCurrentActiveProjetAudit = createAction(AuditActionTypes.SET_CURRENT_ACTIVE_PROJET_AUDIT, props<{ projetAudit: ProjetAuditDto }>());

export const setCurrentActiveProjetAuditById = createAction(AuditActionTypes.SET_CURRENT_ACTIVE_PROJET_AUDIT_BY_ID, props<{ projetAuditId: string }>());

/*** REJECT PROJET AUDIT ***/
export const rejectProjetAudit = createAction(AuditActionTypes.REJECT_PROJET_AUDIT, props<{ projetAuditId: string, rejectData: PatchProjetAuditDto }>());

export const rejectProjetAuditSuccess = createAction(AuditActionTypes.REJECT_PROJET_AUDIT_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const rejectProjetAuditError = createAction(AuditActionTypes.REJECT_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** COMPLETE PROJET AUDIT ***/
export const completeProjetAudit = createAction(AuditActionTypes.COMPLETE_PROJET_AUDIT, props<{ projetAuditId: string, completeData: PatchProjetAuditDto }>());

export const completeProjetAuditSuccess = createAction(AuditActionTypes.COMPLETE_PROJET_AUDIT_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const completeProjetAuditError = createAction(AuditActionTypes.COMPLETE_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** APPROVE PROJET AUDIT ***/
export const approveProjetAudit = createAction(AuditActionTypes.APPROVE_PROJET_AUDIT, props<{ projetAuditId: string, approveData: PatchProjetAuditDto }>());

export const approveProjetAuditSuccess = createAction(AuditActionTypes.APPROVE_PROJET_AUDIT_SUCCESS, props<{ projetAudit: ProjetAuditDto }>());

export const approveProjetAuditError = createAction(AuditActionTypes.APPROVE_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** CANCEL PROJET AUDIT ***/
export const cancelProjetAudit = createAction(AuditActionTypes.CANCEL_PROJET_AUDIT, props<{ projetAuditId: string, cancelData: PatchProjetAuditDto }>());

export const cancelProjetAuditSuccess = createAction(AuditActionTypes.CANCEL_PROJET_AUDIT_SUCCESS, props<{ cancelProjetAudit: ProjetAuditDto }>());

export const cancelProjetAuditError = createAction(AuditActionTypes.CANCEL_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** CLEAR PROJET AUDIT DATA ***/
export const clearProjetAuditData = createAction(AuditActionTypes.CLEAR_PROJET_AUDIT_DATA);

/*** UPDATE POINT AUDIT ***/
export const updatePointAudit = createAction(AuditActionTypes.UPDATE_POINT_AUDIT, props<{ pointAudit: PointAuditDto }>());

export const updatePointAuditSuccess = createAction(AuditActionTypes.UPDATE_POINT_AUDIT_SUCCESS, props<{ pointAudit: PointAuditDto }>());

export const updatePointAuditError = createAction(AuditActionTypes.UPDATE_POINT_AUDIT_ERROR, props<{ error: any }>());

/*** UPDATE PROJET AUDIT TO INDEXEDDB ***/
export const updateProjetAuditToIndexedDb = createAction(AuditActionTypes.UPDATE_PROJET_AUDIT_TO_INDEXEDDB, props<{ projetAuditId: string }>());

/*** ADD POINT AUDIT ***/
export const addPointAudit = createAction(AuditActionTypes.ADD_POINT_AUDIT, props<{ pointAudit: PointAuditDto }>());

export const addPointAuditSuccess = createAction(AuditActionTypes.ADD_POINT_AUDIT_SUCCESS, props<{ pointAudit: PointAuditDto }>());

export const addPointAuditError = createAction(AuditActionTypes.ADD_POINT_AUDIT_ERROR, props<{ error: any }>());

/*** ADD POINT AUDIT PHOTO ***/
export const addPointAuditPhoto = createAction(AuditActionTypes.ADD_POINT_AUDIT_PHOTO, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const addPointAuditPhotoSuccess = createAction(AuditActionTypes.ADD_POINT_AUDIT_PHOTO_SUCCESS, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const addPointAuditPhotoError = createAction(AuditActionTypes.ADD_POINT_AUDIT_PHOTO_ERROR, props<{ error: any }>());

/*** ADD ANOMALIE AUDIT PHOTO ***/
export const addAnomalieAuditPhoto = createAction(AuditActionTypes.ADD_ANOMALIE_AUDIT_PHOTO,
    props<{ pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity }>());

export const addAnomalieAuditPhotoSuccess = createAction(AuditActionTypes.ADD_ANOMALIE_AUDIT_PHOTO_SUCCESS,
    props<{ pointAuditId: string, anomalieAuditId: string, photo: PhotoEntity }>());

export const addAnomalieAuditPhotoError = createAction(AuditActionTypes.ADD_ANOMALIE_AUDIT_PHOTO_ERROR, props<{ error: any }>());

/*** DELETE POINT AUDIT PHOTO ***/
export const deletePointAuditPhoto = createAction(AuditActionTypes.DELETE_POINT_AUDIT_PHOTO, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const deletePointAuditPhotoSuccess = createAction(AuditActionTypes.DELETE_POINT_AUDIT_PHOTO_SUCCESS, props<{ pointAudit: PointAuditDto, photo: PhotoEntity }>());

export const deletePointAuditPhotoError = createAction(AuditActionTypes.DELETE_POINT_AUDIT_PHOTO_ERROR, props<{ error: any }>());

/*** DELETE ANOMALIE AUDIT PHOTO ***/
export const deleteAnomalieAuditPhoto = createAction(AuditActionTypes.DELETE_ANOMALIE_AUDIT_PHOTO,
    props<{ pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity }>());

export const deleteAnomalieAuditPhotoSuccess = createAction(AuditActionTypes.DELETE_ANOMALIE_AUDIT_PHOTO_SUCCESS,
    props<{ pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity }>());

export const deleteAnomalieAuditPhotoError = createAction(AuditActionTypes.DELETE_ANOMALIE_AUDIT_PHOTO_ERROR, props<{ error: any }>());

/*** GET POINTS ACTIVE PROJET AUDIT ***/
export const getPointsActiveProjetAudit = createAction(AuditActionTypes.GET_POINTS_ACTIVE_PROJET_AUDIT);

export const getPointsActiveProjetAuditSuccess = createAction(AuditActionTypes.GET_POINTS_ACTIVE_PROJET_AUDIT_SUCCESS, props<{ pointsAudit: PointAuditDto[] }>());

export const getPointsActiveProjetAuditError = createAction(AuditActionTypes.GET_POINTS_ACTIVE_PROJET_AUDIT_ERROR, props<{ error: any }>());

/*** GET POINTS BY PROJET AUDIT ID***/
export const getPointsAuditByProjetAuditId = createAction(AuditActionTypes.GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID, props<{ projetAuditId: string }>());

export const getPointsAuditByProjetAuditIdSuccess = createAction(AuditActionTypes.GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID_SUCCESS, props<{ pointsAudit: PointAuditDto[] }>());

export const getPointsAuditByProjetAuditIdError = createAction(AuditActionTypes.GET_POINTS_AUDIT_BY_PROJET_AUDIT_ID_ERROR, props<{ error: any }>());

/*** LOAD POINTS AUDIT BBOX ***/
export const loadPointsAuditBbox = createAction(AuditActionTypes.LOAD_POINTS_AUDIT_BBOX, props<BoundingBoxInfo>());

export const loadPointsAuditBboxSuccess = createAction(AuditActionTypes.LOAD_POINTS_AUDIT_BBOX_SUCCESS, props<{ bbox: BoundingBoxInfo, pointsAudit: PointAuditDto[] }>());

export const loadPointsAuditBboxError = createAction(AuditActionTypes.LOAD_POINTS_AUDIT_BBOX_ERROR, props<{ bbox: BoundingBoxInfo, error: any }>());

/*** CREATE POINT INSPECTION AND POINT AUDIT ***/
export const createPointAudit = createAction(AuditActionTypes.CREATE_POINT_INSPECTION_AUDIT, props<{ pointInspection: PointInspectionDto }>());

export const createPointAuditSuccess = createAction(AuditActionTypes.CREATE_POINT_INSPECTION_AUDIT_SUCCESS, props<{ pointAudit: PointAuditDto }>());

export const createPointAuditError = createAction(AuditActionTypes.CREATE_POINT_INSPECTION_AUDIT_ERROR, props<{ error: any }>());
