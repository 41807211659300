import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { of } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../state/app.state';
import * as SyncActions from './synchronisation.actions';
import { SynchronisationService } from '../services/synchronisation.service';
import { getIsAuditeur } from '../../../core/store/selectors/user.selectors';
import { TypeObjetSyncError } from '../models/type-objet-sync-error.enum';

@Injectable()
export class SynchronisationEffects {
    getRequests$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SyncActions.getRequests),
            mergeMap(() => {
                return this.syncService.getRequests()
                    .pipe(
                        map(requests => SyncActions.getRequestsSuccess({ requests })),
                        catchError((error: unknown) => of(SyncActions.getRequestsFailure({ error })))
                    );
            }
            ));
    });

    uploadRequests$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SyncActions.uploadRequests),
            withLatestFrom(this.store.select(getIsAuditeur)),
            mergeMap(([action, isAuditeur]) => {
                const typeErreurSynchronisation = isAuditeur ? TypeObjetSyncError.AUDIT : TypeObjetSyncError.INSPECTION;
                return this.syncService.uploadRequests(
                    {
                        body: action.request,
                        typeErreurSynchronisation,
                        numeroRequete: action.numeroRequete,
                        totalRequete: action.totalRequete,
                        dateSauvegarde: action.dateSauvegarde
                    })
                    .pipe(
                        map(request => SyncActions.uploadRequestsSuccess({ request })),
                        catchError((error: unknown) => of(SyncActions.uploadRequestsFailure({ error })))
                    );
            }
            ));
    });

    deleteRequests$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SyncActions.deleteRequests),
            mergeMap(() => {
                return this.syncService.deleteRequests()
                    .pipe(
                        map((success) => SyncActions.deleteRequestsSuccess({ success })),
                        catchError((error: unknown) => of(SyncActions.deleteRequestsFailure({ error })))
                    );
            }
            ));
    });

    getSyncErrors$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SyncActions.getSyncErrors),
            mergeMap(() =>
                this.syncService.getSyncErrors()
                    .pipe(
                        map(syncErrors => SyncActions.getSyncErrorsSuccess({ syncErrors })),
                        catchError((error: unknown) => of(SyncActions.getSyncErrorsFailure({ error })))
                    )));
    });

    addSyncErrors$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SyncActions.addSyncErrors),
            mergeMap((action) =>
                this.syncService.addSyncErrors(action.syncErrors)
                    .pipe(
                        map(syncErrors => SyncActions.addSyncErrorsSuccess({ syncErrors })),
                        catchError((error: unknown) => of(SyncActions.addSyncErrorsFailure({ error })))
                    )));
    });

    deleteSyncErrors$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SyncActions.deleteSyncErrors),
            mergeMap(() =>
                this.syncService.deleteSyncErrors()
                    .pipe(
                        map(success => SyncActions.deleteSyncErrorsSuccess({ success })),
                        catchError((error: unknown) => of(SyncActions.deleteSyncErrorsFailure({ error })))
                    )));
    });

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private syncService: SynchronisationService
    ) { }
}
