import { createAction, props } from '@ngrx/store';
import { PhotoEntity } from '../../../core/api/client/models';

export enum SharedActionTypes {

    // Upload photo
    LOAD_UPLOADED_PHOTO = '[Photo] Load Uploaded Photo',
    LOAD_UPLOADED_PHOTO_SUCCESS = '[Photo] Load Uploaded Photo Success',
    LOAD_UPLOADED_PHOTO_FAILURE = '[Photo] Load Uploaded Photo Failure',

    // Point Inspection
    LOAD_POINT_INSPECTION_PHOTOS = '[Photo] Load Point Inspection Photos',
    LOAD_POINT_INSPECTION_PHOTOS_SUCCESS = '[Photo] Load Point Inspection Photos Success',
    LOAD_POINT_INSPECTION_PHOTOS_FAILURE = '[Photo] Load Point Inspection Photos Failure',

    CACHE_POINT_INSPECTION_PHOTOS = '[Photo] Cache Point Inspection Photos',
    CACHE_POINT_INSPECTION_PHOTOS_SUCCESS = '[Photo] Cache Point Inspection Photos Success',
    CACHE_POINT_INSPECTION_PHOTOS_FAILURE = '[Photo] Cache Point Inspection Photos Failure',
    UPDATE_NON_CACHED_POINT_INSPECTION_PHOTOS_LIST = '[Photo] Update Non Cached Point Inspection Photos List',
    UPDATE_CACHING_POINT_INSPECTION_PHOTOS_PROGRESSION = '[Photo] Update Cached Point Inspection Photos Progression',

    // Anomalies
    LOAD_ANOMALIES_PHOTOS = '[Photo] Load Anomalies Photos',
    LOAD_ANOMALIES_PHOTOS_SUCCESS = '[Photo] Load Anomalies Photos Success',
    LOAD_ANOMALIES_PHOTOS_FAILURE = '[Photo] Load Anomalies Photos Failure',

    CACHE_ANOMALIES_PHOTOS = '[Photo] Cache Anomalies Photos',
    CACHE_ANOMALIES_PHOTOS_SUCCESS = '[Photo] Cache Anomalies Photos Success',
    CACHE_ANOMALIES_PHOTOS_FAILURE = '[Photo] Cache Anomalies Photos Failure',
    UPDATE_NON_CACHED_ANOMALIES_PHOTOS_LIST = '[Photo] Update Non Cached Anomalies Photos List',
    UPDATE_CACHING_ANOMALIES_PHOTOS_PROGRESSION = '[Photo] Update Caching Anomalies Photos Progression',

    // Point Audit
    LOAD_POINT_AUDIT_PHOTOS = '[Photo] Load Point Audit Photos',
    LOAD_POINT_AUDIT_PHOTOS_SUCCESS = '[Photo] Load Point Audit Photos Success',
    LOAD_POINT_AUDIT_PHOTOS_FAILURE = '[Photo] Load Point Audit Photos Failure',

    CACHE_POINT_AUDIT_PHOTOS = '[Photo] Cache Point Audit Photos',
    CACHE_POINT_AUDIT_PHOTOS_SUCCESS = '[Photo] Cache Point Audit Photos Success',
    CACHE_POINT_AUDIT_PHOTOS_FAILURE = '[Photo] Cache Point Audit Photos Failure',
    UPDATE_NON_CACHED_POINT_AUDIT_PHOTOS_LIST = '[Photo] Update Non Cached Point Audit Photos List',
    UPDATE_CACHING_POINT_AUDIT_PHOTOS_PROGRESSION = '[Photo] Update Caching Point Audit Photos Progression',

    // Anomalies audit
    LOAD_ANOMALIES_AUDIT_PHOTOS = '[Photo] Load Anomalies Audit Photos',
    LOAD_ANOMALIES_AUDIT_PHOTOS_SUCCESS = '[Photo] Load Anomalies Audit Photos Success',
    LOAD_ANOMALIES_AUDIT_FAILURE = '[Photo] Load Anomalies Audit Photos Failure',

    CACHE_ANOMALIES_AUDIT_PHOTOS = '[Photo] Cache Anomalies Audit Photos',
    CACHE_ANOMALIES_AUDIT_PHOTOS_SUCCESS = '[Photo] Cache Anomalies Audit Photos Success',
    CACHE_ANOMALIES_AUDIT_PHOTOS_FAILURE = '[Photo] Cache Anomalies Audit Photos Failure',
    UPDATE_NON_CACHED_ANOMALIES_AUDIT_PHOTOS_LIST = '[Photo] Update Non Cached Anomalies Audit Photos List',
    UPDATE_CACHING_ANOMALIES_AUDIT_PHOTOS_PROGRESSION = '[Photo] Update Caching Anomalies Audit Photos Progression',

    // Offline photos
    ADD_PHOTO_TO_INDEXEDDB = '[Photo] Add Photo To IndexedDb'
}

// Upload photo
export const loadUploadedPhoto = createAction(SharedActionTypes.LOAD_UPLOADED_PHOTO, props<{ photo: PhotoEntity }>());
export const loadUploadedPhotoSuccess = createAction(SharedActionTypes.LOAD_UPLOADED_PHOTO_SUCCESS);
export const loadUploadedPhotoFailure = createAction(SharedActionTypes.LOAD_UPLOADED_PHOTO_FAILURE, props<{ error: any }>());

// Point Inspections
export const loadPointInspectionPhotos = createAction(SharedActionTypes.LOAD_POINT_INSPECTION_PHOTOS, props<{ photos: PhotoEntity[] }>());
export const loadPointInspectionPhotosSuccess = createAction(SharedActionTypes.LOAD_POINT_INSPECTION_PHOTOS_SUCCESS, props<{ photos: PhotoEntity[] }>());
export const loadPointInspectionPhotosFailure = createAction(SharedActionTypes.LOAD_POINT_INSPECTION_PHOTOS_FAILURE, props<{ error: any }>());

export const cachePointInspectionPhotos = createAction(SharedActionTypes.CACHE_POINT_INSPECTION_PHOTOS, props<{ projetId: string }>());
export const cachePointInspectionPhotosSuccess = createAction(SharedActionTypes.CACHE_POINT_INSPECTION_PHOTOS_SUCCESS);
export const cachePointInspectionPhotosFailure = createAction(SharedActionTypes.CACHE_POINT_INSPECTION_PHOTOS_FAILURE, props<{ error: any }>());
export const updateNonCachedPointInspectionPhotosList = createAction(SharedActionTypes.UPDATE_NON_CACHED_POINT_INSPECTION_PHOTOS_LIST, props<{ photo: PhotoEntity }>());
export const updateCachingPointInspectionPhotosProgression = createAction(SharedActionTypes.UPDATE_CACHING_POINT_INSPECTION_PHOTOS_PROGRESSION,
    props<{ progression: number }>());

// Anomalies
export const loadAnomaliesPhotos = createAction(SharedActionTypes.LOAD_ANOMALIES_PHOTOS, props<{ photos: PhotoEntity[] }>());
export const loadAnomaliesPhotosSuccess = createAction(SharedActionTypes.LOAD_ANOMALIES_PHOTOS_SUCCESS, props<{ photos: PhotoEntity[] }>());
export const loadAnomaliesPhotosFailure = createAction(SharedActionTypes.LOAD_ANOMALIES_PHOTOS_FAILURE, props<{ error: any }>());

export const cacheAnomaliesPhotos = createAction(SharedActionTypes.CACHE_ANOMALIES_PHOTOS, props<{ projetId: string }>());
export const cacheAnomaliesPhotosSuccess = createAction(SharedActionTypes.CACHE_ANOMALIES_PHOTOS_SUCCESS);
export const cacheAnomaliesPhotosFailure = createAction(SharedActionTypes.CACHE_ANOMALIES_PHOTOS_FAILURE, props<{ error: any }>());
export const updateNonCachedAnomaliesPhotosList = createAction(SharedActionTypes.UPDATE_NON_CACHED_ANOMALIES_PHOTOS_LIST, props<{ photo: PhotoEntity }>());
export const updateCachingAnomaliesPhotosProgression = createAction(SharedActionTypes.UPDATE_CACHING_ANOMALIES_PHOTOS_PROGRESSION, props<{ progression: number }>());

// Point Audit
export const loadPointAuditPhotos = createAction(SharedActionTypes.LOAD_POINT_AUDIT_PHOTOS, props<{ photos: PhotoEntity[] }>());
export const loadPointAuditPhotosSuccess = createAction(SharedActionTypes.LOAD_POINT_AUDIT_PHOTOS_SUCCESS, props<{ photos: PhotoEntity[] }>());
export const loadPointAuditPhotosFailure = createAction(SharedActionTypes.LOAD_POINT_AUDIT_PHOTOS_FAILURE, props<{ error: any }>());

export const cachePointAuditPhotos = createAction(SharedActionTypes.CACHE_POINT_AUDIT_PHOTOS, props<{ projetAuditId: string }>());
export const cachePointAuditPhotosSuccess = createAction(SharedActionTypes.CACHE_POINT_AUDIT_PHOTOS_SUCCESS);
export const cachePointAuditPhotosFailure = createAction(SharedActionTypes.CACHE_POINT_AUDIT_PHOTOS_FAILURE, props<{ error: any }>());
export const updateNonCachedPointAuditPhotosList = createAction(SharedActionTypes.UPDATE_NON_CACHED_POINT_AUDIT_PHOTOS_LIST, props<{ photo: PhotoEntity }>());
export const updateCachingPointAuditPhotosProgression = createAction(SharedActionTypes.UPDATE_CACHING_POINT_AUDIT_PHOTOS_PROGRESSION, props<{ progression: number }>());

// Anomalies audit
export const loadAnomaliesAuditPhotos = createAction(SharedActionTypes.LOAD_ANOMALIES_AUDIT_PHOTOS, props<{ photos: PhotoEntity[] }>());
export const loadAnomaliesAuditPhotosSuccess = createAction(SharedActionTypes.LOAD_ANOMALIES_AUDIT_PHOTOS_SUCCESS, props<{ photos: PhotoEntity[] }>());
export const loadAnomaliesAuditPhotosFailure = createAction(SharedActionTypes.LOAD_ANOMALIES_PHOTOS_FAILURE, props<{ error: any }>());

export const cacheAnomaliesAuditPhotos = createAction(SharedActionTypes.CACHE_ANOMALIES_AUDIT_PHOTOS, props<{ projetAuditId: string }>());
export const cacheAnomaliesAuditPhotosSuccess = createAction(SharedActionTypes.CACHE_ANOMALIES_AUDIT_PHOTOS_SUCCESS);
export const cacheAnomaliesAuditPhotosFailure = createAction(SharedActionTypes.CACHE_ANOMALIES_AUDIT_PHOTOS_FAILURE, props<{ error: any }>());
export const updateNonCachedAnomaliesAuditPhotosList = createAction(SharedActionTypes.UPDATE_NON_CACHED_ANOMALIES_AUDIT_PHOTOS_LIST, props<{ photo: PhotoEntity }>());
export const updateCachingAnomaliesAuditPhotosProgression = createAction(SharedActionTypes.UPDATE_CACHING_ANOMALIES_AUDIT_PHOTOS_PROGRESSION,
    props<{ progression: number }>());

// Offline photos
export const addPhotoToIndexedDb = createAction(SharedActionTypes.ADD_PHOTO_TO_INDEXEDDB, props<{ photo: PhotoEntity }>());
