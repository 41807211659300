import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { Projet } from '../../models/projet.model';
import {
    activerProjetSuccess, annulerProjetError, annulerProjetSuccess, clearProjetData,
    createProjetSuccess, fetchProjetsError, fetchProjetsSuccess, onApprouveProjetSuccess,
    onModifierProjetSuccess, postProjetSuccess, onModifierStatutProjetSuccess, fetchProjets,
    annulerProjet, rejeterProjet, rejeterProjetSuccess, rejeterProjetError, assigneProjet,
    assigneProjetSuccess, assigneProjetError
} from './projet.actions';

export const projetsAdapter: EntityAdapter<Projet> = createEntityAdapter<Projet>({
    selectId: (projet: Projet) => projet.id as string,
});

export interface ProjetsState extends EntityState<Projet> {
    activeProjet: Projet,
    assigneProjetSuccess: boolean,
    assigneProjetError: any | null,
    projectsLoading: boolean,
};

export const projetInitialState = projetsAdapter.getInitialState({
    assigneProjetSuccess: false,
    assigneProjetError: null,
    projectsLoading: false,
});

export const projetReducer = createReducer(projetInitialState,
    on(fetchProjets, (state) => {
        return {
            ...state,
            projectsLoading: true,
        };
    }),

    on(fetchProjetsSuccess, (state, action) => {
        return projetsAdapter.setAll(action.projets, {
            ...state,
            projectsLoading: false,
        });
    }),

    on(fetchProjetsError, (state) => {
        return {
            ...state,
            projectsLoading: false,
        };
    }),

    on(assigneProjet, (state) => {
        return {
            ...state,
            assigneProjetSuccess: false,
            assigneProjetError: null,
        };
    }),

    on(assigneProjetSuccess, (state, action) => {
        return projetsAdapter.upsertOne(action.projet, {
            ...state,
            assigneProjetSuccess: true,
            assigneProjetError: null
        });
    }),

    on(assigneProjetError, (state, action) => {
        return {
            ...state,
            assigneProjetSuccess: false,
            assigneProjetError: action.error,
        };
    }),

    on(postProjetSuccess, (state, action) => {
        return projetsAdapter.addOne(action.projet, { ...state });
    }),

    on(createProjetSuccess, (state, action) => {
        return projetsAdapter.upsertOne(action.projet, { ...state });
    }),

    on(activerProjetSuccess, (state, action) => {
        return projetsAdapter.upsertOne(action.projet, {
            ...state,
            activeProjet: action.projet
        });
    }),

    on(annulerProjet, (state) => {
        return {
            ...state,
            projectsLoading: true,
        };
    }),

    on(annulerProjetSuccess, (state, action) => projetsAdapter.updateOne(action.projet, {
        ...state,
        projectsLoading: false,
    })),

    on(annulerProjetError, (state) => {
        return {
            ...state,
            projectsLoading: false,
        };
    }),

    on(rejeterProjet, (state) => {
        return {
            ...state,
            projectsLoading: true,
        };
    }),

    on(rejeterProjetSuccess, (state, action) => projetsAdapter.updateOne(action.projet, {
        ...state,
        projectsLoading: false,
    })),

    on(rejeterProjetError, (state) => {
        return {
            ...state,
            projectsLoading: false,
        };
    }),

    on(onModifierProjetSuccess, (state, action) => projetsAdapter.updateOne(action.projet, state)),
    on(onModifierStatutProjetSuccess, (state, action) => projetsAdapter.updateOne(action.projet, state)),
    on(onApprouveProjetSuccess, (state, action) => projetsAdapter.updateOne(action.projet, state)),

    on(clearProjetData, () => {
        return {
            ...projetInitialState
        };
    })
);
