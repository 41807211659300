<div class="map" id="map" class="match-parent">
    <div *ngIf="projetAddedToIndexedDbLoading$ | async" class="app-spinner">
        <app-loading-spinner></app-loading-spinner>
    </div>
    <div *ngIf="isInitMapFinish" [class.p-d-none]="!createMovePoteauPanelVisible" id="center-circle"
        class="center-circle">
        <div class="cross-div">
            <span class="cross">✚</span>
        </div>
    </div>
</div>
<div *ngIf="isNetworkOnline" class="p-d-lg-flex p-d-lg-column p-d-md-flex p-d-md-column p-d-none geocode-container">
    <p-autoComplete [(ngModel)]="geoCodetext" (onSelect)="geoCodeSelect($event)" [multiple]="false"
        [suggestions]="$any(filtreEsriCodage)" field="properties.address" (completeMethod)="getGeocodeService()"
        placeholder="Rechercher une adresse" (keyup.enter)="geoCodeSelectFromArray(filtreEsriCodage)"></p-autoComplete>
    <button type="button" title="Recherche" aria-label="Recherche" pButton pRipple icon="pi pi-search"
        styleClass="p-button-warn" (click)="geoCodeSelectFromArray(filtreEsriCodage)"></button>
</div>
<div class="offline-indicator" *ngIf="!isNetworkOnline">
    <button type="button" pButton icon="pi pi-exclamation-circle" class="p-button-warning" *ngIf="warningMinimized"
        (click)="toggleWarning()"></button>
    <button pButton pRipple type="button" class="p-button-warning" *ngIf="!warningMinimized" (click)="toggleWarning()">
        Vous êtes en mode hors connexion
        <span class="p-ml-2 pi pi-angle-double-left"></span>
    </button>
</div>

<div *ngIf="isUserMobile">
    <div *ngIf="!isNetworkOnline || (isNetworkOnline && requestStackSize > 0)"
        class="mapboxgl-ctrl-group mapboxgl-ctrl sync-button-div">
        <button [disabled]="!isNetworkOnline" class="sync-button" [class.sync-available]="isNetworkOnline"
            (click)="initSync()">
            <span>
                <i title="Synchronisation" class="fas fa-sync icon-xl"></i>
            </span>
            <span *ngIf="requestStackSize > 0" class="badge">{{requestStackSize}}</span>
        </button>
    </div>
</div>

<p-toast class="custom-toast" position="top-right"></p-toast>
<p-toast key="messageProjet" position="top-right">
    <ng-template let-message pTemplate="message">
        <div class="toast-icon">
            <span class="pi pi-check" style="font-size: 2rem"></span>
        </div>
        <div class="toast-detail">
            <div>
                <h5 class="p-p-0"> {{message.summary}}</h5>
            </div>
            <div>
                <span>{{message.data.isErreur ? 'Erreur de création du projet' : 'Le projet'}} <span
                        class="toast-nom_projet">{{message.data.projet.nom}}</span>
                    {{message.data.isErreur ? '' : 'a été créé avec succès'}}.
                    <button pButton pRipple label="Afficher le projet" icon="pi pi-fw pi-search-plus"
                        class="p-button-text projet-button p-pl-0" (click)="onZoomProjet(message.data.projet)"></button>
                </span>
            </div>
        </div>
    </ng-template>
</p-toast>
<p-toast key="messageAnomalie" position="top-right">
    <ng-template let-message pTemplate="message">
        <div class="toast-icon">
            <span class="pi pi-check" style="font-size: 2rem"></span>
        </div>
        <div class="toast-detail">
            <div>
                <h5 class="p-p-0"> {{message.summary}}</h5>
            </div>
            <div>
                <span>L'anomalie <span class="toast-nom_projet">{{message.data.element}}</span> a été créé avec
                    succès.</span>
            </div>
        </div>
    </ng-template>
</p-toast>
<app-feature-dialog [showCreateProjet]="$any(this.uiService.createProjet$|async)"></app-feature-dialog>
<app-recherche-dialog [showRechercheDialog]="$any(this.uiService.recherche$|async)"
    (closeRechercheDialog)="closeRechercheDialog()"></app-recherche-dialog>
<p-dialog [modal]=false header="Couches" [(visible)]="ouvrirLegende" styleClass="legendeContent" position="right">
    <div class="legend-button">
        <button pButton type="button" label="Réinitialiser" icon="pi pi-undo" (click)="getLegendeInit()"></button>
    </div>
    <p-tree [value]="legendeOptions" selectionMode="single">
        <ng-template let-node pTemplate="rect">
            <div class="p-d-flex p-ac-start p-jc-start">
                <p-checkbox [label]="node.label" [binary]="true" [(ngModel)]="node.data.visibilite"
                    (onChange)="changeLayerVisibilite(node)"></p-checkbox>
            </div>
            <div class="p-ml-5"><svg style="height: 20px;width:20px;background-color: rgb(0 0 0 / 5%);">
                    <g [attr.fill]="node.data.paint['fill-color']">
                        <rect x="0" y="0" width="20" height="20" />
                    </g>
                </svg>
            </div>
        </ng-template>
        <ng-template let-node pTemplate="circle">
            <div class="p-d-flex p-ac-start p-jc-start">
                <p-checkbox [label]="node.label" [binary]="true" [(ngModel)]="node.data.visibilite"
                    (onChange)="changeLayerVisibilite(node)">
                </p-checkbox>
            </div>
            <div class="p-ml-5">
                <svg style="height:20px;width:20px;background-color: rgb(0 0 0 / 1%);">
                    <g [attr.fill]="node.data.paint['circle-color']"
                        [attr.stroke]="node.data.paint['circle-stroke-color']"
                        [attr.stroke-width]="node.data.paint['circle-stroke-width']">
                        <circle cx="9" cy="9" [attr.r]="node.data.paint['circle-radius']"></circle>
                    </g>
                </svg>
            </div>
        </ng-template>
        <ng-template let-node pTemplate="symbol">
            <div class="p-d-flex p-ac-start p-jc-start">
                <p-checkbox [label]="node.label" [binary]="true" [(ngModel)]="node.data.visibilite"
                    (onChange)="changeLayerVisibilite(node)">
                </p-checkbox>
            </div>
            <div class="p-ml-5">
                <ng-template ngFor let-image [ngForOf]="node.data.src">
                    <img [src]="image" [style]="node.data.cssText" class="legend-icon" />
                </ng-template>
            </div>
        </ng-template>
    </p-tree>
</p-dialog>

<ng-container *ngIf="infoPointInspection.openSideBar || createMovePoteauPanelVisible">
    <app-action-sheet class="action-sheet-container" [overGridLabel]="selectedPointInspectionCodeBar"
        [topLeftIcon]="topLeftIcon" [topLeftDisabled]="topLeftDisabled" [topRightLabel]="topRightLabel"
        [topRightDisabled]="topRightDisabled" [bottomLeftIcon]="bottomLeftIcon"
        [bottomLeftDisabled]="bottomLeftDisabled" [bottomRightDisabled]="bottomRightDisabled"
        [centerDisable]="centerDisable" [centerDisplay]="isInspecteurPanel" (topLeft)="initPoteauDetailsDialog()"
        (topRight)="actionSheetTopRightButton()" (bottomLeft)="openAnomalieDialog()"
        (bottomRight)="openIgnorePointPanel()" (center)="onUpdateSansAnomalie(infoPointInspection.feature)"
        [displaySubContent]="ignorePointPanelVisible || createMovePoteauPanelVisible">

        <app-ignore-point-panel *ngIf="ignorePointPanelVisible" [pointInspection]="selectedPointInspection!"
            [pointAudit]="selectedPointAudit" [currentUserInfo]="currentUserInfo" [isAuditeur]="markAsAuditeur"
            [visible]="ignorePointPanelVisible" (visibleChange)="ignoreVisibleChanged($event)">
        </app-ignore-point-panel>

        <app-create-move-poteau-panel class="createMovePoteauComponent" *ngIf="createMovePoteauPanelVisible"
            [infoPointInspection]="infoPointInspection" [selectedPointInspection]="selectedPointInspection!"
            [(createPoteauInfo)]="createPoteauInfo" [(createPoteauSansAnomalie)]="createPoteauSansAnomalie"
            [currentUserInfo]="currentUserInfo" [createPoteauPointAudit]="isCreatePoteauPointAudit"
            [visible]="createMovePoteauPanelVisible" (visibleChange)="createMovePoteauPanelVisibleChanged($event)"
            (createPointAudit)="createPointAudit($event)">
        </app-create-move-poteau-panel>
    </app-action-sheet>
</ng-container>

<div id="capture-image_viewer" class="capture-image_viewer">
    <div class="capture-image_viewer-content" id="image_viewer-content"></div>
    <button [disabled]="imageIndex === 0" pButton class="precedent" type="button" (click)="ouvrirImage(-1)"
        icon="pi pi-chevron-left" title="Précédent"></button>
    <button [disabled]="imageIndex + 1 === nombresImages" pButton class="suivant" type="button" (click)="ouvrirImage(1)"
        icon="pi pi-chevron-right" title="Suivant"></button>
    <div class="caption-container">
        <p id="caption" class="nomPhoto"></p>
        <div class="caption-button">
            <button pButton type="button" class="caption-telecharger-btn" title="Télécharger image"
                (click)="telechargerImage()" icon="pi pi-download" iconPos="left"></button>
            <button pButton type="button" class="caption-close-btn" title="Fermer" (click)="fermerImageViewer()"
                icon="pi pi-times" iconPos="left"></button>
        </div>
    </div>
</div>

<ng-container *ngIf="!isUserMobile">
    <div #popUpInfo [style.display]="'none'">
        <app-pop-up-info [features]="selectedPopUpFeature" [currentUserGroups]="userGroups"
            (closed)="closePopUpInfo($event)"></app-pop-up-info>
    </div>
</ng-container>

<app-point-audit-non-conforme-dialog *ngIf="isAuditeur && createPointAuditData" [pointAudit]="createPointAuditData"
    [currentUserInfo]="currentUserInfo" [currentActiveProjetAudit]="currentActiveProjetAudit"
    [(isCreatePoteau)]="isCreatePoteauPointAudit" [(visible)]="pointAuditNonConformeDialogVisible">
</app-point-audit-non-conforme-dialog>

<app-photo-status></app-photo-status>
