import { createAction, props } from '@ngrx/store';
import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';

export enum UserActionTypes {
    GET_USER_FROM_CLAIMS = '[User] Get User From Claims',
    LOGOUT_USER = '[User] Logout User',
}
export const getUserFromClaims = createAction(UserActionTypes.GET_USER_FROM_CLAIMS,
    props<{ userInfo: OpenIdUserInfo | undefined, claims: OpenIdClaims | undefined }>());

export const logoutUser = createAction(UserActionTypes.LOGOUT_USER);
