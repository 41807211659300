<div>
    <h3 *ngIf="progress < 100" class="center-text">Synchronisation du projet en cours</h3>
    <h3 *ngIf="progress === 100 && !hasError" class="center-text">Synchronisation terminée avec succès</h3>
    <h3 *ngIf="progress === 100 && hasError" class="center-text">Synchronisation terminée <span class="font-error">en
            erreur</span></h3>

    <p-progressBar [value]="progress" class="p-mt-4 p-mb-1" [class.over70]="progress > 69"></p-progressBar>

    <span class="center-text" *ngIf="progress < 100">
        <i>Veuillez vous assurer de rester connecté et conservez cette fenêtre ouverte jusqu’à la fin de la
            synchronisation.</i>
    </span>
    <span class="center-text" *ngIf="progress === 100 && !hasError">
        <i>Tous les points d’{{ isAuditeur ? "audit" : "inspection"}} ont été synchronisés avec succès. Vous
            pouvez maintenant fermer cette fenêtre.</i>
    </span>
</div>
<div class="p-mt-4" *ngIf="progress === 100 && hasError">
    <p class="center-text">
        Les données de {{ignoredIds.length || 1}} point{{ignoredIds.length > 1 ? "s" : "" }} d’{{ isAuditeur ? "audit" :
        "inspection"}} n'ont pas pu être synchronisées.
    </p>
    <div>
        <span>Vous pouvez consulter le rapport d’erreurs, effectuer les corrections puis
            synchroniser de nouveau.</span>

    </div>
    <hr class="separation">
    <div class="p-d-flex center-button bouton-vertical">
        <button pButton (click)="openRapportErreursSynchronisationDialog()"
            label="Rapport d’erreurs de synchronisation "></button>
    </div>
</div>