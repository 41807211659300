import { APP_BASE_HREF, CommonModule, registerLocaleData } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeHqPrimeNgA11yModule } from '@ngxhq/theme-ngxhq';
import localeFr from '@angular/common/locales/fr';

registerLocaleData(localeFr, 'fr');

import { setupBluebird } from '@ngxhq/utilities';

/* App Root */
import { AppComponent } from './app.component';

/* Routing Module */
import { AppRoutingModule } from './app-routing.module';

/* Feature Modules */
import { CoreModule } from './core/core.module';
import { ShellModule } from './shell/shell.module';

import { FormsModule } from '@angular/forms';
import { VersionComponent } from './version/version.component';

import { logger } from './core/store/utils';
import { ApiService } from './services/api.service';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { environment } from '@app/environment';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { indexedDBConfig } from './features/offline/models/indexed-db-config.const';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { IndexedDbService } from './features/offline/services/indexed-db.service';
import { OfflineService } from './features/offline/services/offline.service';
import { AnomaliePilotageService } from './services/anomalie-pilotage.service';
import { InvalidRoleComponent } from './invalid-role/invalid-role.component';
import { AuditModule } from './features/audit/audit.module';
import { OfflineModule } from './features/offline/offline.module';
import { RechercheModule } from './features/recherche/recherche.module';
import { ProjetModule } from './features/projet/projet.module';
import { SynchronisationModule } from './features/synchronisation/synchronisation.module';
import { AnomalieModule } from './features/anomalie/anomalie.module';
import { MapModule } from './map/map.module';
import { appEffects, appReducer } from './state/app.state';
import { PilotageModule } from './features/pilotage/pilotage.module';
import { AuthModule } from './features/auth/auth.module';
import { ServiceWorkerModule } from '@angular/service-worker';
import { InspectionModule } from './features/inspection/inspection.module';
import { NetworkService } from './services/network.service';
/* Store setup */
export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];
@NgModule({
    declarations: [
        AppComponent,
        VersionComponent,
        InvalidRoleComponent
    ],
    imports: [
        CommonModule,
        AppRoutingModule,
        AnomalieModule,
        AuditModule,
        AuthModule,
        InspectionModule,
        MapModule,
        OfflineModule,
        PilotageModule,
        ProjetModule,
        RechercheModule,
        SynchronisationModule,
        BrowserModule,
        BrowserAnimationsModule,
        HttpClientModule,
        ThemeHqPrimeNgA11yModule,
        FormsModule,
        CoreModule,
        ShellModule,
        // ServiceWorkerModule.register('/ngsw-worker.js', {
        //     enabled: environment.production, // TODO: Configurer selon l'environment (prod)
        // }),
        ServiceWorkerModule.register('ngsw-worker.js', {
            enabled: true,
            registrationStrategy: 'registerWhenStable:30000'
        }),
        StoreModule.forRoot(appReducer),
        EffectsModule.forRoot(...appEffects),
        StoreDevtoolsModule.instrument({ maxAge: 1000, logOnly: environment.production }),
        NgxIndexedDBModule.forRoot(indexedDBConfig)
    ],
    providers: [
        NetworkService,
        {
            provide: APP_INITIALIZER,
            useFactory: (ns: NetworkService) => () => ns.watchNetworkStatus(),
            deps: [NetworkService],
            multi: true
        },
        {
            provide: APP_BASE_HREF,
            useValue: '/',
        },
        {
            provide: LOCALE_ID,
            useValue: 'fr'
        },
        ApiService,
        IndexedDbService,
        OfflineService,
        AnomaliePilotageService,
        DynamicDialogRef,
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
    constructor() {
        setupBluebird();
    }
}
