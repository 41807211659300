/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { IdentiteUtilisateur } from '../models/identite-utilisateur';
import { AnomaliePilotageDtoSearchResult } from '../models/anomalie-pilotage-dto-search-result';
import { ExtractionInfosDTO } from '../models/extraction-infos-dto';
@Injectable({
  providedIn: 'root',
})
class UtilitaireService extends __BaseService {
  static readonly getApiV1UtilitairesTokenPath = '/api/v1/utilitaires/token';
  static readonly getApiV1UtilitairesMePath = '/api/v1/utilitaires/me';
  static readonly getApiV1UtilitairesAboutPath = '/api/v1/utilitaires/about';
  static readonly getApiV1UtilitairesAnomaliePilotagePath = '/api/v1/utilitaires/anomaliePilotage';
  static readonly postApiV1UtilitairesExtractionPath = '/api/v1/utilitaires/extraction';
  static readonly getApiV1UtilitairesExtractionKeyPath = '/api/v1/utilitaires/extraction/{key}';
  static readonly getApiV1UtilitairesSearchPath = '/api/v1/utilitaires/search';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @return Success
   */
  getApiV1UtilitairesTokenResponse(): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/utilitaires/token`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @return Success
   */
  getApiV1UtilitairesToken(): __Observable<string> {
    return this.getApiV1UtilitairesTokenResponse().pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @return Success
   */
  getApiV1UtilitairesMeResponse(): __Observable<__StrictHttpResponse<IdentiteUtilisateur>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/utilitaires/me`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<IdentiteUtilisateur>;
      })
    );
  }
  /**
   * @return Success
   */
  getApiV1UtilitairesMe(): __Observable<IdentiteUtilisateur> {
    return this.getApiV1UtilitairesMeResponse().pipe(
      __map(_r => _r.body as IdentiteUtilisateur)
    );
  }
  getApiV1UtilitairesAboutResponse(): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/utilitaires/about`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }  getApiV1UtilitairesAbout(): __Observable<null> {
    return this.getApiV1UtilitairesAboutResponse().pipe(
      __map(_r => _r.body as null)
    );
  }

  /**
   * @param params The `UtilitaireService.GetApiV1UtilitairesAnomaliePilotageParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  getApiV1UtilitairesAnomaliePilotageResponse(params: UtilitaireService.GetApiV1UtilitairesAnomaliePilotageParams): __Observable<__StrictHttpResponse<AnomaliePilotageDtoSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/utilitaires/anomaliePilotage`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<AnomaliePilotageDtoSearchResult>;
      })
    );
  }
  /**
   * @param params The `UtilitaireService.GetApiV1UtilitairesAnomaliePilotageParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * @return Success
   */
  getApiV1UtilitairesAnomaliePilotage(params: UtilitaireService.GetApiV1UtilitairesAnomaliePilotageParams): __Observable<AnomaliePilotageDtoSearchResult> {
    return this.getApiV1UtilitairesAnomaliePilotageResponse(params).pipe(
      __map(_r => _r.body as AnomaliePilotageDtoSearchResult)
    );
  }

  /**
   * @param params The `UtilitaireService.PostApiV1UtilitairesExtractionParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `format`:
   *
   * @return Success
   */
  postApiV1UtilitairesExtractionResponse(params: UtilitaireService.PostApiV1UtilitairesExtractionParams): __Observable<__StrictHttpResponse<string>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.format != null) __params = __params.set('format', params.format.toString());
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/utilitaires/extraction`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'text'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<string>;
      })
    );
  }
  /**
   * @param params The `UtilitaireService.PostApiV1UtilitairesExtractionParams` containing the following parameters:
   *
   * - `type`:
   *
   * - `format`:
   *
   * @return Success
   */
  postApiV1UtilitairesExtraction(params: UtilitaireService.PostApiV1UtilitairesExtractionParams): __Observable<string> {
    return this.postApiV1UtilitairesExtractionResponse(params).pipe(
      __map(_r => _r.body as string)
    );
  }

  /**
   * @param key undefined
   * @return Success
   */
  getApiV1UtilitairesExtractionKeyResponse(key: string): __Observable<__StrictHttpResponse<ExtractionInfosDTO>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/utilitaires/extraction/${encodeURIComponent(String(key))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<ExtractionInfosDTO>;
      })
    );
  }
  /**
   * @param key undefined
   * @return Success
   */
  getApiV1UtilitairesExtractionKey(key: string): __Observable<ExtractionInfosDTO> {
    return this.getApiV1UtilitairesExtractionKeyResponse(key).pipe(
      __map(_r => _r.body as ExtractionInfosDTO)
    );
  }

  /**
   * @param params The `UtilitaireService.GetApiV1UtilitairesSearchParams` containing the following parameters:
   *
   * - `valeur`:
   *
   * - `type`:
   *
   * - `inclureSig`:
   *
   * - `ced`:
   */
  getApiV1UtilitairesSearchResponse(params: UtilitaireService.GetApiV1UtilitairesSearchParams): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.valeur != null) __params = __params.set('valeur', params.valeur.toString());
    if (params.type != null) __params = __params.set('type', params.type.toString());
    if (params.inclureSig != null) __params = __params.set('inclureSig', params.inclureSig.toString());
    if (params.ced != null) __params = __params.set('ced', params.ced.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/utilitaires/search`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param params The `UtilitaireService.GetApiV1UtilitairesSearchParams` containing the following parameters:
   *
   * - `valeur`:
   *
   * - `type`:
   *
   * - `inclureSig`:
   *
   * - `ced`:
   */
  getApiV1UtilitairesSearch(params: UtilitaireService.GetApiV1UtilitairesSearchParams): __Observable<null> {
    return this.getApiV1UtilitairesSearchResponse(params).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module UtilitaireService {

  /**
   * Parameters for getApiV1UtilitairesAnomaliePilotage
   */
  export interface GetApiV1UtilitairesAnomaliePilotageParams {
    pageSize?: number;
    pageNumber?: number;
  }

  /**
   * Parameters for postApiV1UtilitairesExtraction
   */
  export interface PostApiV1UtilitairesExtractionParams {
    type?: any;
    format?: any;
  }

  /**
   * Parameters for getApiV1UtilitairesSearch
   */
  export interface GetApiV1UtilitairesSearchParams {
    valeur?: string;
    type?: string;
    inclureSig?: boolean;
    ced?: string;
  }
}

export { UtilitaireService }
