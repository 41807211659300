export enum StatutPoteauLabel {
    nonModifie = 'Non modifié',
    ajoute = 'Ajouté',
    deplace = 'Déplacé',
    supprime = 'Supprimé',
    modifie = 'Modifié',
}

export enum StatutPoteau {
    nonModifie = 'nonModifie',
    ajoute = 'ajoute',
    deplace = 'deplace',
    supprime = 'supprime',
    modifie = 'modifie'
}
