import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { OpenIdService } from '@ngxhq/security';
import { tap } from 'rxjs/operators';
import { LocalStorageIndex } from '../../../shared/enums/local-storage-index.enum';
import { logoutUser, getUserFromClaims } from '../actions/user.actions';

@Injectable()
export class UserEffects {
    public getUserInfo$ = createEffect(() => this.actions$.pipe(
        ofType(getUserFromClaims),
        tap(({ userInfo }) => {
            if (userInfo) {
                window.localStorage.setItem(LocalStorageIndex.USER_ID, userInfo!.id);
                window.localStorage.setItem(LocalStorageIndex.USER_EMAIL, userInfo!.email);
            }
        }),
    ), { dispatch: false });
    public logout$ = createEffect(() => this.actions$.pipe(
        ofType(logoutUser),
        tap(async () => {
            await this.openIdService.logout();
            window.localStorage.removeItem(LocalStorageIndex.USER_ID);
            window.localStorage.removeItem(LocalStorageIndex.USER_EMAIL);
            window.localStorage.removeItem(LocalStorageIndex.USER_ROLES);
            window.localStorage.removeItem(LocalStorageIndex.FIRME);
        }),
    ), { dispatch: false });

    constructor(private actions$: Actions, private openIdService: OpenIdService,
    ) { }
}
