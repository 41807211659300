/* tslint:disable */
import { NgModule, ModuleWithProviders } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ApiConfiguration, ApiConfigurationInterface } from './api-configuration';

import { AnomalieService } from './services/anomalie.service';
import { AnomalieAuditService } from './services/anomalie-audit.service';
import { ErreurSynchronisationService } from './services/erreur-synchronisation.service';
import { InspectionService } from './services/inspection.service';
import { LogService } from './services/log.service';
import { PointsAuditService } from './services/points-audit.service';
import { ProjetService } from './services/projet.service';
import { ProjetAuditsService } from './services/projet-audits.service';
import { TaxonomieService } from './services/taxonomie.service';
import { UtilitaireService } from './services/utilitaire.service';

/**
 * Provider for all Api services, plus ApiConfiguration
 */
@NgModule({
  imports: [
    HttpClientModule
  ],
  exports: [
    HttpClientModule
  ],
  declarations: [],
  providers: [
    ApiConfiguration,
    AnomalieService,
    AnomalieAuditService,
    ErreurSynchronisationService,
    InspectionService,
    LogService,
    PointsAuditService,
    ProjetService,
    ProjetAuditsService,
    TaxonomieService,
    UtilitaireService
  ],
})
export class ApiModule {
  static forRoot(customParams: ApiConfigurationInterface): ModuleWithProviders<ApiModule> {
    return {
      ngModule: ApiModule,
      providers: [
        {
          provide: ApiConfiguration,
          useValue: {rootUrl: customParams.rootUrl}
        }
      ]
    }
  }
}
