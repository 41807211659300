import { Component, ViewChild, OnDestroy } from '@angular/core';
import { ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';
import { Table } from 'primeng/table';
import { Observable, Subscription } from 'rxjs';
import { Taxonomie, TaxonomieRaw } from '../models/taxonomie.model';
import { ConfirmationService, SelectItem } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';
import { tap } from 'rxjs/operators';
import { TableColumns } from '../models/table-columns.model';
import { UiService } from '../../../services/ui.service';
import { TaxonomieGroupeValue } from '../models/taxonomie-groupe.enum';
import { select, Store } from '@ngrx/store';
import {
    getAllTaxonomieState,
    getTaxonomieGroupeOptions,
    getTaxonomieGroupeSpecifiqueOptions,
    getToutesTaxonomieGroupesOptions
} from '../../../core/store/selectors/taxonomie.selectors';
import { createOneTaxonomie, deleteOneTaxonomie, updateOneTaxonomie } from '../../../core/store/actions/taxonomie.action';

@Component({
    styleUrls: ['./pilotage-dialog.component.scss'],
    templateUrl: './pilotage-dialog.component.html',
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class PilotageDialogComponent extends ComponentBase implements OnDestroy {
    public taxonomiesGroupesGlobal$: Observable<SelectItem<string>[]>;
    public taxonomiesGroupes$: Observable<SelectItem<string>[]>;
    public fournisseurOptions$: Observable<SelectItem<string>[]>;
    public fournisseurAuditOptions$: Observable<SelectItem<string>[]>;

    public specificFournisseurHqd$: Observable<SelectItem<string>[]>;
    private specificFournisseurHqd: SelectItem<string>;

    private subscriptions: Subscription[] = [];
    public groupeSelectedValue: string = '';
    public originalValues: { [id: string]: Taxonomie; } = {};
    public taxonomieForm: FormGroup;
    public displayRowsAction: boolean[] = [];
    public originalNonEditedValues: any;
    public allOriginalData: Taxonomie[] = [];
    public allOriginalRawData: TaxonomieRaw[] = [];
    public filterGlobalDisabledAddButton = true;
    public editDisabledAddButton = false;
    public disableGlobalFilter = false;
    public colonnes: TableColumns[];

    public allTaxonomie: TaxonomieRaw[] = [];
    public taxonomieGroupeValue = TaxonomieGroupeValue;

    public currentAllTaxonomies$: Observable<Taxonomie[]> = this.store
        .pipe(
            select(getAllTaxonomieState),
            tap((taxonomies: Taxonomie[]) => this.populateData(taxonomies))
        );

    private idNew = 'new';
    private groupeTout = 'tout';

    @ViewChild('taxoTable', { static: false }) taxoTable: Table;

    constructor(
        private formBuilder: FormBuilder,
        private confirmationService: ConfirmationService,
        private readonly uiService: UiService,
        private readonly store: Store
    ) {
        super();
        this.taxonomiesGroupesGlobal$ = this.store.select(getToutesTaxonomieGroupesOptions());
        this.taxonomiesGroupes$ = this.store.select(getToutesTaxonomieGroupesOptions(false));
        this.fournisseurOptions$ = this.store.select(getTaxonomieGroupeOptions(this.taxonomieGroupeValue.FIRME));
        this.fournisseurAuditOptions$ = this.store.select(getTaxonomieGroupeOptions(this.taxonomieGroupeValue.FIRME_AUDIT));
        this.specificFournisseurHqd$ = this.store
            .pipe(
                select(getTaxonomieGroupeSpecifiqueOptions(this.taxonomieGroupeValue.FIRME, 'HQD')),
                tap((value: any) => {
                    this.specificFournisseurHqd = value[0];
                })
            );

        this.taxonomieForm = this.formBuilder.group({
            taxonomieFormDetails: this.formBuilder.array([]),
        });

        this.colonnes = [
            { field: 'code', header: 'Code', dataType: 'text', filterType: 'text' },
            { field: 'estActif', header: 'Actif', dataType: 'checkbox', filterType: 'checkbox' },
            { field: 'groupe', header: 'Groupe', dataType: 'dropdown', filterType: 'dropdown', width: '200px' },
            { field: 'proprietes', header: 'Propriété', dataType: 'dropdown', filterType: 'dropdown', width: '200px' },
        ];

        this.addSubscription();
    }

    private addSubscription(): void {
        this.subscriptions.push(
            this.specificFournisseurHqd$.subscribe(),
            this.fournisseurOptions$.subscribe(),
            this.taxonomiesGroupesGlobal$.subscribe(),
            this.taxonomiesGroupes$.subscribe(),
        );
    }

    public ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.uiService.openPilotageModal(false);
    }

    public filterGlobal(eventValue: string): void {
        if (eventValue !== '' && eventValue !== this.groupeTout) {
            this.groupeSelectedValue = eventValue;
            this.taxoTable.filterGlobal(eventValue, 'equals');
            this.filterGlobalDisabledAddButton = false;
        } else {
            this.filterGlobalDisabledAddButton = true;
            this.groupeSelectedValue = this.groupeTout;
            this.taxoTable.filterGlobal('', 'contains');
        }
    }

    public filtrerCheckbox(checked: boolean, field: string, match: string = 'equals'): void {
        this.filtrerTable(checked ? checked : '', field, match);
    }

    public filtrerTexte(input: any, field: string, match: string = 'contains'): void {
        this.taxoTable.filter(input.value, field, match);
    }

    public filtrerDropdown(event: string, field: string, match: string = 'contains'): void {
        this.filtrerTable(event, field, match);
    }

    private filtrerTable(value: any, field: string, match: string): void {
        this.taxoTable.filter(value, field, match);
    }

    public populateData(taxonomies: Taxonomie[]): void {
        const formatTaxo: TaxonomieRaw[] = [];
        taxonomies.forEach((taxo: Taxonomie) => {
            formatTaxo.push({
                ...taxo,
                proprietesJson: taxo.proprietes !== '' ? JSON.parse(taxo.proprietes!.replace(/\'/gm, '"')) : { firmeID: '' }
            });
        });

        this.allTaxonomie = formatTaxo;
        this.allOriginalRawData = formatTaxo;
        this.displayRowsActions(true);
    }

    private displayRowsActions(disabled: boolean): void {
        this.allOriginalRawData.forEach((data: Taxonomie) => {
            this.displayRowsAction[data.id! as any] = disabled;
        });
    }

    public getNomFirme(taxonomie: TaxonomieRaw): string {
        if (taxonomie.proprietesJson && taxonomie.proprietesJson.firmeID !== '') {
            const myTaxo = this.allOriginalRawData.find((taxo: Taxonomie) => {
                return taxo.id === taxonomie.proprietesJson!.firmeID;
            });

            return myTaxo ? myTaxo.code : '';
        } else {
            return '';
        }
    }

    public ajouterTaxonomie(): void {
        this.editDisabledAddButton = true;
        this.disableGlobalFilter = true;
        this.onAjouter();
        this.filterGlobal(this.groupeSelectedValue);
        this.displayRowsActions(false);
        this.displayRowsAction[this.idNew as any] = true;
        this.enableEditRow(this.allTaxonomie[0]);
    }

    public onAjouter(): void {
        this.allTaxonomie.unshift({
            code: '',
            estActif: true,
            groupe: (this.groupeSelectedValue !== '' && this.groupeSelectedValue !== this.groupeTout) ? this.groupeSelectedValue : '',
            id: this.idNew,
            proprietes: this.groupeSelectedValue === this.taxonomieGroupeValue.AUDITEURS ? this.specificFournisseurHqd.value : '',
            proprietesJson: {
                firmeID: this.groupeSelectedValue === this.taxonomieGroupeValue.AUDITEURS ? this.specificFournisseurHqd.value : ''
            },
        });
    }

    public enableEditRow(taxonomie: Taxonomie, resetInitValue = true): void {
        this.onRowEditInit(taxonomie, resetInitValue);
        this.taxoTable.initRowEdit(taxonomie);
    }

    private removeUnsavedTaxonomie(taxonomie: Taxonomie): void {
        this.allTaxonomie = this.allTaxonomie.filter((data) => data.id !== taxonomie.id);
    }

    public applyOriginalData(): void {
        const idFound = this.allTaxonomie.findIndex((taxo: TaxonomieRaw) => taxo.id === this.originalNonEditedValues.id);
        if (idFound !== -1) {
            this.allTaxonomie[idFound] = { ...this.originalNonEditedValues };
        }
    }

    public onRowEditInit(taxonomie: TaxonomieRaw, resetOriginal = true): void {
        if (resetOriginal) {
            this.originalNonEditedValues = JSON.parse(JSON.stringify(taxonomie));
        }
        this.displayRowsActions(false);
        this.displayRowsAction[taxonomie.id! as any] = true;
        this.editDisabledAddButton = true;
        this.disableGlobalFilter = true;
        this.taxoTable.editingRowKeys = { [taxonomie.id!]: true };
    }

    public onRowEditCancel(taxonomie: TaxonomieRaw): void {
        this.displayRowsActions(true);
        delete this.taxoTable.editingRowKeys[taxonomie.id!];

        if (taxonomie.id === this.idNew) {
            this.removeUnsavedTaxonomie(taxonomie);
        } else {
            this.applyOriginalData();
        }

        this.filterGlobal(this.groupeSelectedValue);
        this.editDisabledAddButton = false;
        this.disableGlobalFilter = false;
    }

    public onRowEditSave(taxonomie: TaxonomieRaw): void {
        this.displayRowsActions(true);
        this.editDisabledAddButton = false;
        this.disableGlobalFilter = false;
        delete this.taxoTable.editingRowKeys[taxonomie.id!];

        if (taxonomie.groupe === (this.taxonomieGroupeValue.FIRME || this.taxonomieGroupeValue.FIRME_AUDIT) &&
            taxonomie.proprietesJson && taxonomie.proprietesJson.firmeID !== '') {
            taxonomie.proprietes = '';
            taxonomie.proprietesJson.firmeID = '';
        }

        if (taxonomie.proprietesJson && taxonomie.proprietesJson.firmeID !== '') {
            taxonomie.proprietes = JSON.stringify(taxonomie.proprietesJson);
        }

        if (taxonomie.groupe.toLowerCase() !== this.taxonomieGroupeValue.FIRME &&
            taxonomie.groupe.toLowerCase() !== this.taxonomieGroupeValue.FIRME_AUDIT) {
            taxonomie.code = taxonomie.code.trim().toLowerCase();
        }

        taxonomie.id = taxonomie.id === this.idNew ? '' : taxonomie.id;

        const { proprietesJson, ...taxo } = taxonomie;

        if ((taxo as Taxonomie).id !== '') {
            this.store.dispatch(updateOneTaxonomie({ taxonomie: taxo }));
        } else {
            const { id, proprietes, ...base } = taxo;

            if (taxo.groupe === this.taxonomieGroupeValue.FIRME || taxo.groupe === this.taxonomieGroupeValue.FIRME_AUDIT) {
                this.store.dispatch(createOneTaxonomie({ taxonomie: base }));
            } else {
                const empOrInsp = {
                    ...base,
                    firmeId: proprietesJson?.firmeID || '',
                };

                this.store.dispatch(createOneTaxonomie({ taxonomie: empOrInsp }));
            }
        }
    }

    public onDelete(taxonomie: TaxonomieRaw): void {
        this.displayRowsActions(true);
        this.editDisabledAddButton = false;
        this.disableGlobalFilter = false;

        if (taxonomie.id !== undefined && taxonomie.id !== null && taxonomie.id !== '') {
            const { proprietesJson, ...taxo } = taxonomie;
            this.store.dispatch(deleteOneTaxonomie({ taxonomie: taxo }));
        }
    }

    public confirmDelete(taxonomie: TaxonomieRaw): void {
        this.confirmationService.confirm({
            message: taxonomie.id !== this.idNew && taxonomie.code !== ''
                ? `Voulez-vous vraiment supprimer le code ${taxonomie.code} ?`
                : `Voulez-vous vraiment supprimer cette taxonomie ?`,
            accept: () => {
                this.onDelete(taxonomie);
            },
            key: 'deleteConfirm'
        });
    }

    private isTaxonomieEdited(editedTaxonomie: any): boolean {
        return editedTaxonomie.code !== this.originalNonEditedValues.code
            || editedTaxonomie.estActif !== this.originalNonEditedValues.estActif
            || editedTaxonomie.groupe !== this.originalNonEditedValues.groupe
            || editedTaxonomie.proprietesJson.firmeID !== this.originalNonEditedValues.proprietesJson.firmeID;
    }

    public confirmCancelMAJ(taxonomie: TaxonomieRaw): void {
        if (this.isTaxonomieEdited(taxonomie)) {
            this.confirmationService.confirm({
                message: `Voulez-vous vraiment annuler les modifications ?`,
                accept: () => {
                    this.onRowEditCancel(taxonomie);
                },
                reject: () => {
                    this.enableEditRow(taxonomie, false);
                },
                key: 'updateConfirm'
            });
        } else {
            this.onRowEditCancel(taxonomie);
        }
    }

    public valueAreInvalide(taxonomie: TaxonomieRaw): boolean {
        if (taxonomie.groupe === this.taxonomieGroupeValue.FIRME || taxonomie.groupe === this.taxonomieGroupeValue.FIRME_AUDIT) {
            return taxonomie.code === '';
        } else {
            return taxonomie.code === '' || (taxonomie.proprietesJson !== undefined && taxonomie.proprietesJson.firmeID === '');
        }
    }

    public canDelete(taxonomie: TaxonomieRaw): boolean {
        if (taxonomie.groupe === this.taxonomieGroupeValue.FIRME || taxonomie.groupe === this.taxonomieGroupeValue.FIRME_AUDIT) {
            return this.allOriginalRawData.filter((taxo: TaxonomieRaw) => taxo.proprietesJson?.firmeID === taxonomie.id).length === 0;
        } else {
            return true;
        }
    }
}
