<ng-container *ngIf="projetAuditList.length">
    <div *ngIf="projetsAuditDownloadedLoading" class="projet-audit-list-mobile-dialog__loading">
        <app-loading-spinner></app-loading-spinner>
    </div>
    <p-contextMenu #menu [model]="menuItems" triggerEvent="click" [target]="actionsButton"></p-contextMenu>
    <p-table *ngIf="!projetsAuditDownloadedLoading" [value]="projetAuditList" selectionMode="single"
        [(selection)]="selectedProjetAudit" dataKey="id" (onRowSelect)="onRowSelect(selectedProjetAudit, menu)"
        (onRowUnselect)="onRowUnselect(menu)" responsiveLayout="scroll" styleClass="table-mobile">
        <ng-template pTemplate="header">
            <tr>
                <th scope="col" *ngFor="let col of columns" [style.width]="col.width">
                    {{col.header}}
                </th>
            </tr>
        </ng-template>
        <ng-template pTemplate="body" let-rowData>
            <tr [pSelectableRow]="rowData">
                <td *ngFor="let col of columns">
                    <ng-container [ngSwitch]="col.field">
                        <ng-container *ngSwitchCase="'statut'">
                            <span *ngIf="statutProjetAuditValue[rowData.statut] === statutProjetAuditValue.enCours">
                                <i class="pi pi-check"></i>
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchCase="'downloaded'">
                            <span *ngIf="isProjetAuditDownloaded(rowData)">
                                <i class="pi pi-check"></i>
                            </span>
                        </ng-container>
                        <ng-container *ngSwitchDefault>
                            {{rowData[col.field]}}
                        </ng-container>
                    </ng-container>
                </td>
            </tr>
        </ng-template>
    </p-table>
    <div class="projet-audit-list-mobile-dialog__info" *ngIf="selectedProjetAudit">
        <div class="info-properties">Projet:
            <div class="info-properties__value">{{selectedProjetAudit.nom}}</div>
        </div>
        <div class="info-properties">Statut:
            <div class="info-properties__value">{{statutProjetAuditValue[selectedProjetAudit.statut!]}}</div>
        </div>
        <div class="info-properties">Assigné le:
            <div class="info-properties__value">{{selectedProjetAudit.assigneLe | date: 'dd/MM/YYYY' }}
            </div>
        </div>
        <div class="info-properties">
            <div class="info-properties__label">Nombre total de points d'inspection à auditer:</div>
            <div class="info-properties__value">{{selectedProjetAudit.nombreTotalDePoteaux}}</div>
        </div>
        <button #actionsButton pButton pRipple icon="pi pi-ellipsis-v"
            class="p-button-rounded p-button-secondary actions-button"
            (click)="onActionsButtonClick($event, menu, selectedProjetAudit)"></button>
    </div>
</ng-container>
<p *ngIf="!projetAuditList.length">
    Aucun projet d'audit activé ou téléchargé.
</p>