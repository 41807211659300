import { Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MessageService } from 'primeng/api';
import { FileUpload } from 'primeng/fileupload';
import { PhotoEntity } from '../../../core/api/client/models';

@Component({
    selector: 'app-photos-upload',
    templateUrl: './photos-upload.component.html',
    styleUrls: ['./photos-upload.component.scss']
})
export class PhotosUploadComponent {
    public photos: PhotoEntity[] = [];
    @ViewChild('fileUpload') fileUpload: FileUpload;
    @Input() set currentPhotos(value: PhotoEntity[]) {
        this.photos = value;
        if (this.fileUpload) {
            this.fileUpload.clear();
        }
    }
    @Input() maxPhotos = 3;
    @Output() upload = new EventEmitter<File[]>();

    public uploadedPhotos: File[] = [];

    constructor(
        private messageService: MessageService
    ) { }

    onSelect(event: any) {
        const newPhotosCount = event.currentFiles.length;
        const totalPhotosCount = this.photos.length + newPhotosCount;

        if (totalPhotosCount > this.maxPhotos) {
            this.showMaxPhotosError();
            const excessPhotosCount = totalPhotosCount - this.maxPhotos;
            this.fileUpload.files.splice(-excessPhotosCount, excessPhotosCount);
        }

        const duplicatePhotos = this.getDuplicatePhotos(this.fileUpload.files);
        if (duplicatePhotos.length > 0) {
            this.showDuplicateNamesError();
            this.fileUpload.files = this.fileUpload.files.filter(file => !duplicatePhotos.includes(file));
        }

        this.uploadedPhotos = this.fileUpload.files;
        this.upload.emit(this.uploadedPhotos);
    }

    private showMaxPhotosError(): void {
        this.messageService.add({
            severity: 'error',
            summary: 'Limite de photos atteinte',
            detail: `Vous ne pouvez pas dépasser la limite de ${this.maxPhotos} photos.`
        });
    }

    private getDuplicatePhotos(newPhotos: File[]): File[] {
        const currentPhotoNames = this.photos.map(photo => photo.nom);
        return newPhotos.filter(file => currentPhotoNames.includes(file.name));
    }

    private showDuplicateNamesError(): void {
        this.messageService.add({
            severity: 'error',
            summary: 'Nom de photo existant',
            detail: 'Une des photos téléversées possède déjà un nom existant.'
        });
    }
}
