import { Component, OnDestroy, ViewChild, AfterViewInit, OnInit, ViewChildren, QueryList, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { ComponentFeatures, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { cloneDeep, concat, omit } from 'lodash';
import { ConfirmationService, MenuItem, MessageService } from 'primeng/api';
import { Observable, Subscription } from 'rxjs';
import { filter, tap, takeUntil, take } from 'rxjs/operators';
import {
    canAnnulerProjet,
    canApprouverProjet,
    canAssignerProjet,
    canEnvoyerAvis,
    canModifierProjet,
    canRapportCreationAvis,
    canValiderProjet,
    canCreateProjetAudit,
    canAssignerProjetAQ,
    getUserRoles,
    canAssignerFirmeProjet,
    canAssignerProjetPourCorrection,
    canRejeterProjet
} from '../../../../core/store/selectors/user.selectors';
import {
    getAssigneProjetError,
    getAssigneProjetSuccess,
    isProjetsLoading,
    selectAllProjects
} from '../../store/projet/projet.selectors';
import { ProjetAction } from '../../../inspection/models/projet-action.enum';
import { territoireList } from '../../../../enums/territoire.enum';
import { StatutProjetValue, StatutProjet, statutProjetName } from '../../models/statut-projet.enum';
import { projetTypesMap, projetTypesListe } from '../../models/projet-type.enum';
import { ModifierProjet } from '../../models/modifier-projet.model';
import { MultiSelectFilters } from '../../../../shared/models/multi-select-filters.model';
import { TableColumn } from '../../../../shared/models/table-column.model';
import { Projet } from '../../models/projet.model';
import * as ProjetActions from '../../store/projet/projet.actions';
import * as SharedActions from '../../../../state/shared/shared.actions';
import { UiService } from '../../../../services/ui.service';
import { UtilisateurService } from '../../../../services/utilisateur.service';
import { UserRole } from '../../../../shared/models/user-roles.model';
import { dateTimeChange, saveFile } from '../../../../shared/utils';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ContextMenu } from 'primeng/contextmenu';
import { RapportCreationAvisDialogComponent } from '../rapports/rapport-creation-avis-dialog/rapport-creation-avis-dialog.component';
import { Table } from 'primeng/table';
import { utils, WorkBook, write } from 'xlsx';
import { DataExtractionFormat } from '../../../../shared/enums/data-extraction-format.enum';
import { TaxonomieGroupeValue } from '../../../pilotage/models/taxonomie-groupe.enum';
import { Severite } from '../../../../enums/severite';
import { selectUserInformation } from '../../../../core/store/selectors/userInformation.selector';
import { UserInformation } from '../../../../shared/models/user-informations.model';
import {
    getAnnuleProjetPermission,
    getAssigneProjetPermission,
    getApprouveProjetPermission,
    getAssigneProjetAQPermission,
    getCreateAuditPermission,
    getEnvoyerAvisPermission,
    getModifierProjetPermission,
    getRapportCreationAvisPermission,
    getValiderProjetPermission,
    getAssigneFirmeProjetPermission,
    getAssigneProjetPourCorrectionPermission,
    getRejeterProjetPermission
} from '../../utils/projet-permissions.utils';
import { AnomalieListComponent } from '../../../anomalie/components/anomalie-list/anomalie-list.component';
import { TypeReseau } from '../../models/type-reseau.enum';
import * as ProjetHistoryActions from '../../store/projet-history/projet-history.actions';
import { getProjetHistory, getProjetHistoryError } from '../../store/projet-history/projet-history.selectors';
import { ProjetHistory } from '../../models/projet-history.model';
import { CaptureMenuItem } from '../../models/capture-menu-item.model';
import { getGroupeFullTaxonomie } from '../../../../core/store/selectors/taxonomie.selectors';
import { Taxonomie } from '../../../pilotage/models/taxonomie.model';
import { MapService } from '../../../../map/services/map.service';
import { CreateProjetAuditDialogComponent } from '../../../audit/components/create-projet-audit-dialog/create-projet-audit-dialog.component';
import { getDataExtraction } from '../../../../state/shared/shared.selectors';
import { DataExtractionType } from '../../../../shared/photos/models';

@Component({
    selector: 'app-projet-list-dialog',
    templateUrl: './projet-list-dialog.component.html',
    styleUrls: ['./projet-list-dialog.component.scss']
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class ProjetListDialogComponent extends BaseComponent implements OnInit, OnDestroy, AfterViewInit {
    private canAssignerProjet$: Observable<boolean> = this.store.select(canAssignerProjet);
    private canAssignerProjet: boolean = false;
    private canAssignerProjetAQ$: Observable<boolean> = this.store.select(canAssignerProjetAQ);
    private canAssignerProjetAQ: boolean = false;
    private canAssignerFirmeProjet$: Observable<boolean> = this.store.select(canAssignerFirmeProjet);
    private canAssignerFirmeProjet: boolean = false;
    private canAnnulerProjet$: Observable<boolean> = this.store.select(canAnnulerProjet);
    private canAnnulerProjet: boolean = false;
    private canModifierProjet$: Observable<boolean> = this.store.select(canModifierProjet);
    private canModifierProjet: boolean = false;
    private canApprouverProjet$: Observable<boolean> = this.store.select(canApprouverProjet);
    private canApprouverProjet: boolean = false;
    private canValiderProjet$: Observable<boolean> = this.store.select(canValiderProjet);
    private canValiderProjet: boolean = false;
    private canEnvoyerAvis$: Observable<boolean> = this.store.select(canEnvoyerAvis);
    private canEnvoyerAvis: boolean = false;
    private canRapportCreationAvis$: Observable<boolean> = this.store.select(canRapportCreationAvis);
    private canRapportCreationAvis: boolean = false;
    private canCreateAudit$: Observable<boolean> = this.store.select(canCreateProjetAudit);
    private canCreateAudit: boolean = false;
    private canAssignPourCorrection: boolean = false;
    private canRejeterProjet = false;

    private disableAssigneProjet = false;
    private disableAnnuleProjet = false;
    private disableModifierProjet = false;
    private disableValiderProjet = false;
    private disableApprouverProjet = false;
    private disableEnvoyerAvis = false;
    private disableCreateAudit = false;
    private disableRapportCreationAvis = false;
    private disableAssigneProjetAQ = false;
    private disableAssigneFirmeProjet = false;
    private disableAssignerProjetPourCorrection = false;
    private disableRejeterProjet = false;

    public extractFileFormat = DataExtractionFormat;
    public projets: Projet[];
    public inspecteurSelect: string;
    public items: MenuItem[];
    public colonnes: TableColumn[];
    public extractMenuItems: MenuItem[];
    public projets$: Observable<Projet[]> = this.store.select(selectAllProjects); // TODO USE NEXT LINE
    // public projets$: Observable<ProjetCompletDto[]> = this.store.select(getProjetInspectionList);

    public firme$: Observable<Taxonomie[]> = this.store.select(getGroupeFullTaxonomie(TaxonomieGroupeValue.FIRME));
    public firmeList: Taxonomie[] = [];
    public selectedProjet: Projet;
    public projetTypesListe: Array<{ champ: string, valeur: string }> = projetTypesListe;
    public projetTypesMap = projetTypesMap;
    public statutProjet = StatutProjet;
    public statutProjetName = statutProjetName;
    public territoireList: string[] = territoireList;
    public typeReseau = TypeReseau;
    public modificationForm: FormGroup;
    public filtersEntries: MultiSelectFilters[] = [];
    public tableFiltersItems: MultiSelectFilters[] = [];
    private notPreSelectedStatut: string[] = [this.statutProjet.annule, this.statutProjet.approbationFinaleHQ, this.statutProjet.erreur];
    private subscriptions: Subscription[] = [];
    private exportedProjets: Projet[] = [];
    private currentUserRole: UserRole;
    private currentUserInfos: UserInformation;
    public displayModificationProjet = false;
    public dataExtractionLoading = false;
    public displayAssignation = false;
    public createProjetAuditDialogVisible: boolean;
    public assigneProjetDialogVisible: boolean;
    public rejeterProjetDialogVisible: boolean;
    public assigneProjetDialogText: string;
    public assigneProjetDialogHeader: string;
    public assigneProjetDialogAction: ProjetAction;
    public taxonomieGroupes: string[];
    public first: number = 0;
    public statutProjetEnum = StatutProjet;

    public projetsLoading$: Observable<boolean> = this.store.select(isProjetsLoading);

    @ViewChild('dt', { static: false }) dt: Table;
    @ViewChildren('ellipsisv') ellipsisv: QueryList<ElementRef>;

    constructor(
        private readonly fb: FormBuilder,
        private confirmationService: ConfirmationService,
        private store: Store<State>,
        private readonly uiService: UiService,
        private mapService: MapService,
        public readonly utilisateurService: UtilisateurService,
        private readonly dialogService: DialogService,
        private messageService: MessageService,
        public ref: DynamicDialogRef,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(ProjetActions.fetchProjets()); // TODO USE INSPECTION ACTIONS loadProjetsInspectionList

        this.subscriptions.push(this.firme$.pipe(tap((firmes: Taxonomie[]) => {
            this.firmeList = firmes;
            this.subscriptions.push(
                this.projets$.pipe(tap(projet => this.initProjets((projet as Projet[])))).subscribe()
            );
        }
        )).subscribe());

        this.subscriptions.push(this.canAssignerProjet$.pipe(tap((canAssigner) => this.canAssignerProjet = canAssigner)).subscribe());
        this.subscriptions.push(this.canAnnulerProjet$.pipe(tap((canAnnuler) => this.canAnnulerProjet = canAnnuler)).subscribe());
        this.subscriptions.push(this.canModifierProjet$.pipe(tap((canModifier) => this.canModifierProjet = canModifier)).subscribe());
        this.subscriptions.push(this.canValiderProjet$.pipe(tap((canValider) => this.canValiderProjet = canValider)).subscribe());
        this.subscriptions.push(this.canApprouverProjet$.pipe(tap((canApprouver) => this.canApprouverProjet = canApprouver)).subscribe());
        this.subscriptions.push(this.canEnvoyerAvis$.pipe(tap((canEnvoyer) => this.canEnvoyerAvis = canEnvoyer)).subscribe());
        this.subscriptions.push(this.canRapportCreationAvis$.pipe(tap((canRapport) => this.canRapportCreationAvis = canRapport)).subscribe());
        this.subscriptions.push(this.canCreateAudit$.pipe(tap((canAudit) => this.canCreateAudit = canAudit)).subscribe());
        this.subscriptions.push(this.canAssignerProjetAQ$.pipe(tap((canAssignerAQ) => this.canAssignerProjetAQ = canAssignerAQ)).subscribe());
        this.subscriptions.push(this.canAssignerFirmeProjet$.pipe(tap((canAssignerFirme) => this.canAssignerFirmeProjet = canAssignerFirme)).subscribe());

        this.initForm();
        this.initWebMenu();
        this.initDownloadButton();
        this.initMultiSelectStatut();
        this.subscribeToDataExtractionLoading();
        this.subscribeToAssigneProjet();
        this.subscribeToGetUserRoles();
        this.subscribeToSelectUserInformations();
        this.subscribeToCanAssignProjetPourCorrection();
        this.subscribeToCanRejeterProjet();
    }

    ngAfterViewInit(): void {
        if (this.dt) {
            this.onFilterChange(this.filtersEntries, 'statut');
        }
        this.cd.detectChanges();
    }

    public initDownloadButton() {
        this.extractMenuItems = [
            {
                label: 'GeoJSON',
                icon: 'fas fa-globe',
                command: () => { this.exportToZipFile(DataExtractionFormat.GEOJSON); },
            },
            {
                label: 'JSON',
                icon: 'fas fa-code',
                command: () => { this.exportToZipFile(DataExtractionFormat.JSON); },
            },
            {
                label: 'SQLite',
                icon: 'fas fa-database',
                command: () => { this.exportToZipFile(DataExtractionFormat.SQLITE); }, disabled: true
            },
        ];
    }

    public initMultiSelectStatut() {
        (Object.values(this.statutProjet)).forEach((statut) => {
            const newStatut = {
                name: StatutProjetValue[statut],
                code: statut
            };
            this.tableFiltersItems.push(newStatut);

            if (!this.notPreSelectedStatut.includes(statut)) {
                this.filtersEntries.push(newStatut);
            }
        });

        this.tableFiltersItems.sort((a: MultiSelectFilters, b: MultiSelectFilters) => {
            if (a.name < b.name) { return -1; }
            if (a.name > b.name) { return 1; }
            return 0;
        });
    }

    public onFilterChange(value: any, fieldName: string) {
        const values = value.map((selectedValue: any) => selectedValue.code);
        this.dt.filter(values, fieldName, 'in');
    }

    private getFirme(firmeId: string | undefined): string {
        return this.firmeList.filter((firme: Taxonomie) => firme.id === firmeId)[0]?.code;
    }

    private initProjets(projets: Projet[]) {
        if (!projets?.length) {
            return;
        }

        const projetsModifiers = cloneDeep(projets);
        projetsModifiers.forEach((projet) => {
            projet.creeLe = dateTimeChange(projet.creeLe, 'YYYY-MM-dd');
            projet.assigneLe = dateTimeChange(projet.assigneLe, 'YYYY-MM-dd');
            projet.inspectionDateDeDebut = dateTimeChange(projet.inspectionDateDeDebut, 'YYYY-MM-dd');
            projet.inspectionDateDeFin = dateTimeChange(projet.inspectionDateDeFin, 'YYYY-MM-dd');
            projet.dateTransfertSap = dateTimeChange(projet.dateTransfertSap, 'YYYY-MM-dd');
            projet.assigneAQLe = dateTimeChange(projet.assigneAQLe, 'YYYY-MM-dd');
            projet.pourcentageCompletion = this.statistiquesInspection(projet);
            projet.statutModifieLe = dateTimeChange(projet.statutModifieLe, 'YYYY-MM-dd');
            projet.firmeName = projet.firme ? this.getFirme(projet.firme) : 'À déterminer';
        });

        this.projets = projetsModifiers;
        this.exportedProjets = this.projets.map(projet => {
            const exportedProjet = omit(projet, ['geometrie', 'firmeName']);
            exportedProjet.firme = projet.firmeName;
            return exportedProjet;
        });
    }

    private initForm() {
        this.colonnes = [
            { field: 'nom', header: 'Nom du projet' },
            { field: 'type', header: 'Type de projet' },
            { field: 'statut', header: 'Statut' },
            { field: 'statutModifieLe', header: 'Date statut' },
            { field: 'numeroOrdreDeTravail', header: `Numéro d'ordre de travail` },
            { field: 'creeLe', header: `Créé le` },
            { field: 'firmeName', header: 'Firme' },
            { field: 'territoire', header: 'Territoire' },
            { field: 'inspectionAnnee', header: 'Année', width: '75px' },
            { field: 'assigneA', header: 'Assigné inspecteur', width: '220px' },
            { field: 'assigneLe', header: 'Assigné le', width: '220px' },
            { field: 'assigneAQA', header: 'Assigné AQ', width: '220px' },
        ];

        if (!this.utilisateurService.isInspecteur) {
            const statiqueColonnes = [
                { field: 'nombreTotalPoteaux', header: 'Nombre de poteaux total' },
                { field: 'pourcentageCompletion', header: 'Pourcentage complété' }
            ];
            this.colonnes = concat(this.colonnes, statiqueColonnes);
        };
    }

    private initWebMenu() {
        this.items = [
            {
                label: 'Zoom sur',
                icon: 'pi pi-fw pi-search-plus',
                disabled: false,
                command: () => { this.mapService.zoomProjet(this.selectedProjet); }
            },
            {
                label: 'Assigner inspecteur',
                icon: 'pi pi-fw pi-user',
                visible: this.canAssignerProjet,
                disabled: this.disableAssigneProjet,
                command: () => this.assigneProjetConfirm(
                    this.selectedProjet,
                    [TaxonomieGroupeValue.INSPECTEURS],
                    `Choisissez l'inspecteur qui réalisera le projet: :`,
                    `Veuillez assigner le projet`,
                    ProjetAction.ASSIGNER)
            },
            {
                label: 'Assigner pour correction',
                icon: 'pi pi-fw pi-user',
                visible: this.canAssignPourCorrection,
                disabled: this.disableAssignerProjetPourCorrection,
                command: () => this.assigneProjetConfirm(
                    this.selectedProjet,
                    [TaxonomieGroupeValue.INSPECTEURS, TaxonomieGroupeValue.CONTROLEUR_QUALITE],
                    `Choisissez l'utilisateur qui réalisera les corrections :`,
                    `Veuillez assigner le projet`,
                    ProjetAction.ASSIGNER_DEMANDE_CORRECTION)
            },
            {
                label: 'Assigner AQ',
                icon: 'pi pi-fw pi-check-circle',
                visible: this.canAssignerProjetAQ,
                disabled: this.disableAssigneProjetAQ,
                command: () => this.assigneProjetConfirm(
                    this.selectedProjet,
                    [TaxonomieGroupeValue.CONTROLEUR_QUALITE],
                    `Choisissez l'utilisateur qui réalisera le contrôle qualité du projet :`,
                    `Veuillez assigner le projet`,
                    ProjetAction.ASSIGNER_AQ)
            },
            {
                label: 'Assigner une firme',
                icon: 'pi pi-fw pi-reply',
                visible: this.canAssignerFirmeProjet,
                disabled: this.disableAssigneFirmeProjet,
                command: () => this.assigneProjetConfirm(
                    this.selectedProjet,
                    [TaxonomieGroupeValue.FIRME],
                    `Choisissez la firme qui réalisera le projet :`,
                    `Veuillez assigner la firme`,
                    ProjetAction.ASSIGNER_FIRME)
            },
            {
                label: 'Annuler',
                icon: 'pi pi-times',
                visible: this.canAnnulerProjet,
                disabled: this.disableAnnuleProjet,
                command: () => this.annuleProjetConfirm(this.selectedProjet)
            },
            {
                label: 'Modifier',
                icon: 'pi pi-pencil',
                visible: this.canModifierProjet,
                disabled: this.disableModifierProjet,
                command: () => this.openModifierProjet(this.selectedProjet)
            },
            {
                label: 'Valider',
                icon: 'fas fa-check-double',
                visible: this.canValiderProjet,
                disabled: this.disableValiderProjet,
                command: () => this.validerProjet(this.selectedProjet)
            },
            {
                label: 'Approuver',
                icon: 'fas fa-flag-checkered',
                visible: this.canApprouveProjet(),
                disabled: this.disableApprouverProjet,
                command: () => this.onApprouveProjet(this.selectedProjet)
            },
            {
                label: 'Créer les avis prioritaires',
                icon: 'pi pi-fw pi-exclamation-circle',
                visible: this.canEnvoyerAvis,
                disabled: this.disableEnvoyerAvis,
                command: () => this.onEnvoyerAvis(this.selectedProjet)
            },
            {
                label: `Créer projet d'audit`,
                icon: 'fas fa-clipboard-check',
                visible: this.canCreateAudit,
                disabled: this.disableCreateAudit,
                command: () => this.openCreateProjetAuditDialog(this.selectedProjet)
            },
            {
                label: `Rapport de création d'avis`,
                icon: 'pi pi-fw pi-file',
                visible: this.canRapportCreationAvis,
                disabled: this.disableRapportCreationAvis,
                command: () => {
                    this.showRapportCreationAvis(this.selectedProjet);
                }
            },
            {
                label: `Rapport d'anomalies`,
                icon: 'pi pi-fw pi-list',
                command: () => {
                    this.ref.close(true);
                    this.showRapportAnomalie(this.selectedProjet);
                }
            },
            {
                label: 'Rejeter',
                icon: 'pi pi-ban',
                visible: this.canRejeterProjet,
                disabled: this.disableRejeterProjet,
                command: () => this.openRejeterDialog()
            },
            {
                label: `Extraire historique`,
                icon: 'pi pi-fw pi-download',
                command: () => {
                    this.subscribeToProjetHistory(this.selectedProjet);
                }
            }
        ];
    }

    public getProjetInfo(projet: Projet) {
        this.selectedProjet = projet;

        this.disableAssigneProjet = !getAssigneProjetPermission(projet, this.currentUserRole, this.currentUserInfos);
        this.disableAnnuleProjet = !getAnnuleProjetPermission(projet, this.currentUserRole, this.currentUserInfos);

        //Même si l'utilisateur a les permissions pour annuler un projet, dès qu'il y a un point d'inspection inspecté, on ne permet plus d'annuler le projet
        if (projet.nombrePoteauxInspectes) {
            this.disableAnnuleProjet = true;
        }

        this.disableModifierProjet = !getModifierProjetPermission(projet, this.currentUserRole, this.currentUserInfos);
        this.disableValiderProjet = !getValiderProjetPermission(projet, this.currentUserRole, this.currentUserInfos);
        this.disableRapportCreationAvis = !getRapportCreationAvisPermission(projet);
        this.disableApprouverProjet = !getApprouveProjetPermission(projet);
        this.disableRejeterProjet = !getRejeterProjetPermission(projet);

        this.disableEnvoyerAvis = true;
        this.disableEnvoyerAvis = !getEnvoyerAvisPermission(projet, this.currentUserRole, this.currentUserInfos);

        //Même si l'utilisateur a les permissions pour créer les avis prioritaires, s'il n'y a pas d'anomalie, on ne lui permet pas de créer les avis
        if (!projet.nombrePoteauxAvecAnomalie) {
            this.disableEnvoyerAvis = true;
        }

        this.disableCreateAudit = !getCreateAuditPermission(projet);
        this.disableAssigneProjetAQ = !getAssigneProjetAQPermission(projet);
        this.disableAssigneFirmeProjet = !getAssigneFirmeProjetPermission(projet);
        this.disableAssignerProjetPourCorrection = !getAssigneProjetPourCorrectionPermission(projet);

        this.initWebMenu();
    }

    public onActionsButtonClick(event: MouseEvent, contextMenu: ContextMenu, rowData: Projet) {
        this.getProjetInfo(rowData);
        event.stopPropagation();
        event.preventDefault();
        contextMenu.model.forEach((menuItem: CaptureMenuItem) => {
            menuItem['data'] = rowData;
        });
        contextMenu.show(event);
    }

    private openRejeterDialog() {
        this.rejeterProjetDialogVisible = true;
    }

    private canApprouveProjet(): boolean {
        const isAllowed = this.canApprouverProjet;
        if (isAllowed && this.utilisateurService.isRole(UserRole.TECH_INGENIEURS_RESEAU)) {
            return this.selectedProjet?.creePar?.toLowerCase() === this.utilisateurService.email?.toLowerCase();
        };
        return isAllowed;
    }

    private subscribeToSelectUserInformations() {
        this.store.select(selectUserInformation).pipe(
            filter(userInfos => !!userInfos),
            takeUntil(this.destroyed)
        ).subscribe(currentUserInfos => {
            this.currentUserInfos = currentUserInfos;
        });
    }

    private subscribeToGetUserRoles() {
        this.store.select(getUserRoles).pipe(
            filter(userRole => !!userRole),
            takeUntil(this.destroyed)
        ).subscribe(currentUserRole => {
            this.currentUserRole = currentUserRole[0];
        });
    }

    private subscribeToCanAssignProjetPourCorrection() {
        this.store.select(canAssignerProjetPourCorrection).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canAssignPourCorrection => {
            this.canAssignPourCorrection = _canAssignPourCorrection;
        });
    }

    private subscribeToCanRejeterProjet() {
        this.store.select(canRejeterProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canRejeterProjet => {
            this.canRejeterProjet = _canRejeterProjet;
        });
    }

    private subscribeToAssigneProjet() {
        this.store.select(getAssigneProjetSuccess).pipe(
            filter(success => !!success),
            takeUntil(this.destroyed)
        ).subscribe(assigneResult => {
            if (assigneResult.success) {
                this.refreshProjetList();
                this.messageService.add(
                    {
                        severity: Severite.info,
                        closable: true,
                        summary: `Assignation d'un projet`,
                        detail: `L'assignation a été effectuée avec succès.`
                    });
            }
        });

        this.store.select(getAssigneProjetError).pipe(
            filter(error => !!error),
            takeUntil(this.destroyed)
        ).subscribe(error => {
            this.messageService.add(
                {
                    severity: Severite.erreur,
                    closable: true,
                    summary: `Assignation d'un projet`,
                    detail: `${error.error.erreurs}` ?? `Une erreur est survenue lors de l'assignation d'un projet.`
                }
            );
        });
    }

    private subscribeToDataExtractionLoading() {
        this.store.select(getDataExtraction)
            .pipe(
                filter(loading => !!loading),
                takeUntil(this.destroyed)
            ).subscribe((loading) => {
                if (loading) {
                    this.dataExtractionLoading = false;
                }
            });
    }

    private refreshProjetList() {
        this.store.dispatch(ProjetActions.clearProjetData());
        this.store.dispatch(ProjetActions.fetchProjets());
    }

    private subscribeToProjetHistory(projet: Projet) {
        this.store.dispatch(ProjetHistoryActions.loadProjetHistory({ projetId: projet.id! }));

        this.store.select(getProjetHistory)
            .pipe(
                filter(success => !!success),
                take(1)
            )
            .subscribe(projetHistory => {
                this.exportExcelProjetHistory(projet, projetHistory!);
            });

        this.store.select(getProjetHistoryError)
            .pipe(
                filter(error => !!error),
                takeUntil(this.destroyed)
            ).subscribe(() => {
                this.messageService.add(
                    {
                        severity: Severite.erreur,
                        summary: `Erreur Extraction historique`,
                        detail: `Une erreur est survenue lors de l'extraction de l'historique.`,
                        closable: true
                    });
            });
    }

    public validerProjet(projet: Projet) {
        const statut: ModifierProjet = { action: ProjetAction.VALIDER };
        this.store.dispatch(ProjetActions.onModifierStatutProjet({ projet: projet, statut }));
    }

    private annuleProjetConfirm(projet: Projet) {
        if (projet.statut === StatutProjet.enCreation || projet.statut === StatutProjet.nouveau ||
            projet.statut === StatutProjet.inspectionAssigne || projet.statut === StatutProjet.erreur) {
            this.confirmationService.confirm({
                message: 'Voulez-vous vraiment annuler le projet ?',
                accept: () => {
                    const modificationData: ModifierProjet = {
                        action: ProjetAction.ANNULER,
                    };

                    if (projet.statut && (projet.statut === StatutProjet.nouveau || projet.statut === StatutProjet.erreur)) {
                        this.store.dispatch(ProjetActions.supprimerProjet({ projet: projet }));
                        this.messageService.add(
                            {
                                severity: Severite.info,
                                closable: true,
                                summary: 'Annulation de projet',
                                detail: `Le projet ${projet.nom} sera supprimé`
                            });
                        this.refreshProjetList();
                    } else {
                        this.store.dispatch(ProjetActions.annulerProjet({ projet: projet, modificationData }));
                        this.messageService.add(
                            {
                                severity: Severite.info,
                                closable: true,
                                summary: 'Annulation de projet',
                                detail: `Le projet ${projet.nom} sera annulé`
                            });
                        this.refreshProjetList();
                    }
                    this.displayAssignation = false;
                },
                key: 'annuleConfirm',
            });
        }
    }

    private assigneProjetConfirm(projet: Projet, groupes: string[], text: string, header: string, action: ProjetAction) {
        this.taxonomieGroupes = groupes;
        if ((groupes.length === 1 && groupes[0] === TaxonomieGroupeValue.INSPECTEURS && projet.assigneA) ||
            (groupes.length === 1 && groupes[0] === TaxonomieGroupeValue.CONTROLEUR_QUALITE && projet.assigneAQA)) {
            this.confirmationService.confirm({
                message: `Voulez-vous vraiment assigner le projet de nouveau ?<br><br>
                    La réassignation entrainera la perte des données non synchronisées.`,
                accept: () => {
                    this.openAssigneProjetDialog(projet, text, header, action);
                },
                key: 'assigneProjetConfirm',
            });
        } else {
            this.openAssigneProjetDialog(projet, text, header, action);
        }
    }

    private showRapportCreationAvis(projet: Projet) {
        this.ref = this.dialogService.open(RapportCreationAvisDialogComponent,
            {
                header: `Rapport de création d'avis`,
                width: '100%',
                height: '100%',
                modal: false,
                styleClass: 'desktop-dialog',
                data: { projet: projet }
            });
    }

    private showRapportAnomalie(projet: Projet) {
        this.ref = this.dialogService.open(AnomalieListComponent,
            {
                header: `Rapport d'anomalies - ` + this.selectedProjet.nom,
                width: '100%',
                height: '45%',
                modal: false,
                maximizable: true,
                styleClass: 'desktop-dialog',
                style: {
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'align-self': 'flex-start',
                    // eslint-disable-next-line @typescript-eslint/naming-convention
                    'margin-top': 'var(--spacing-xxl)',
                },
                data: { projet: projet }
            });
    }

    public exportToZipFile(format: DataExtractionFormat) {
        this.dataExtractionLoading = true;
        this.store.dispatch(SharedActions.startDataExtraction({ typeExtraction: DataExtractionType.INSPECTION, format }));
    }

    public exportToCsv() {
        const worksheet = utils.json_to_sheet(this.exportedProjets);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const workbook: WorkBook = { Sheets: { 'listeProjets': worksheet }, SheetNames: ['listeProjets'] };
        const excelBuffer: any = write(workbook, { bookType: 'csv', type: 'array' });
        const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        saveFile(excelBuffer, 'Liste des projets', FILE_TYPE, '.csv');
    }

    public exportToExcel() {
        const worksheet = utils.json_to_sheet(this.exportedProjets);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const workbook: WorkBook = { Sheets: { 'listeProjets': worksheet }, SheetNames: ['listeProjets'] };
        const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
        const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        saveFile(excelBuffer, 'Liste des projets', FILE_TYPE, '.xlsx');
    }

    public exportExcelProjetHistory(projet: Projet, projetHistory: ProjetHistory[]) {
        const worksheet = utils.json_to_sheet(projetHistory);
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const workbook: WorkBook = { Sheets: { 'historiqueProjet': worksheet }, SheetNames: ['historiqueProjet'] };
        const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
        const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

        saveFile(excelBuffer, projet.nom + '_' + dateTimeChange(new Date().toString(), 'YYYYMMdd'), FILE_TYPE, '.xlsx');
    }

    private openModifierProjet(projet: Projet) {
        this.modificationForm = this.fb.group({
            numeroOrdreDeTravail: [projet.numeroOrdreDeTravail, Validators.maxLength(8)],
            annee: [projet.inspectionAnnee],
        });
        this.displayModificationProjet = true;
    }

    public closeModification() {
        this.displayModificationProjet = false;
    }

    public onModifierCurrentProjet() {
        const projet: Projet = this.selectedProjet;
        const projetId: string = projet.id as string;
        const modificationData: ModifierProjet = {
            numeroOrdreDeTravail: this.modificationForm.value.numeroOrdreDeTravail,
            inspectionAnnee: this.modificationForm.value.annee,
        };
        this.store.dispatch(ProjetActions.onModifierProjet({ projetId: projetId, modificationData: modificationData }));
        this.displayModificationProjet = false;
    }

    private statistiquesInspection(projet: Projet): number {
        const nombreTotalPoteaux: number | undefined = projet.nombreTotalPoteaux;
        const nombrePoteauxIgnores: number = projet.nombrePoteauxIgnores || 0;
        const nombrePoteauxInspectes: number = projet.nombrePoteauxInspectes || 0;

        if (nombreTotalPoteaux) {
            return (nombrePoteauxIgnores + nombrePoteauxInspectes) / nombreTotalPoteaux;
        }

        return 0;
    }

    private onApprouveProjet(projet: Projet) {
        const statutProjet: ModifierProjet = { action: ProjetAction.TERMINER };
        this.store.dispatch(ProjetActions.onApprouveProjet({ projet: projet, statut: statutProjet }));
    }

    private onEnvoyerAvis(projet: Projet) {
        this.store.dispatch(ProjetActions.onEnvoyerAvisSAP({ projetId: projet.id }));
    }

    openCreateProjetAuditDialog(projet: Projet) {
        this.ref = this.dialogService.open(CreateProjetAuditDialogComponent, {
            header: 'Générer un audit pour le projet sélectionné',
            width: '38%',
            showHeader: true,
            closable: true,
            data: {
                selectedProjet: projet,
            },
            styleClass: 'desktop-dialog',
        });
    }

    openAssigneProjetDialog(projet: Projet, text: string, header: string, action: ProjetAction) {
        this.selectedProjet = projet;
        this.assigneProjetDialogVisible = true;
        this.assigneProjetDialogHeader = header;
        this.assigneProjetDialogText = text;
        this.assigneProjetDialogAction = action;
    }

    closeAssigneProjetDialog() {
        this.assigneProjetDialogVisible = false;
    }

    public closeModificationDialog() {
        this.displayAssignation = false;
        this.inspecteurSelect = '';
    }

    public paginate(event: any) {
        this.first = event.first;
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach(subscription => subscription.unsubscribe());
        this.uiService.openProjetListModal(false);
    }
}
