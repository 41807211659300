<div class="properties">
    <ng-container *ngFor="let data of currentDatas | keyvalue : orderValues; let i = index">
        <div class="properties__container" [ngClass]="[verticalProperties.includes(data.key) ? 'column' : 'row', i%2 ? 'transparent': 'gray']">
            <div class="container__name">
                {{data.key}}&nbsp;:
            </div>
            <div class="container__value" [class.leftPadding]="verticalProperties.includes(data.key)">
                <span class="value__text">{{ data.value }}</span>
            </div>
        </div>
    </ng-container>
</div>
