import { Pipe, PipeTransform } from '@angular/core';

/*
 * Arrondi à la baisse à l'entier inférieur et retourne le pourcentage
 *   Retourne la valeur avec un espace inséquable entre le chiffre et
 *   le symbole de pourcentage.
 *   Si la valeur n'est pas un nombre, on retourne "0 %"
 * Usage:
 *   value | myFloorPercent
 * Example:
 *   {{ 0.17658 | myFloorPercent }}
 *   retourne la valeur: "17 %"
*/
@Pipe({ name: 'myFloorPercent' })
export class MyFloorPercentPipe implements PipeTransform {
    transform(value: number | null): string {
        if (value !== NaN && value !== null) {
            return `${Math.floor(value * 100)}${String.fromCharCode(160)}%`;
        }
        return `0${String.fromCharCode(160)}%`;
    }
}
