import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { ModifierProjet, Projet, ProjetAction } from '../../models';
import { Store } from '@ngrx/store';
import { rejeterProjet } from '../../store/projet/projet.actions';
import { State } from '../../../../state/app.state';

@Component({
    selector: 'app-rejeter-projet-dialog',
    templateUrl: './rejeter-projet-dialog.component.html',
    styleUrls: ['./rejeter-projet-dialog.component.scss']
})
export class RejeterProjetDialogComponent extends BaseComponent implements OnDestroy {
    public form: FormGroup;

    @Input() projet: Projet;
    @Input() visible: boolean;

    @Output() visibleChange = new EventEmitter<boolean>();

    constructor(
        private store: Store<State>,
        private readonly fb: FormBuilder,
    ) {
        super();

        this.initForm();
    }

    private initForm() {
        this.form = this.fb.group({
            justificatif: [null, Validators.required]
        });
    }

    public onRejeter() {
        if (this.projet) {
            const { justificatif } = this.form.controls;
            const data: ModifierProjet = { action: ProjetAction.REJETER, remarque: justificatif.value };
            this.store.dispatch(rejeterProjet({ projet: this.projet, rejeterData: data })); // TODO
            this.closeDialog();
        }
    }

    public closeDialog() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
        this.initForm();
    }
}
