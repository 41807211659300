import { Component, OnDestroy, OnInit,/* EventEmitter, Input, Output*/ } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { filter, takeUntil } from 'rxjs/operators';
import { State } from '../../../../state/app.state';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { getCreateProjetAuditLoading, getCreateProjetAuditSuccess, getCreateProjetAuditError } from '../../state/audit.selectors';
import * as AuditActions from '../../state/audit.actions';
import * as InspectionActions from '../../../inspection/state/inspection.actions';
import { CreateProjetAuditDto } from '../../../../core/api/client/models';
import { Severite } from '../../../../enums/severite';
import { MessageService } from 'primeng/api';
import { Projet } from '../../../projet/models/projet.model';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-audit-create-dialog',
    templateUrl: './create-projet-audit-dialog.component.html',
    styleUrls: ['./create-projet-audit-dialog.component.scss']
})
export class CreateProjetAuditDialogComponent extends BaseComponent implements OnInit, OnDestroy {
    public projetAuditForm: FormGroup;
    public saveLoading = false;
    public audit: CreateProjetAuditDto | null;

    private _projet: Projet; // TODO CHANGE TO ProjetCompletDto
    private set projet(value: Projet) {
        this._projet = value;
        if (this._projet) {
            this.initForm();
        }
    }

    constructor(
        private messageService: MessageService,
        private store: Store<State>,
        private config: DynamicDialogConfig,
        public ref: DynamicDialogRef,
    ) {
        super();
        this.projet = this.config.data.selectedProjet;
    }

    ngOnInit() {
        this.subscribeToCreateProjetAuditLoading();
        this.subscribeToProjetAuditCreateSuccess();
        this.subscribeToProjetAuditCreateError();
    }

    private initForm() {
        this.projetAuditForm = new FormGroup({
            seuilPourcentage: new FormControl(null, [Validators.pattern('[0-9]+'), Validators.min(1), Validators.max(100)]),
            seuilEntier: new FormControl(null, [Validators.pattern('[0-9]+'), Validators.min(1), Validators.max(this._projet.nombreTotalPoteaux!)]),
            nom: new FormControl(this._projet.nom + '_AUDIT'),
        });
    }

    private subscribeToCreateProjetAuditLoading() {
        this.store.select(getCreateProjetAuditLoading)
            .pipe(takeUntil(this.destroyed))
            .subscribe(loading => this.saveLoading = loading);
    }

    private subscribeToProjetAuditCreateSuccess() {
        this.store.select(getCreateProjetAuditSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            )
            .subscribe(createResult => {
                if (createResult.success) {
                    this.store.dispatch(InspectionActions.loadProjetInspectionList());
                    this.messageService.add(
                        {
                            severity: Severite.success,
                            closable: true,
                            summary: `Création de projet d'audit`,
                            detail: `Le projet ${createResult.projetAudit!.nom} a bien été créé.`,
                            life: 15000
                        });

                    this.closeCreateProjetAuditDialog();
                }
            });
    }

    private subscribeToProjetAuditCreateError() {
        this.store.select(getCreateProjetAuditError)
            .pipe(
                filter(error => !!error),
                takeUntil(this.destroyed)
            )
            .subscribe(error => {
                this.messageService.add(
                    {
                        severity: Severite.erreur,
                        closable: true,
                        summary: `Création de projet d'audit`,
                        detail: `${error.error.erreurs}`
                    }
                );
            });
    }

    public save() {
        if (this.projetAuditForm.valid) {
            const audit: CreateProjetAuditDto = {
                seuilPourcentage: this.projetAuditForm.controls.seuilPourcentage.value,
                seuilEntier: this.projetAuditForm.controls.seuilEntier.value,
            };
            this.store.dispatch(AuditActions.startCreateProjetAudit({ projetId: this._projet.id!, projetAudit: audit }));
        }
    }

    public closeCreateProjetAuditDialog() {
        this.ref.close();
    }

    ngOnDestroy() {
        this.store.dispatch(AuditActions.clearProjetAuditData());
    }
}
