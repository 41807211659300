import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { ProjetService } from '../../services/projet.service';
import * as ProjetHistoryActions from './projet-history.actions';

@Injectable()
export class ProjetHistoryEffects {

    loadProjetHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(ProjetHistoryActions.loadProjetHistory),
            mergeMap(action =>
                this.projetService.getProjetHistory(action.projetId)
                    .pipe(
                        map(projetHistory => ProjetHistoryActions.loadProjetHistorySuccess({ projetHistory })),
                        catchError((error: unknown) => of(ProjetHistoryActions.loadProjetHistoryError({ error })))
                    )));
    });

    constructor(
        private actions$: Actions,
        private projetService: ProjetService
    ) { }
}
