export enum AccessibleCamionValue {
    inconnu = ' ',
    oui = 'Oui',
    non = 'Non',
}

export enum AccessibleCamion {
    inconnu = 'inconnu',
    oui = 'oui',
    non = 'non',
}

export const accessibleCamionListe = Object.keys(AccessibleCamion).filter((key: string) => key !== 'inconnu').map((key: string) => (
    { champ: key, valeur: AccessibleCamionValue[key] }
));

export const accessibleCamionMap = new Map<AccessibleCamion, string>([
    [AccessibleCamion.inconnu, AccessibleCamionValue.inconnu],
    [AccessibleCamion.oui, AccessibleCamionValue.oui],
    [AccessibleCamion.non, AccessibleCamionValue.non],
]);
