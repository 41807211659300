export enum FabricantValue {
    cam = 'CAM',
    carney = 'CARNEY',
    ccc = 'CCC',
    domtar = 'DOMTAR',
    gmi = 'GMI',
    ipb = 'IPB',
    kms = 'KMS',
    lg = 'LG',
    lpb = 'LPB',
    mac = 'MAC',
    nwp = 'NWP',
    pbrl = 'PBRL',
    sj = 'SJ',
    tdl = 'TDL',
    wpi = 'WPI',
    autre = 'AUTRE',
}

export enum Fabricant {
    cam = 'cam',
    carney = 'carney',
    ccc = 'ccc',
    domtar = 'domtar',
    gmi = 'gmi',
    ipb = 'ipb',
    kms = 'kms',
    lg = 'lg',
    lpb = 'lpb',
    mac = 'mac',
    nwp = 'nwp',
    pbrl = 'pbrl',
    sj = 'sj',
    tdl = 'tdl',
    wpi = 'wpi',
    autre = 'autre',
}

export const fabricantListe = Object.keys(Fabricant).map(key => ({ champ: key, valeur: FabricantValue[key] }));

export const fabricantMap = new Map<Fabricant, string>([
    [Fabricant.cam, Fabricant.cam],
    [Fabricant.carney, Fabricant.carney],
    [Fabricant.ccc, Fabricant.ccc],
    [Fabricant.domtar, Fabricant.domtar],
    [Fabricant.gmi, Fabricant.gmi],
    [Fabricant.ipb, Fabricant.ipb],
    [Fabricant.kms, Fabricant.kms],
    [Fabricant.lg, Fabricant.lg],
    [Fabricant.lpb, Fabricant.lpb],
    [Fabricant.mac, Fabricant.mac],
    [Fabricant.nwp, Fabricant.nwp],
    [Fabricant.pbrl, Fabricant.pbrl],
    [Fabricant.sj, Fabricant.sj],
    [Fabricant.tdl, Fabricant.tdl],
    [Fabricant.wpi, Fabricant.wpi],
    [Fabricant.autre, Fabricant.autre],
]);
