<div class="anomalie-audit__container">
    <ng-container *ngIf="anomaliesAuditPhotosLoading && (photosUploading$ | async) === false; else anomaliesAuditList">
        <p-skeleton min-width="150px" height="150px"
            [style]="{'margin-bottom': '10px', 'margin-right': '20px'}"></p-skeleton>
    </ng-container>
    <ng-template #anomaliesAuditList>
        <div *ngFor="let anomalieAudit of anomaliesAudit">
            <app-info-card [infos]="anomalieAudit.auditData"
                [loadingPhotos]="anomaliesAuditPhotosLoading && (photosUploading$ | async) === false">
                <div class="title">
                    <div>{{anomalieAudit.element}}</div>
                    <div>{{anomalieAudit.type}}</div>
                    <div class="title__icon" *ngIf="isAuditeur">
                        <span pTooltip="Modifier la non-conformité" tooltipPosition="top">
                            <i class="fa-regular fa-pen-to-square"
                                (click)="onOpenAnomalieAuditNonConformeDialog(anomalieAudit)">
                            </i>
                        </span>
                        <span pTooltip="Supprimer la non-conformité" tooltipPosition="top">
                            <i class="fa-regular fa-trash-can"
                                (click)="onDeleteAnomalieAuditNonConforme(anomalieAudit)">
                            </i>
                        </span>
                    </div>
                </div>
            </app-info-card>
        </div>
    </ng-template>
</div>