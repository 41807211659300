import { AbstractControl, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { ValidatorService } from './validator.service';

export enum HQPattern {
    URL = 'url',
    EMAIL = 'email',
    INTEGER = 'integer',
    NUMBER = 'number',
    ALPHA = 'alpha',
    ALPHANUMERIC = 'alphanumeric',
    PHONE = 'phone',
    CODEABARRES = 'codeabarres',
    LCLCL = 'lclcl',
}

export class HQValidators {

    static pattern(pattern: RegExp, msg?: string | null, invalidLabel?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, pattern, msg, invalidLabel);
        };
    }

    static alpha(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.ALPHA, msg);
        };
    }

    static alphanumeric(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.ALPHANUMERIC, msg);
        };
    }

    static email(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.EMAIL, msg);
        };
    }

    static integer(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.INTEGER, msg);
        };
    }

    static number(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.NUMBER, msg);
        };
    }

    static phone(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.PHONE, msg);
        };
    }

    static url(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.URL, msg);
        };
    }

    static codeabarres(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.CODEABARRES, msg);
        };
    }

    static lclcl(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.formatValidator(control, HQPattern.LCLCL, msg);
        };
    }

    static requiredTrue(msg?: string): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            return HQValidators.confirmCheckbox(control, msg);
        };
    }

    static isControlEmpty(control: AbstractControl): boolean {
        return control.pristine || control.value === undefined || control.value == null || control.value.length === 0;
    }

    static isControlValidAgainstRegexp(control: AbstractControl, re: RegExp): boolean {
        // test the pattern provided by user
        return re.test(control.value);
    }

    static isControlValidAgainstPattern(control: AbstractControl, pattern: HQPattern): boolean {
        const ALPHA_REGEXP = /^[a-zA-Z-_\s]+$/;
        const ALPHANUMERIC_REGEXP = /^[a-z\d\-_\s]+$/i;
        const INTEGER_REGEXP = /\d+/;
        const NUMBER_REGEXP = /^-?[\d.,]+(?:e-?\d+)?$/;
        const patternTypeToValidator: { [pattern: string]: (value: any) => boolean } = {};
        patternTypeToValidator[HQPattern.URL] = (v) => ValidatorService.isValidUrl(v);
        patternTypeToValidator[HQPattern.EMAIL] = (v) => ValidatorService.isValidEmail(v);
        patternTypeToValidator[HQPattern.PHONE] = (v) => ValidatorService.isValidPhone(v);
        patternTypeToValidator[HQPattern.ALPHA] = (v) => ALPHA_REGEXP.test(v);
        patternTypeToValidator[HQPattern.ALPHANUMERIC] = (v) => ALPHANUMERIC_REGEXP.test(v);
        patternTypeToValidator[HQPattern.INTEGER] = (v) => INTEGER_REGEXP.test(v) && Number.isInteger(+v);
        patternTypeToValidator[HQPattern.NUMBER] = (v) => NUMBER_REGEXP.test(v) && ValidatorService.isNumeric(v.toString());
        const validatorFunction = patternTypeToValidator[pattern];
        if (!validatorFunction) {
            throw new Error(`Le validator pour ${pattern} n'est pas implémenté!`);
        }
        return validatorFunction(control.value);
    }

    static formatValidator(control: AbstractControl, pattern: HQPattern | RegExp, msg?: string | null, customErrorKey?: string): ValidationErrors | null {

        const DEFAULT_ERROR_KEY = 'invalid';

        const errorKey = DEFAULT_ERROR_KEY;

        // ne va pas vérifier que le validator est requis, Veuillez utiliser Validators.Required pour cela!
        if (HQValidators.isControlEmpty(control)) {
            return null;
        }

        let isValid = false;
        const obj: ValidationErrors = {};

        // Si un msg de retour est fourni
        if (msg) {
            obj['invalidMsg'] = msg;
        }

        // included Pattern
        if (typeof pattern === 'string') {

            // check if we have this pattern
            if (Object.values(HQPattern).includes(pattern)) {

                if (customErrorKey) {
                    obj[customErrorKey] = true;
                } else if (msg) { //  name of the pattern provided just like Angular
                    obj[errorKey] = true;
                } else {
                    obj[pattern.toString()] = true;
                }

                switch (pattern) {
                    case HQPattern.URL:
                        isValid = ValidatorService.isValidUrl(control.value);
                        break;

                    case HQPattern.EMAIL:
                        isValid = ValidatorService.isValidEmail(control.value);
                        break;

                    case HQPattern.PHONE:
                        isValid = ValidatorService.isValidPhone(control.value);
                        break;

                    case HQPattern.ALPHA:
                        const ALPHA_REGEXP = /^[a-zA-Z-_\s]+$/;
                        isValid = ALPHA_REGEXP.test(control.value);
                        break;

                    case HQPattern.ALPHANUMERIC:
                        const ALPHANUMERIC_REGEXP = /^[a-z\d\-_\s]+$/i;
                        isValid = ALPHANUMERIC_REGEXP.test(control.value);
                        break;

                    case HQPattern.INTEGER:
                        const INTEGER_REGEXP = /[0-9]+/;
                        if (INTEGER_REGEXP.test(control.value)) {
                            isValid = Number.isInteger(+control.value);
                        }
                        break;

                    case HQPattern.NUMBER:
                        const NUMBER_REGEXP = /^-?[\d.,]+(?:e-?\d+)?$/;
                        if (NUMBER_REGEXP.test(control.value)) {
                            isValid = ValidatorService.isNumeric(control.value.toString());
                        }
                        break;

                    case HQPattern.CODEABARRES:
                        const cab = String(control.value).toUpperCase();
                        const CAB_REGEXP = /^[A-HJ-NP-Z0-9]{6}$/;
                        isValid = CAB_REGEXP.test(cab);

                        if (isValid) {
                            const binary_string = cab;
                            const len = binary_string.length;
                            const bytes = new Uint8Array(len);
                            for (let i = 0; i < len; i++) {
                                bytes[i] = binary_string.charCodeAt(i);
                            }

                            const mod34 = (Math.pow(bytes[0], 1) + Math.pow(bytes[1], 2) + Math.pow(bytes[2], 3) + Math.pow(bytes[3], 4) + Math.pow(bytes[4], 5)) % 34;
                            const caracteresPermis = 'ABCDEFGHJKLMNPQRSTUVWXYZ0123456789';

                            if (mod34 === caracteresPermis.indexOf(control.value[5])) {
                                isValid = true;
                            } else {
                                isValid = false;
                            }
                        }

                        break;

                    case HQPattern.LCLCL:
                        const LCLCL_REGEXP = /^[a-zA-Z][0-9][a-zA-Z][0-9][a-zA-Z]$/;
                        isValid = LCLCL_REGEXP.test(control.value);
                        break;

                    default:
                        throw new Error(`Le validator pour ${pattern} n'est pas implémenté!`);

                }

                if (isValid) {
                    return null;
                }

            } else {
                throw new Error(`HQPattern ${pattern} ne se retrouve pas dans notre collection!`);
            }

        } else {
            isValid = HQValidators.isControlValidAgainstRegexp(control, pattern);
            if (!isValid && !customErrorKey) {
                customErrorKey = DEFAULT_ERROR_KEY;
            }
        }

        if (isValid) {
            return null;
        }

        if (customErrorKey) {
            obj[customErrorKey] = true;
        } else if (msg) { //  name of the pattern provided just like Angular
            obj[DEFAULT_ERROR_KEY] = true;
        } else { //  name of the pattern provided just like Angular
            obj[pattern.toString()] = true;
        }
        return obj;
    }

    static confirmCheckbox(control: AbstractControl, msg?: string | null) {
        const element = Validators.requiredTrue(control);
        if (element) {
            if (msg) {
                return {
                    invalid: true,
                    invalidMsg: msg
                };
            } else {
                return {
                    requiredTrue: true
                };
            }
        }

        return null;
    }
}
