import { createSelector } from '@ngrx/store';
import { SelectItem } from 'primeng/api';
import { CoreState, getCoreState } from '../reducers';
import { TaxonomieState, taxonomieSelectors } from '../reducers/taxonomie.reducer';
import { Taxonomie } from '../../../features/pilotage/models/taxonomie.model';
import { TaxonomieGroupeValue, taxonomieNames } from '../../../features/pilotage/models/taxonomie-groupe.enum';


export const getTaxonomieStore = createSelector(
    getCoreState,
    (state: CoreState) => state && state.taxonomie
);

export const getAllTaxonomieState = createSelector(
    getTaxonomieStore,
    taxonomieSelectors.selectAll
);

export const isTaxonomiesFetched = () => createSelector(
    getTaxonomieStore,
    (taxonomiesState: TaxonomieState,) => taxonomiesState.fetched
);

export const getTaxonomiePourGroupes = (groupes: string[]) => createSelector(
    getAllTaxonomieState,
    (taxonomies: Taxonomie[],) => taxonomies.filter(taxo => groupes.includes(taxo.groupe))
        .map(filteredTaxonomie => filteredTaxonomie.code)
);

export const getTaxonomiePourGroupe = (groupe: string) => getTaxonomiePourGroupes([groupe]);

export const getGroupeFullTaxonomie = (groupe: string) => createSelector(
    getAllTaxonomieState,
    (taxonomies: Taxonomie[]) => taxonomies.filter(taxo => taxo.groupe === groupe)
);

export const getTaxonomieById = (id: string, groupe: string | null = null) => createSelector(
    getAllTaxonomieState,
    (taxonomies: Taxonomie[]) => {
        const taxonomie = taxonomies.filter(taxo => taxo.id === id && (taxo.groupe === (groupe || taxo.groupe)));

        return taxonomie.length ? taxonomie[0] : null;
    }
);

export const getTaxonomieByIdPourGroupe = (id: string) => createSelector(
    getAllTaxonomieState,
    (taxonomies: Taxonomie[],) => taxonomies.filter(taxo => taxo.id === id)[0]
);

export const getTaxonomiePourGroupesParFirme = (groupes: string[], firme: string) => createSelector(
    getAllTaxonomieState,
    (taxonomies: Taxonomie[],) => {
        return taxonomies
            .filter(taxo => groupes.includes(taxo.groupe) && taxo.proprietes === `{"firmeID":"${firme}"}`)
            .map(filteredTaxonomie => filteredTaxonomie.code);
    }
);

export const getTaxonomieGroupeParFirme = (groupe: string, firme: string) => getTaxonomiePourGroupesParFirme([groupe], firme);

export const getTaxonomieGroupeOptions = (nomgroupe: string) => createSelector(
    getAllTaxonomieState,
    (taxonomies: Taxonomie[]) => {
        const items: SelectItem[] = [];
        taxonomies.filter((taxonomie) => taxonomie.groupe === nomgroupe).forEach((taxonomie: Taxonomie) => {
            items.push({
                label: taxonomie.code,
                value: taxonomie.id
            } as SelectItem);
        });
        return items;
    }
);

export const getTaxonomieGroupeSpecifiqueOptions = (nomgroupe: string, specificValue: string) => createSelector(
    getTaxonomieGroupeOptions(nomgroupe),
    (taxonomies: SelectItem[]) => {
        const items: SelectItem[] = [];
        if (taxonomies && taxonomies.length > 0) {
            taxonomies.forEach((item: SelectItem<any>) => {
                if (item.label && item.label.toLowerCase() === specificValue.toLowerCase()) {
                    items.push(item);
                }
            });
        }
        return items;
    }
);

export const getToutesTaxonomieGroupesOptions = (enableGroupeTout: boolean = true) => createSelector(
    getAllTaxonomieState,
    (_: Taxonomie[],) => {
        const groupeValues: SelectItem<string>[] = [];
        if (enableGroupeTout) {
            groupeValues.push({
                label: 'tout',
                value: 'tout',
            });
        }

        (Object.values(TaxonomieGroupeValue)).forEach((groupeValue) => {
            groupeValues.push({
                label: taxonomieNames.get(groupeValue),
                value: groupeValue,
            });
        });

        return groupeValues;
    }
);
