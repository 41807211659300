import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { Observable, filter, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../state/audit.state';
import * as AuditActions from '../../state/audit.actions';
import { ActionProjetAudit, PatchProjetAuditDto, ProjetAuditDto } from '../../../../core/api/client/models';
import { getPointsActiveProjetAuditSuccess, getPointsAuditLoading } from '../../state/audit.selectors';
import { StatutPointAudit } from '../../models/statut-point-audit.enum';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-complete-projet-audit-dialog',
    templateUrl: './complete-projet-audit-dialog.component.html',
    styleUrls: ['./complete-projet-audit-dialog.component.scss']
})
export class CompleteProjetAuditDialogComponent extends BaseComponent implements OnInit {

    public get projetAudit(): ProjetAuditDto {
        return this.config.data.projet;
    }

    public form: FormGroup;
    public pointsAuditLoading$: Observable<boolean>;
    public nbPointsAuditAAuditer: number;
    public nbPointsAuditTotal: number;
    public nbNonConformites: number;

    constructor(
        private store: Store<State>,
        public ref: DynamicDialogRef,
        private config: DynamicDialogConfig
    ) {
        super();
    }

    ngOnInit(): void {
        this.pointsAuditLoading$ = this.store.select(getPointsAuditLoading);

        this.subscribeToPointsActiveProjetAuditSuccess();
    }

    private subscribeToPointsActiveProjetAuditSuccess() {
        this.store.select(getPointsActiveProjetAuditSuccess)
            .pipe(
                filter(pointsAudit => !!pointsAudit),
                takeUntil(this.destroyed)
            )
            .subscribe(pointsAudit => {
                if (pointsAudit) {
                    this.nbNonConformites = pointsAudit.filter(point => point.statut === StatutPointAudit.nonConforme).length;
                    this.nbPointsAuditTotal = pointsAudit.filter(point => point.statut !== StatutPointAudit.exclu).length;
                    this.nbPointsAuditAAuditer = pointsAudit.filter(point => point.statut === StatutPointAudit.aAuditer).length;

                    if (this.nbPointsAuditAAuditer > 0) {
                        this.form = new FormGroup({ justificatif: new FormControl(null, [Validators.required]) });
                    }
                }
            });
    }

    public completeProjetAudit() {
        const data: PatchProjetAuditDto = { action: ActionProjetAudit.completer, remarque: this.form?.controls?.justificatif?.value };
        this.store.dispatch(AuditActions.completeProjetAudit({ projetAuditId: this.projetAudit.id, completeData: data }));
        this.ref.close();
    }

    public closeCompleteProjetAuditDialog() {
        this.ref.close();
    }
}
