export enum PointInspectionProperties {
    inspectionId = 'inspectionId',
    poteauId = 'poteau_id',
    projetId = 'projetId',
    pointInspectionId = 'pointInspectionId',
    statut = 'Statut',
    justification = 'Justification',
    remarque = 'Remarque',
    remarquePoteau = 'Remarque poteau',
    inspecteLe = 'Inspecté le',
    inspectePar = 'Inspecté par',
    codeABarres = 'Code à barres',
    lclcl = 'LCLCL',
    proprietaire = 'Propriétaire',
    montage = 'Montage',
    longueur = 'Longueur (pied)',
    hauteurHorsSol = 'Hauteur hors sol (mètre)',
    classe = 'Classe',
    materiau = 'Matériau',
    anneeInstallation = `Année d'installation`,
    anneeFabrication = 'Année de fabrication',
    statutPoteau = 'Statut (poteau)',
    usage = 'Usage',
    inclinaison = 'Inclinaison (degré)',
    accessibleCamion = 'Accessible camion nacelle',
    indEquipementMajeur = `Présence d'appareil lourd`,
    adresseTravaux = 'Adresse des travaux',
    localisation = 'Localisation',
    essence = 'Essence du poteau',
    typePosition = 'Type de position',
    fabricant = 'Fabricant',
    traitementInitial = 'Traitement initial',
    photos = 'photos',
    pointsAudit = 'pointsAudit',
    anomalies = 'anomalies',
    malt = 'Malt',
    tuteur = 'Tuteur',
    luminaire = 'Luminaire',
    jambeDeForce = 'Jambe de force',
    liaisonAerosouterraine = 'Liaison aérosouterraine',
    panneau = 'Panneau municipal',
    equipementsMineurs = 'Équipements mineurs',
}
