<hq-side-bar (expandedChange)='expanded=$event'>
    <ng-container>
        <hq-menu-item label="Carte" icon="fas fa-map" routerLink="carte">
        </hq-menu-item>
    </ng-container>
    <ng-container>
        <hq-menu-item label='Pilotage' icon='fas fa-cogs' routerLink="pilotage">
        </hq-menu-item>
    </ng-container>
</hq-side-bar>
