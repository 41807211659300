
import { Guid } from 'guid-typescript';
import { AnomalieAuditDto, PointAuditDto, PointInspectionDto, StatutAudit } from '../../../core/api/client/models';
import { StatutPointAudit } from '../models/statut-point-audit.enum';

export function getStatutGlobal(pointAudit: PointAuditDto): StatutAudit {
    const anomaliesAudit = pointAudit.anomaliesAudit;
    let statutGlobal = StatutPointAudit.aAuditer;

    if (pointAudit.statut === StatutPointAudit.ignore) {
        statutGlobal = StatutPointAudit.ignore;
    } else if (pointAudit.statut === StatutPointAudit.exclu) {
        statutGlobal = StatutPointAudit.exclu;
    } else if (pointAudit.statut === StatutPointAudit.conforme && anomaliesAudit.every(anomalie => anomalie.statut === StatutPointAudit.conforme)) {
        statutGlobal = StatutPointAudit.conforme;
    } else if (pointAudit.statut === StatutPointAudit.nonConforme || anomaliesAudit.find(anomalie => anomalie.statut === StatutPointAudit.nonConforme)) {
        if (pointAudit.statut !== StatutPointAudit.aAuditer && anomaliesAudit.every(anomalie => anomalie.statut !== StatutPointAudit.aAuditer)) {
            statutGlobal = StatutPointAudit.nonConforme;
        }
    }
    return statutGlobal;
}

export function generateAnomalieAudit(pointInspection: PointInspectionDto, pointAuditId: string): AnomalieAuditDto[] {
    return pointInspection.anomalies.map(anomalie => (
        {
            id: Guid.create().toString(),
            statut: StatutPointAudit.aAuditer,
            geometrie: anomalie.geometrie,
            pointAuditId: pointAuditId,
            anomalieInspectionId: anomalie.id,
            photos: []
        }
    ));
}

