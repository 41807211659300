import { Component, Injector } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
    selector: 'app-form-field-group',
    templateUrl: './form-field-group.component.html'
})
export class FormFieldGroupComponent extends FormFieldBaseComponent {

    constructor(injector: Injector) {
        super(injector);
    }
}
