import { createSelector } from '@ngrx/store';
import { CoreState, getCoreState } from '../../../../core/store/reducers';
import { anomalieSelector } from './anomalie.reducer';

export const getAnomalieStore = createSelector(
    getCoreState,
    (state: CoreState) => state.anomalie
);

export const getAllAnomaliesState = createSelector(
    getAnomalieStore,
    anomalieSelector.selectAll
);
