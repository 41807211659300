import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'app-loading-spinner',
    templateUrl: './loading-spinner.component.html',
    styleUrls: ['./loading-spinner.component.scss']
})
export class LoadingSpinnerComponent implements OnInit {

    @Input() size = 50;
    @Input() backdrop = false;
    @Input() opacity = 40;
    @Input() backgroundColor = '#ffffff';

    constructor() { }

    ngOnInit(): void {
    }

}
