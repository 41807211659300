/* eslint-disable @typescript-eslint/naming-convention */
import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';
import { LayerType } from '../layer-type.enum';

export const pointAuditSelected: AnyLayer = {
    id: MapLayersSources.POINT_AUDIT_SELECTED,
    type: LayerType.SYMBOL,
    source: MapLayersSources.POINT_AUDIT_SELECTED,
    minzoom: mapStyleConfig.pointAudit.minZoom,
    paint: { 'icon-color': '#FFFFFF' },
    layout: { 'icon-image': 'selected', 'icon-allow-overlap': true, 'visibility': 'visible' },
};
