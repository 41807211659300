import {
    AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren
} from '@angular/core';
import { Store } from '@ngrx/store';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { Table } from 'primeng/table';
import * as AuditActions from '../../../state/audit.actions';
import * as SharedActions from '../../../../../state/shared/shared.actions';
import { MultiSelectFilters } from '../../../../../shared/models/multi-select-filters.model';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { TableColumn } from '../../../../../shared/models/table-column.model';
import { StatutProjetAuditValue } from '../../../models/statut-projet-audit.enum';
import { State } from '../../../state/audit.state';
import { ProjetAuditDto, ActionProjetAudit, PatchProjetAuditDto } from '../../../../../core/api/client/models';
import { StatutPointAudit, StatutPointAuditValue } from '../../../models/statut-point-audit.enum';
import { MapService } from '../../../../../map/services/map.service';
import { DynamicDialogRef } from 'primeng/dynamicdialog';
import { BookType, WorkBook, utils, write } from 'xlsx';
import { dateTimeChange, saveFile } from '../../../../../shared/utils';
import { DatePipe } from '@angular/common';
import { omit } from 'lodash';
import { getGroupeFullTaxonomie } from '../../../../../core/store/selectors/taxonomie.selectors';
import { TaxonomieGroupeValue } from '../../../../pilotage/models/taxonomie-groupe.enum';
import { Taxonomie } from '../../../../pilotage/models/taxonomie.model';
import { filter, takeUntil } from 'rxjs';
import { DataExtractionFormat } from '../../../../../shared/enums/data-extraction-format.enum';
import { DataExtractionType } from '../../../../../shared/photos/models';
import { getDataExtraction } from '../../../../../state/shared/shared.selectors';
import {
    getApproveProjetAuditPermission,
    getAssignProjetAuditPermission,
    getCancelProjetAuditPermission,
    getRejectProjetAuditPermission
} from '../../../utils/audit-permissions.utils';

@Component({
    selector: 'app-projet-audit-list-dialog',
    templateUrl: './projet-audit-list-dialog.component.html',
    styleUrls: ['./projet-audit-list-dialog.component.scss']
})
export class ProjetAuditListDialogComponent extends BaseComponent implements AfterViewInit, OnDestroy, OnInit {
    @ViewChild('dt') auditListTable: Table;
    @ViewChildren('actionsButton') actionsButton: QueryList<ElementRef>;

    @Input() projetAuditList: ProjetAuditDto[];
    @Input() columns: TableColumn[];
    @Input() statutFiltersItems: MultiSelectFilters[] = [];
    @Input() canAssignProjetAudit: boolean;
    @Input() canCancelProjetAudit: boolean;
    @Input() canExtractProjetAuditHistory: boolean;
    @Input() canRejectProjetAudit: boolean;
    @Input() canApproveProjetAudit: boolean;
    @Input() canZoomProjetAudit: boolean;
    @Input() isOffline: boolean;
    @Input()
    get statutGlobalFiltersItems() {
        return this._statutGlobalFiltersItems;
    }
    set statutGlobalFiltersItems(value: MultiSelectFilters[]) {
        if (value) {
            const validStatus = [StatutPointAudit.conforme, StatutPointAudit.nonConforme].map(statut => statut.toString());
            this._statutGlobalFiltersItems = value.filter(filtre => validStatus.includes(filtre.code));
        }
    }

    statutProjetAudit = StatutProjetAuditValue;
    statutPointAudit = StatutPointAuditValue;
    _statutGlobalFiltersItems: MultiSelectFilters[] = [];
    menuItems: MenuItem[];
    extractMenuItems: MenuItem[];

    public selectedProjetAudit: ProjetAuditDto;
    public assignProjetAuditDialogVisible: boolean;
    public dataExtractionLoading = false;

    private disableAssignProjetAudit = false;
    private disableCancelProjetAudit = false;
    private disableRejectProjetAudit = false;
    private disableApproveProjetAudit = false;
    private firmesAudit: Taxonomie[] = [];

    constructor(
        private store: Store<State>,
        private confirmationService: ConfirmationService,
        private cdr: ChangeDetectorRef,
        public ref: DynamicDialogRef,
        private mapService: MapService
    ) {
        super();
    }

    ngOnInit(): void {
        this.initDownloadButton();
        this.subscribeToFirmesAudit();
        this.subscribeToDataExtractionLoading();
    }

    private subscribeToFirmesAudit() {
        this.store.select(getGroupeFullTaxonomie(TaxonomieGroupeValue.FIRME_AUDIT))
            .pipe(
                takeUntil(this.destroyed)
            ).subscribe((firmes) => {
                if (firmes) {
                    this.firmesAudit = firmes;
                }
            });
    }

    private subscribeToDataExtractionLoading() {
        this.store.select(getDataExtraction).pipe(
            filter(loading => !!loading),
            takeUntil(this.destroyed)
        ).subscribe((loading) => {
            if (loading) {
                this.dataExtractionLoading = false;
            }
        });
    }

    initMenuItems(audit: ProjetAuditDto) {
        this.menuItems = [
            {
                label: 'Zoom sur',
                icon: 'pi pi-fw pi-search-plus',
                visible: this.canZoomProjetAudit,
                disabled: false,
                command: () => {
                    this.ref.close(true);
                    this.mapService.zoomProjetAudit(audit);
                }
            },
            {
                label: 'Approuver',
                icon: 'fas fa-flag-checkered',
                visible: this.canApproveProjetAudit,
                disabled: this.disableApproveProjetAudit,
                command: () => this.approveProjetAuditConfirm(audit)
            },
            {
                label: 'Assigner',
                icon: 'pi pi-fw pi-check-square',
                visible: this.canAssignProjetAudit,
                disabled: this.disableAssignProjetAudit,
                command: () => this.assignProjetAuditConfirm(audit)
            },
            {
                label: 'Annuler',
                icon: 'pi pi-times',
                visible: this.canCancelProjetAudit,
                disabled: this.disableCancelProjetAudit,
                command: () => this.cancelProjetAuditConfirm(audit)
            },
            {
                label: 'Rejeter',
                icon: 'pi pi-ban',
                visible: this.canRejectProjetAudit,
                disabled: this.disableRejectProjetAudit,
                command: () => this.rejectProjetAuditConfirm(audit)
            },
            {
                label: `Extraire historique`,
                icon: 'pi pi-fw pi-file',
                visible: this.canExtractProjetAuditHistory,
                command: () => this.store.dispatch(AuditActions.loadProjetAuditHistory({ projetAuditId: audit.id! }))
            }
        ];
    }

    initDownloadButton() {
        this.extractMenuItems = [
            {
                label: 'GeoJSON',
                icon: 'fas fa-globe',
                command: () => { this.exportToZipFile(DataExtractionFormat.GEOJSON); },
            },
            {
                label: 'JSON',
                icon: 'fas fa-code',
                command: () => { this.exportToZipFile(DataExtractionFormat.JSON); },
            },
            {
                label: 'SQLite',
                icon: 'fas fa-database',
                command: () => { this.exportToZipFile(DataExtractionFormat.SQLITE); }, disabled: true
            },
        ];
    }

    public exportToZipFile(format: DataExtractionFormat) {
        this.dataExtractionLoading = true;
        this.store.dispatch(SharedActions.startDataExtraction({ typeExtraction: DataExtractionType.AUDIT, format }));
    }

    globalFilter(event: Event, field: string, mode: string) {
        this.auditListTable.filter((event.target as HTMLInputElement).value, field, mode);
    }

    onFilterChange(value: any, fieldName: string) {
        const values = value.map((selectedValue: any) => selectedValue.code);
        this.auditListTable.filter(values, fieldName, 'in');
    }

    onActionsButtonClick(event: MouseEvent, contextMenu: ContextMenu, audit: ProjetAuditDto) {
        this.getDisabledMenuItems(audit);
        this.initMenuItems(audit);
        event.stopPropagation();
        event.preventDefault();
        contextMenu.show(event);
    }

    private getDisabledMenuItems(projetAudit: ProjetAuditDto) {
        this.disableAssignProjetAudit = !getAssignProjetAuditPermission(projetAudit);
        this.disableCancelProjetAudit = !getCancelProjetAuditPermission(projetAudit);
        this.disableRejectProjetAudit = !getRejectProjetAuditPermission(projetAudit);
        this.disableApproveProjetAudit = !getApproveProjetAuditPermission(projetAudit, this.isOffline);
    }

    openAssignProjetAuditDialog(projetAudit: ProjetAuditDto) {
        this.selectedProjetAudit = projetAudit;
        this.assignProjetAuditDialogVisible = true;
    }

    closeAssignProjetAuditDialog() {
        this.assignProjetAuditDialogVisible = false;
    }

    assignProjetAuditConfirm(projetAudit: ProjetAuditDto) {
        if (projetAudit.assigneA) {
            this.confirmationService.confirm({
                message: `Voulez-vous vraiment assigner le projet de nouveau ?<br><br>
                La réassignation entrainera la perte des données non synchronisées.`,
                accept: () => {
                    this.openAssignProjetAuditDialog(projetAudit);
                },
                key: 'assignProjetAuditConfirm',
            });
        } else {
            this.openAssignProjetAuditDialog(projetAudit);
        }
    }

    approveProjetAuditConfirm(projetAudit: ProjetAuditDto) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment approuver le projet d'audit ?`,
            accept: () => {
                const data: PatchProjetAuditDto = { action: ActionProjetAudit.approuver };
                this.store.dispatch(AuditActions.approveProjetAudit({ projetAuditId: projetAudit.id!, approveData: data }));
            },
            key: 'approveProjetAuditConfirm',
        });
    }

    cancelProjetAuditConfirm(projetAudit: ProjetAuditDto) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment annuler le projet d'audit ?`,
            accept: () => {
                const data: PatchProjetAuditDto = { action: ActionProjetAudit.annuler };
                this.store.dispatch(AuditActions.cancelProjetAudit({ projetAuditId: projetAudit.id!, cancelData: data }));
            },
            key: 'cancelProjetAuditConfirm',
        });
    }

    rejectProjetAuditConfirm(projetAudit: ProjetAuditDto) {
        this.confirmationService.confirm({
            message: `Voulez-vous vraiment rejeter le projet d'audit ?`,
            accept: () => {
                const data: PatchProjetAuditDto = { action: ActionProjetAudit.rejeter };
                this.store.dispatch(AuditActions.rejectProjetAudit({ projetAuditId: projetAudit.id!, rejectData: data }));
            },
            key: 'rejectProjetAuditConfirm',
        });
    }

    public export(format: BookType) {
        const formatDate = (date: Date | number) => date ? dateTimeChange(date.toString(), 'YYYY-MM-dd') : '';
        const EXCEL_COLUMNS_ORDER = [
            'id', 'projetId', 'nom', 'creeLe', 'seuilAudit', 'typeDeSeuil', 'nombreTotalDePoteaux',
            'firme', 'statut', 'dateStatut', 'statutGlobal', 'assignePar', 'assigneA', 'assigneLe', 'remarques'
        ];
        const EXCLUDED_FIELDS = ['geometrie', 'historique'];
        const FILE_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
        const SHEET_NAME = 'listeProjets';

        const exportData = this.projetAuditList.map(projetAudit => {
            const projetAuditExport = {
                ...projetAudit,
                creeLe: formatDate(projetAudit.creeLe),
                dateStatut: formatDate(projetAudit.dateStatut),
                assigneLe: formatDate(projetAudit.assigneLe),
                firme: this.firmesAudit.find(firme => firme.id === projetAudit.firme)?.code
            };

            return omit(projetAuditExport, EXCLUDED_FIELDS);
        });

        const worksheet = utils.json_to_sheet(exportData, { header: EXCEL_COLUMNS_ORDER });
        // eslint-disable-next-line @typescript-eslint/naming-convention
        const workbook: WorkBook = { Sheets: { [SHEET_NAME]: worksheet }, SheetNames: [SHEET_NAME] };
        const excelBuffer: any = write(workbook, { bookType: format, type: 'array' });

        const date = new DatePipe('en-US').transform(new Date(), 'yyyy_MM_dd_HH_mm_ss');
        const fileName = `liste_projets_audit_${date}`;
        saveFile(excelBuffer, fileName, FILE_TYPE, `.${format}`);
    }

    ngAfterViewInit() {
        this.cdr.detectChanges();
    }
}
