import { Component } from '@angular/core';
import { UtilisateurService } from '../services/utilisateur.service';

@Component({
    selector: 'app-invalid-role',
    templateUrl: './invalid-role.component.html',
    styleUrls: ['./invalid-role.component.scss']
})
export class InvalidRoleComponent {
    public get roles(): string[] {
        return this.utilisateurService.roles;
    }
    constructor(private utilisateurService: UtilisateurService) { }
}
