import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormFieldInitialComponent } from './form/form-field-initial/form-field-initial.component';
import { FormFieldComponent } from './form/form-field/form-field.component';
import { FormInfoComponent } from './form/form-info/form-info.component';
import { FormFieldErrorComponent } from './form/form-field-error/form-field-error.component';
import { TooltipModule } from 'primeng/tooltip';
import { FormFieldCheckboxComponent } from './form/form-field-checkbox/form-field-checkbox.component';
import { FormFieldGroupComponent } from './form/form-field-group/form-field-group.component';

@NgModule({
    declarations: [
        FormFieldErrorComponent,
        FormInfoComponent,
        FormFieldComponent,
        FormFieldInitialComponent,
        FormFieldCheckboxComponent,
        FormFieldGroupComponent
    ],
    imports: [
        CommonModule,
        TooltipModule
    ],
    exports: [
        FormFieldErrorComponent,
        FormInfoComponent,
        FormFieldComponent,
        FormFieldInitialComponent,
        FormFieldCheckboxComponent,
        FormFieldGroupComponent
    ]
})
export class HQFormLayoutModule { }
