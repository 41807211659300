import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';
import { ProjetProperties } from '../../../../enums/projet-properties.enum';
import { omit } from 'lodash';
import { takeUntil, tap } from 'rxjs';
import { getTaxonomieById } from '../../../../core/store/selectors/taxonomie.selectors';
import { Store, select } from '@ngrx/store';
import { Taxonomie } from '../../../../features/pilotage/models/taxonomie.model';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { State } from '../../../../state/app.state';
import { PopUpInfoCloseEvent } from '../../../models/pop-up-info-close-event.model';

@Component({
    selector: 'app-info-projet',
    templateUrl: './info-projet.component.html',
    styleUrls: ['./info-projet.component.scss']
})
export class InfoProjetComponent extends BaseComponent implements OnChanges {
    public projetName: string = '';
    public subTitle: string = `Projet d'inspection`;
    public get enumProperties() {
        return ProjetProperties;
    }

    public properties: string[] = [];
    public data: { [name: string]: any } = {};
    public verticalProperties: string[] = [];

    public currentFeature: mapboxgl.MapboxGeoJSONFeature;

    @Input() feature: mapboxgl.MapboxGeoJSONFeature;
    @Output() closed: EventEmitter<PopUpInfoCloseEvent> = new EventEmitter<PopUpInfoCloseEvent>();

    constructor(
        private store: Store<State>
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.feature && changes.feature.currentValue) {
            this.currentFeature = changes.feature.currentValue;
            this.setUpProjet();
        }
    }

    public close() {
        this.closed.emit({ closed: true });
    }

    private setUpProjectName() {
        this.projetName = this.currentFeature.properties[ProjetProperties.nomProjet];
    }

    private getFirmeById(firmeId: string) {
        this.store.pipe(
            select(getTaxonomieById(firmeId)),
            tap((firme: Taxonomie) => {
                this.data[ProjetProperties.firme] = firme.code;
            }),
            takeUntil(this.destroyed),
        ).subscribe();
    }

    private prepareData() {
        this.data = {};
        this.getProperties().forEach(valueKey => {
            if (valueKey === ProjetProperties.firme) {
                this.getFirmeById(this.currentFeature.properties[valueKey]);
            } else {
                this.data[valueKey] = this.currentFeature.properties[valueKey];
            }
        });
    }

    private getProperties(): string[] {
        this.verticalProperties = [
            ProjetProperties.assigneA,
        ];
        const propertiesToExclude = [
            ProjetProperties.nomProjet,
            ProjetProperties.projetId,
        ];
        const result = omit(this.currentFeature.properties, propertiesToExclude) as object;
        return Object.keys(result);
    }

    private setUpProjet() {
        this.setUpProjectName();
        this.prepareData();
    }
}
