import { ApplicationRef, Injectable } from '@angular/core';
import { concat, filter, first, interval } from 'rxjs';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';

@Injectable({
    providedIn: 'root'
})

export class ServiceWorkerUpdateService {

    constructor(
        private appRef: ApplicationRef,
        public swUpdate: SwUpdate
    ) {
        if (swUpdate.isEnabled) {
            const appIsStable$ = this.appRef.isStable.pipe(first(isStable => isStable === true));
            const everyHour$ = interval(60 * 60 * 1000);
            const everyHourOnceAppIsStable$ = concat(appIsStable$, everyHour$);
            everyHourOnceAppIsStable$.subscribe(() => swUpdate.checkForUpdate()
                .then((updateFound) =>
                    console.log(updateFound ? `Une nouvelle version de l'application est disponible.` : `La version de l'application est la plus récente.`)));
        }
    }

    public checkForUpdates() {
        this.swUpdate.versionUpdates.
            pipe(filter((event): event is VersionReadyEvent => event.type === 'VERSION_READY'),
            ).subscribe(event => {
                this.promptUser(event);
            });
    }


    private promptUser(event: VersionReadyEvent) {
        const version = Object.values(event.latestVersion.appData);
        this.swUpdate.activateUpdate().then(() => {
            if (confirm(`Une nouvelle version (` + version + `) de l'application est disponible. Voulez-vous la télécharger ?`)) {
                document.location.reload();
            }
        }).catch((error) => {
            console.error(`Service worker registration failed: ${error}`);
        });
    }


}
