export enum ProjetProperties {
    projetId = 'id_projet',
    nomProjet = 'Nom du projet',
    typeProjet = 'Type de projet',
    statut = 'Statut',
    numeroOrdreDeTravail = 'Numéro d\'ordre de travail',
    creeLe = 'Créé le',
    firme = 'Firme',
    territoire = 'Territoire',
    anneeInspection = 'Année',
    assigneA = 'Assigné à',
}
