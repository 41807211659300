import { Component, Input, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { takeUntil } from 'rxjs/operators';
import { ProjetCompletDto } from '../../../../core/api/client/models';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { projetInspectionListMobileDialogColumn } from '../../models/projet-inspection-list-dialog.column';
import { getIsAppOnline } from '../../../../state/shared/shared.selectors';
import {
    getIsUserMobile,
    canCompleteProjet,
    canInspecterProjet,
    canInspecterProjetAQ,
    canCompleteProjetAQ
} from '../../../../core/store/selectors/user.selectors';

@Component({
    selector: 'app-projet-inspection-list',
    templateUrl: './projet-inspection-list.component.html',
    styleUrls: ['./projet-inspection-list.component.scss']
})
export class ProjetInspectionListComponent extends BaseComponent implements OnInit {

    projetInspectionList: ProjetCompletDto[];

    @Input() set projetInspection(value: ProjetCompletDto[]) {
        this.projetInspectionList = [...value ?? []];
    }

    mobileColumns = projetInspectionListMobileDialogColumn;

    public isAppOffline = true;
    public isUserMobile = false;
    public canInspecterProjet = false;
    public canInspecterProjetAQ = false;
    public canCompleteProjet = false;
    public canCompleteProjetAQ = false;

    constructor(
        private store: Store<State>
    ) {
        super();
    }

    ngOnInit() {
        this.subscribeToIsAppOffline();
        this.subscribeToIsUserMobile();
        this.subscribeToCanInspecterProjet();
        this.subscribeToCanInspecterProjetAQ();
        this.subscribeToCanCompleteProjet();
        this.subscribeToCanCompleteProjetAQ();
    }

    private subscribeToIsAppOffline() {
        this.store.select(getIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isAppOffline = !_isAppOnline;
        });
    }

    private subscribeToIsUserMobile() {
        this.store.select(getIsUserMobile).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isUserMobile => {
            this.isUserMobile = _isUserMobile;
        });
    }

    private subscribeToCanInspecterProjet() {
        this.store.select(canInspecterProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canInspecterProjet => {
            this.canInspecterProjet = _canInspecterProjet;
        });
    }

    private subscribeToCanInspecterProjetAQ() {
        this.store.select(canInspecterProjetAQ).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canInspecterProjetAQ => {
            this.canInspecterProjetAQ = _canInspecterProjetAQ;
        });
    }

    private subscribeToCanCompleteProjet() {
        this.store.select(canCompleteProjet).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canCompleteProjet => {
            this.canCompleteProjet = _canCompleteProjet;
        });
    }

    private subscribeToCanCompleteProjetAQ() {
        this.store.select(canCompleteProjetAQ).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_canCompleteProjetAQ => {
            this.canCompleteProjetAQ = _canCompleteProjetAQ;
        });
    }
}
