export enum StatutPointInspection {
    nonInspecte = 'nonInspecte',
    urgence = 'urgence',
    anomaliePrioriteC = 'anomaliePrioriteC',
    anomaliePrioriteE = 'anomaliePrioriteE',
    anomaliePrioriteG = 'anomaliePrioriteG',
    anomaliePrioriteK = 'anomaliePrioriteK',
    anomaliePrioriteM = 'anomaliePrioriteM',
    anomaliePrioriteN = 'anomaliePrioriteN',
    sansAnomalie = 'sansAnomalie',
    aCompleter = 'aCompleter',
    ignore = 'ignore',
    exclu = 'exclu'
}

export enum StatutPointInspectionValue {
    nonInspecte = 'Non inspecté',
    urgence = 'Urgence',
    anomaliePrioriteC = 'Inspecté, avec anomalie (5 jours et moins)',
    anomaliePrioriteE = 'Inspecté, avec anomalie (1 mois et moins)',
    anomaliePrioriteG = 'Inspecté, avec anomalie (3 mois et moins)',
    anomaliePrioriteK = 'Inspecté, avec anomalie (6 mois et moins)',
    anomaliePrioriteM = 'Inspecté, avec anomalie (12 mois et moins)',
    anomaliePrioriteN = 'Inspecté, avec anomalie (24 mois et moins)',
    sansAnomalie = 'Inspecté, sans anomalie',
    aCompleter = 'Inspecté, à compléter',
    ignore = 'Ignoré',
    exclu = 'Exclu'
}

export enum StatutPointInspectionNumberValue {
    urgence = 1,
    anomaliePrioriteC = 2,
    anomaliePrioriteE = 3,
    anomaliePrioriteG = 4,
    anomaliePrioriteK = 5,
    anomaliePrioriteM = 6,
    anomaliePrioriteN = 7,
    sansAnomalie = 8,
    aCompleter = 9,
    ignore = 10,
    nonInspecte = 11,
}

export const statutPointInspectionMap = new Map<StatutPointInspection, string>([
    [StatutPointInspection.nonInspecte, StatutPointInspectionValue.nonInspecte],
    [StatutPointInspection.urgence, StatutPointInspectionValue.urgence],
    [StatutPointInspection.anomaliePrioriteC, StatutPointInspectionValue.anomaliePrioriteC],
    [StatutPointInspection.anomaliePrioriteE, StatutPointInspectionValue.anomaliePrioriteE],
    [StatutPointInspection.anomaliePrioriteG, StatutPointInspectionValue.anomaliePrioriteG],
    [StatutPointInspection.anomaliePrioriteK, StatutPointInspectionValue.anomaliePrioriteK],
    [StatutPointInspection.anomaliePrioriteM, StatutPointInspectionValue.anomaliePrioriteM],
    [StatutPointInspection.anomaliePrioriteN, StatutPointInspectionValue.anomaliePrioriteN],
    [StatutPointInspection.sansAnomalie, StatutPointInspectionValue.sansAnomalie],
    [StatutPointInspection.aCompleter, StatutPointInspectionValue.aCompleter],
    [StatutPointInspection.ignore, StatutPointInspectionValue.ignore],
    [StatutPointInspection.exclu, StatutPointInspectionValue.exclu]
]);
