import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../../shared/shared.module';
import { RechercheEffects } from './state/recherche.effects';
import { rechercheReducer } from './state/recherche.reducer';
import { RechercheDialogComponent } from './recherche-dialog/recherche-dialog.component';

@NgModule({
    declarations: [
        RechercheDialogComponent,
    ],
    imports: [
        SharedModule,
        StoreModule.forFeature('recherche', rechercheReducer),
        EffectsModule.forFeature([RechercheEffects]),
    ],
    exports: [
        RechercheDialogComponent,
    ],
})
export class RechercheModule { }
