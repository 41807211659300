import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { UtilitaireService } from '../../../core/api/client/services';
import { RechercheListe } from '../models/recherche-liste.model';

@Injectable({
    providedIn: 'root'
})
export class RechercheListeService {
    constructor(
        private utilitaireApiService: UtilitaireService,
    ) { }

    public getRechercheZoom(params: UtilitaireService.GetApiV1UtilitairesSearchParams) {
        return this.utilitaireApiService.getApiV1UtilitairesSearch(params);
    }

    public getRecherche(params: UtilitaireService.GetApiV1UtilitairesSearchParams): Observable<RechercheListe[]> {
        return this.utilitaireApiService.getApiV1UtilitairesSearch(params);
    }
}
