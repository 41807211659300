export enum ClasseValue {
    n1 = '1',
    n2 = '2',
    n3 = '3',
    n4 = '4',
    n5 = '5',
    n6 = '6',
    n7 = '7',
    at = 'AT',
    bt = 'BT',
    c = 'C',
    d = 'D',
    e = 'E',
    f = 'F',
    g = 'G',
    h = 'H',
    h1 = 'H1',
    h2 = 'H2',
    h3 = 'H3',
    j = 'J',
    k = 'K',
    l = 'L',
    m = 'M',
}

export enum Classe {
    n1 = 'n1',
    n2 = 'n2',
    n3 = 'n3',
    n4 = 'n4',
    n5 = 'n5',
    n6 = 'n6',
    n7 = 'n7',
    at = 'at',
    bt = 'bt',
    c = 'c',
    d = 'd',
    e = 'e',
    f = 'f',
    g = 'g',
    h = 'h',
    h1 = 'h1',
    h2 = 'h2',
    h3 = 'h3',
    j = 'j',
    k = 'k',
    l = 'l',
    m = 'm',
}

export const classeListe = Object.keys(Classe).map(key => ({ champ: key, valeur: ClasseValue[key] }));

export const classeMap = new Map<Classe, string>([
    [Classe.n1, '1'],
    [Classe.n2, '2'],
    [Classe.n3, '3'],
    [Classe.n4, '4'],
    [Classe.n5, '5'],
    [Classe.n6, '6'],
    [Classe.n7, '7'],
    [Classe.at, 'AT'],
    [Classe.bt, 'BT'],
    [Classe.c, 'C'],
    [Classe.d, 'D'],
    [Classe.e, 'E'],
    [Classe.f, 'F'],
    [Classe.g, 'G'],
    [Classe.h, 'H'],
    [Classe.h1, 'H1'],
    [Classe.h2, 'H2'],
    [Classe.h3, 'H3'],
    [Classe.j, 'J'],
    [Classe.k, 'K'],
    [Classe.l, 'L'],
    [Classe.m, 'M'],
]);

