/// <reference types="w3c-web-serial" />
import { Injectable } from '@angular/core';
import { BehaviorSubject, EMPTY, Subject, catchError, defer, finalize, from, mergeMap, repeat, switchMap, takeUntil, tap, toArray, windowTime } from 'rxjs';
import { Coordinates, GpsPortConnectionState, GpsState } from '../models';

@Injectable({
    providedIn: 'root'
})
export class GpsService {
    // Serial device
    private _serialPort: SerialPort;
    private _reader: ReadableStreamDefaultReader<Uint8Array>;

    // Misc
    private decoder = new TextDecoder('utf-8');

    // Coordinates
    private _coordinates: Coordinates | null = null;
    private _coordinatesSubject = new BehaviorSubject<Coordinates | null>(null);
    public coordinates$ = this._coordinatesSubject.asObservable();

    private set coordinates(value: Coordinates) {
        this._coordinates = value;
        this._coordinatesSubject.next(this._coordinates);
    }

    private stopReadingGpsDataSubject = new Subject<void>();
    private stopReadingGpsData$ = this.stopReadingGpsDataSubject.asObservable();

    // GPS Data
    private _gpsDataSubject = new Subject<string>();
    public gpsData$ = this._gpsDataSubject.asObservable();

    // GPS state
    private _state: GpsState = GpsState.OFFLINE;

    private set state(value: GpsState) {
        this._state = value;
        this._stateSubject.next(this._state);
    }

    private _stateSubject = new BehaviorSubject<GpsState>(GpsState.OFFLINE);
    public state$ = this._stateSubject.asObservable();

    // GPS port connection state
    private _portConnectionState: GpsPortConnectionState = GpsPortConnectionState.DISCONNECTED;

    private set portConnectionState(value: GpsPortConnectionState) {
        this._portConnectionState = value;
        this._portConnectionStateSubject.next(this._portConnectionState);
    }

    private _portConnectionStateSubject = new BehaviorSubject<GpsPortConnectionState>(GpsPortConnectionState.DISCONNECTED);
    public portconnectionState$ = this._portConnectionStateSubject.asObservable();

    constructor() { }

    public connect() {
        const portOptions = {
            baudRate: 9600,
            dataBits: 8,
            parity: <const>'none',
            stopBits: 1,
            flowControl: <const>'none',
            buggerSize: 8 * 1024
        };

        from(navigator.serial.requestPort())
            .pipe(
                mergeMap((serialPort: SerialPort) => {
                    this._serialPort = serialPort;
                    return from(this._serialPort.open(portOptions))
                        .pipe(
                            // Erreur ouverture port
                            catchError((error: any) => {
                                console.error(error);
                                return EMPTY;
                            })
                        );
                }),
                catchError((error: any) => {
                    // Erreur connection port
                    console.error(error);
                    return EMPTY;
                })
            )
            .subscribe(() => {
                this.portConnectionState = GpsPortConnectionState.CONNECTED;
                // Ouverture port réussi et connection faite, envoi des données
                this.sendData();
            });
    }

    private sendData() {
        this._reader = this._serialPort.readable.getReader();

        const gpsData$ = defer(() => this._reader.read()).pipe(repeat());


        gpsData$
            .pipe(
                tap((data) => {
                    if (data.value) {
                        this._gpsDataSubject.next(this.decoder.decode(data.value));
                    }
                }),
                windowTime(5000),
                switchMap(window$ => window$.pipe(toArray())),
                repeat(),
                takeUntil(this.stopReadingGpsData$),
                finalize(() => this._reader.releaseLock())
            )
            .subscribe((data) => {
                const concatenatedData = data
                    .filter(({ value }) => value)
                    .map(({ value }) => this.decoder.decode(value))
                    .join('');

                if (concatenatedData) {
                    this.getNmeaData(concatenatedData);
                }

                if (data.some(({ done }) => done)) {
                    this.stopReadingGpsDataSubject.next();
                }
            });
    }

    public disconnect() {
        if (this._serialPort) {
            this.stopReadingGpsDataSubject.next();
            from(this._serialPort.close()).subscribe(() => {
                this.portConnectionState = GpsPortConnectionState.DISCONNECTED;
                this.state = GpsState.OFFLINE;
            });
        }
    }

    private getNmeaData(data: string) {
        const lines = data.split('\n');
        let lastValidCoordinates: Coordinates = null;

        lines
            .filter(line => line.includes('GPGGA'))
            .forEach(line => {
                const nmeaData = line.split(',');

                if (!isNaN(+nmeaData[4]) && !isNaN(+nmeaData[2]) && nmeaData[5] && nmeaData[3]) {
                    lastValidCoordinates = {
                        longitude: nmeaData[4],
                        directionEO: nmeaData[5],
                        latitude: nmeaData[2],
                        directionNS: nmeaData[3]
                    };
                }

                if (lastValidCoordinates) {
                    this.coordinates = lastValidCoordinates;
                    this.state = GpsState.ONLINE;
                } else {
                    this.coordinates = null;
                    this.state = GpsState.OFFLINE;
                }
            });
    }
}
