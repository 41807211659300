<div class="p-field-error"
    *ngIf="!control.disabled && control.invalid && control.errors && (control.dirty || control.touched)">

    <span class="hq-icon-draw appicons ic-exclamation-triangle"></span>
    <span>

        <ng-container *ngIf="control?.errors?.invalid">
            {{ control?.errors?.invalidMsg }}
        </ng-container>

        <ng-container *ngIf="control?.errors?.required">
            Le champ {{ label }} est requis.
        </ng-container>

        <ng-container *ngIf="control?.errors?.min">
            La valeur doit être supérieure à {{ control.errors.min.min }}.
        </ng-container>

        <ng-container *ngIf="control?.errors?.max">
            La valeur ne peut être supérieure à {{ control.errors.max.max }}.
        </ng-container>

        <ng-container *ngIf="control?.errors?.maxlength">
            Le champ {{ label }} ne peut contenir plus {{ control.errors.maxlength.requiredLength }} de caractères
        </ng-container>

        <ng-container *ngIf="control?.errors?.minlength">
            Le champ {{ label }} doit contenir au moins {{ control.errors.minlength.requiredLength }} caractères.
        </ng-container>

        <ng-container *ngIf="control?.errors?.email">
            Le champ {{ label }} doit être un courriel valide.
        </ng-container>

        <ng-container *ngIf="control?.errors?.pattern">
            Le champ {{ label }} n'est pas dans le bon format.
        </ng-container>

        <ng-container *ngIf="control?.errors?.integer">
            Le champ {{ label }} doit être un nombre entier.
        </ng-container>

        <ng-container *ngIf="control?.errors?.number">
            Le champ {{ label }} doit contenir un nombre.
        </ng-container>

        <ng-container *ngIf="control?.errors?.phone">
            Le champ {{ label }} n'est pas un numéro de téléphone valide
        </ng-container>

        <ng-container *ngIf="control?.errors?.alpha">
            Le champ {{ label }} doit seulement contenir des lettres.
        </ng-container>

        <ng-container *ngIf="control?.errors?.alphanumeric">
            Le champ {{ label }} doit seulement contenir des chiffres et des lettres.
        </ng-container>

    </span>
    <ng-content></ng-content>
</div>
