<div class="anomalie__content">
    <div *ngIf="anomalieListLoading$ | async" class="anomalie__content__loading">
        <app-loading-spinner></app-loading-spinner>
    </div>
    <div *ngIf="anomalieList$ | async as anomalieList" class="anomalie__content__list">
        <div *ngIf="anomalieList.length" class="anomalie__content__list__download">
            Statut d'extraction : {{photoExtraction?.statut!}}
            <button type="button" pButton pRipple label="Télécharger le fichier"
                [disabled]="photoExtraction?.statut !== statutPhotoExtractionEnum.StatutEnum.Disponible"
                (click)="onDownloadPhotoExtraction()"></button>
            <button type="button" pButton pRipple label="Extraire les photos"
                [disabled]="photoExtraction?.statut === statutPhotoExtractionEnum.StatutEnum.EnCours"
                (click)="onPhotoExtraction()"></button>
            <button type="button" pButton pRipple icon="pi pi-file-excel" class="p-button-success" pTooltip="XLS"
                (click)="exportExcel(anomalieList)" tooltipPosition="bottom"></button>
        </div>
        <app-anomalie-list-dialog [anomalie]="anomalieList" [(visible)]="anomalieListDialogVisible"
            [anomalieListLoading]="(anomalieListLoading$ | async) || false">
        </app-anomalie-list-dialog>
        <app-photo-extraction-dialog *ngIf="photoExtraction" [photoExtraction]="photoExtraction!"
            [(visible)]="photoExtractionDialogVisible" (createPhotoExtraction)="createPhotoExtraction()">
        </app-photo-extraction-dialog>
    </div>
</div>