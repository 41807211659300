import { createAction, props } from '@ngrx/store';
import { ProjetHistory } from '../../models/projet-history.model';

export enum ProjetHistoryActionTypes {
    LOAD_PROJET_HISTORY = '[Projet] Load Projet History',
    LOAD_PROJET_HISTORY_SUCCESS = '[Projet] Load Projet History Success',
    LOAD_PROJET_HISTORY_ERROR = '[Projet] Load Projet History Error',
}

export const loadProjetHistory = createAction(ProjetHistoryActionTypes.LOAD_PROJET_HISTORY, props<{ projetId: string }>());

export const loadProjetHistorySuccess = createAction(ProjetHistoryActionTypes.LOAD_PROJET_HISTORY_SUCCESS, props<{ projetHistory: ProjetHistory[] }>());

export const loadProjetHistoryError = createAction(ProjetHistoryActionTypes.LOAD_PROJET_HISTORY_ERROR, props<{ error: any }>());
