import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { getAnomaliesBboxSuccess } from 'src/app/features/anomalie/state/anomalie/anomalie.action';
import { AnomalieBaseDto } from '../../../../core/api/client/models';

export interface AnomalieState extends EntityState<AnomalieBaseDto> { }

export const anomalieAdapter: EntityAdapter<AnomalieBaseDto> = createEntityAdapter<AnomalieBaseDto>({
    selectId: (anomalie: AnomalieBaseDto) => anomalie.id as string
});

export const anomalieSelector = anomalieAdapter.getSelectors();

export const initialAnomalieAdapter = anomalieAdapter.getInitialState();

export const anomalieReducer = createReducer(initialAnomalieAdapter,
    on(getAnomaliesBboxSuccess, (state, action) => anomalieAdapter.upsertMany(action.anomalies, state)),
);

