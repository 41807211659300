import { TableColumn } from '../../../shared/models/table-column.model';

export const projetAuditListDialogColumn: TableColumn[] = [
    {
        field: 'nom',
        header: 'Nom du projet',
        sortable: true,
        filterable: true
    },
    {
        field: 'creeLe',
        header: 'Créé le',
        sortable: true,
        filterable: true,
        isDate: true
    },
    {
        field: 'statut',
        header: 'Statut',
        sortable: true,
        filterable: true
    },
    {
        field: 'dateStatut',
        header: 'Date statut',
        sortable: true,
        filterable: true,
        isDate: true
    },
    {
        field: 'assigneA',
        header: 'Assigné à',
        sortable: true,
        filterable: true
    },
    {
        field: 'assigneLe',
        header: 'Assigné le',
        sortable: true,
        filterable: true,
        isDate: true
    },
    {
        field: 'assignePar',
        header: 'Assigné par',
        sortable: true,
        filterable: true
    },
    {
        field: 'nombreTotalDePoteaux',
        header: `Nombre de points d'audit`,
        sortable: true,
        filterable: true
    },
    {
        field: 'statutGlobal',
        header: `Résultat d'audit`,
        sortable: true,
        filterable: true
    },
    {
        field: 'actions',
        header: 'Actions',
        sortable: false,
        filterable: false,
        width: '95px'
    }
];

export const projetAuditListMobileDialogColumn: TableColumn[] = [
    {
        field: 'nom',
        header: 'Nom du projet',
    },
    {
        field: 'statut',
        header: 'Activé',
        width: '20%'
    },
    {
        field: 'downloaded',
        header: 'Téléchargé',
        width: '20%'
    }
];

