import { KeyValue } from '@angular/common';
import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'app-pop-up-info-properties',
    templateUrl: './pop-up-info-properties.component.html',
    styleUrls: ['./pop-up-info-properties.component.scss']
})
export class PopUpInfoPropertiesComponent implements OnChanges {
    public currentDatas: { [name: string]: any } = {};

    @Input() datas: { [name: string]: any };
    @Input() verticalProperties: string[] = [];
    @Input() keepOriginalOrder: boolean = false;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes) {
            if (changes.datas && changes.datas.currentValue) {
                this.currentDatas = changes.datas.currentValue;
            }
        }
    }

    public orderValues = (a: KeyValue<string, string>, b: KeyValue<string, string>): number => {
        if (this.keepOriginalOrder) {
            return 0;
        } else {
            return a.key.localeCompare(b.key);
        };
    };
}
