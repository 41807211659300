import * as mapboxgl from 'mapbox-gl';

import MapboxDraw from '@mapbox/mapbox-gl-draw';

export class MapboxButtonControl {

    _map!: mapboxgl.Map;

    _container!: HTMLElement;

    draw;
    buttons: Object[];
    onAddOrig;
    onRemoveOrig;
    constructor(opt: {
        buttons: Array<{ classes: string[], on: string, action: () => void, content: string }>,
        draw?: MapboxDraw
    }) {
        this.buttons = opt.buttons || [];
        if (opt.draw) {
            this.draw = opt.draw;
            this.onAddOrig = opt.draw.onAdd;
            this.onRemoveOrig = opt.draw.onRemove;
        }
    }
    onAdd(map: mapboxgl.Map) {
        this._map = map;
        this._container = document.createElement('div');
        this._container.className = 'mapboxgl-ctrl-group mapboxgl-ctrl';
        if (this.draw) {
            this._container = (this.onAddOrig as any)(map);
        }
        this.buttons.forEach((b: Object) => {
            this.addButton(b);
        });
        return this._container;
    }
    addButton(opt: any) {
        const btn = document.createElement('button');
        if (opt.classes instanceof Array) {
            opt.classes.forEach((c: any) => { btn.classList.add(c); });
        }
        if (opt.content) {
            if (opt.content instanceof Element) {
                btn.appendChild(opt.content);
            } else {
                btn.innerHTML = opt.content;
            }
        }
        btn.addEventListener(opt.on, opt.action);
        this._container.appendChild(btn); opt.buttons = btn;
    }
    onRemove(opt: any) {
        opt.buttons.removeEventListener(opt.on, opt.action);
        opt.buttons.remove();
    }
};


