import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'app-pop-up-info-header',
    templateUrl: './pop-up-info-header.component.html',
    styleUrls: ['./pop-up-info-header.component.scss']
})
export class PopUpInfoHeaderComponent {
    public disabledColor: string = '#d1d1d1';
    @Input() mainTitle: string = '';
    @Input() subTitle: string = '';

    @Input() logoHigh: string = 'fa-solid fa-camera';
    @Input() logoHighQuantity: number = 0;
    @Input() logoHighColor: string = '#1b75bb';

    @Input() logoLow: string = 'fa-solid fa-bug';
    @Input() logoLowQuantity: number = 0;
    @Input() logoLowColor: string = '#ff0000';

    @Input() displayLogos: boolean = true;
    @Input() displayLogoHigh: boolean = true;
    @Input() displayLogoLow: boolean = true;

    @Output() closed: EventEmitter<boolean> = new EventEmitter();

    public close() {
        this.closed.emit(true);
    }

}
