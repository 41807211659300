import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { TagModule } from 'primeng/tag';
import { GpsComponent } from './gps.component';

@NgModule({
    declarations: [
        GpsComponent
    ],
    imports: [
        CommonModule,
        ButtonModule,
        TagModule
    ],
})
export class GpsModule { }
