<div class="anomalies">
    <ng-container *ngFor="let anomalie of anomalies; let i = index">
        <pop-up-info-accordion-component
            [title]="anomalieKeyHaveSpace ? anomalie[anomalieProperties.ELEMENT] : anomalie.element"
            [displayBorders]="false" [logoEnd]="'fa-solid fa-camera'" [quantityEnd]="numberOfPhotos[i]"
            [class.deleted-anomalie]="!!(anomalie.statut)"
            [titleTooltip]="anomalie.statut ? statutAnomalieLabel.StatutEnum.Supprime : null">
            <app-pop-up-info-properties [datas]="data[i]"
                [verticalProperties]="verticalProperties"></app-pop-up-info-properties>
            <ng-container *ngIf="anomalie.photos.length > 0">
                <pop-up-info-accordion-component [title]="anomalie.photos.length > 1 ? 'Photos' : 'Photo'"
                    [logo]="'fa-solid fa-camera'" [displayBorders]="false" [isAlwaysOpen]="true"
                    [quantity]="anomalie.photos.length">
                    <app-photo-container
                        [photos]="anomalieKeyHaveSpace ? imagesAnomalies[anomalie[anomalieProperties.ANOMALIE_ID]] : imagesAnomalies[anomalie.id]">
                    </app-photo-container>
                </pop-up-info-accordion-component>
            </ng-container>
        </pop-up-info-accordion-component>
    </ng-container>
</div>
