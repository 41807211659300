<p-panel *ngIf="showPanel">
    <ng-template pTemplate="header">
        <div class="photo-status__header">
            <span class="photo-status__header__title text-primary font-semibold text-xl">Téléchargement des
                photos</span>
            <button pButton icon="pi pi-times" class="p-button-rounded p-button-text" (click)="closePanel()"></button>
        </div>
    </ng-template>
    <div class="photo-status">
        <div *ngIf="showInspectionPhotosProgression" class="photo-status__progress">
            <label>Photos d'inspection :</label>
            <div class="photo-status__progress__bar__container">
                <div class="photo-status__progress__bar">
                    <p-progressBar styleClass="hq-progress-bar" [value]="inspectionPhotosProgression"
                        [color]=" inspectionPhotosProgression === 100 ? '#477d00': '#1b75bb'"></p-progressBar>
                </div>
            </div>
        </div>

        <div *ngIf="showAnomaliesPhotosProgression" class="photo-status__progress">
            <label>Photos d'anomalies :</label>
            <div class="photo-status__progress__bar__container">
                <div class="photo-status__progress__bar">
                    <p-progressBar styleClass="hq-progress-bar" [value]="anomaliesPhotosProgression"
                        [color]=" anomaliesPhotosProgression === 100 ? '#477d00': '#1b75bb'"></p-progressBar>
                </div>
            </div>
        </div>

        <div *ngIf="showAuditPhotosProgression" class="photo-status__progress">
            <label>Photos d'audit :</label>
            <div class="photo-status__progress__bar__container">
                <div class="photo-status__progress__bar">
                    <p-progressBar styleClass="hq-progress-bar" [value]="auditPhotosProgression"
                        [color]=" auditPhotosProgression === 100 ? '#477d00': '#1b75bb'"></p-progressBar>
                </div>
            </div>
        </div>

        <div *ngIf="showAnomaliesAuditPhotosProgression" class="photo-status__progress">
            <label>Photos d'anomalies d'audit :</label>
            <div class="photo-status__progress__bar__container">
                <div class="photo-status__progress__bar">
                    <p-progressBar styleClass="hq-progress-bar" [value]="anomaliesAuditPhotosProgression"
                        [color]=" anomaliesAuditPhotosProgression === 100 ? '#477d00': '#1b75bb'"></p-progressBar>
                </div>
            </div>
        </div>
    </div>
</p-panel>
