<div class="popUpContainer">
    <div class="popUpContainer__content" [class.bottom]="!displayNavigation">
        <ng-container [ngSwitch]="source">
            <ng-container *ngSwitchCase="featureSource.POINTS_INSPECTION">
                <app-info-point-inspection [feature]="selectedFeature"
                    (closed)="close($event)"></app-info-point-inspection>
            </ng-container>
            <ng-container *ngSwitchCase="featureSource.POINTS_AUDIT">
                <app-info-point-audit [currentUserGroups]="userGroups" [pointAuditId]="selectedFeature.properties.id"
                    [feature]="featurePointInspection" [auditFeature]="selectedFeature" (closed)="close($event)" />
            </ng-container>
            <ng-container *ngSwitchCase="featureSource.PROJETS_INSPECTION">
                <app-info-projet [feature]="selectedFeature" (closed)="close($event)"></app-info-projet>
            </ng-container>
            <ng-container *ngSwitchCase="featureSource.PROJETS_AUDIT">
                <app-info-projet-audit [projetAuditId]="selectedFeature.properties.id"
                    (closed)="close($event)"></app-info-projet-audit>
            </ng-container>
            <ng-container *ngSwitchDefault></ng-container>
        </ng-container>
    </div>
    <div *ngIf="displayNavigation" class="popUpContainer__footer">
        <div class="footer__navigation">
            <button pButton pRipple icon="fa-solid fa-arrow-left-long" class="navigation__bouton"
                (click)="this.previousPage()" [disabled]="disableBackButton"></button>
        </div>
        <div class="footer__navigation">
            <span class="navigation__texte">
                ({{currentPage}} de {{totalPage}})
            </span>
        </div>
        <div class="footer__navigation">
            <button pButton pRipple icon="fa-solid fa-arrow-right-long" class="navigation__bouton"
                (click)="this.nextPage()" [disabled]="disableForwardButton"></button>
        </div>
    </div>
</div>
