import { Directive, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';

@Directive()
export abstract class BaseComponent implements OnDestroy {
    protected destroyed = new Subject<void>();
    constructor() { }
    ngOnDestroy() {
        this.destroyed.next();
        this.destroyed.complete();
    }
}
