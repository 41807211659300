import { Component, Input } from '@angular/core';
import { InfosData } from '../../models/infos-data.model';

@Component({
    selector: 'app-info-card',
    templateUrl: './info-card.component.html',
    styleUrls: ['./info-card.component.scss']
})
export class InfoCardComponent {
    @Input() infos: InfosData;
    @Input() loadingPhotos = false;
}
