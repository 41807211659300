export enum ProjetAction {
    ACTIVER = 'activer',
    ACTIVER_AQ = 'activeraq',
    ANNULER = 'annuler',
    ASSIGNER = 'assigner',
    ASSIGNER_AQ = 'assigneraq',
    ASSIGNER_FIRME = 'assignerfirme',
    COMPLETER = 'completer',
    COMPLETER_AQ = 'completeraq',
    SUSPENDRE = 'suspendre',
    CORRECTION_ASSIGNEE = 'correctionAssignee',
    TERMINER = 'terminer',
    VALIDER = 'valider',
    REJETER = 'rejeter',
    ASSIGNER_DEMANDE_CORRECTION = 'assignerdemandecorrection'
}
