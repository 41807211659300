import { Component, OnInit } from '@angular/core';
import { Observable, of } from 'rxjs';
import { concatMap, concatAll, switchMap, finalize, catchError, filter, takeUntil, mergeMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { round } from 'lodash';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ApiService } from '../../../../services/api.service';
import { SyncErrors } from '../../models/sync-errors.model';
import { HttpErrorResponse } from '@angular/common/http';
import { TypeObjetSync } from '../../models/type-objet-sync.enum';
import { transformToFormData } from '../../../../shared/utils';
import { RequestType } from '../../models/request-type.enum';
import { Requests } from '../../models/requests.model';
import * as SyncActions from '../../../../features/synchronisation/state/synchronisation.actions';
import * as OfflineActions from '../../../../features/offline/state/offline.actions';
import { getIsAuditeur } from '../../../../core/store/selectors/user.selectors';
import { State } from '../../../../state/app.state';
import { ProjetAuditDto, ProjetCompletDto } from '../../../../core/api/client/models';
import { RapportErreurSynchronisationDialogComponent } from '../rapport-erreur-synchronisation-dialog/rapport-erreur-synchronisation-dialog.component';
import { getRequests } from '../../state/synchronisation.selectors';
import { SynchronisationService } from '../../services/synchronisation.service';

@Component({
    selector: 'app-synchronisation-dialog',
    templateUrl: './synchronisation-dialog.component.html',
    styleUrls: ['./synchronisation-dialog.component.scss']
})
export class SynchronisationDialogComponent extends BaseComponent implements OnInit {
    public serverErrorNumber: number[] = [0, 403, 413, 500, 501, 502, 503, 504, 505, 506, 507, 508, 510, 511];
    public labelAucunCodeABarres = 'Sans CAB';
    public requests: Requests[] = [];
    public totalRequests: number;
    public remainingRequests: number = 0;
    public syncErrors: SyncErrors[] = [];
    public ignoredIds: string[] = [];
    public isAuditeur = false;
    public projetsAuditDownloaded: ProjetAuditDto[] = [];
    public projetsInspectionDownloaded: ProjetCompletDto[] = [];

    public get progress(): number {
        return round(((this.totalRequests - this.remainingRequests) / this.totalRequests) * 100);
    }

    public get hasError(): boolean {
        return !!this.ignoredIds?.length || !!this.syncErrors?.length;
    }

    constructor(
        public ref: DynamicDialogRef,
        private store: Store<State>,
        private apiService: ApiService,
        private dialogService: DialogService,
        private syncService: SynchronisationService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(SyncActions.getRequests());
        this.store.dispatch(OfflineActions.getProjetsDownloaded());

        this.subscribeToRequests();
        this.subscribeToIsAuditeur();
    }

    private subscribeToRequests() {
        this.store.select(getRequests)
            .pipe(
                filter(requests => !!requests),
                takeUntil(this.destroyed))
            .subscribe(requests => {
                this.requests = requests;
                this.totalRequests = requests.length;
                this.remainingRequests = requests.length;
                this.initSync();
            });
    }

    private subscribeToIsAuditeur() {
        this.store.select(getIsAuditeur)
            .pipe(
                filter(isAuditeur => !!isAuditeur),
                takeUntil(this.destroyed))
            .subscribe(isAuditeur => {
                this.store.dispatch(OfflineActions.getProjetsAuditDownloaded());
                this.isAuditeur = isAuditeur;
            });
    }

    private initSync() {
        if (this.requests.length) {
            this.store.dispatch(SyncActions.deleteSyncErrors());
            this.synchronize();
        }
    }

    public synchronize() {
        let currentRequest: Requests;
        this.syncService.getRequests()
            .pipe(
                concatAll(),
                concatMap((request) => {
                    currentRequest = request;
                    return this.getApiRequestType(request)
                        .pipe(
                            mergeMap(response => { return of(response); }),
                            catchError((error: unknown) => of(error))
                        );
                }),
                switchMap((response) => {
                    if (response?.name === 'HttpErrorResponse') {
                        if (this.serverErrorNumber.includes(response.status)) {
                            const error = response.statusText || 'Erreur serveur';
                            this.receivedError(currentRequest, null, false, error);
                        } else {
                            this.receivedError(currentRequest, response);
                        }

                    } else {
                        this.remainingRequests--;
                        return this.syncService.deleteRequestFromStack(currentRequest);
                    }
                    return of(response);
                }),
                finalize(() => {
                    this.updateProjetsToIndexedDb();
                    this.addSyncErrors();
                }),
            ).subscribe();
    }

    private updateProjetsToIndexedDb() {
        this.projetsInspectionDownloaded = this.syncService.updateProjetsInspectionToIndexedDb();
        if (this.isAuditeur) {
            this.projetsAuditDownloaded = this.syncService.updateProjetsAuditToIndexedDb();
        }
    }

    private addSyncErrors() {
        if (this.syncErrors.length > 0) {
            this.store.dispatch(SyncActions.addSyncErrors({ syncErrors: this.syncErrors }));
        }
    }

    private getApiRequestType(request: Requests): Observable<any> {
        switch (request.type) {
            case RequestType.GET:
                return this.apiService.get(request.route);
            case RequestType.PUT:
                return this.apiService.put(request.route, request.body);
            case RequestType.POST: {
                const body = request.typeObjet === TypeObjetSync.PHOTO ? transformToFormData(request.body) : request.body;
                return this.apiService.post(request.route, body);
            }
            case RequestType.DELETE:
                return this.apiService.delete(request.route);
            default:
                return null;
        }
    }

    private receivedError(request: Requests, response: any | null, isBackendError: boolean = true, error?: any) {
        //    if (!this.ignoredIds.includes(request.pointInspection?.id)) { TODO KEEP
        // if (request.pointInspection || request.pointAudit) {
        //     this.ignoredIds.push(request.pointInspection?.id || request.pointAudit?.id);
        // }
        const formatedErrorObject: SyncErrors = this.formateErrorObject(request);
        let syncError: any;
        if (isBackendError) {
            syncError = this.initMessage(formatedErrorObject, request, response);
        } else {
            const errorMessage = this.initApiGatewayErrorMessage(request, error);
            syncError = this.messageApiGateway(formatedErrorObject, request, errorMessage);
        }
        this.syncErrors.push(syncError);
        //}
        this.remainingRequests--;
    }

    private formateErrorObject(request: Requests): SyncErrors {
        return {
            id: request.id,
            type: request.type,
            route: request.route,
            typeObjet: request.typeObjet,
            pointAudit: request?.pointAudit,
            pointInspection: request?.pointInspection
        } as SyncErrors;
    }

    private initMessage(syncError: SyncErrors, request: Requests, response: HttpErrorResponse) {
        const cab = this.isAuditeur ? this.syncService.getCodeABarres(request.pointAudit?.pointInspectionId) || this.labelAucunCodeABarres
            : request.pointInspection?.poteau.codeABarres || this.labelAucunCodeABarres;
        const geometrie = this.isAuditeur ? request.pointAudit?.geometrie || '' : request.pointInspection?.poteau.geometrie || '';

        switch (request.typeObjet) {
            case TypeObjetSync.POINT_AUDIT:
            case TypeObjetSync.POINT_INSPECTION: {
                return this.messageForPoint(syncError, request, response, cab, geometrie);
            }
            case TypeObjetSync.ANOMALIE: {
                return this.messageForAnomalie(syncError, request, response, cab, geometrie);
            }
            case TypeObjetSync.PHOTO: {
                return this.messageForPhoto(syncError, request, response, cab, geometrie);
            }
            default: {
                return syncError;
            }
        }
    }

    private initApiGatewayErrorMessage(request: Requests, error: any) {
        switch (request.typeObjet) {
            case TypeObjetSync.POINT_AUDIT: {
                return `sur le point d'audit`;
            }
            case TypeObjetSync.POINT_INSPECTION: {
                return `sur le point d'inspection`;
            }
            case TypeObjetSync.ANOMALIE: {
                return `sur une anomalie`;
            }
            case TypeObjetSync.PHOTO: {
                return `sur la photo "${request.body.nomOriginal}"`;
            }
            default: {
                return `: ${error}`;
            }
        }
    }

    private messageApiGateway(syncError: SyncErrors, request: Requests, error: any) {
        const cab = this.isAuditeur ? this.syncService.getCodeABarres(request.pointAudit?.pointInspectionId) || this.labelAucunCodeABarres
            : request.pointInspection?.poteau.codeABarres || this.labelAucunCodeABarres;
        const geometrie = this.isAuditeur ? request.pointAudit?.geometrie || '' : request.pointInspection?.poteau.geometrie || '';

        return syncError = {
            ...syncError,
            body: request.body,
            element: cab,
            geometrie: geometrie,
            message: [`Une erreur s'est produite ${error}`]
        };
    }

    private messageErrorFromResponse(response: HttpErrorResponse): string {
        let messageErreur = '';
        if (response) {
            if (response.error) {
                if (response.error.erreurs) {
                    messageErreur = response.error.erreurs;
                } else {
                    messageErreur = response.error;
                }
            } else {
                messageErreur = 'Une erreur est survenue';
            }
        } else {
            messageErreur = 'Une erreur est survenue';
        }
        return messageErreur;
    }

    private messageForPoint(syncError: SyncErrors, request: Requests, response: HttpErrorResponse, cab: string, geometrie: string) {
        return syncError = {
            ...syncError,
            body: request.body,
            element: cab,
            geometrie: geometrie,
            message: [this.messageErrorFromResponse(response)]
        };
    }

    private messageForAnomalie(syncError: SyncErrors, request: Requests, response: HttpErrorResponse, cab: string, geometrie: string) {
        if (request.type === RequestType.PUT) {
            syncError = {
                ...syncError,
                body: request.body,
            };
        }
        return syncError = {
            ...syncError,
            element: cab,
            geometrie: geometrie,
            message: [this.messageErrorFromResponse(response)]
        };
    }

    private messageForPhoto(syncError: SyncErrors, request: Requests, response: HttpErrorResponse, cab: string, geometrie: string) {
        if (request.type === RequestType.POST) {
            syncError = {
                ...syncError,
                body: request.body,
            };
        }

        let message: string[] = [];
        if (typeof response.error === 'object' && response.error instanceof ProgressEvent) {
            message = [`L'application a rencontré une erreur lors du chargement de la photo "${request.body.nomOriginal}".
            Veuillez charger une autre photo ou contacter votre administrateur.`];
        } else {
            message = [this.messageErrorFromResponse(response)];
        }
        return syncError = {
            ...syncError,
            element: cab,
            geometrie: geometrie,
            message: message,
        };
    }

    public openRapportErreursSynchronisationDialog() {
        this.ref.close();
        this.dialogService.open(RapportErreurSynchronisationDialogComponent, {
            header: `Rapport d’erreurs de synchronisation`,
            width: '100%',
            height: '100%',
            modal: false,
            styleClass: 'mobile-dialog',
            data: {
                requests: this.requests,
                isAuditeur: this.isAuditeur
            }
        });
    }
}
