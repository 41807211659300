import { NgModule } from '@angular/core';
import { SharedModule } from '../../shared/shared.module';
import { PilotageDialogComponent } from './pilotage-dialog/pilotage-dialog.component';

@NgModule({
    declarations: [
        PilotageDialogComponent,
    ],
    imports: [
        SharedModule
    ],
    exports: [],
})
export class PilotageModule { }
