import { createReducer, on } from '@ngrx/store';
import * as PhotosActions from './photos.actions';
import { PhotosState } from './photos.state';

export const photosInitialState: PhotosState = {
    // Photos
    loadUploadedPhotosSuccess: false,
    loadUploadedPhotosLoading: false,
    loadUploadedPhotosError: null,
    // Photos
    pointInspectionPhotos: [],
    loadPointInspectionPhotosLoading: false,
    loadPointInspectionPhotosError: null,
    cachePointInspectionPhotosSuccess: false,
    cachePointInspectionPhotosLoading: false,
    cachePointInspectionPhotosError: null,
    nonCachedPointInspectionPhotos: [],
    cachingPointInspectionPhotosProgression: 0,
    // Anomalies
    anomaliesPhotos: [],
    loadAnomaliesPhotosLoading: false,
    loadAnomaliesPhotosError: null,
    cacheAnomaliesPhotosSuccess: false,
    cacheAnomaliesPhotosLoading: false,
    cacheAnomaliesPhotosError: null,
    nonCachedAnomaliesPhotos: [],
    cachingAnomaliesPhotosProgression: 0,
    // Audit
    pointAuditPhotos: [],
    loadPointAuditPhotosLoading: false,
    loadPointAuditPhotosError: null,
    cachePointAuditPhotosSuccess: false,
    cachePointAuditPhotosLoading: false,
    cachePointAuditPhotosError: null,
    nonCachedPointAuditPhotos: [],
    cachingPointAuditPhotosProgression: 0,
    // Anomalies audit
    anomaliesAuditPhotos: [],
    loadAnomaliesAuditPhotosLoading: false,
    loadAnomaliesAuditPhotosError: null,
    cacheAnomaliesAuditPhotosSuccess: false,
    cacheAnomaliesAuditPhotosLoading: false,
    cacheAnomaliesAuditPhotosError: null,
    nonCachedAnomaliesAuditPhotos: [],
    cachingAnomaliesAuditPhotosProgression: 0,
};

export const photosReducer = createReducer<PhotosState>(
    photosInitialState,
    // Uploaded Photos
    on(PhotosActions.loadUploadedPhoto, state => {
        return {
            ...state,
            loadUploadedPhotosSuccess: false,
            loadUploadedPhotosLoading: true,
            loadUploadedPhotosError: null,
        };
    }),
    on(PhotosActions.loadUploadedPhotoSuccess, state => {
        return {
            ...state,
            loadUploadedPhotosSuccess: true,
            loadUploadedPhotosLoading: false,
            loadUploadedPhotosError: null,
        };
    }),
    on(PhotosActions.loadUploadedPhotoFailure, (state, action) => {
        return {
            ...state,
            loadUploadedPhotosSuccess: false,
            loadUploadedPhotosLoading: false,
            loadUploadedPhotosError: action.error,
        };
    }),
    // Points Inspection
    on(PhotosActions.loadPointInspectionPhotos, state => {
        return {
            ...state,
            pointInspectionPhotos: [],
            loadPointInspectionPhotosLoading: true,
            loadPointInspectionPhotosError: null
        };
    }),
    on(PhotosActions.loadPointInspectionPhotosSuccess, (state, action) => {
        return {
            ...state,
            pointInspectionPhotos: action.photos,
            loadPointInspectionPhotosLoading: false,
            loadPointInspectionPhotosError: null,
        };
    }),
    on(PhotosActions.loadPointInspectionPhotosFailure, (state, action) => {
        return {
            ...state,
            loadPointInspectionPhotosLoading: false,
            loadPointInspectionPhotosError: action.error
        };
    }),
    on(PhotosActions.cachePointInspectionPhotosSuccess, state => {
        return {
            ...state,
            cachePointInspectionPhotosSuccess: true,
            cachePointInspectionPhotosLoading: false,
            cachePointInspectionPhotosError: null,
        };
    }),
    on(PhotosActions.cachePointInspectionPhotosFailure, (state, action) => {
        return {
            ...state,
            cachePointInspectionPhotosSuccess: false,
            cachePointInspectionPhotosLoading: false,
            cachePointInspectionPhotosError: action.error,
        };
    }),
    on(PhotosActions.cachePointInspectionPhotos, state => {
        return {
            ...state,
            cachePointInspectionPhotosSuccess: false,
            cachePointInspectionPhotosLoading: true,
            cachePointInspectionPhotosError: null,
            loadPhotosInError: [],
            cachingPointInspectionPhotosProgression: 0
        };
    }),
    on(PhotosActions.cachePointInspectionPhotosSuccess, state => {
        return {
            ...state,
            cachePointInspectionPhotosSuccess: true,
            cachePointInspectionPhotosLoading: false,
            cachePointInspectionPhotosError: null,
        };
    }),
    on(PhotosActions.cachePointInspectionPhotosFailure, (state, action) => {
        return {
            ...state,
            cachePointInspectionPhotosSuccess: false,
            cachePointInspectionPhotosLoading: false,
            cachePointInspectionPhotosError: action.error,
        };
    }),
    on(PhotosActions.updateNonCachedPointInspectionPhotosList, (state, action) => {
        return {
            ...state,
            nonCachedPointInspectionPhotos: [...state.nonCachedPointInspectionPhotos, action.photo]
        };
    }),
    on(PhotosActions.updateCachingPointInspectionPhotosProgression, (state, action) => {
        return {
            ...state,
            cachingPointInspectionPhotosProgression: action.progression
        };
    }),
    // Anomalies
    on(PhotosActions.loadAnomaliesPhotos, state => {
        return {
            ...state,
            anomaliesPhotos: [],
            loadAnomaliesPhotosLoading: true,
            loadAnomaliesPhotosError: null
        };
    }),
    on(PhotosActions.loadAnomaliesPhotosSuccess, (state, action) => {
        return {
            ...state,
            anomaliesPhotos: action.photos,
            loadAnomaliesPhotosLoading: false,
            loadAnomaliesPhotosError: null,
        };
    }),
    on(PhotosActions.loadAnomaliesPhotosFailure, (state, action) => {
        return {
            ...state,
            loadAnomaliesPhotosLoading: false,
            loadAnomaliesPhotosError: action.error
        };
    }),
    on(PhotosActions.cacheAnomaliesPhotos, state => {
        return {
            ...state,
            cacheAnomaliesPhotosSuccess: false,
            cacheAnomaliesPhotosLoading: true,
            cacheAnomaliesPhotosError: null,
            loadAnomaliesPhotosInError: [],
            cachingAnomaliesPhotosProgression: 0
        };
    }),
    on(PhotosActions.cacheAnomaliesPhotosSuccess, state => {
        return {
            ...state,
            cacheAnomaliesPhotosSuccess: true,
            cacheAnomaliesPhotosLoading: false,
            cacheAnomaliesPhotosError: null,
        };
    }),
    on(PhotosActions.cacheAnomaliesPhotosFailure, (state, action) => {
        return {
            ...state,
            cacheAnomaliesPhotosSuccess: false,
            cacheAnomaliesPhotosLoading: false,
            cacheAnomaliesPhotosError: action.error,
        };
    }),
    on(PhotosActions.updateNonCachedAnomaliesPhotosList, (state, action) => {
        return {
            ...state,
            nonCachedAnomaliesPhotos: [...state.nonCachedAnomaliesPhotos, action.photo]
        };
    }),
    on(PhotosActions.updateCachingAnomaliesPhotosProgression, (state, action) => {
        return {
            ...state,
            cachingAnomaliesPhotosProgression: action.progression
        };
    }),
    // Points Audit
    on(PhotosActions.loadPointAuditPhotos, state => {
        return {
            ...state,
            pointAuditPhotos: [],
            loadPointAuditPhotosLoading: true,
            loadPointAuditPhotosError: null
        };
    }),
    on(PhotosActions.loadPointAuditPhotosSuccess, (state, action) => {
        return {
            ...state,
            pointAuditPhotos: action.photos,
            loadPointAuditPhotosLoading: false,
            loadPointAuditPhotosError: null,
        };
    }),
    on(PhotosActions.loadPointAuditPhotosFailure, (state, action) => {
        return {
            ...state,
            loadPointAuditPhotosLoading: false,
            loadPointAuditPhotosError: action.error
        };
    }),
    on(PhotosActions.cachePointAuditPhotos, state => {
        return {
            ...state,
            cachePointAuditPhotosSuccess: false,
            cachePointAuditPhotosLoading: true,
            cachePointAuditPhotosError: null,
            loadAuditPhotosInError: [],
            cachingPointAuditPhotosProgression: 0
        };
    }),
    on(PhotosActions.cachePointAuditPhotosSuccess, state => {
        return {
            ...state,
            cachePointAuditPhotosSuccess: true,
            cachePointAuditPhotosLoading: false,
            cachePointAuditPhotosError: null,
        };
    }),
    on(PhotosActions.cachePointAuditPhotosFailure, (state, action) => {
        return {
            ...state,
            cachePointAuditPhotosSuccess: false,
            cachePointAuditPhotosLoading: false,
            cachePointAuditPhotosError: action.error,
        };
    }),
    on(PhotosActions.updateNonCachedPointAuditPhotosList, (state, action) => {
        return {
            ...state,
            nonCachedPointAuditPhotos: [...state.nonCachedPointAuditPhotos, action.photo]
        };
    }),
    on(PhotosActions.updateCachingPointAuditPhotosProgression, (state, action) => {
        return {
            ...state,
            cachingPointAuditPhotosProgression: action.progression
        };
    }),
    // Anomalies audit
    on(PhotosActions.loadAnomaliesAuditPhotos, state => {
        return {
            ...state,
            anomaliesAuditPhotos: [],
            loadAnomaliesAuditPhotosLoading: true,
            loadAnomaliesAuditPhotosError: null
        };
    }),
    on(PhotosActions.loadAnomaliesAuditPhotosSuccess, (state, action) => {
        return {
            ...state,
            anomaliesAuditPhotos: action.photos,
            loadAnomaliesAuditPhotosLoading: false,
            loadAnomaliesAuditPhotosError: null,
        };
    }),
    on(PhotosActions.loadAnomaliesAuditPhotosFailure, (state, action) => {
        return {
            ...state,
            loadAnomaliesAuditPhotosLoading: false,
            loadAnomaliesAuditPhotosError: action.error
        };
    }),
    on(PhotosActions.cacheAnomaliesAuditPhotos, state => {
        return {
            ...state,
            cacheAnomaliesAuditPhotosSuccess: false,
            cacheAnomaliesAuditPhotosLoading: true,
            cacheAnomaliesAuditPhotosError: null,
            loadAnomaliesAuditPhotosInError: [],
            cachingAnomaliesAuditPhotosProgression: 0
        };
    }),
    on(PhotosActions.cacheAnomaliesAuditPhotosSuccess, state => {
        return {
            ...state,
            cacheAnomaliesAuditPhotosSuccess: true,
            cacheAnomaliesAuditPhotosLoading: false,
            cacheAnomaliesAuditPhotosError: null,
        };
    }),
    on(PhotosActions.cacheAnomaliesAuditPhotosFailure, (state, action) => {
        return {
            ...state,
            cacheAnomaliesAuditPhotosSuccess: false,
            cacheAnomaliesAuditPhotosLoading: false,
            cacheAnomaliesAuditPhotosError: action.error,
        };
    }),
    on(PhotosActions.updateNonCachedAnomaliesAuditPhotosList, (state, action) => {
        return {
            ...state,
            nonCachedAnomaliesAuditPhotos: [...state.nonCachedAnomaliesAuditPhotos, action.photo]
        };
    }),
    on(PhotosActions.updateCachingAnomaliesAuditPhotosProgression, (state, action) => {
        return {
            ...state,
            cachingAnomaliesAuditPhotosProgression: action.progression
        };
    }),
    // Offline photos
    on(PhotosActions.addPhotoToIndexedDb, state => {
        return {
            ...state,
            loadUploadedPhotosSuccess: false,
            loadUploadedPhotosLoading: true,
            loadUploadedPhotosError: null
        };
    }),
);
