import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dateTimeChange } from '../../../shared/utils';
import { ProjetHistory } from '../../projet/models/projet-history.model';

import {
    AnomalieBaseDto,
    PhotoEntity,
    PointInspectionDto,
    ProjetAvecHistoriqueBaseDto,
    ProjetCompletDto,
    ProjetHistoriqueDto
} from '../../../core/api/client/models';
import {
    AnomalieService,
    InspectionService as PointsInspectionService,
    ProjetService,
} from '../../../core/api/client/services';

@Injectable({
    providedIn: 'root'
})
export class InspectionService {

    constructor(
        private anomalieApiService: AnomalieService,
        private projetApiService: ProjetService,
        private pointsInspectionApiService: PointsInspectionService
    ) { }

    public getProjetsInspection(params: ProjetService.GetApiV1ProjetsParams): Observable<ProjetCompletDto[]> {
        return this.projetApiService.getApiV1Projets(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public getProjetInspection(params: ProjetService.GetApiV1ProjetsIdParams): Observable<ProjetCompletDto> {
        return this.projetApiService.getApiV1ProjetsId(params);
    }

    public getProjetInspectionHistory(projetId: string): Observable<ProjetHistory[]> {
        return this.projetApiService.getApiV1ProjetsIdHistorique(projetId)
            .pipe(
                map(data => this.formatProjetHistory(data)));
    }

    public getPointsInspection(params: PointsInspectionService.GetApiV1InspectionsParams): Observable<PointInspectionDto[]> {
        return this.pointsInspectionApiService.getApiV1Inspections(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public createProjetInspection(params: ProjetService.PostApiV1ProjetsIdInspectionsParams): Observable<PointInspectionDto> {
        return this.projetApiService.postApiV1ProjetsIdInspections(params);
    }

    public getCreateProjetInspectionStatus(id: string) {
        return this.projetApiService.getApiV1ProjetsIdStatut(id);
    }

    public updateProjetInspection(params: ProjetService.PatchApiV1ProjetsIdParams): Observable<ProjetCompletDto> {
        return this.projetApiService.patchApiV1ProjetsId(params);
    }

    public createPointInspection(params: ProjetService.PostApiV1ProjetsIdInspectionsParams): Observable<PointInspectionDto> {
        return this.projetApiService.postApiV1ProjetsIdInspections(params);
    }

    public updatePointInspection(params: PointsInspectionService.PutApiV1InspectionsIdParams): Observable<PointInspectionDto> {
        return this.pointsInspectionApiService.putApiV1InspectionsId(params);
    }

    public updateAnomalieInspection(params: ProjetService.PutApiV1ProjetsIdInspectionInspectionIdAnomaliesParams): Observable<AnomalieBaseDto> {
        return this.projetApiService.putApiV1ProjetsIdInspectionInspectionIdAnomalies(params);
    }

    public deleteAnomalieInspection(anomalieId: string): Observable<null> {
        return this.anomalieApiService.deleteApiV1AnomaliesAnomalieId(anomalieId);
    }

    public addPointInspectionPhoto(params: PointsInspectionService.PostApiV1InspectionsInspectionIdPhotosParams): Observable<PhotoEntity> {
        return this.pointsInspectionApiService.postApiV1InspectionsInspectionIdPhotos(params);
    }

    public deletePointInspectionPhoto(params: PointsInspectionService.DeleteApiV1InspectionsInspectionIdPhotosNomPhotoParams): Observable<null> {
        return this.pointsInspectionApiService.deleteApiV1InspectionsInspectionIdPhotosNomPhoto(params);
    }

    public addAnomalieInspectionPhoto(params: AnomalieService.PostApiV1AnomaliesAnomalieIdPhotosParams): Observable<PhotoEntity> {
        return this.anomalieApiService.postApiV1AnomaliesAnomalieIdPhotos(params);
    }

    public deleteAnomalieInspectionPhoto(params: AnomalieService.DeleteApiV1AnomaliesAnomalieIdPhotosNomPhotoParams): Observable<null> {
        return this.anomalieApiService.deleteApiV1AnomaliesAnomalieIdPhotosNomPhoto(params);
    }

    private formatProjetHistory(data: ProjetAvecHistoriqueBaseDto): ProjetHistory[] {
        return data.historique!
            .map((historique: ProjetHistoriqueDto) => ({
                ...historique,
                dateOperation: dateTimeChange(historique.dateOperation?.toString(), 'YYYY-MM-dd'),
                creeLe: dateTimeChange(historique.creeLe?.toString(), 'YYYY-MM-dd'),
                assigneLe: dateTimeChange(historique.assigneLe?.toString(), 'YYYY-MM-dd'),
                activeLe: dateTimeChange(historique.activeLe?.toString(), 'YYYY-MM-dd'),
                completeLe: dateTimeChange(historique.completeLe?.toString(), 'YYYY-MM-dd'),
                valideLe: dateTimeChange(historique.valideLe?.toString(), 'YYYY-MM-dd'),
                auditAnnuleLe: dateTimeChange(historique.auditAnnuleLe?.toString(), 'YYYY-MM-dd'),
                auditCreeLe: dateTimeChange(historique.auditCreeLe?.toString(), 'YYYY-MM-dd'),
                approbationHqLe: dateTimeChange(historique.approbationHqLe?.toString(), 'YYYY-MM-dd'),
                annuleLe: dateTimeChange(historique.annuleLe?.toString(), 'YYYY-MM-dd'),
                aqAssigneA: historique.aqAssigneA,
                aqAssignePar: historique.aqAssignePar,
                aqActiveLe: dateTimeChange(historique.aqActiveLe?.toString(), 'YYYY-MM-dd'),
                aqActivePar: historique.aqActivePar,
                aqCompletePar: historique.aqCompletePar,
                aqCompleteLe: dateTimeChange(historique.aqCompleteLe?.toString(), 'YYYY-MM-dd')
            }) as ProjetHistory);
    }
}
