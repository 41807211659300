import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ProjetService as ProjetApiService } from '../../../core/api/client/services';
import { dateTimeChange } from '../../../shared/utils';
import { ProjetHistory } from '../models/projet-history.model';
import {
    ProjetCompletDto,
    ProjetHistoriqueDto,
    ProjetAvecHistoriqueBaseDto,
    ProjetBaseDto
} from '../../../core/api/client/models';

@Injectable({
    providedIn: 'root'
})
export class ProjetService {
    constructor(
        private projetApiService: ProjetApiService
    ) { }

    public getProjet(params: ProjetApiService.GetApiV1ProjetsIdParams): Observable<ProjetCompletDto> {
        return this.projetApiService.getApiV1ProjetsId(params);
    }

    public updateProjet(params: ProjetApiService.PatchApiV1ProjetsIdParams): Observable<ProjetBaseDto> {
        return this.projetApiService.patchApiV1ProjetsId(params);
    }

    public getProjetHistory(projetId: string): Observable<ProjetHistory[]> {
        return this.projetApiService.getApiV1ProjetsIdHistorique(projetId)
            .pipe(
                map(data => this.formatProjetHistory(data)));
    }

    private formatProjetHistory(data: ProjetAvecHistoriqueBaseDto): ProjetHistory[] {
        return data.historique!
            .map((historique: ProjetHistoriqueDto) => ({
                ...historique,
                dateOperation: dateTimeChange(historique.dateOperation?.toString(), 'YYYY-MM-dd'),
                creeLe: dateTimeChange(historique.creeLe?.toString(), 'YYYY-MM-dd'),
                assigneLe: dateTimeChange(historique.assigneLe?.toString(), 'YYYY-MM-dd'),
                activeLe: dateTimeChange(historique.activeLe?.toString(), 'YYYY-MM-dd'),
                completeLe: dateTimeChange(historique.completeLe?.toString(), 'YYYY-MM-dd'),
                valideLe: dateTimeChange(historique.valideLe?.toString(), 'YYYY-MM-dd'),
                auditAnnuleLe: dateTimeChange(historique.auditAnnuleLe?.toString(), 'YYYY-MM-dd'),
                auditCreeLe: dateTimeChange(historique.auditCreeLe?.toString(), 'YYYY-MM-dd'),
                approbationHqLe: dateTimeChange(historique.approbationHqLe?.toString(), 'YYYY-MM-dd'),
                annuleLe: dateTimeChange(historique.annuleLe?.toString(), 'YYYY-MM-dd'),
                aqAssigneA: historique.aqAssigneA,
                aqAssignePar: historique.aqAssignePar,
                aqActiveLe: dateTimeChange(historique.aqActiveLe?.toString(), 'YYYY-MM-dd'),
                aqActivePar: historique.aqActivePar,
                aqCompletePar: historique.aqCompletePar,
                aqCompleteLe: dateTimeChange(historique.aqCompleteLe?.toString(), 'YYYY-MM-dd')
            }) as ProjetHistory);
    }
}
