import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../../shared/shared.module';
import { AnomalieEffects } from './state/anomalie/anomalie.effects';
import { anomalieReducer } from './state/anomalie/anomalie.reducer';
import { AnomalieDialogComponent } from './components/anomalie-dialog/anomalie-dialog.component';
import { PhotoService } from '../../services/photo.service';
import { AnomalieListComponent } from './components/anomalie-list/anomalie-list.component';
import { AnomalieListDialogComponent } from './components/anomalie-list/anomalie-list-dialog/anomalie-list-dialog.component';
import { AnomalieDialogAuditDataComponent } from './components/anomalie-dialog/anomalie-dialog-audit-data/anomalie-dialog-audit-data.component';
import { AnomalieListEffects } from './state/anomalie-list/anomalie-list.effects';
import { anomalieListReducer } from './state/anomalie-list/anomalie-list.reducer';
import { PhotoExtractionDialogComponent } from './components/photo-extraction-dialog/photo-extraction-dialog.component';
import { AuditModule } from '../audit/audit.module';

@NgModule({
    declarations: [
        AnomalieListComponent,
        AnomalieDialogComponent,
        AnomalieListDialogComponent,
        PhotoExtractionDialogComponent,
        AnomalieDialogAuditDataComponent,
    ],
    imports: [
        AuditModule,
        SharedModule,
        StoreModule.forFeature('anomalie', anomalieReducer),
        StoreModule.forFeature('anomalieList', anomalieListReducer),
        EffectsModule.forFeature([AnomalieEffects, AnomalieListEffects])
    ],
    providers: [
        PhotoService
    ],
})
export class AnomalieModule { }
