import { DBConfig } from 'ngx-indexed-db';
import { StoreName } from './indexed-db-store-name.enum';

export const indexedDBConfig: DBConfig = {
    name: 'Capture',
    version: 1,
    objectStoresMeta: [
        {
            store: StoreName.ANOMALIE_PILOTAGE,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: []
        },
        {
            store: StoreName.PROJETS_AUDIT,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'projetId', keypath: 'projetId', options: { unique: false } },
                { name: 'pointAudits', keypath: 'pointAudits', options: { unique: false } },
                { name: 'nom', keypath: 'nom', options: { unique: false } },
                { name: 'statut', keypath: 'statut', options: { unique: false } },
                { name: 'assigneA', keypath: 'assigneA', options: { unique: false } },
                { name: 'assigneLe', keypath: 'assigneLe', options: { unique: false } },
                { name: 'assignePar', keypath: 'assignePar', options: { unique: false } },
                { name: 'seuilAudit', keypath: 'seuilAudit', options: { unique: false } },
                { name: 'typeDeSeuil', keypath: 'typeDeSeuil', options: { unique: false } },
                { name: 'geometrie', keypath: 'geometrie', options: { unique: false } },
                { name: 'nombreTotalDePoteaux', keypath: 'nombreTotalDePoteaux', options: { unique: false } },
                { name: 'firme', keypath: 'firme', options: { unique: false } },
                { name: 'remarques', keypath: 'remarques', options: { unique: false } },
            ]
        },
        {
            store: StoreName.PROJETS,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'nom', keypath: 'nom', options: { unique: false } },
                { name: 'type', keypath: 'type', options: { unique: false } },
                { name: 'statut', keypath: 'statut', options: { unique: false } },
                { name: 'firme', keypath: 'firme', options: { unique: false } },
                { name: 'territoire', keypath: 'territoire', options: { unique: false } },
                { name: 'assigneLe', keypath: 'assigneLe', options: { unique: false } },
                { name: 'assigneA', keypath: 'assigneA', options: { unique: false } },
                { name: 'numeroOrdreDeTravail', keypath: 'numeroOrdreDeTravail', options: { unique: false } },
                { name: 'inspectionAnnee', keypath: 'inspectionAnnee', options: { unique: false } },
                { name: 'assignePar', keypath: 'assignePar', options: { unique: false } },
                { name: 'transfertSap', keypath: 'transfertSap', options: { unique: false } },
                { name: 'dateTransfertSap', keypath: 'dateTransfertSap', options: { unique: false } },
                { name: 'geometrie', keypath: 'geometrie', options: { unique: false } },
                { name: 'pointInspections', keypath: 'pointInspections', options: { unique: false } },
                { name: 'nombreTotalPoteaux', keypath: 'nombreTotalPoteaux', options: { unique: false } },
                { name: 'moyenneMinuteInspectionPoteau', keypath: 'moyenneMinuteInspectionPoteau', options: { unique: false } },
                { name: 'nombrePoteauxInspectes', keypath: 'nombrePoteauxInspectes', options: { unique: false } },
                { name: 'nombrePoteauxNonInspectes', keypath: 'nombrePoteauxNonInspectes', options: { unique: false } },
                { name: 'nombrePoteauxAvecAnomalie', keypath: 'nombrePoteauxAvecAnomalie', options: { unique: false } },
                { name: 'nombrePoteauxIgnores', keypath: 'nombrePoteauxIgnores', options: { unique: false } },
            ]
        },
        {
            store: StoreName.REQUESTS,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'type', keypath: 'type', options: { unique: false } },
                { name: 'route', keypath: 'route', options: { unique: false } },
                { name: 'body', keypath: 'body', options: { unique: false } }
            ]
        },
        {
            store: StoreName.SYNC_ERRORS,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'type', keypath: 'type', options: { unique: false } },
                { name: 'route', keypath: 'route', options: { unique: false } },
                { name: 'body', keypath: 'body', options: { unique: false } },
                { name: 'element', keypath: 'element', options: { unique: false } },
                { name: 'message', keypath: 'message', options: { unique: false } },
            ]
        },
        {
            store: StoreName.TAXONOMIE,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'groupe', keypath: 'groupe', options: { unique: false } },
                { name: 'code', keypath: 'code', options: { unique: false } },
                { name: 'estActif', keypath: 'estActif', options: { unique: false } },
                { name: 'proprietes', keypath: 'proprietes', options: { unique: false } }
            ]
        },
        {
            store: StoreName.PHOTOS,
            storeConfig: { keyPath: 'id', autoIncrement: true },
            storeSchema: [
                { name: 'anomalieAuditId', keypath: 'anomalieAuditId', options: { unique: false } },
                { name: 'anomalieInspectionId', keypath: 'anomalieInspectionId', options: { unique: false } },
                { name: 'data', keypath: 'data', options: { unique: false } },
                { name: 'nom', keypath: 'nom', options: { unique: false } },
                { name: 'nomOriginal', keypath: 'nomOriginal', options: { unique: false } },
                { name: 'pointAuditId', keypath: 'pointAuditId', options: { unique: false } },
                { name: 'pointInspectionId', keypath: 'pointInspectionId', options: { unique: false } },
                { name: 'projetId', keypath: 'projetId', options: { unique: false } },
                { name: 'projetAuditId', keypath: 'projetAuditId', options: { unique: false } },
            ]
        }
    ]
};
