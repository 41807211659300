export enum ModeCreationProjetValue {
    polygone = 'Polygone',
    ligne = 'Ligne',
    //sqrc = 'SQRC',
}

export enum ModeCreationProjet {
    polygone = 'polygone',
    ligne = 'ligne',
    //sqrc = 'sqrc',
}

export const modeCreationList = Object.keys(ModeCreationProjet).map(
    key => ({ mode: key, label: ModeCreationProjetValue[key], inactive: false })
);
