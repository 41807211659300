import { createSelector } from '@ngrx/store';
import { StatutProjet } from '../../models/statut-projet.enum';
import { Projet } from '../../models/projet.model';
import { projetsAdapter, ProjetsState } from './projet.reducers';
import { getProjetState, ProjetState } from '../';

const {
    selectAll,
} = projetsAdapter.getSelectors();

export const getProjetsListState = createSelector(
    getProjetState,
    (state: ProjetState) => {
        return state && state.projets;
    }
);

export const selectAllProjects = createSelector(
    getProjetsListState,
    selectAll
);

export const selectAssigneProjet = createSelector(
    selectAllProjects,
    (projects: Projet[]) => Array.isArray(projects) && projects.filter(p => p.assigneA) || []
);

export const selectActiveProjet = createSelector(
    getProjetsListState,
    (projets: ProjetsState) => projets?.activeProjet
);

export const selectActiveProjetWithStatus = createSelector(
    selectAllProjects,
    (projets) => {
        const validStatus = [StatutProjet.inspectionEnCours, StatutProjet.correctionEnCours];
        return Array.isArray(projets) && projets.filter(p => validStatus.includes(p.statut)) as any[] || []; // TODO TYPER
    }
);

export const getCurrentActiveProjetAQ = createSelector(
    selectAllProjects,
    (projets) => {
        const validStatus = [StatutProjet.aqEnCours, StatutProjet.correctionEnCours];
        return Array.isArray(projets) && projets.filter(p => validStatus.includes(p.statut)) || [];
    }
);

export const getCurrentActiveProjetWithFilteredDeletedAnomalies = createSelector(
    selectActiveProjet,
    (activeProjet: Projet) => {
        activeProjet.pointInspections = activeProjet.pointInspections.map(pointInspection => {
            pointInspection.anomalies = pointInspection.anomalies.filter(anomalie => !anomalie.statut);
            return pointInspection;
        });

        return activeProjet;
    }
);

export const getAssigneProjetSuccess = createSelector(
    getProjetsListState,
    (projets: ProjetsState) => {
        return {
            success: projets.assigneProjetSuccess
        };
    }
);

export const getAssigneProjetError = createSelector(
    getProjetsListState,
    (projets: ProjetsState) => projets.assigneProjetError
);


export const isProjetsLoading = createSelector(
    getProjetsListState,
    (projets: ProjetsState) => projets.projectsLoading
);

export const getProjetById = (projetId: string) => createSelector(
    selectAllProjects,
    (projects: Projet[]) => Array.isArray(projects) && projects.filter(p => p.id === projetId)[0] || null
);
