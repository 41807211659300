import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Observable } from 'rxjs';
import { select, Store } from '@ngrx/store';
import * as AuditActions from '../../state/audit.actions';
import { State } from '../../state/audit.state';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { getTaxonomiePourGroupe } from '../../../../core/store/selectors/taxonomie.selectors';
import { TaxonomieGroupeValue } from '../../../pilotage/models/taxonomie-groupe.enum';
import { ProjetAuditDto, PatchProjetAuditDto, ActionProjetAudit } from '../../../../core/api/client/models';

@Component({
    selector: 'app-assign-projet-audit-dialog',
    templateUrl: './assign-projet-audit-dialog.component.html',
    styleUrls: ['./assign-projet-audit-dialog.component.scss']
})
export class AssignProjetAuditDialogComponent extends BaseComponent implements OnInit {

    @Input() projetAudit: ProjetAuditDto;
    @Input() visible: boolean;
    @Output() visibleChange = new EventEmitter<boolean>();

    public auditeursList$: Observable<string[]>;
    public auditeurSelected: string;

    constructor(
        private store: Store<State>
    ) {
        super();
    }

    ngOnInit() {
        this.auditeursList$ = this.store.pipe(select(getTaxonomiePourGroupe(TaxonomieGroupeValue.AUDITEURS)));
    }

    public onAssigne() {
        if (this.auditeurSelected) {
            const data: PatchProjetAuditDto = { action: ActionProjetAudit.assigner, assignea: this.auditeurSelected };
            this.store.dispatch(AuditActions.assignProjetAudit({ projetAuditId: this.projetAudit.id!, assignData: data }));
            this.closeAssignDialog();
        }
    }

    public closeAssignDialog() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
        this.auditeurSelected = '';
    }
}
