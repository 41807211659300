/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AnomalieAuditDtoSearchResult } from '../models/anomalie-audit-dto-search-result';
@Injectable({
    providedIn: 'root',
})
class AnomalieAuditService extends __BaseService {
    static readonly getApiV1AnomaliesauditPath = '/api/v1/anomaliesaudit';
    static readonly getApiV1AnomaliesauditIdPhotosIdPhotoPath = '/api/v1/anomaliesaudit/{id}/photos/{idPhoto}';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * @param params The `AnomalieAuditService.GetApiV1AnomaliesauditParams` containing the following parameters:
     *
     * - `ymin`:
     *
     * - `ymax`:
     *
     * - `xmin`:
     *
     * - `xmax`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * @return Success
     */
    getApiV1AnomaliesauditResponse(params: AnomalieAuditService.GetApiV1AnomaliesauditParams): __Observable<__StrictHttpResponse<AnomalieAuditDtoSearchResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.ymin != null) __params = __params.set('ymin', params.ymin.toString());
        if (params.ymax != null) __params = __params.set('ymax', params.ymax.toString());
        if (params.xmin != null) __params = __params.set('xmin', params.xmin.toString());
        if (params.xmax != null) __params = __params.set('xmax', params.xmax.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/anomaliesaudit`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<AnomalieAuditDtoSearchResult>;
            })
        );
    }
    /**
     * @param params The `AnomalieAuditService.GetApiV1AnomaliesauditParams` containing the following parameters:
     *
     * - `ymin`:
     *
     * - `ymax`:
     *
     * - `xmin`:
     *
     * - `xmax`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * @return Success
     */
    getApiV1Anomaliesaudit(params: AnomalieAuditService.GetApiV1AnomaliesauditParams): __Observable<AnomalieAuditDtoSearchResult> {
        return this.getApiV1AnomaliesauditResponse(params).pipe(
            __map(_r => _r.body as AnomalieAuditDtoSearchResult)
        );
    }

    /**
     * @param params The `AnomalieAuditService.GetApiV1AnomaliesauditIdPhotosIdPhotoParams` containing the following parameters:
     *
     * - `idPhoto`:
     *
     * - `id`:
     *
     * - `taille`:
     */
    getApiV1AnomaliesauditIdPhotosIdPhotoResponse(params: AnomalieAuditService.GetApiV1AnomaliesauditIdPhotosIdPhotoParams): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        if (params.taille != null) __params = __params.set('taille', params.taille.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/anomaliesaudit/${encodeURIComponent(String(params.id))}/photos/${encodeURIComponent(String(params.idPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'blob'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            })
        );
    }
    /**
     * @param params The `AnomalieAuditService.GetApiV1AnomaliesauditIdPhotosIdPhotoParams` containing the following parameters:
     *
     * - `idPhoto`:
     *
     * - `id`:
     *
     * - `taille`:
     */
    getApiV1AnomaliesauditIdPhotosIdPhoto(params: AnomalieAuditService.GetApiV1AnomaliesauditIdPhotosIdPhotoParams): __Observable<Blob> {
        return this.getApiV1AnomaliesauditIdPhotosIdPhotoResponse(params).pipe(
            __map(_r => _r.body as Blob)
        );
    }
}

module AnomalieAuditService {

    /**
     * Parameters for getApiV1Anomaliesaudit
     */
    export interface GetApiV1AnomaliesauditParams {
        ymin?: number;
        ymax?: number;
        xmin?: number;
        xmax?: number;
        pageSize?: number;
        pageNumber?: number;
    }

    /**
     * Parameters for getApiV1AnomaliesauditIdPhotosIdPhoto
     */
    export interface GetApiV1AnomaliesauditIdPhotosIdPhotoParams {
        idPhoto: string;
        id: string;
        taille?: any;
    }
}

export { AnomalieAuditService }
