import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
//import { Store } from '@ngrx/store';
import { of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
// import { fetchProjets } from 'src/app/features/projet/store/projet/projet.actions';
// import * as AuditActions from '../../../features/audit/state/audit.actions';
// import * as InspectionActions from '../../../features/inspection/state/inspection.actions';
// import { State } from '../../../features/audit/state/audit.state';
import { LocalStorageIndex } from '../../../shared/enums/local-storage-index.enum';
import { UserInformation } from '../../../shared/models/user-informations.model';
import { ApiService } from '../../../services/api.service';
import { getUserInformationSuccess, UserInformationActionTypes } from '../actions/userInformation.action';

@Injectable()
export class UserInformationEffects {

    public fetchUserInformation$ = createEffect(() => this.actions$.pipe(
        ofType(UserInformationActionTypes.GET_USER_INFORMATION),
        switchMap(() => {
            if (navigator.onLine) {
                return this.apiService.get<UserInformation>('/utilitaires/me');
            }
            return of(null);
        }),
        map(userInformation => {
            if (userInformation) {
                localStorage.setItem(LocalStorageIndex.FIRME, userInformation.firme);
                localStorage.setItem(LocalStorageIndex.USER_ROLES, JSON.stringify(userInformation.groupes));
                return getUserInformationSuccess({ userInformation });
            } else {
                const firme = localStorage.getItem(LocalStorageIndex.FIRME) as string;
                const groupes = JSON.parse(localStorage.getItem(LocalStorageIndex.USER_ROLES) as string);
                return getUserInformationSuccess({ userInformation: { firme, groupes } });
            }
        }),
    ));

    // public fetchUserProjet$ = createEffect(() => this.actions$.pipe(
    //     ofType(UserInformationActionTypes.GET_USER_INFORMATION_SUCCESS),
    //     switchMap(() => {
    //         this.store.dispatch(AuditActions.loadProjetAuditList());
    //         this.store.dispatch(InspectionActions.loadProjetInspectionList());
    //         return of(fetchProjets());
    //     })
    // ));

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        //private store: Store<State>,
    ) { }
}
