<div class="card">
    <div class="card__title">
        <ng-content></ng-content>
    </div>
    <div class="card__content" *ngIf="infos !== undefined">
        <div class="content__date">
            <span>{{infos.date | date: 'dd/MM/YYYY': undefined: 'fr'}}</span>
        </div>
        <div class="content__remarque">
            <span>{{infos.info}}</span>
        </div>
        <div class="photos">
            <span class="p-column-title photos__title">Photos *</span>
            <div class="photos__list">
                <app-photos-container [loading]="loadingPhotos" [photos]="infos.photos"></app-photos-container>
            </div>
        </div>
    </div>
</div>
