<div>
    <div *ngIf="syncErrorsLoading$ | async">
        <app-loading-spinner></app-loading-spinner>
    </div>
    <div *ngIf="syncErrors$ | async as syncErrors">
        <p-table [columns]="columns" [value]="syncErrors" selectionMode='single' styleClass="p-datatable-striped"
            responsiveLayout="scroll" [paginator]="syncErrors.length > 0" [rows]="10" [showCurrentPageReport]="false"
            (onRowSelect)="zoomFeature($event.data)">
            <ng-template pTemplate="header" let-columns>
                <tr *ngIf="syncErrors.length > 0">
                    <th *ngFor="let col of columns; let i = index" class="p-flex-column" [class.first_column]="i === 0">
                        {{col.header}}
                    </th>
                </tr>
            </ng-template>

            <ng-template pTemplate="body" let-rowData let-columns="columns">
                <tr *ngFor="let message of rowData['message']; let i = index" [pSelectableRow]="rowData">
                    <ng-container *ngFor="let col of columns" class="p-flex-column">
                        <td *ngIf="i === 0 && col.field !== 'message'" [attr.rowspan]="rowData['message'].length">
                            {{rowData[col.field]}}
                        </td>
                        <td *ngIf="col.field === 'message'">
                            {{message}}
                        </td>
                    </ng-container>
                </tr>
            </ng-template>

            <ng-template pTemplate="emptymessage" let-columns>
                <tr>
                    <td [attr.colspan]="columns.length" *ngIf="syncErrors.length === 0">
                        Aucune erreur de synchronisation.
                    </td>
                </tr>
            </ng-template>
        </p-table>

        <div class="p-d-flex center-button vertical-button">
            <button pButton class="erreur" (click)="saveFileAndUploadFailedRequestsToS3()"
                [disabled]="disableClearCacheButton"
                label="Sauvegarder les requêtes échouées au serveur et vider la cache"></button>
        </div>
    </div>
</div>

<p-dialog [modal]="true" [maximizable]="false" header="Requêtes échouées" (onHide)="onCloseFailedRequestUploadDialog()"
    [(visible)]="failedRequestUploadDialogVisible">
    <ng-container *ngIf="!isFailedRequestsUploaded; else uploaded">
        La sauvegarde des requêtes a échouée.<br> Vous pouvez toujours <span class="clikable-text"
            (click)="clearCacheConfirm()">vider votre cache</span>.<br> <strong>Les données non synchronisées seront
            perdues</strong>
    </ng-container>
    <ng-template #uploaded>
        les requêtes échouées ont été sauvegardées et la cache a été vidée avec succès.
    </ng-template>
    <p-footer>
        <p-button (click)="onCloseFailedRequestUploadDialog()" label="Fermer"></p-button>
    </p-footer>
</p-dialog>