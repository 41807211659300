import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { NgxIndexedDBService } from 'ngx-indexed-db';
import { concatMap, map, tap } from 'rxjs/operators';
import { StoreName } from '../../../features/offline/models/indexed-db-store-name.enum';
import { AnomaliePilotage } from '../../../shared/models/anomalie-pilotage.model';
import { AnomaliePilotageDtoSearchResult } from '../../api/client/models';
import { ApiService } from '../../../services/api.service';
import { AnomaliePilotageActionTypes, getAllAnomaliesPilotageSuccess } from '../actions/anomaliesPilotage.actions';

@Injectable()
export class AnomaliePilotageEffects {

    public getAllAnomaliesPilotage$ = createEffect(() => this.actions$.pipe(
        ofType(AnomaliePilotageActionTypes.GET_ANOMALIES_PILOTAGE),
        concatMap(() => {
            if (!navigator.onLine) {
                return this.dbService.getAll<AnomaliePilotage>(StoreName.ANOMALIE_PILOTAGE);
            } else {
                return this.apiService.get<AnomaliePilotageDtoSearchResult>('/utilitaires/anomaliePilotage?pageSize=5000').pipe(
                    tap(ap => this.dbService.bulkAdd(StoreName.ANOMALIE_PILOTAGE, ap.items).subscribe()),
                    map((x: any) => x.items)
                );
            }
        }),
        map((anomaliesPilotage) => {
            return getAllAnomaliesPilotageSuccess({ anomaliesPilotage: anomaliesPilotage });
        })
    ));

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private readonly dbService: NgxIndexedDBService
    ) { }

}
