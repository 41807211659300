import { Component, Injector, Input } from '@angular/core';
import { FormFieldBaseComponent } from '../form-field-base.component';

@Component({
    selector: 'app-form-field-initial',
    templateUrl: './form-field-initial.component.html'
})
export class FormFieldInitialComponent extends FormFieldBaseComponent {

    @Input() subtitle: string;

    constructor(injector: Injector) {
        super(injector);
    }
}
