import { Component, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-form-field-error',
    templateUrl: './form-field-error.component.html'
})
export class FormFieldErrorComponent {
    @Input() control: FormControl;
    @Input() label: string;
}
