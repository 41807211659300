import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../shared/components/abstract-base-component';
import { Store } from '@ngrx/store';
import { State } from '../../state/app.state';
import * as InspectionActions from './state/inspection.actions';
import { getProjetInspectionList, getProjetInspectionListLoading } from './state/inspection.selectors';


@Component({
    selector: 'app-inspection',
    templateUrl: './inspection.component.html',
    styleUrls: ['./inspection.component.scss']
})
export class InspectionComponent extends BaseComponent implements OnInit, OnDestroy {

    public projetInspectionList$ = this.store.select(getProjetInspectionList);
    public projetInspectionListLoading$ = this.store.select(getProjetInspectionListLoading);

    constructor(private store: Store<State>
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(InspectionActions.loadProjetInspectionList());
    }

}
