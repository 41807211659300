export enum IndEquipementMajeurLabel {
    'null' = ' ',
    true = 'Oui',
    false = 'Non',
}

export const IND_EQUIPEMENT_MAJEUR_VALUE = {
    'null': 'null',
    true: true,
    false: false,
};

export const indEquipementMajeurMap = new Map<boolean | null | string, string>([
    [IND_EQUIPEMENT_MAJEUR_VALUE.null, IndEquipementMajeurLabel.null],
    [IND_EQUIPEMENT_MAJEUR_VALUE.true, IndEquipementMajeurLabel.true],
    [IND_EQUIPEMENT_MAJEUR_VALUE.false, IndEquipementMajeurLabel.false],
]);

export const indEquipementMajeurListe = Object.keys(IND_EQUIPEMENT_MAJEUR_VALUE).filter((key: string) => key !== 'null').map(key => (
    { label: IndEquipementMajeurLabel[key], value: IND_EQUIPEMENT_MAJEUR_VALUE[key] }
));
