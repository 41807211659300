import { createAction, props } from '@ngrx/store';
import { UserInformation } from '../../../shared/models/user-informations.model';

export enum UserInformationActionTypes {
    GET_USER_INFORMATION = '[User] Get User Information From Backend',
    GET_USER_INFORMATION_SUCCESS = '[User] Get User Information From Backend Success',
}

export const getUserInformation = createAction(
    UserInformationActionTypes.GET_USER_INFORMATION,
);

export const getUserInformationSuccess = createAction(
    UserInformationActionTypes.GET_USER_INFORMATION_SUCCESS,
    props<{ userInformation: UserInformation }>()
);
