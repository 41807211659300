export enum UsageValue {
    poteauBasseTension = 'Poteau basse tension',
    poteauHaubanage = 'Poteau d\'haubanage',
    poteauDeLigne = 'Poteau de ligne',
    inconnu = 'Inconnu',
}

export enum Usage {
    poteauBasseTension = 'poteauBasseTension',
    poteauHaubanage = 'poteauHaubanage',
    poteauDeLigne = 'poteauDeLigne',
    inconnu = 'inconnu',
}

export const usageListe = Object.keys(Usage).map(key => ({ champ: key, valeur: UsageValue[key] }));

export const usageMap = new Map<Usage, string>([
    [Usage.poteauBasseTension, UsageValue.poteauBasseTension],
    [Usage.poteauHaubanage, UsageValue.poteauHaubanage],
    [Usage.poteauDeLigne, UsageValue.poteauDeLigne],
    [Usage.inconnu, UsageValue.inconnu],
]);
