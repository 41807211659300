<div class="anomalies-audit">
    <ng-container *ngFor="let data of anomalieData; let i = index">
        <pop-up-info-accordion-component [title]="data.anomalie.element" [displayBorders]="false"
            [logoEnd]="'fa-solid fa-camera'" [quantityEnd]="data.anomalieAudit.photos.length">
            <app-pop-up-info-properties [datas]="data.data" [verticalProperties]="verticalProperties"
                [keepOriginalOrder]="true">
            </app-pop-up-info-properties>
            <ng-container *ngIf="data.photos.length > 0">
                <pop-up-info-accordion-component [title]="data.photos.length > 1 ? 'Photos' : 'Photo'"
                    [logo]="'fa-solid fa-camera'" [displayBorders]="false" [isAlwaysOpen]="true"
                    [quantity]="data.photos.length">
                    <app-photo-container [photos]="data.photos"></app-photo-container>
                </pop-up-info-accordion-component>
            </ng-container>
        </pop-up-info-accordion-component>
    </ng-container>
</div>
