import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';
export const rechercheLigne: AnyLayer = {
    id: MapLayersSources.RECHERCHE_LIGNE,
    type: 'line',
    source: MapLayersSources.RECHERCHE_LIGNE,
    minzoom: mapStyleConfig.rechercheLigne.minZoom,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'line-color': mapStyleConfig.colors.rechercheColor,
        'line-width': mapStyleConfig.rechercheLigne.lineWidth,
        'line-opacity': mapStyleConfig.rechercheLigne.opacity,
        /* eslint-enable @typescript-eslint/naming-convention */
    }

};
