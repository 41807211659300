import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { TableColumn } from '../../../../shared/models/table-column.model';
import { MapService } from '../../../../map/services/map.service';
import { PointInspectionDto } from '../../../../core/api/client/models';
import { getInvalidPointsInspection, getInvalidPointsInspectionLoading } from '../../state/inspection.selectors';

@Component({
    selector: 'app-rapport-validation-dialog',
    templateUrl: './rapport-validation-dialog.component.html',
    styleUrls: ['./rapport-validation-dialog.component.scss']
})

export class RapportValidationDialogComponent {

    public pointsInspection$ = this.store.select(getInvalidPointsInspection);
    public pointsInspectionLoading$ = this.store.select(getInvalidPointsInspectionLoading);

    public pointInspection: PointInspectionDto[] = this.config.data.projet.pointInspections;

    columns: TableColumn[] = [
        {
            field: 'codeABarres',
            header: 'Code à barres'
        },
        {
            field: 'codeErreur',
            header: 'Message',
        },
        {
            field: 'action',
            header: '',
        }
    ];

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private store: Store<State>,
        private mapService: MapService,
    ) { }

    public onZoomPointInspection(pointInspection: PointInspectionDto) {
        this.mapService.zoomPointInspection(pointInspection);
        this.ref.close(true);
    }
}
