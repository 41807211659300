import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';

import { NgxIndexedDBService } from 'ngx-indexed-db';
import { catchError, map, switchMap, mergeMap, tap, concatMap } from 'rxjs/operators';
import { of } from 'rxjs';
import { MessageService } from 'primeng/api';
import { Severite } from '../../../enums/severite';

import { StoreName } from '../../../features/offline/models/indexed-db-store-name.enum';
import { Taxonomie } from '../../../features/pilotage/models/taxonomie.model';
import { ApiService } from '../../../services/api.service';
import {
    TaxonomieActionTypes,
    getAllTaxonomiesSuccess,
    createOneTaxonomieSuccess,
    updateOneTaxonomieSuccess,
    deleteOneTaxonomieSuccess,
    messageTaxonomie,
    deleteOneTaxonomieError,
    updateOneTaxonomieError,
    createOneTaxonomieError,
    getAllTaxonomies,
} from '../actions/taxonomie.action';

@Injectable()
export class TaxonomieEffects {
    ///  GET ALL  ///
    public getAllTaxonomie$ = createEffect(() => this.actions$.pipe(
        ofType(TaxonomieActionTypes.GET_ALL_TAXONOMIE),
        switchMap(() => {
            if (!navigator.onLine) {
                return this.dbService.getAll<Taxonomie>(StoreName.TAXONOMIE).pipe(
                    map((taxomomies) => getAllTaxonomiesSuccess({ taxonomies: taxomomies }))
                );
            }
            return this.apiService.get<Taxonomie[]>(`/taxonomies`).pipe(
                map((allTaxonomies: Taxonomie[]) => {
                    return getAllTaxonomiesSuccess({ taxonomies: allTaxonomies });
                }),
            );
        }),
    ));

    public getAllTaxonomieSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(TaxonomieActionTypes.GET_ALL_TAXONOMIE_SUCCESS),
            switchMap((action: any) => this.dbService.bulkAdd(StoreName.TAXONOMIE, action.taxonomies))
        ),
        { dispatch: false }
    );

    ///  CREATE ONE  ///
    public createOneTaxonomie$ = createEffect(() => this.actions$.pipe(
        ofType(TaxonomieActionTypes.CREATE_ONE_TAXONOMIE),
        mergeMap((action: any) => {
            return this.apiService.post<Taxonomie, Taxonomie>(`/taxonomies`, action.taxonomie).pipe(
                mergeMap((newTaxonomie: Taxonomie) => [
                    createOneTaxonomieSuccess({ taxonomie: newTaxonomie }),
                    messageTaxonomie({
                        severity: Severite.success,
                        summary: `Création d'une taxonomie`,
                        detail: `La nouvelle taxonomie a bien été ajoutée.`,
                    }),
                ]),
                catchError((error: any) => of(createOneTaxonomieError(error)))
            );
        })
    ));

    public createOnTaxonomieSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(TaxonomieActionTypes.CREATE_ONE_TAXONOMIE_SUCCESS),
            mergeMap((action: any) => this.dbService.add(StoreName.TAXONOMIE, action.taxonomie))
        ),
        { dispatch: false }
    );

    public createOnTaxonomieError$ = createEffect(
        () => this.actions$.pipe(
            ofType(TaxonomieActionTypes.CREATE_ONE_TAXONOMIE_ERROR),
            mergeMap((_: any) => [
                getAllTaxonomies(),
                messageTaxonomie({
                    severity: Severite.erreur,
                    summary: `Création d'une taxonomie`,
                    detail: `Une erreur est survenue lors de la création de la nouvelle taxonomie.`,
                })
            ])
        ),
        { dispatch: true }
    );

    ///  UPDATE ONE  ///
    public updateOneTaxonomie$ = createEffect(() => this.actions$.pipe(
        ofType(TaxonomieActionTypes.UPDATE_ONE_TAXONOMIE),
        mergeMap((action: any) => {
            return this.apiService.put<Taxonomie, Taxonomie>(`/taxonomies/${action.taxonomie.id}`, action.taxonomie).pipe(
                mergeMap((updatedTaxonomie: Taxonomie) => [
                    updateOneTaxonomieSuccess({ taxonomie: updatedTaxonomie }),
                    messageTaxonomie({
                        severity: Severite.success,
                        summary: `Mise à jour d'une taxonomie`,
                        detail: `Les informations de la taxonomie ont bien été modifiées.`,
                    }),
                ]),
                catchError((error: any) => of(updateOneTaxonomieError(error)))
            );
        })
    ));

    public updateOneTaxonomieSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(TaxonomieActionTypes.UPDATE_ONE_TAXONOMIE_SUCCESS),
            mergeMap((action: any) => this.dbService.update(StoreName.TAXONOMIE, action.taxonomie))
        ),
        { dispatch: false }
    );

    public updateOneTaxonomieError$ = createEffect(
        () => this.actions$.pipe(
            ofType(TaxonomieActionTypes.UPDATE_ONE_TAXONOMIE_ERROR),
            mergeMap((_: any) => [
                getAllTaxonomies(),
                messageTaxonomie({
                    severity: Severite.erreur,
                    summary: `Mise à jour d'une taxonomie`,
                    detail: `Une erreur est survenue lors de la mise à jour de la taxonomie.`,
                })
            ])
        ),
        { dispatch: true }
    );

    ///  DELETE ONE  ///
    public deleteOneTaxonomie$ = createEffect(() => this.actions$.pipe(
        ofType(TaxonomieActionTypes.DELETE_ONE_TAXONOMIE),
        mergeMap((action: any) => {
            return this.apiService.delete<Taxonomie>(`/taxonomies/${action.taxonomie.id}`).pipe(
                concatMap(() => [
                    deleteOneTaxonomieSuccess({ taxonomie: action.taxonomie }),
                    messageTaxonomie({
                        severity: Severite.success,
                        summary: `Suppression d'une taxonomie`,
                        detail: `La taxonomie a bien été supprimée.`,
                    }),
                ]),
                catchError((error: any) => of(deleteOneTaxonomieError(error)))
            );
        })
    ));

    public deleteOnTaxonomieSuccess$ = createEffect(
        () => this.actions$.pipe(
            ofType(TaxonomieActionTypes.DELETE_ONE_TAXONOMIE_SUCCESS),
            mergeMap((action: any) => {
                return this.dbService.deleteByKey(StoreName.TAXONOMIE, action.taxonomie.id);
            })
        ),
        { dispatch: false }
    );

    public deleteOnTaxonomieError$ = createEffect(
        () => this.actions$.pipe(
            ofType(TaxonomieActionTypes.DELETE_ONE_TAXONOMIE_ERROR),
            mergeMap((_: any) => [
                getAllTaxonomies(),
                messageTaxonomie({
                    severity: Severite.erreur,
                    summary: `Suppression d'une taxonomie`,
                    detail: `Une erreur est survenue lors de la suppression d'une taxonomie.`,
                })
            ])
        ),
        { dispatch: true }
    );

    ///  MESSAGE TAXONOMIE  ///
    public messageTaxonomie$ = createEffect(
        () => this.actions$.pipe(
            ofType(TaxonomieActionTypes.MESSAGE_TAXONOMIE),
            tap((action: { severity: Severite, summary: string, detail: string }) => {
                this.messageService.add({
                    severity: action.severity,
                    closable: true,
                    summary: action.summary,
                    detail: action.detail
                });
            })
        ),
        { dispatch: false }
    );

    constructor(
        private actions$: Actions,
        private apiService: ApiService,
        private messageService: MessageService,
        private readonly dbService: NgxIndexedDBService
    ) { }
}
