/* tslint:disable */
export type ActionProjetAudit = 'assigner' | 'activer' | 'annuler' | 'completer' | 'rejeter' | 'approuver';

export const ActionProjetAudit = {
    assigner: 'assigner' as ActionProjetAudit,
    activer: 'activer' as ActionProjetAudit,
    annuler: 'annuler' as ActionProjetAudit,
    completer: 'completer' as ActionProjetAudit,
    rejeter: 'rejeter' as ActionProjetAudit,
    approuver: 'approuver' as ActionProjetAudit
}
