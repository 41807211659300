import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PhotoExtraction } from '../../models/photo-extraction.model';
import saveAs from 'file-saver';

@Component({
    selector: 'app-photo-extraction-dialog',
    templateUrl: './photo-extraction-dialog.component.html',
    styleUrls: ['./photo-extraction-dialog.component.scss']
})
export class PhotoExtractionDialogComponent {

    @Input() photoExtraction: PhotoExtraction;
    @Input() visible: boolean;
    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() createPhotoExtraction = new EventEmitter();

    constructor() { }

    public onDownloadPhotoExtraction() {
        saveAs(this.photoExtraction.url!);
        this.closePhotoExtractionDialog();
    }

    public onCreatePhotoExtraction() {
        this.createPhotoExtraction.emit();
        this.closePhotoExtractionDialog();
    }

    public closePhotoExtractionDialog() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }

}
