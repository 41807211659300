import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { fromEvent, map, merge, of, BehaviorSubject } from 'rxjs';
import { State } from '../state/app.state';
import * as SharedActions from '../state/shared/shared.actions';

@Injectable({
    providedIn: 'root'
})
export class NetworkService {
    private networkOnline = new BehaviorSubject<boolean>(true);
    networkOnline$ = this.networkOnline.asObservable();

    isOnline: boolean;

    constructor(
        private store: Store<State>
    ) { }

    private get networkStatusChange() {
        return merge(
            of(null),
            fromEvent(window, 'online'),
            fromEvent(window, 'offline')
        ).pipe(
            map(() => navigator.onLine)
        );
    }

    watchNetworkStatus() {
        this.networkStatusChange.subscribe({
            next: (online) => {
                this.store.dispatch(SharedActions.setAppOnlineStatut({ online }));
                this.networkOnline.next(online);
                this.isOnline = online;
            }
        });
    }
}
