import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule, Optional, SkipSelf } from '@angular/core';
import { environment } from '@app/environment';
import { EffectsModule } from '@ngrx/effects';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import {
    BusyModule,
    BusyRouterService,
    BusyService,
    CommonUiModule,
    LoadingBehaviorsModule,
    ShellModule
} from '@ngxhq/common-ui';
import { ConfigModule } from '@ngxhq/config';
import { APP_ENVIRONMENT_CONFIG, OpenIdModule, SecurityModule } from '@ngxhq/security';
import { UtilitiesModule } from '@ngxhq/utilities';
import { GlobalErrorComponent, GlobalErrorHandler } from './errors';
import { throwIfAlreadyLoaded } from './module-import-guard';
import { coreReducers } from './store/reducers';
import { logger } from './store/utils';
import { coreStoreEffects } from './store/effects';
import { ApiModule } from './api/client/api.module';
import { MessageService } from 'primeng/api';

/* Store setup */
export const metaReducers: MetaReducer<any>[] = !environment.production ? [logger] : [];

@NgModule({
    imports: [
        CommonModule,
        UtilitiesModule.forRoot(),
        ConfigModule,
        ConfigModule.forRoot(),
        OpenIdModule.forRoot(),
        SecurityModule.forRoot(),
        CommonUiModule,
        CommonUiModule.forRoot(),
        ShellModule.forRoot(),
        BusyModule,
        BusyModule.forRoot(),
        LoadingBehaviorsModule.forRoot(),
        StoreDevtoolsModule.instrument({
            maxAge: 1000, // Retains last 25 states
            logOnly: environment.production, // Restrict extension to log-only mode
        }),
        StoreModule.forFeature('core', coreReducers),
        EffectsModule.forFeature(coreStoreEffects),

        ApiModule.forRoot({ rootUrl: '[CONFIG:BACKEND_BASE_URL]' })
    ],
    exports: [
        CommonModule,
        UtilitiesModule,
        ConfigModule,
        SecurityModule,
        CommonUiModule,
    ],
    declarations: [
        GlobalErrorComponent,
    ],
    providers: [
        BusyRouterService,
        BusyService,
        MessageService,
        {
            provide: ErrorHandler,
            useClass: GlobalErrorHandler
        },
        {
            provide: APP_ENVIRONMENT_CONFIG,
            useValue: environment
        }
    ]
})
export class CoreModule {
    constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
        throwIfAlreadyLoaded(parentModule, 'CoreModule');
    }
}
