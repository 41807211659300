import { TableColumn } from '../../../shared/models/table-column.model';

export const projetInspectionListMobileDialogColumn: TableColumn[] = [
    {
        field: 'nom',
        header: 'Nom du projet',
    },
    {
        field: 'statut',
        header: 'Activé',
        width: '20%'
    },
    {
        field: 'downloaded',
        header: 'Téléchargé',
        width: '20%'
    }
];

