<section class="error__wrapper">
    <ng-container>
        <h1>Le compte utilisé possède plusieurs rôles pour l'application Capture.</h1>
        <h2>L'utilisateur possède les rôles suivants, tel que demandés dans les SIGA:
            <ng-container *ngFor="let role of roles">
                <br>
                - {{role}}
            </ng-container>
        </h2>
    </ng-container>
    <p>
        Veuillez valider vos demandes SIGA afin d'avoir uniquement un seul rôle
    </p>
</section>
