import { /*createFeatureSelector,*/ createSelector } from '@ngrx/store';
import { getProjetState, ProjetState } from '..';
// import { ProjetHistoryState } from './projet-history.state';

// const getProjetHistoryFeatureState = createFeatureSelector<ProjetHistoryState>('projetHistory');

export const getProjetHistory = createSelector(
    getProjetState,
    (state: ProjetState) => state && state.projetHistory.projetHistory
);

export const getProjetHistoryError = createSelector(
    getProjetState,
    (state: ProjetState) => state && state.projetHistory.projetHistoryError
);

