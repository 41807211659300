import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { map, switchMap, takeUntil } from 'rxjs/operators';
import { select, Store } from '@ngrx/store';
import * as projetActions from '../../store/projet/projet.actions';
import { State } from '../../../../state/app.state';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { ProjetAction } from '../../../inspection/models/projet-action.enum';
import { AssigneProjet } from '../../models/assigne-projet.model';
import { getTaxonomiePourGroupes, getTaxonomiePourGroupesParFirme } from '../../../../core/store/selectors/taxonomie.selectors';
import { Projet } from '../../models/projet.model';
import { getFirmeUser } from '../../../../core/store/selectors/user.selectors';
import { TaxonomieGroupeValue } from '../../../pilotage/models/taxonomie-groupe.enum';

@Component({
    selector: 'app-assign-projet-dialog',
    templateUrl: './assign-projet-dialog.component.html',
    styleUrls: ['./assign-projet-dialog.component.scss']
})
export class AssignProjetDialogComponent extends BaseComponent implements OnDestroy {

    @Input() projet: Projet;
    @Input() visible: boolean;
    @Input() assignText: string;
    @Input() headerText: string;
    @Input() action: ProjetAction;
    @Input() set taxonomieGroupes(value: string[]) {
        if (value) {
            this.subscribeToItemList(value);
        }
    }

    @Output() visibleChange = new EventEmitter<boolean>();

    public itemList: string[] = [];
    public itemSelected: string;

    constructor(
        private store: Store<State>
    ) {
        super();
    }

    private subscribeToItemList(groupes: string[]) {
        const observable = this.store
            .pipe(
                select(getFirmeUser),
                switchMap(firmeUsagerCourant => {
                    const selector = (groupes.length === 1 && groupes[0] === TaxonomieGroupeValue.FIRME) ?
                        getTaxonomiePourGroupes(groupes) :
                        getTaxonomiePourGroupesParFirme(groupes, firmeUsagerCourant.id!);

                    const result = this.store.pipe(select(selector));
                    return result;
                }),
                map(users => users.map(user => user.toLowerCase()).sort()),
                takeUntil(this.destroyed)
            );

        observable.subscribe(_itemList =>
            this.itemList = _itemList
        );

        return observable;
    }

    public onAssign() {
        if (this.itemSelected) {
            const data: AssigneProjet = { action: this.action, assignea: this.itemSelected };
            this.store.dispatch(projetActions.assigneProjet({ projetId: this.projet.id, assigneData: data }));
            this.closeAssignDialog();
        }
    }

    public closeAssignDialog() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
        this.itemSelected = '';
    }
}
