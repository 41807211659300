import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';
import { UserRole } from '../../../shared/models/user-roles.model';
import { getUserFromClaims } from '../actions/user.actions';

export interface User {
    roles: UserRole[];
    user: Partial<OpenIdClaims & OpenIdUserInfo & {
        roles: UserRole[];
    }>;
}
export interface UserState extends EntityState<User> {

}

export function selectedUserId(user: User): string {
    if (user.user.id) {
        return user.user.id;
    } else {
        return '';
    }
}

export const userAdapter: EntityAdapter<User> = createEntityAdapter<User>({
    selectId: selectedUserId
});

export const userInitialState = userAdapter.getInitialState();

export const userSelectors = userAdapter.getSelectors();

export const userReducer = createReducer(userInitialState,
    on(getUserFromClaims, (state, { claims, userInfo }) => {
        const user = { ...claims, ...userInfo };
        return userAdapter.upsertOne({ user: user, roles: claims.roles as UserRole[] }, state);
    }));
