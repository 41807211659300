import { Injectable } from '@angular/core';
import { queryFeatures, GeometryType, IQueryResponse, IQueryFeaturesResponse } from '@esri/arcgis-rest-feature-layer';
import { ServiceFeature } from '../enums/serviceFeature';
import { QueryLigne } from '../shared/models/query-ligne.model';
import { LocalStorageIndex } from '../shared/enums/local-storage-index.enum';

@Injectable({
    providedIn: 'root'
})

export class EsriRequeteService {
    esriAccessToken = JSON.parse(window.localStorage.getItem(LocalStorageIndex.ESRI_ACCESS_TOKEN));
    serviceFeatureUrl: string = `https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/A396_Capture_Inst_Dist_Vue/FeatureServer`;
    serviceFeatureAiresProtegees: string = `https://services5.arcgis.com/jD5Z6QYavsBfnE6G/arcgis/rest/services/Aires_protegees_vue/FeatureServer/21`;

    constructor() { }

    public executeQueryspatial(geometry: any, geometryType: GeometryType): Promise<IQueryFeaturesResponse | IQueryResponse> {
        return queryFeatures
            ({
                url: `${this.serviceFeatureUrl}/${ServiceFeature.POTEAU}`,
                httpMethod: 'POST',
                geometry: geometry,
                params: { 'token': `${this.esriAccessToken.token}` },
                hideToken: true,
                geometryType,
                spatialRel: 'esriSpatialRelIntersects',
                inSR: '4326',
                outSR: '4326',
                f: 'geojson',
                returnGeometry: true,
                outFields: ['poteau_id', 'OBJECTID', 'Usage', 'Proprietaire']
            });
    }

    public rechercheNumeroLigne(queryParametres: QueryLigne, triphaseSeulement: boolean): Promise<IQueryFeaturesResponse | IQueryResponse> {
        const query: string = this.getLigneSpatialQuery(queryParametres, triphaseSeulement);
        return queryFeatures
            ({
                url: `${this.serviceFeatureUrl}/${ServiceFeature.CONDUCTEUR_MT_AERIEN}`,
                httpMethod: 'POST',
                params: { 'token': `${this.esriAccessToken.token}` },
                hideToken: true,
                where: query,
                inSR: '4326',
                outSR: '4326',
                groupByFieldsForStatistics: 'poste_ligne',
                f: 'geojson',
                orderByFields: 'poste_ligne',
                returnDistinctValues: true,
                returnGeometry: true
            });
    }

    private getLigneSpatialQuery(querystring: QueryLigne, triphaseSeulement: boolean): string {
        if (triphaseSeulement) {
            return `poste_ligne = '${querystring.nomLigne}' AND code_ced = '${querystring.territoire}' AND nb_phase = 'Triphasé'`;
        } else {
            return `poste_ligne = '${querystring.nomLigne}' AND code_ced = '${querystring.territoire}'`;
        };
    }

    public verifierIntersect(geometry: any, geometryType: GeometryType): Promise<IQueryFeaturesResponse | IQueryResponse> {
        return queryFeatures
            ({
                url: `${this.serviceFeatureAiresProtegees}`,
                httpMethod: 'POST',
                geometry: geometry,
                params: { 'token': `${this.esriAccessToken.token}` },
                hideToken: true,
                geometryType,
                spatialRel: 'esriSpatialRelIntersects',
                where: `HQ_ETIQUETTE LIKE '%rainette%'`,
                inSR: '4326',
                outSR: '4326',
                f: 'geojson',
                returnGeometry: true
            });
    }
}
