import { createSelector } from '@ngrx/store';
import { userInformationSelectors } from '../reducers/userInformation.reducer';
import { CoreState, getCoreState } from '../reducers';
import { UserInformation } from 'src/app/shared/models/user-informations.model';


export const getUserInformationState = createSelector(
    getCoreState,
    (state: CoreState) => state && state.userInformation
);

export const selectAllUserInformation = createSelector(
    getUserInformationState,
    userInformationSelectors.selectAll
);

export const selectUserInformation = createSelector(
    selectAllUserInformation,
    (state: UserInformation[]) => state[0]
);
