import { Directive, ElementRef, Injector, Input, AfterViewInit, ContentChild } from '@angular/core';
import { AbstractControl, FormControl, FormControlName } from '@angular/forms';
import { FormFieldInterface } from '../../../interfaces/form-field.interface';

export const isControlRequired = (control: AbstractControl): boolean => {
    if (!control) {
        return false;
    }

    if (control.validator) {
        const validator = control.validator({} as AbstractControl);
        if (validator && validator.required) {
            return true;
        }
    }
    return false;
};

@Directive()
export abstract class FormFieldBaseComponent implements FormFieldInterface, AfterViewInit {
    @ContentChild(FormControlName) controlName: FormControlName;
    @Input() inputId: string;
    @Input() format: string;
    @Input() label: string;
    @Input() info: string;

    @Input()
    set control(absCtrl: AbstractControl | FormControl) {
       this._control = absCtrl as FormControl;
    }
    private _control: FormControl;

    get formControl(): FormControl {
        return this._control;
    }

    protected el: ElementRef;

    constructor(
        protected injector: Injector) {

        this.el = injector.get(ElementRef);
    }

    ngAfterViewInit() {

        const derivedClassName = this.constructor['name'];

        if (!this.formControl) {
            console.error(`${derivedClassName} needs to have @Input() control`);
        }

        if (!this.controlName) {
            console.error(`${derivedClassName} should provide a control with formControlName`);
        }
    }

    isRequired() {
        return isControlRequired(this.formControl);
    }

    toControl(absCtrl: AbstractControl): FormControl {
        return absCtrl as FormControl;
    }
}
