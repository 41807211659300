import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})
export class ValidatorService {

    /**
     * check if the argument is a javascript number
     * @param n any
     */
    static isNumeric(input: string): boolean {
        const value = input.replace(/,/g, '.');
        return !Number.isNaN(parseFloat(value)) && Number.isFinite(+value);
    }

    /**
     * Returns a boolean (true) if the input string is a valid phone
     * @param input string
     */
    static isValidPhone(input: string): boolean {
        const re = /^\(?(\d{3})\)?[-. ]?(\d{3})[-. ]?(\d{4})$/;
        return re.test(input);
    }

    /**
     * Returns a boolean if the input string is a valid email
     * @param input string
     */
    static isValidEmail(input: string): boolean {
        const re = /^\w+([.+-]?\w+)*@\w+([.-]?\w+)*(\.[a-zA-Z]{2,})+$/;
        return re.test(input);
    }

    /**
     * Returns a boolean if the input string is a valid url
     * @param input string
     */
    static isValidUrl(input: string): boolean {
        // eslint-disable-next-line max-len
        const re = /^(https?|ftp):\/\/[a-z0-9\u00a1-\uffff-]+(\.[a-z0-9\u00a1-\uffff-]*[a-z0-9\u00a1-\uffff]+)*\.[a-z0-9-]{1,3}(:\d{2,5})?(\/\S*)?$/;
        return re.test(input);
    }
}
