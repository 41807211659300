<div class="p-field" [ngClass]="{
    'p-invalid': formControl.invalid && (formControl.dirty || formControl.touched),
    'p-field-disabled': formControl.disabled
}">

    <label [attr.for]="inputId">
        {{ label }}
        <span class="field-required" *ngIf="isRequired()">*</span>

        <app-form-info *ngIf="info" [message]="info"></app-form-info>
    </label>

    <ng-content></ng-content>

    <small *ngIf="format">{{ format }}</small>

    <app-form-field-error [label]="label" [control]="formControl"></app-form-field-error>

</div>
