<div class="header">
    <div class="header__title">
        <div class="title__main">{{mainTitle}}</div>
        <div class="title__subtitle">{{subTitle}}</div>
    </div>
    <div *ngIf="displayLogos" class="header__icons">
        <div *ngIf="displayLogoHigh" class="icons__container">
            <span *ngIf="logoHighQuantity > 0" class="container__quantity">{{logoHighQuantity}}</span>
            <i class="{{logoHigh}}" [ngStyle]="{'color' : logoHighQuantity > 0 ? logoHighColor : disabledColor}"></i>
        </div>
        <div *ngIf="displayLogoLow" class="icons__container">
            <span *ngIf="logoLowQuantity > 0" class="container__quantity">{{logoLowQuantity}}</span>
            <i class="{{logoLow}}" [ngStyle]="{'color' : logoLowQuantity > 0 ? logoLowColor : disabledColor }"></i>
        </div>
    </div>
    <div class="header__closeBtn">
        <button pButton pRipple icon="pi pi-times" class="closeBtn__bouton" (click)="close()"></button>
    </div>
</div>
