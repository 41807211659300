import { Component, EventEmitter, Output, Input, ViewChild } from '@angular/core';
import { HqSideBarComponent } from '@ngxhq/theme-ngxhq';

@Component({
    selector: 'app-main-menu',
    templateUrl: './main-menu.component.html',
    styleUrls: ['./main-menu.component.scss'],
})
export class MainMenuComponent {
    @Output() expandedChange = new EventEmitter<boolean>();
    @ViewChild(HqSideBarComponent, { static: true })

    private sideBar: HqSideBarComponent;

    @Input()
    set expanded(value: boolean) {
        if (this.sideBar && this.sideBar.expanded !== value) {
            this.sideBar.expanded = value;
        }
        this.expandedChange.emit(value);
    }
}
