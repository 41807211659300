<ng-container *ngIf="visible">
    <ng-container *ngIf="isAuditeur">
        <div class="container">
            <span class="container__title">Inspection</span>
            <div class="container__table" *ngIf="haveBeenIngored">
                <div class="table__row">
                    <div class="row__label">
                        Justificatif&nbsp;:&nbsp;
                    </div>
                    <div class="row__control">
                        {{ fetchJustification }}
                    </div>
                </div>
                <div class="table__row">
                    <div class="row__label">
                        Remarque&nbsp;:&nbsp;
                    </div>
                    <div class="row__control">
                        {{ selectedPointInspection.remarque }}
                    </div>
                </div>
            </div>
            <div class="container" *ngIf="!haveBeenIngored">
                <div class="container__info">
                    <i class="fa-solid fa-circle-info"></i>
                    Ce poteau n'a pas été ignoré par l'inspecteur
                </div>
            </div>
        </div>
        <p-divider></p-divider>
    </ng-container>
    <div class="container">
        <span class="container__title" *ngIf="isAuditeur">Audit</span>
        <div class="container__table audit" [formGroup]="ignorePointForm">
            <div class="table__row">
                <div class="row__label">
                    <span>Justificatif<span class="required">&nbsp;*</span>&nbsp;:&nbsp;</span>
                </div>
                <div class="row__control">
                    <p-dropdown [options]="motifIgnoreListe" optionLabel="valeur" placeholder="Choisir un motif"
                        formControlName="motifIgnore" optionValue="champ" [style]="{'width':'100%'}" appendTo="body">
                    </p-dropdown>
                </div>
            </div>
            <div class="table__row">
                <div class="row__label">
                    <span>Remarque<span *ngIf="remarqueRequise" class="required">&nbsp;*</span>&nbsp;:&nbsp;</span>
                </div>
                <div class="row__control">
                    <textarea rows="1" class="textarea" pInputTextarea formControlName="remarque" maxlength="200"
                        [autoResize]="true"></textarea>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-button">
        <button pButton type="button" label="Annuler" class="btn-annuler" (click)="onCancelIgnorePointForm()"></button>
        <button pButton type="button" label="Enregistrer" class="btn-enregistrer" (click)="onSaveIgnorePointForm()"
            [disabled]="!(ignorePointForm.controls['remarque'].valid && ignorePointForm.controls['motifIgnore'].valid)"></button>
    </div>
</ng-container>