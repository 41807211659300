import { Injectable } from '@angular/core';
import { StatutProjet } from '../../features/projet/models';
import { ProjetAuditDto, ProjetCompletDto } from '../../core/api/client/models';
import { UserRole } from '../../shared/models/user-roles.model';
import { UtilisateurService } from '../../services/utilisateur.service';
import { StatutProjetAudit } from '../../features/audit/models/statut-projet-audit.enum';

@Injectable({
    providedIn: 'root'
})
export class MapPermissionsService {
    constructor(
        private utilisateurService: UtilisateurService
    ) { }

    public canFetchAnomaliesPilotageData(): boolean {
        return this.utilisateurService.isOneOfRoles([
            UserRole.ADMIN_EXTERNE,
            UserRole.ADMIN_GCSP,
            UserRole.AUDITEUR_EXTERNE,
            UserRole.AUDITEUR_HQD,
            UserRole.CONTROLEUR_QUALITE_EXTERNE,
            UserRole.INSPECTEUR_EXTERNE,
            UserRole.INSPECTEUR_HQD,
        ]);
    }

    /** PUBLIC */
    // Inspection
    public canEditPointInspection(): boolean {
        return this.utilisateurService.isOneOfRoles([UserRole.ADMIN_EXTERNE, UserRole.ADMIN_GCSP]);
    }

    public canEditPointInspectionByProjet(projetInspection: ProjetCompletDto): boolean {
        return [
            this.adminExterneCanEditPointInspectionByProjet(projetInspection),
            this.adminGCSPCanEditPointInspectionByProjet(projetInspection),
        ].some(value => value);
    }

    // Audit
    public canEditPointAudit(): boolean {
        return this.utilisateurService.isOneOfRoles([UserRole.ADMIN_GCSP]);
    }

    public canEditPointAuditByProject(projectAudit: ProjetAuditDto | undefined): boolean {
        if (projectAudit) {
            return [
                this.adminGCSPCanEditPointAuditByProject(projectAudit),
            ].some(value => value);
        }

        return false;
    }

    public roleIsMarkAsAuditeur() {
        return this.utilisateurService.isOneOfRoles([UserRole.ADMIN_GCSP, UserRole.AUDITEUR_EXTERNE, UserRole.AUDITEUR_HQD]);
    }

    /** PRIVATE */
    private adminExterneCanEditPointInspectionByProjet(projetInspection: ProjetCompletDto): boolean {
        const canModifyStatus: string[] = [StatutProjet.inspectionCompletee, StatutProjet.aqCompletee, StatutProjet.nonConforme, StatutProjet.correctionCompletee];

        return this.utilisateurService.isOneOfRoles([UserRole.ADMIN_EXTERNE]) &&
            this.utilisateurService.firme === projetInspection.firme &&
            canModifyStatus.includes(projetInspection.statut);
    }

    private adminGCSPCanEditPointInspectionByProjet(projetInspection: ProjetCompletDto): boolean {
        const canModifyStatus: string[] = [StatutProjet.inspectionCompletee, StatutProjet.nonConforme, StatutProjet.correctionCompletee];

        return this.utilisateurService.isOneOfRoles([UserRole.ADMIN_GCSP]) &&
            this.utilisateurService.firme === projetInspection.firme &&
            canModifyStatus.includes(projetInspection.statut);
    }

    private adminGCSPCanEditPointAuditByProject(projectAudit: ProjetAuditDto): boolean {
        const canModifyStatus: string[] = [StatutProjetAudit.auditComplete];

        return this.utilisateurService.isOneOfRoles([UserRole.ADMIN_GCSP]) &&
            canModifyStatus.includes(projectAudit.statut);
    }
}
