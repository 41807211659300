export enum Territoire {
    LAV = 'LAV',
    MAT = 'MAT',
    NOR = 'NOR',
    ORL = 'ORL',
    SEI = 'SEI',
    IDM = 'IDM',
}

export const territoireList = ['LAV', 'MAT', 'NOR', 'ORL', 'SEI', 'IDM'];
