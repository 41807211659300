export enum AnomalieValue {
    equipements = 'Équipements mineurs',
    anomalie = 'Anomalie',
    audit = `Données de l'audit`,
}

export enum AnomalieTab {
    equipements = 'equipements',
    anomalie = 'anomalie',
    audit = 'audit',
}
