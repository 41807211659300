<div class="header">
    <div class="filterSelect p-d-flex">
        <span class="p-col-12 p-md-12 p-lg-3">
            <p-dropdown [options]="$any(taxonomiesGroupesGlobal$ | async)" [style]="{'width':'100%'}"
                [placeholder]="'Sélectionner'" (onChange)="filterGlobal($event.value)" appendTo="body"
                [disabled]="disableGlobalFilter">
                <ng-template pTemplate="selectedItem">
                    <div>{{groupeSelectedValue | frenchTitleCase}}</div>
                </ng-template>
                <ng-template let-groupe pTemplate="item">
                    <div>{{groupe.label | frenchTitleCase}}</div>
                </ng-template>
            </p-dropdown>
        </span>
    </div>
    <div *ngIf="currentAllTaxonomies$ | async">
        <p-table #taxoTable [value]="allTaxonomie" [columns]="colonnes" dataKey="id" editMode="row" [autoLayout]="true"
            [rowHover]="true" [paginator]="true" [rows]="10" [rowsPerPageOptions]="[10,25,50,100,200]"
            [globalFilterFields]="['groupe']" responsiveLayout="scroll">
            <ng-template pTemplate="caption">
                <div class="p-grid p-justify-end">
                    <button pbutton label="Ajouter" icon="fas fa-plus" id="addBtn"
                        class="p-element p-button-secondary p-button p-component" (click)="ajouterTaxonomie()"
                        [disabled]="filterGlobalDisabledAddButton || editDisabledAddButton">
                        <span class="p-button-icon p-button-icon-left fas fa-plus" aria-hidden="true"></span>
                        <span class="p-button-label">Ajouter</span>
                    </button>
                </div>
            </ng-template>
            <ng-template pTemplate="header" let-columns>
                <tr class="header__titleRow">
                    <th *ngFor="let col of columns" [pSortableColumn]="col.field" class="{{col.field}}">
                        <div class="p-d-flex" [class.estActifColomn]="col.field === 'estActif'">
                            {{ col.header }}

                            <p-sortIcon [field]="col.field" aria-label="Trier" aria-label-desc="Trier descendant"
                                aria-label-asc="Trier ascendent"></p-sortIcon>
                        </div>
                    </th>
                    <th class="titleRow__actions"></th>
                </tr>
                <tr class="header__filterRow">
                    <th *ngFor="let col of columns" [ngSwitch]="col.filterType">
                        <div *ngSwitchCase="'checkbox'" class="p-d-flex" [class.estActif]="col.field === 'estActif'">
                            <p-checkbox [binary]="true"
                                (onChange)="taxoTable.filter($event.checked ? $event.checked : '', col.field, 'contains')">
                            </p-checkbox>
                        </div>
                        <ng-container *ngSwitchCase="'dropdown'">
                            <p-dropdown *ngIf="col.field === 'groupe'" [options]="$any(taxonomiesGroupes$ | async)"
                                [placeholder]="'Filtrer'"
                                (onChange)="taxoTable.filter($event.value, col.field, 'contains')" [showClear]="true"
                                [style]="{'width':'100%'}" appendTo="body">
                                <ng-template let-item pTemplate="selectedItem">
                                    <div>{{item.label | frenchTitleCase}}</div>
                                </ng-template>
                                <ng-template let-item pTemplate="item">
                                    <div>{{item.label | frenchTitleCase}}</div>
                                </ng-template>
                            </p-dropdown>
                            <p-dropdown *ngIf="col.field === 'proprietes'" [options]="$any(fournisseurOptions$ | async)"
                                [placeholder]="'Filtrer'"
                                (onChange)="taxoTable.filter($event.value, col.field, 'contains')" [showClear]="true"
                                optionLabel="label" optionValue="value" [style]="{'width':'100%'}" appendTo="body">
                            </p-dropdown>
                        </ng-container>
                        <div *ngSwitchDefault>
                            <input pInputText type="text" styleClass="p-column-filter"
                                (input)="filtrerTexte($event.target, col.field, 'contains')" [placeholder]="'Filtrer'"
                                id="id-{{col.field}}" aria-placeholder="Filtrer">
                        </div>
                    </th>
                    <th></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-taxonomie let-editing="editing" let-columns="columns" let-i="rowIndex">
                <tr class="tableRow" [pEditableRow]="taxonomie">
                    <td *ngFor="let col of columns">
                        <ng-container [ngSwitch]="col.dataType">
                            <p-cellEditor>
                                <ng-template pTemplate="input">
                                    <div *ngSwitchCase="'checkbox'">
                                        <p-checkbox [(ngModel)]="taxonomie[col.field]" [binary]="true" class="p-d-flex"
                                            [class.estActif]="col.field === 'estActif'"></p-checkbox>
                                    </div>
                                    <input *ngSwitchCase="'text'" pInputText type="text"
                                        [(ngModel)]="taxonomie[col.field]" [class.taxoCode]="col.field === 'code'">
                                    <ng-container *ngSwitchCase="'dropdown'">
                                        <p-dropdown *ngIf="col.field === 'groupe'"
                                            [options]="$any(taxonomiesGroupes$ | async)"
                                            [(ngModel)]="taxonomie[col.field]" [placeholder]="'Sélectionner'"
                                            [style]="{'width':'100%'}" appendTo="body">
                                            <ng-template let-item pTemplate="selectedItem">
                                                <div>{{item.label | frenchTitleCase}}</div>
                                            </ng-template>
                                            <ng-template let-item pTemplate="item">
                                                <div>{{item.label | frenchTitleCase}}</div>
                                            </ng-template>
                                        </p-dropdown>
                                        <p-dropdown *ngIf="col.field === 'proprietes'
                                                && taxonomie['groupe'] !== taxonomieGroupeValue.FIRME
                                                && taxonomie['groupe'] !== taxonomieGroupeValue.FIRME_AUDIT
                                                && taxonomie['groupe'] !== taxonomieGroupeValue.AUDITEURS"
                                            [options]="$any(fournisseurOptions$ | async)" [placeholder]="'Sélectionner'"
                                            [(ngModel)]="taxonomie.proprietesJson.firmeID" optionLabel="label"
                                            optionValue="value" [style]="{'width':'100%'}" appendTo="body">
                                        </p-dropdown>
                                        <p-dropdown
                                            *ngIf="col.field === 'proprietes' && taxonomie['groupe'] === taxonomieGroupeValue.AUDITEURS"
                                            [options]="$any(fournisseurAuditOptions$  | async)"
                                            [placeholder]="'Sélectionner'"
                                            [(ngModel)]="taxonomie.proprietesJson.firmeID" optionLabel="label"
                                            optionValue="value" [style]="{'width':'100%'}" appendTo="body">
                                        </p-dropdown>
                                        <!-- <p-dropdown *ngIf="col.field === 'proprietes' && taxonomie['groupe'] === enumTaxonomieGroupe.firmeAudit" [options]="$any(fournisseurAuditOptions$  | async)"
                                            [placeholder]="'Sélectionner'"
                                            [(ngModel)]="taxonomie.proprietesJson.firmeID"
                                            optionLabel="label" optionValue="value"
                                            [style]="{'width':'100%'}"
                                            appendTo="body">
                                        </p-dropdown> -->
                                    </ng-container>
                                </ng-template>
                                <ng-template pTemplate="output">
                                    <div *ngSwitchCase="'checkbox'" class="p-d-flex"
                                        [class.estActif]="col.field === 'estActif'">
                                        <p-checkbox [(ngModel)]="taxonomie[col.field]" [binary]="true"
                                            [disabled]="true"></p-checkbox>
                                    </div>
                                    <ng-container *ngSwitchDefault>
                                        <ng-container *ngIf="col.field === 'proprietes'">
                                            <label *ngIf="taxonomie[col.field] !== ''">{{
                                                getNomFirme(taxonomie)}}</label>
                                        </ng-container>
                                        <ng-container *ngIf="col.field !== 'proprietes'">
                                            <label *ngIf="col.field === 'code'; else everyThingElse">{{
                                                taxonomie[col.field] }}</label>
                                            <ng-template #everyThingElse>
                                                <label>{{ taxonomie[col.field] | frenchTitleCase }}</label>
                                            </ng-template>
                                        </ng-container>
                                    </ng-container>
                                </ng-template>
                            </p-cellEditor>
                        </ng-container>
                    </td>
                    <td style="text-align:center">
                        <div class="actions" *ngIf="displayRowsAction[taxonomie.id]">
                            <p-button *ngIf="!editing" pInitEditableRow icon="pi pi-pencil"
                                (click)="onRowEditInit(taxonomie)" class="base"></p-button>
                            <p-button *ngIf="!editing && canDelete(taxonomie)" icon="pi pi-trash"
                                (click)="confirmDelete(taxonomie)" class="base"></p-button>
                            <span *ngIf="!editing && !canDelete(taxonomie)" class="base__hidden"></span>
                            <p-button *ngIf="editing" pSaveEditableRow icon="pi pi-check"
                                (click)="onRowEditSave(taxonomie)" class="base"
                                [disabled]="valueAreInvalide(taxonomie)"></p-button>
                            <p-button *ngIf="editing" pCancelEditableRow icon="pi pi-times"
                                (click)="confirmCancelMAJ(taxonomie)" class="base"></p-button>
                        </div>
                    </td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>

<p-confirmDialog class="line-break" #cd header="Confirmation de supression" icon="pi pi-exclamation-triangle"
    key='deleteConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cd.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cd.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog class="line-break" #am header="Annulation des modifications" icon="pi pi-exclamation-triangle"
    key='updateConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="am.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="am.accept()"></button>
    </ng-template>
</p-confirmDialog>