import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { Operation } from 'fast-json-patch';
import { PointInspection } from 'src/app/features/projet/models/point-inspection.model';
import { Anomalie } from '../../../features/anomalie/models/anomalie.model';
import { Bounds } from '../../../shared/models/bounds.model';
import { Photo } from '../../../shared/models/photo.model';
import { PhotoBackend } from '../../../shared/models/photo-backend.model';
import { CreatePointInspection } from '../../../features/projet/models/create-point-inspection.model';
import { PointInspectionDto } from '../../api/client/models';
//import { PointInspectionDto } from '../../api/client/models';

export enum PointInspectionActionTypes {
    GET_POINTS_INSPECTIONS_BBOX = '[PointsInspection] Get Points inspection with bbox from backend',
    GET_POINTS_INSPECTIONS_BBOX_SUCCESS = '[PointsInspection] Get Points inspection with bbox from backend Success',
    GET_POINTS_INSPECTIONS_BBOX_FORBIDDEN = '[PointsInspection] Get Points inspection with bbox from backend forbidden',
    GET_POINTS_INSPECTIONS_ID_PROJET = '[PointsInspection] Get ALL Points inspection by id projet from backend',
    UPDATE_POINT_INSPECTION = '[Mobile] Update Point inspection',
    UPDATE_POINT_INSPECTION_SUCCESS = '[Mobile] Update Point inspection Success',
    UPDATE_POINT_INSPECTION_FORBIDDEN = '[Mobile] Update Point inspection Forbidden',
    DELETE_ANOMALIE = '[Mobile] Delete Anomalie',
    DELETE_ANOMALIE_SUCCESS = '[Mobile] Delete Anomalie Success',
    PATCH_POINT_INSPECTION = '[Mobile] Patch point inspection',
    PATCH_POINT_INSPECTION_SUCCESS = '[Mobile] Patch point inspection success',
    GET_POINT_INSPECTION_PROJET_ACTIF = '[Mobile] Get ALL Points inspection from Projet actif',
    SET_POINT_INSPECTION_SANS_ANOMALIE = '[Mobile] Set Point inspection sans anomalie',
    SET_POINT_INSPECTION_SANS_ANOMALIE_SUCCESS = '[Mobile] Set Point inspection sans anomalie Success',
    CREATE_ANOMALIE = '[Mobile] Create Anomalie',
    CREATE_ANOMALIE_SUCCESS = '[Mobile] Create Anomalie Success',
    CREATE_POINT_INSPECTION = '[Mobile] Create point inspection',
    CREATE_POINT_INSPECTION_SUCCESS = '[Mobile] Create point inspection Success',
    UPDATE_ANOMALIE = '[Anomalie] Update Anomalie',
    UPDATE_ANOMALIE_SUCCESS = '[Anomalie] Update Anomalie Success',
    UPDATE_ANOMALIE_FORBIDDEN = '[Anomalie] Update Anomalie Forbidden',
    SET_POINTS_INSPECTION_SUCCESS = `[PointsInspection] Set Points Inspection success`,
    SET_POINTS_INSPECTION_ERROR = `[PointsInspection] Set Points Inspection error`
}

export const getPointsInspectionBbox = createAction(PointInspectionActionTypes.GET_POINTS_INSPECTIONS_BBOX, props<{ bbox: Bounds, zoomLevel: number }>());

export const getPointsInspectionBboxSuccess = createAction(PointInspectionActionTypes.GET_POINTS_INSPECTIONS_BBOX_SUCCESS,
    props<{ pointsInspection: PointInspection[] }>());

export const forbiddenGetPointsInspectionBbox = createAction(PointInspectionActionTypes.GET_POINTS_INSPECTIONS_BBOX_FORBIDDEN);

export const getPointsInspectionByIdProjet = createAction(PointInspectionActionTypes.GET_POINTS_INSPECTIONS_ID_PROJET, props<{ projetId: string | undefined }>());

export const updatePointInspection = createAction(PointInspectionActionTypes.UPDATE_POINT_INSPECTION,
    props<{ pointInspection: Partial<PointInspection>, originalPointInspection: PointInspection, originalPhotos?: (Photo | PhotoBackend)[] }>());

export const updatePointInspectionSuccess = createAction(PointInspectionActionTypes.UPDATE_POINT_INSPECTION_SUCCESS,
    props<{ pointInspection: Update<PointInspection> }>());

export const forbiddenUpdatePointInspection = createAction(PointInspectionActionTypes.UPDATE_POINT_INSPECTION_FORBIDDEN);

export const deleteAnomalie = createAction(PointInspectionActionTypes.DELETE_ANOMALIE, props<{ anomalie: Anomalie }>());

export const deleteAnomalieSuccess = createAction(PointInspectionActionTypes.DELETE_ANOMALIE_SUCCESS, props<{ pointInspectionId: string, anomalieId: string }>());

export const patchPointInspection = createAction(
    PointInspectionActionTypes.PATCH_POINT_INSPECTION,
    props<{
        pointInspectionId: string,
        pointInspectionUpdate: Partial<PointInspection>,
        patch: Operation[],
        pointInspectionOriginal: PointInspection,
        isDeplacerPoteau: boolean,
    }>()
);

export const patchPointInspectionSuccess = createAction(PointInspectionActionTypes.PATCH_POINT_INSPECTION_SUCCESS, props<{ pointInspection: Update<PointInspection> }>());

export const getPointInspectionProjetActif = createAction(PointInspectionActionTypes.GET_POINT_INSPECTION_PROJET_ACTIF, props<{ projetId: string | undefined }>());

export const setPointInspectionSansAnomalie = createAction(
    PointInspectionActionTypes.SET_POINT_INSPECTION_SANS_ANOMALIE,
    props<{
        pointInspectionId: string,
        pointInspectionUpdate: Partial<PointInspection>,
        patch: Operation[],
        pointInspectionOriginal: PointInspectionDto
    }>()
);

export const setPointInspectionSansAnomalieSuccess = createAction(PointInspectionActionTypes.SET_POINT_INSPECTION_SANS_ANOMALIE_SUCCESS,
    props<{ pointInspection: Update<PointInspection> }>());

export const createAnomalie = createAction(PointInspectionActionTypes.CREATE_ANOMALIE,
    props<{ anomalie: Anomalie, inspectionId: string, projetId: string, originalPhotos?: PhotoBackend[] }>());

export const createAnomalieSuccess = createAction(PointInspectionActionTypes.CREATE_ANOMALIE_SUCCESS, props<{ anomalie: Anomalie }>());

export const createPointInspection = createAction(PointInspectionActionTypes.CREATE_POINT_INSPECTION, props<{ pointInspection: CreatePointInspection }>());

export const createPointInspectionSuccess = createAction(PointInspectionActionTypes.CREATE_POINT_INSPECTION_SUCCESS, props<{ pointInspection: PointInspection }>());

export const updateAnomalie = createAction(PointInspectionActionTypes.UPDATE_ANOMALIE,
    props<{ anomalie: Anomalie, inspectionId: string, projetId: string, originalPhotos?: PhotoBackend[] }>());

export const updateAnomalieSuccess = createAction(PointInspectionActionTypes.UPDATE_ANOMALIE_SUCCESS, props<{ anomalie: Anomalie }>());

export const forbiddenAnomalieInspection = createAction(PointInspectionActionTypes.UPDATE_ANOMALIE_FORBIDDEN,);

export const setPointsInspectionSuccess = createAction(PointInspectionActionTypes.SET_POINTS_INSPECTION_SUCCESS, props<{ pointsInspection: PointInspection[] }>());

export const setPointsInspectionError = createAction(PointInspectionActionTypes.SET_POINTS_INSPECTION_ERROR, props<{ error: any }>());
