import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RechercheState } from './recherche.state';

const getRechercheFeatureState = createFeatureSelector<RechercheState>('recherche');

export const isRechercheLoading = createSelector(
    getRechercheFeatureState,
    state => state.rechercheIsLoading
);

export const getRechercheListError = createSelector(
    getRechercheFeatureState,
    state => state.rechercheListeError
);

export const getRechercheList = createSelector(
    getRechercheFeatureState,
    state => state.rechercheListe === null ? null : state.rechercheListe
);

export const getRechercheZoom = createSelector(
    getRechercheFeatureState,
    state => state.rechercheZoom === null ? null : state.rechercheZoom
);
