import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';
export const ligneProjet: AnyLayer = {
    id: MapLayersSources.LIGNE_PROJET,
    type: 'line',
    source: MapLayersSources.LIGNE_PROJET,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'line-color': mapStyleConfig.colors.rechercheColor,
        'line-width': mapStyleConfig.projetOutline.lineWidth,
        /* eslint-enable @typescript-eslint/naming-convention */
    },
    //filter: ['==', ['geometry-type'], 'Polygon']
};
