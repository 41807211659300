import { Injectable } from '@angular/core';
import { LocalStorageIndex } from '../shared/enums/local-storage-index.enum';
import { UserRole } from '../shared/models/user-roles.model';

@Injectable({
    providedIn: 'root'
})
export class UtilisateurService {

    public get roles(): string[] {
        const role = localStorage.getItem(LocalStorageIndex.USER_ROLES);
        return role ? JSON.parse(role) : [];
    }

    public get email(): string {
        return localStorage.getItem(LocalStorageIndex.USER_EMAIL) as string;
    }

    public get firme(): string {
        return localStorage.getItem(LocalStorageIndex.FIRME) as string;
    }

    public get isUserMobile(): boolean {
        return this.isOneOfRoles(
            [
                UserRole.AUDITEUR_EXTERNE,
                UserRole.AUDITEUR_HQD,
                UserRole.CONTROLEUR_QUALITE_EXTERNE,
                UserRole.INSPECTEUR_EXTERNE,
                UserRole.INSPECTEUR_HQD
            ]);
    }

    public get isInspecteur(): boolean {
        return this.isOneOfRoles([UserRole.INSPECTEUR_HQD, UserRole.INSPECTEUR_EXTERNE]);
    }

    public get isAuditeur(): boolean {
        return this.isOneOfRoles([UserRole.AUDITEUR_HQD, UserRole.AUDITEUR_EXTERNE]);
    }

    public get isControleurQualite(): boolean {
        return this.isOneOfRoles([UserRole.CONTROLEUR_QUALITE_EXTERNE]);
    }

    public get isPiloteOrAdminTIC(): boolean {
        return this.isOneOfRoles([UserRole.PILOTE, UserRole.ADMIN_TIC]);
    }

    public get isUserOnlyPilote(): boolean {
        return this.isOneOfRoles([UserRole.PILOTE]);
    }

    /* Class Functions */

    public isRole(role: UserRole): boolean {
        return this.roles?.includes(role);
    }

    public isOneOfRoles(roles: UserRole[]): boolean {
        return roles.some(r => this.isRole(r));
    }
}
