<p-fileUpload #fileUpload [multiple]="true" (onSelect)="onSelect($event)" accept="image/*" [maxFileSize]="8912896"
    chooseLabel="Ajouter des photos" [showUploadButton]="false" [showCancelButton]="false"
    invalidFileSizeMessageDetail="'La taille maximum est de 8,5 Mo ou 8704 Ko'" [fileLimit]="maxPhotos - photos.length"
    invalidFileSizeMessageSummary="{0}: Taille de fichier invalide, "
    invalidFileLimitMessageSummary="Nombre maximum de photos dépassé, "
    [invalidFileLimitMessageDetail]="'la limite est de ' + maxPhotos"
    invalidFileTypeMessageSummary="{0}: Mauvais type de fichier, "
    invalidFileTypeMessageDetail="types de fichier autorisés : {0}."
    [disabled]="photos.length + uploadedPhotos.length >= maxPhotos">
    <ng-template pTemplate="content">
        <div class="dragAndDrop" *ngIf="photos.length + fileUpload.files.length < maxPhotos">
            <span>ou déposer des photos</span>
        </div>
    </ng-template>
</p-fileUpload>