import { Pipe, PipeTransform } from '@angular/core';
/*
 * Change la première lettre d'une "string" pour la mettre en majuscule
 * Usage:
 *   value | frenchTitleCase
 * Example:
 *   {{ "firme d'audit" | frenchTitleCase }}
 *   formats to: "Firme d'audit"
*/
@Pipe({ name: 'frenchTitleCase' })
export class FrenchTitleCasePipe implements PipeTransform {
    transform(value: string): string {
        const first = value.substring(0, 1).toUpperCase();
        return first + value.substring(1);
    }
}
