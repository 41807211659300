export enum UserRole {
    ADMIN_EXTERNE = 'AdminExterne',
    ADMIN_GCSP = 'AdminGCSP',
    ADMIN_TIC = 'AdminTIC',
    AUDITEUR_EXTERNE = 'AuditeurExterne',
    AUDITEUR_HQD = 'AuditeurHQD',
    CONTROLEUR_QUALITE_EXTERNE = 'ControleurQualiteExterne',
    GESTION = 'Gestion',
    INSPECTEUR_EXTERNE = 'InspecteurExterne',
    INSPECTEUR_HQD = 'InspecteurHQD',
    PILOTE = 'Pilote',
    TECH_INGENIEURS_RESEAU = 'TechIngenieursReseau',
}
