import { Injectable } from '@angular/core';
import { StatutPointInspection } from '../features/inspection/models/statut-point-inspection.enum';
import { Anomalie } from '../features/anomalie/models/anomalie.model';
import { AnomaliePilotage } from '../shared/models/anomalie-pilotage.model';


@Injectable()
export class AnomaliePilotageService { // TODO DELETE THIS SERVICE WHEN PDL-655 IS DONE

    public getAnomaliePriorite(anomalie: Anomalie, anomaliesPilotage: AnomaliePilotage[]): string {
        const anomaliePilotage = anomaliesPilotage.find(ap => {
            if (ap.type) {
                return ap.element === anomalie.element &&
                    ap.type === anomalie.type &&
                    ap.cause === anomalie.cause;
            } else {
                return ap.element === anomalie.element &&
                    ap.cause === anomalie.cause;
            }
        }

        ) as AnomaliePilotage;

        return this.getPrioriteMapping(anomaliePilotage.prioriteOFRP);
    }

    private getPrioriteMapping(priorite: string): StatutPointInspection {
        switch (priorite) {
            case 'C':
                return StatutPointInspection.anomaliePrioriteC;
            case 'E':
                return StatutPointInspection.anomaliePrioriteE;
            case 'G':
                return StatutPointInspection.anomaliePrioriteG;
            case 'K':
                return StatutPointInspection.anomaliePrioriteK;
            case 'M':
                return StatutPointInspection.anomaliePrioriteM;
            case 'N':
                return StatutPointInspection.anomaliePrioriteN;
            default:
                return priorite as any;
        }
    }

}
