<div [formGroup]="form" *ngIf="form && projet" class="p-my-2">
    <span *ngIf="projet.nombrePoteauxNonInspectes! === 1">{{projet.nombrePoteauxNonInspectes}} poteau sur {{projet.nombreTotalPoteaux}} n'a pas été inspecté.</span>
    <span *ngIf="projet.nombrePoteauxNonInspectes! > 1">{{projet.nombrePoteauxNonInspectes}} poteaux sur {{projet.nombreTotalPoteaux}} n'ont pas été inspectés.</span>
    <br>
    <span *ngIf="projet.nombrePoteauxAvecAnomalie! === 1">{{projet.nombrePoteauxAvecAnomalie}} poteau avec anomalie(s).</span>
    <span *ngIf="projet.nombrePoteauxAvecAnomalie! > 1">{{projet.nombrePoteauxAvecAnomalie}} poteaux avec anomalie(s).</span>
    <input pInputText type="text" placeholder="Justificatif" formControlName="justificatif" class="p-mt-3">
</div>

<div class="p-d-flex p-jc-center">
    <button type="button" pButton icon="pi pi-flag" class="p-button-text" label="Compléter" (click)="completeProjet()"></button>
</div>
