<section class="error__wrapper">
    <ng-container *ngIf="!httpCode || !httpCodes[httpCode]">
        <h1>Erreur</h1>
    </ng-container>
    <ng-container *ngIf="httpCode && !!httpCodes[httpCode]">
        <h1>Erreur lors de l'appel à une ressource HTTP</h1>
        <h2>Erreur {{httpCode}} - {{httpCodes[httpCode]}}</h2>
    </ng-container>
    <p>
        Si le problème persiste, veuillez contacter le support.
        <br>
        Veuillez <a [href]='href'>recharger l'application.</a>
    </p>
</section>
