import { createAction, props } from '@ngrx/store';
import { EsriAccessToken } from '../../shared/models/esri-access-token.model';
import { AnomaliePilotageDto, ExtractionInfosDTO } from '../../core/api/client/models';
import { DataExtractionFormat } from '../../shared/enums/data-extraction-format.enum';

export enum SharedActionTypes {
    SET_ONLINE_STATUT = '[Shared] Set App Online Statut',
    GET_ESRI_ACCESS_TOKEN = '[Shared] Get Esri Access Token',
    GET_ESRI_ACCESS_TOKEN_SUCCESS = '[Shared] Get Esri Access Token Success',
    GET_ESRI_ACCESS_TOKEN_FAILURE = '[Shared] Get Esri Access Token Failure',
    GET_DATA_EXTRACTION = '[Shared] Get Data Extraction',
    GET_DATA_EXTRACTION_SUCCESS = '[Shared] Get Data Extraction Success',
    GET_DATA_EXTRACTION_FAILURE = '[Shared] Get Data Extraction Failure',
    START_DATA_EXTRACTION = '[Shared] Start Data Extraction',
    START_DATA_EXTRACTION_SUCCESS = '[Shared] Start Data Extraction Success',
    START_DATA_EXTRACTION_FAILURE = '[Shared] Start Data Extraction Failure',
    LOAD_ANOMALIES_PILOTAGE = '[Anomalie Pilotage] Get Anomalies Pilotage',
    LOAD_ANOMALIES_PILOTAGE_SUCCESS = '[Anomalie Pilotage] Get Anomalies Pilotage Success',
    LOAD_ANOMALIES_PILOTAGE_FAILURE = '[Anomalie Pilotage] Get Anomalies Pilotage Failure'
}

export const setAppOnlineStatut = createAction(SharedActionTypes.SET_ONLINE_STATUT, props<{ online: boolean }>());

export const getEsriAccessToken = createAction(SharedActionTypes.GET_ESRI_ACCESS_TOKEN);

export const getEsriAccessTokenSuccess = createAction(SharedActionTypes.GET_ESRI_ACCESS_TOKEN_SUCCESS, props<{ esriAccessToken: EsriAccessToken }>());

export const getEsriAccessTokenFailure = createAction(SharedActionTypes.GET_ESRI_ACCESS_TOKEN_FAILURE, props<{ error: any }>());

export const getDataExtraction = createAction(SharedActionTypes.GET_DATA_EXTRACTION, props<{ key: string }>());

export const getDataExtractionSuccess = createAction(SharedActionTypes.GET_DATA_EXTRACTION_SUCCESS, props<{ dataExtraction: ExtractionInfosDTO }>());

export const getDataExtractionFailure = createAction(SharedActionTypes.GET_DATA_EXTRACTION_FAILURE, props<{ error: any }>());

export const startDataExtraction = createAction(SharedActionTypes.START_DATA_EXTRACTION, props<{ typeExtraction: string, format: DataExtractionFormat }>());

export const startDataExtractionSuccess = createAction(SharedActionTypes.START_DATA_EXTRACTION_SUCCESS, props<{ id: string, format: DataExtractionFormat }>());

export const startDataExtractionFailure = createAction(SharedActionTypes.START_DATA_EXTRACTION_FAILURE, props<{ error: any }>());

export const loadAnomaliesPilotage = createAction(SharedActionTypes.LOAD_ANOMALIES_PILOTAGE);

export const loadAnomaliesPilotageSuccess = createAction(SharedActionTypes.LOAD_ANOMALIES_PILOTAGE_SUCCESS, props<{ anomaliesPilotage: AnomaliePilotageDto[] }>());

export const loadAnomaliesPilotageFailure = createAction(SharedActionTypes.LOAD_ANOMALIES_PILOTAGE_FAILURE, props<{ error: any }>());
