export enum LayerGroupe {
    PARC_DE_POTEAUX = 'Parcs de poteaux (tiers)',
    POINTS_INSPECTION = `Points d'inspection`,
    POINTS_AUDIT = `Points d'audit`,
    PROJETS_INSPECTION = `Projets d'inspection`,
    PROJETS_AUDIT = `Projets d'audit`,
    INSPECTION_POTEAU_LIGNE = 'Inspection de poteau/ligne',
    ANOMALIES = 'Anomalies',
    ENVIRONNEMENT = 'Environnement',
}
