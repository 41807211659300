// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.
const version = require('../../package.json').version;

export const environment = {
    name: 'dev',
    appVersion: version,
    production: false,
    securityRating: 1,
    config: {
        logStoreActions: false
    },
    imagePath: {
        assets: './assets/images/'
    },
};
