export enum ProjetTypesValue {
    planifie = 'Planifié',
    prioritaire = 'Prioritaire',
}

export enum ProjetTypes {
    planifie = 'planifie',
    prioritaire = 'prioritaire'
}

export const projetTypesListe = Object.keys(ProjetTypes).map(key => ({ champ: key, valeur: ProjetTypesValue[key] }));

export const projetTypesMap = new Map<ProjetTypes, string>([
    [ProjetTypes.planifie, ProjetTypesValue.planifie],
    [ProjetTypes.prioritaire, ProjetTypesValue.prioritaire],
]);
