import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { ButtonModule } from 'primeng/button';
import { ImageModule } from 'primeng/image';
import { PanelModule } from 'primeng/panel';
import { ProgressBarModule } from 'primeng/progressbar';
import { ToastModule } from 'primeng/toast';
import { PhotosStatusComponent } from './photos-status/photos-status.component';
import { PhotosContainerComponent } from './photos-container/photos-container.component';
import { PhotosUploadComponent } from './photos-upload/photos-upload.component';
import { PhotosEffects } from './state/photos.effects';
import { photosReducer } from './state/photos.reducer';
import { SkeletonModule } from 'primeng/skeleton';


import { FileUploadModule } from 'primeng/fileupload';
@NgModule({
    declarations: [
        PhotosContainerComponent,
        PhotosStatusComponent,
        PhotosUploadComponent
    ],
    imports: [
        CommonModule,
        ImageModule,
        ToastModule,
        ProgressBarModule,
        PanelModule,
        ButtonModule,
        FileUploadModule,
        SkeletonModule,
        StoreModule.forFeature('photos', photosReducer),
        EffectsModule.forFeature([PhotosEffects]),
    ],
    exports: [
        PhotosContainerComponent,
        PhotosStatusComponent,
        PhotosUploadComponent
    ],
    providers: [
        MessageService
    ]
})
export class PhotosModule { }
