import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AnomalieAuditDto, AnomalieBaseDto, PhotoEntity, PointAuditDto } from '../../../../core/api/client/models';
import { PhotoService } from '../../../../services/photo.service';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { UserInformation } from '../../../../shared/models/user-informations.model';
import { exportPhotosInErrorList } from '../../../../shared/utils/photo.utils';
import { State } from '../../../../state/app.state';
import { StatutPointAudit } from '../../models/statut-point-audit.enum';
import * as AuditActions from '../../state/audit.actions';
import * as PhotosActions from '../../../../shared/photos/state/photos.actions';
import { getStatutGlobal } from '../../utils/audit.utils';
import { Guid } from 'guid-typescript';
import { MessageService } from 'primeng/api';
import { concatMap, finalize, from, map, takeUntil, toArray } from 'rxjs';
import { MapPermissionsService } from '../../../../map/services/map-permissions.service';
import { arePhotosUploading, getAnomaliesAuditPhotos, getAnomaliesAuditPhotosLoading } from '../../../../shared/photos/state/photos.selectors';
import { StoreName } from '../../../offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../../offline/services/indexed-db.service';
import { MAX_PHOTO_ANOMALIE_AUDIT_NC } from '../../models/audit.const';

@Component({
    selector: 'app-anomalie-audit-non-conforme-dialog',
    templateUrl: './anomalie-audit-non-conforme-dialog.component.html',
    styleUrls: ['./anomalie-audit-non-conforme-dialog.component.scss']
})
export class AnomalieAuditNonConformeDialogComponent extends BaseComponent {

    selectedPointAudit: PointAuditDto;
    selectedAnomalieAudit: AnomalieAuditDto;

    @Input() set pointAudit(value: PointAuditDto) {
        if (value) {
            this.selectedPointAudit = value;
        }
    }
    @Input() set anomalie(value: AnomalieBaseDto) {
        if (value.id) {
            this.selectedAnomalieAudit = this.selectedPointAudit.anomaliesAudit.find(anomalie => anomalie.anomalieInspectionId === value.id);
            this.initPhotos();
            this.originalPhotos = this.selectedAnomalieAudit?.photos.length ? [...this.selectedAnomalieAudit?.photos] : [];
            this.loadPhotos(this.originalPhotos);
        }
        this.initForm();
    }
    @Input() currentUserInfo: UserInformation;
    @Input() visible: boolean;
    @Output() visibleChange = new EventEmitter<boolean>();
    @Output() closeSelectedAnomalie = new EventEmitter();
    @Output() closeAnomalieDialog = new EventEmitter();

    public form: FormGroup;

    // Photos
    public photosWithData: PhotoEntity[] = [];
    public originalPhotos: PhotoEntity[] = [];
    public uploadedPhotos: PhotoEntity[] = [];
    public uploadedPhotoLoading = false;
    public photosLoading = false;
    public photosUploading$ = this.store.select(arePhotosUploading);
    public readonly maxPhotoAnomalieAuditNonConforme = MAX_PHOTO_ANOMALIE_AUDIT_NC;

    constructor(
        private store: Store<State>,
        private photoService: PhotoService,
        private mapPermissionsService: MapPermissionsService,
        private dbService: IndexedDbService,
        private messageService: MessageService,
    ) {
        super();
    }

    private initForm() {
        this.form = new FormGroup({
            remarque: new FormControl(this.selectedAnomalieAudit?.remarque, [Validators.required, Validators.maxLength(500)])
        });
    }

    private initPhotos() {
        this.photosWithData = [];
        this.uploadedPhotos = [];
        this.originalPhotos = [];
    }

    public saveAnomalieAuditNonConforme() {

        if (this.form.valid) {

            const pointAudit: PointAuditDto = {
                ...this.selectedPointAudit,
                anomaliesAudit: this.selectedPointAudit.anomaliesAudit.map(anomalie => anomalie.id === this.selectedAnomalieAudit.id ? {
                    ...anomalie,
                    statut: StatutPointAudit.nonConforme,
                    remarque: this.form.controls.remarque.value,
                    auditeLe: new Date().getTime(),
                    auditePar: this.currentUserInfo.courriel,
                    photos: this.photoService.removeDataFromPhotoArray(anomalie.photos)
                } : anomalie)
            };

            const updatedPointAudit: PointAuditDto = {
                ...pointAudit,
                statutGlobal: getStatutGlobal(pointAudit)
            };

            this.store.dispatch(AuditActions.updatePointAudit({ pointAudit: updatedPointAudit }));

            const updatedAnomalieAuditPhotos: PhotoEntity[] = [
                ...this.photosWithData,
                ...this.uploadedPhotos
            ];

            if (updatedAnomalieAuditPhotos) {
                this.saveAnomalieAuditPhoto(updatedPointAudit, updatedAnomalieAuditPhotos);
            }

            this.closeAnomalieAuditNonConformeDialog();

            if (updatedPointAudit.anomaliesAudit.every(anomalie => anomalie.statut !== StatutPointAudit.aAuditer)) {
                this.closeAnomalieDialog.emit();
            }
        }
    }

    public saveAnomalieAuditPhoto(pointAudit: PointAuditDto, updatedAnomalieAuditPhotos: PhotoEntity[]) {

        const deletedPhotos = this.originalPhotos.filter(originalPhoto =>
            !updatedAnomalieAuditPhotos.some(updatedPhoto => updatedPhoto.id === originalPhoto.id)
        );

        const addedPhotos = updatedAnomalieAuditPhotos.filter(updatedPhoto =>
            !this.originalPhotos.some(originalPhoto => originalPhoto.id === updatedPhoto.id) &&
            !deletedPhotos.some(deletedPhoto => deletedPhoto.id === updatedPhoto.id)
        );

        deletedPhotos.forEach((deletedPhoto: PhotoEntity) => {
            this.store.dispatch(AuditActions.deleteAnomalieAuditPhoto(
                {
                    pointAudit: pointAudit,
                    anomalieAuditId: this.selectedAnomalieAudit.id,
                    photo: deletedPhoto
                }));
        });

        addedPhotos.forEach((addedPhoto: PhotoEntity) => {
            this.store.dispatch(AuditActions.addAnomalieAuditPhoto(
                {
                    pointAudit: pointAudit,
                    anomalieAuditId: this.selectedAnomalieAudit.id,
                    photo: addedPhoto
                }
            ));
        });
    }

    public closeAnomalieAuditNonConformeDialog() {
        this.visible = false;
        this.visibleChange.emit(this.visible);
        this.closeSelectedAnomalie.emit();
        this.form.reset();
    }

    public removePhoto(photoId: string) {
        this.photosWithData = this.photoService.removePhoto(this.photosWithData, photoId);
    }

    public onUploadPhotos(photos: File[]) {
        this.uploadedPhotoLoading = true;
        from(photos).pipe(
            concatMap(photo => from(this.photoService.cleanImage(photo))
                .pipe(
                    map((imageBase64: string): PhotoEntity => {

                        return {
                            id: Guid.create().toString(),
                            nomOriginal: photo.name,
                            nom: '',
                            anomalieAuditId: this.selectedAnomalieAudit.id,
                            data: this.photoService.base64ToFile(imageBase64, photo.name)
                        };
                    }),
                )
            ),
            toArray(),
            finalize(() => this.uploadedPhotoLoading = false)
        ).subscribe((cleanedPhotos) => this.uploadedPhotos = cleanedPhotos);
    }

    private loadPhotos(photosWithoutData: PhotoEntity[]) {
        // Ici, s'il est admin, on charge les photos de l'anomalie actuelle
        if (this.mapPermissionsService.canEditPointInspection()) {
            this.store.dispatch(PhotosActions.loadAnomaliesAuditPhotos({ photos: photosWithoutData }));

            this.store.select(getAnomaliesAuditPhotos)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photosWithData => this.photosWithData = [...photosWithData]);

            this.store.select(getAnomaliesAuditPhotosLoading).pipe(
                takeUntil(this.destroyed)
            ).subscribe(loading => this.photosLoading = loading);
            // Sinon, on récupère les photos de indexdb
        } else {
            this.photosLoading = true;
            this.dbService.getAll<PhotoEntity>(StoreName.PHOTOS)
                .pipe(
                    takeUntil(this.destroyed)
                ).subscribe(photos => {
                    this.photosLoading = false;
                    const photoIds = photosWithoutData.map(photo => photo.id);
                    this.photosWithData = photos.filter(photo => photoIds.includes(photo.id));

                    if (this.photosWithData.length !== photosWithoutData.length) {
                        const photosInError = photosWithoutData.filter(photo => this.photosWithData.map(displayedPhoto => displayedPhoto.id).includes(photo.id));
                        exportPhotosInErrorList(photosInError);

                        this.messageService.add({
                            severity: 'warn',
                            summary: 'Photos manquantes',
                            detail: `Certaines photos n'ont pas pu être chargées. La liste des photos manquantes a été téléchargée.`,
                            life: 5000
                        });
                    }
                });
        }
    }
}
