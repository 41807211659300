import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppConfiguration } from '../shared/config/app-config.model';

@Injectable()
export class ApiService {

    constructor(
        private httpClient: HttpClient,
        private configService: AppConfiguration,
    ) { }

    public get<T>(route: string, header?: HttpHeaders): Observable<T> {
        return this.httpClient.get<T>(this.getApiPath(route), { headers: header });
    }

    public post<T, R>(route: string, body: R, header?: HttpHeaders): Observable<T> {
        return this.httpClient.post<T>(this.getApiPath(route), body, { headers: header });
    }

    public put<T, R>(route: string, body: R, header?: HttpHeaders): Observable<T> {
        return this.httpClient.put<T>(this.getApiPath(route), body, { headers: header });
    }

    public patch<T, R>(route: string, body: R, header?: HttpHeaders): Observable<T> {
        return this.httpClient.patch<T>(this.getApiPath(route), body, { headers: header });
    }

    public delete<T>(route: string, header?: HttpHeaders): Observable<T> {
        return this.httpClient.delete<T>(this.getApiPath(route), { headers: header });
    }

    public getWithResponseType<T, R>(route: string, body?: R): Observable<T> {
        return this.httpClient.get<T>(this.getApiPath(route), body);
    }

    public getWithObserveFullResponse(route: string, responseType: any, header: HttpHeaders): Observable<HttpResponse<Object | Blob | string | ArrayBuffer>> {
        return this.httpClient.get(this.getApiPath(route), { headers: header, responseType: responseType, observe: 'response' });
    }

    protected getApiPath(route: string): string {
        return this.configService.baseBackendUrl + '/api/v1' + route;
    }
}
