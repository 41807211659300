/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { AnomalieBaseDtoSearchResult } from '../models/anomalie-base-dto-search-result';
import { PhotoEntity } from '../models/photo-entity';
@Injectable({
    providedIn: 'root',
})
class AnomalieService extends __BaseService {
    static readonly getApiV1AnomaliesPath = '/api/v1/anomalies';
    static readonly getApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoPath = '/api/v1/anomalies/{anomalieId}/photos/{identifiantPhoto}';
    static readonly getApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnailsPath = '/api/v1/anomalies/{anomalieId}/photos/{nomPhoto}/thumbnails';
    static readonly postApiV1AnomaliesAnomalieIdPhotosPath = '/api/v1/anomalies/{anomalieId}/photos';
    static readonly deleteApiV1AnomaliesAnomalieIdPhotosNomPhotoPath = '/api/v1/anomalies/{anomalieId}/photos/{nomPhoto}';
    static readonly deleteApiV1AnomaliesAnomalieIdPath = '/api/v1/anomalies/{anomalieId}';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * @param params The `AnomalieService.GetApiV1AnomaliesParams` containing the following parameters:
     *
     * - `ymin`:
     *
     * - `ymax`:
     *
     * - `xmin`:
     *
     * - `xmax`:
     *
     * - `projetId`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * @return Success
     */
    getApiV1AnomaliesResponse(params: AnomalieService.GetApiV1AnomaliesParams): __Observable<__StrictHttpResponse<AnomalieBaseDtoSearchResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.ymin != null) __params = __params.set('ymin', params.ymin.toString());
        if (params.ymax != null) __params = __params.set('ymax', params.ymax.toString());
        if (params.xmin != null) __params = __params.set('xmin', params.xmin.toString());
        if (params.xmax != null) __params = __params.set('xmax', params.xmax.toString());
        if (params.projetId != null) __params = __params.set('projetId', params.projetId.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/anomalies`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<AnomalieBaseDtoSearchResult>;
            })
        );
    }
    /**
     * @param params The `AnomalieService.GetApiV1AnomaliesParams` containing the following parameters:
     *
     * - `ymin`:
     *
     * - `ymax`:
     *
     * - `xmin`:
     *
     * - `xmax`:
     *
     * - `projetId`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * @return Success
     */
    getApiV1Anomalies(params: AnomalieService.GetApiV1AnomaliesParams): __Observable<AnomalieBaseDtoSearchResult> {
        return this.getApiV1AnomaliesResponse(params).pipe(
            __map(_r => _r.body as AnomalieBaseDtoSearchResult)
        );
    }

    /**
     * @param params The `AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoParams` containing the following parameters:
     *
     * - `identifiantPhoto`:
     *
     * - `anomalieId`:
     *
     * - `taille`:
     */
    getApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoResponse(params: AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoParams): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        if (params.taille != null) __params = __params.set('taille', params.taille.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/anomalies/${encodeURIComponent(String(params.anomalieId))}/photos/${encodeURIComponent(String(params.identifiantPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'blob'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            })
        );
    }
    /**
     * @param params The `AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoParams` containing the following parameters:
     *
     * - `identifiantPhoto`:
     *
     * - `anomalieId`:
     *
     * - `taille`:
     */
    getApiV1AnomaliesAnomalieIdPhotosIdentifiantPhoto(params: AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoParams): __Observable<Blob> {
        return this.getApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoResponse(params).pipe(
            __map(_r => _r.body as Blob)
        );
    }

    /**
     * @param params The `AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnailsParams` containing the following parameters:
     *
     * - `nomPhoto`:
     *
     * - `anomalieId`:
     */
    getApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnailsResponse(params: AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnailsParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/anomalies/${encodeURIComponent(String(params.anomalieId))}/photos/${encodeURIComponent(String(params.nomPhoto))}/thumbnails`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnailsParams` containing the following parameters:
     *
     * - `nomPhoto`:
     *
     * - `anomalieId`:
     */
    getApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnails(params: AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnailsParams): __Observable<null> {
        return this.getApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnailsResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `AnomalieService.PostApiV1AnomaliesAnomalieIdPhotosParams` containing the following parameters:
     *
     * - `anomalieId`:
     *
     * - `Photo`:
     *
     * - `NomOriginal`:
     *
     * - `Nom`:
     *
     * - `Id`:
     *
     * @return Created
     */
    postApiV1AnomaliesAnomalieIdPhotosResponse(params: AnomalieService.PostApiV1AnomaliesAnomalieIdPhotosParams): __Observable<__StrictHttpResponse<PhotoEntity>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.Photo != null) { __formData.append('Photo', params.Photo as string | Blob); }
        if (params.NomOriginal != null) { __formData.append('NomOriginal', params.NomOriginal as string | Blob); }
        if (params.Nom != null) { __formData.append('Nom', params.Nom as string | Blob); }
        if (params.Id != null) { __formData.append('Id', params.Id as string | Blob); }
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/anomalies/${encodeURIComponent(String(params.anomalieId))}/photos`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PhotoEntity>;
            })
        );
    }
    /**
     * @param params The `AnomalieService.PostApiV1AnomaliesAnomalieIdPhotosParams` containing the following parameters:
     *
     * - `anomalieId`:
     *
     * - `Photo`:
     *
     * - `NomOriginal`:
     *
     * - `Nom`:
     *
     * - `Id`:
     *
     * @return Created
     */
    postApiV1AnomaliesAnomalieIdPhotos(params: AnomalieService.PostApiV1AnomaliesAnomalieIdPhotosParams): __Observable<PhotoEntity> {
        return this.postApiV1AnomaliesAnomalieIdPhotosResponse(params).pipe(
            __map(_r => _r.body as PhotoEntity)
        );
    }

    /**
     * @param params The `AnomalieService.DeleteApiV1AnomaliesAnomalieIdPhotosNomPhotoParams` containing the following parameters:
     *
     * - `nomPhoto`:
     *
     * - `anomalieId`:
     */
    deleteApiV1AnomaliesAnomalieIdPhotosNomPhotoResponse(params: AnomalieService.DeleteApiV1AnomaliesAnomalieIdPhotosNomPhotoParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/api/v1/anomalies/${encodeURIComponent(String(params.anomalieId))}/photos/${encodeURIComponent(String(params.nomPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `AnomalieService.DeleteApiV1AnomaliesAnomalieIdPhotosNomPhotoParams` containing the following parameters:
     *
     * - `nomPhoto`:
     *
     * - `anomalieId`:
     */
    deleteApiV1AnomaliesAnomalieIdPhotosNomPhoto(params: AnomalieService.DeleteApiV1AnomaliesAnomalieIdPhotosNomPhotoParams): __Observable<null> {
        return this.deleteApiV1AnomaliesAnomalieIdPhotosNomPhotoResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param anomalieId undefined
     */
    deleteApiV1AnomaliesAnomalieIdResponse(anomalieId: string): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/api/v1/anomalies/${encodeURIComponent(String(anomalieId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param anomalieId undefined
     */
    deleteApiV1AnomaliesAnomalieId(anomalieId: string): __Observable<null> {
        return this.deleteApiV1AnomaliesAnomalieIdResponse(anomalieId).pipe(
            __map(_r => _r.body as null)
        );
    }
}

module AnomalieService {

    /**
     * Parameters for getApiV1Anomalies
     */
    export interface GetApiV1AnomaliesParams {
        ymin?: number;
        ymax?: number;
        xmin?: number;
        xmax?: number;
        projetId?: string;
        pageSize?: number;
        pageNumber?: number;
    }

    /**
     * Parameters for getApiV1AnomaliesAnomalieIdPhotosIdentifiantPhoto
     */
    export interface GetApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoParams {
        identifiantPhoto: string;
        anomalieId: string;
        taille?: any;
    }

    /**
     * Parameters for getApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnails
     */
    export interface GetApiV1AnomaliesAnomalieIdPhotosNomPhotoThumbnailsParams {
        nomPhoto: string;
        anomalieId: string;
    }

    /**
     * Parameters for postApiV1AnomaliesAnomalieIdPhotos
     */
    export interface PostApiV1AnomaliesAnomalieIdPhotosParams {
        anomalieId: string;
        Photo?: Blob;
        NomOriginal?: string;
        Nom?: string;
        Id?: string;
    }

    /**
     * Parameters for deleteApiV1AnomaliesAnomalieIdPhotosNomPhoto
     */
    export interface DeleteApiV1AnomaliesAnomalieIdPhotosNomPhotoParams {
        nomPhoto: string;
        anomalieId: string;
    }
}

export { AnomalieService }
