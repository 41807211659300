import { TableColumn } from '../../../shared/models/table-column.model';

export const anomalieListDialogColumn: TableColumn[] = [
    {
        field: 'codeABarres',
        header: 'Code à barres',
        sortable: true,
        filterable: true,
        width: '125px',
        textAlign: 'left'
    },
    {
        field: 'lclcl',
        header: 'LCLCL',
        sortable: true,
        filterable: true,
        width: '100px',
        textAlign: 'left'
    },
    {
        field: 'element',
        header: 'Élément',
        sortable: true,
        filterable: true,
        width: '120px',
        textAlign: 'left'
    },
    {
        field: 'type',
        header: 'Type',
        sortable: true,
        filterable: true,
        width: '200px',
        textAlign: 'left'
    },
    {
        field: 'cause',
        header: 'Cause',
        sortable: true,
        filterable: true,
        width: '200px',
        textAlign: 'left'
    },
    {
        field: 'urgence',
        header: 'Urgence',
        sortable: true,
        filterable: true,
        width: '110px',
        textAlign: 'left'
    },
    {
        field: 'remarque',
        header: 'Remarque',
        sortable: true,
        filterable: true,
        width: '140px',
        textAlign: 'left'
    },
    {
        field: 'priorite',
        header: 'Priorité',
        sortable: true,
        filterable: true,
        width: '160px',
        textAlign: 'left'
    },
    {
        field: 'photos',
        header: 'Photo',
        width: '75px',
        textAlign: 'left'
    },
    {
        field: 'dateTransfertSap',
        header: 'Date transfert SAP',
        sortable: true,
        filterable: true,
        width: '120px',
        textAlign: 'left'
    },
    {
        field: 'indCreationAvis',
        header: `Ind. création d'avis`,
        sortable: true,
        filterable: true,
        width: '100px',
        textAlign: 'left'
    },
    {
        field: 'prioriteAnalyse',
        header: `Priorité analyse`,
        sortable: true,
        filterable: true,
        width: '100px',
        textAlign: 'left'
    },
    {
        field: 'remarqueAnalyse',
        header: `Remarque analyse`,
        sortable: true,
        filterable: true,
        width: '200px',
        textAlign: 'left'
    },
    {
        field: 'modification',
        header: 'Modifier',
        width: '110px',
        textAlign: 'left'
    },
    {
        field: 'actions',
        header: 'Zoom sur',
        width: '75px',
        textAlign: 'left'
    }
];

