import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';
export const projetZoomHighlightOutline: AnyLayer = {
    id: MapLayersSources.POLYGONE_PROJET_ZOOM_HIGHLIGHTED_OUTLINE,
    type: 'line',
    source: MapLayersSources.POLYGONE_PROJET_ZOOM_HIGHLIGHTED,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'line-color': mapStyleConfig.colors.transparent,
        'line-width': mapStyleConfig.projetZoomHighlightOutline.lineWidth,
        /* eslint-enable @typescript-eslint/naming-convention */
    },
    filter: ['==', ['geometry-type'], 'Polygon']
};
