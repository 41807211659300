import { createAction, props } from '@ngrx/store';
import { AnomalieList } from '../../models/anomalie-list.model';
import { Anomalie } from '../../models/anomalie.model';
import { PhotoExtraction } from '../../models/photo-extraction.model';

export enum AnomalieListActionTypes {
    LOAD_ANOMALIE_LIST = '[Anomalie List] Load Anomalie List',
    LOAD_ANOMALIE_LIST_SUCCESS = '[Anomalie List] Load Anomalie List Success',
    LOAD_ANOMALIE_LIST_ERROR = '[Anomalie List] Load Anomalie List Error',
    UPDATE_ANOMALIE_LIST_SUCCESS = '[Anomalie List] Update Anomalie List Success',
    LOAD_STATUT_PHOTO_EXTRACTION = '[Anomalie List] Load Statut Photo Extraction',
    LOAD_STATUT_PHOTO_EXTRACTION_SUCCESS = '[Anomalie List] Load Statut Photo Extraction Success',
    LOAD_STATUT_PHOTO_EXTRACTION_ERROR = '[Anomalie List] Load Statut Photo Extraction Error',
    CREATE_PHOTO_EXTRACTION = '[Anomalie List] Create Photos Extraction',
    CREATE_PHOTO_EXTRACTION_SUCCESS = '[Anomalie List] Create Photo Extraction Success',
    CREATE_PHOTO_EXTRACTION_ERROR = '[Anomalie List] Create Photo Extraction Error'
}

/*** LOAD ANOMALIE LIST */
export const loadAnomalieList = createAction(AnomalieListActionTypes.LOAD_ANOMALIE_LIST, props<{ projetId: string }>());

export const loadAnomalieListSuccess = createAction(AnomalieListActionTypes.LOAD_ANOMALIE_LIST_SUCCESS, props<{ anomalieList: AnomalieList[] }>());

export const loadAnomalieListError = createAction(AnomalieListActionTypes.LOAD_ANOMALIE_LIST_ERROR, props<{ error: any }>());

/*** LOAD STATUT PHOTO EXTRACTION */
export const loadStatutPhotoExtraction = createAction(AnomalieListActionTypes.LOAD_STATUT_PHOTO_EXTRACTION, props<{ projetId: string }>());

export const loadStatutPhotoExtractionSuccess = createAction(AnomalieListActionTypes.LOAD_STATUT_PHOTO_EXTRACTION_SUCCESS,
    props<{ statutPhotoExtraction: PhotoExtraction }>());

export const loadStatutPhotoExtractionError = createAction(AnomalieListActionTypes.LOAD_STATUT_PHOTO_EXTRACTION_ERROR, props<{ error: any }>());

/*** CREATE PHOTO EXTRACTION */
export const createPhotoExtraction = createAction(AnomalieListActionTypes.CREATE_PHOTO_EXTRACTION, props<{ projetId: string }>());

export const createPhotoExtractionSuccess = createAction(AnomalieListActionTypes.CREATE_PHOTO_EXTRACTION_SUCCESS, props<{ photoExtraction: PhotoExtraction }>());

export const createPhotoExtractionError = createAction(AnomalieListActionTypes.CREATE_PHOTO_EXTRACTION_ERROR, props<{ error: any }>());

/*** UPDATE ANOMALIE LIST */
export const updateAnomalieListSuccess = createAction(AnomalieListActionTypes.UPDATE_ANOMALIE_LIST_SUCCESS, props<{ anomalie: Anomalie }>());

