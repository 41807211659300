
export enum StatutProjetAudit {
    approuve = 'approuve',
    nonApprouve = 'nonApprouve',
    cree = 'cree',
    assigne = 'assigne',
    enCours = 'enCours',
    enCreation = 'enCreation',
    auditComplete = 'auditComplete',
    annule = 'annule',
    enErreur = 'enErreur'
}

export enum StatutProjetAuditValue {
    approuve = 'Audit approuvé',
    nonApprouve = 'Audit non approuvé',
    cree = 'Audit créé',
    assigne = 'Audit assigné',
    enCours = 'Audit en cours',
    enCreation = 'Audit en création',
    auditComplete = 'Audit completé',
    annule = 'Audit annulé',
    enErreur = 'En erreur'
}

export const statutProjetAuditName = new Map<StatutProjetAudit, string>([
    [StatutProjetAudit.approuve, StatutProjetAuditValue.approuve],
    [StatutProjetAudit.nonApprouve, StatutProjetAuditValue.nonApprouve],
    [StatutProjetAudit.cree, StatutProjetAuditValue.cree],
    [StatutProjetAudit.assigne, StatutProjetAuditValue.assigne],
    [StatutProjetAudit.enCours, StatutProjetAuditValue.enCours],
    [StatutProjetAudit.enCreation, StatutProjetAuditValue.enCreation],
    [StatutProjetAudit.auditComplete, StatutProjetAuditValue.auditComplete],
    [StatutProjetAudit.annule, StatutProjetAuditValue.annule],
    [StatutProjetAudit.enErreur, StatutProjetAuditValue.enErreur]
]);