import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { catchError, concatMap, map, mergeMap, of, tap, withLatestFrom } from 'rxjs';
import { AnomaliePilotageDto } from '../../core/api/client/models';
import { Severite } from '../../enums/severite';
import { StoreName } from '../../features/offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../features/offline/services/indexed-db.service';
import * as OfflineActions from '../../features/offline/state/offline.actions';
import { SharedService } from '../../services/shared.service';
import * as SharedActions from './shared.actions';
import { getIsAppOnline } from './shared.selectors';
import { State } from './shared.state';

@Injectable()
export class SharedEffects {
    getEsriAccessToken$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.getEsriAccessToken),
            withLatestFrom(this.store.select(getIsAppOnline)),
            mergeMap(([_, isAppOnline]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.getEsriAccessToken());
                } else {
                    return this.sharedService.getEsriAccessToken()
                        .pipe(
                            map(esriAccessToken => SharedActions.getEsriAccessTokenSuccess({ esriAccessToken })),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: Severite.erreur,
                                            summary: 'Erreur Token',
                                            detail: `Une erreur est survenue lors de la récupération du token Esri.`
                                        }
                                    );
                                }

                                return of(SharedActions.getEsriAccessTokenFailure({ error }));
                            })
                        );
                }
            })
        );
    });

    startDataExtraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.startDataExtraction),
            mergeMap((action) => {
                return this.sharedService.startDataExtraction({ type: action.typeExtraction, format: action.format })
                    .pipe(
                        map(response => SharedActions.startDataExtractionSuccess({ id: response, format: action.format })),
                        catchError((error: any) => of(SharedActions.startDataExtractionFailure({ error })))
                    );
            })
        );
    });

    getDataExtraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.getDataExtraction),
            mergeMap((action) => {
                return this.sharedService.getDataExtraction(action.key)
                    .pipe(
                        map(dataExtraction => SharedActions.getDataExtractionSuccess({ dataExtraction })),
                        catchError((error: any) => of(SharedActions.getDataExtractionFailure({ error })))
                    );
            })
        );
    });

    loadAnomaliesPilotage$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(SharedActions.loadAnomaliesPilotage),
            withLatestFrom(this.store.select(getIsAppOnline)),
            concatMap(([_, isAppOnline]) => {
                if (!isAppOnline) {
                    return this.dbService.getAll<AnomaliePilotageDto>(StoreName.ANOMALIE_PILOTAGE);
                } else {
                    return this.sharedService.getAnomaliesPilotage({ pageSize: 5000 }).pipe(
                        tap(anomalies => this.dbService.bulkAdd(StoreName.ANOMALIE_PILOTAGE, anomalies.items).subscribe()),
                        map((anomalies) => anomalies.items)
                    );
                }
            }),
            map(anomaliesPilotage => SharedActions.loadAnomaliesPilotageSuccess({ anomaliesPilotage })),
            catchError((error: any) => {
                if (error.status !== 404) {
                    this.messageService.add(
                        {
                            severity: Severite.erreur,
                            summary: 'Erreur Anomalie Pilotage',
                            detail: `Une erreur est survenue lors de la récupération des anomalies pilotage.`
                        }
                    );
                }

                return of(SharedActions.loadAnomaliesPilotageFailure({ error }));
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private sharedService: SharedService,
        private messageService: MessageService,
        private dbService: IndexedDbService
    ) { }

}


