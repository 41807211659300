<div class="p-field p-float-label" [ngClass]="{
    'p-invalid': formControl.invalid && (formControl.dirty || formControl.touched),
    'p-field-disabled': formControl.disabled
}">

    <ng-content></ng-content>

    <app-form-info *ngIf="info" [message]="info" typeInfo="field"></app-form-info>

    <label [attr.for]="inputId">
        <div class="hq-label-description">{{ label }} <span class="field-required" *ngIf="isRequired()">*</span></div>
        <div class="hq-label--format" *ngIf="subtitle">{{ subtitle }}</div>
    </label>

    <small *ngIf="format">{{ format }}</small>

    <app-form-field-error [label]="label" [control]="formControl"></app-form-field-error>

</div>
