<p-dialog header="Extraction des photos" [(visible)]="visible" [modal]=true [draggable]=false
    (onHide)="closePhotoExtractionDialog()">
    <div>
        <p>Un fichier d'extraction des photos en date du {{photoExtraction.dateExtraction}} à
            {{photoExtraction.heureExtraction}} existe pour ce projet.</p>
        <p>Voulez-vous télécharger le fichier ou extraire les photos à nouveau ?</p>
    </div>
    <ng-template pTemplate="footer">
        <button class="p-button-text" pButton pRipple label="Télécharger le fichier"
            (click)="onDownloadPhotoExtraction()"></button>
        <button class="p-button-text" pButton pRipple label="Extraire les photos"
            (click)="onCreatePhotoExtraction()"></button>
    </ng-template>
</p-dialog>
