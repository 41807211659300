import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-waiting',
    templateUrl: './waiting.component.html',
    styleUrls: ['./waiting.component.scss'],
})
export class WaitingComponent {
    @Input() size = 75;
    @Input() opacity = 8;
    @Input() displayLabel = false;
    @Input() label = 'Veuillez patienter...';
    @Input() labelFontSize = 16;
    @Input() position = 'absolute';
}
