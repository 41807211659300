<div class="audit-list">
    <ng-container *ngIf="projetAuditList">
        <app-projet-audit-list-dialog *ngIf="!isUserMobile" [projetAuditList]="projetAuditList" [columns]="columns"
            [statutFiltersItems]="statutFiltersItems" [canAssignProjetAudit]="canAssignProjetAudit"
            [canCancelProjetAudit]="canCancelProjetAudit" [canExtractProjetAuditHistory]="canExtractProjetAuditHistory"
            [canRejectProjetAudit]="canRejectProjetAudit" [canApproveProjetAudit]="canApproveProjetAudit"
            [isOffline]="isAppOffline" [canZoomProjetAudit]="canZoomProjetAudit"
            [statutGlobalFiltersItems]="statutGlobalFiltersItems">
        </app-projet-audit-list-dialog>
        <app-projet-audit-list-mobile-dialog *ngIf="isUserMobile" [projetAuditList]="projetAuditList"
            [columns]="mobileColumns" [canCompleteProjetAudit]="canCompleteProjetAudit" [isOffline]="isAppOffline">
        </app-projet-audit-list-mobile-dialog>
    </ng-container>
</div>

<p-confirmDialog #cdAssign header="Confirmation d'assignation" icon="pi pi-exclamation-triangle"
    key='assignProjetAuditConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cdAssign.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cdAssign.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cdCancel header="Confirmation d'annulation" icon="pi pi-exclamation-triangle"
    key='cancelProjetAuditConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cdCancel.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cdCancel.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cdReject header="Confirmation du rejet" icon="pi pi-exclamation-triangle"
    key='rejectProjetAuditConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cdReject.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cdReject.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cdApprove header="Confirmation de l'approbation" icon="pi pi-exclamation-triangle"
    key='approveProjetAuditConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Non"
            (click)="cdApprove.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="Oui"
            (click)="cdApprove.accept()"></button>
    </ng-template>
</p-confirmDialog>

<p-confirmDialog #cdDownload header="Téléchargement projet d'audit" icon="pi pi-exclamation-triangle"
    key='downloadProjetAuditConfirm'>
    <ng-template pTemplate="footer">
        <button type="button" pButton icon="pi pi-times" class="p-button-text p-mr-4" label="Annuler"
            (click)="cdDownload.reject()"></button>
        <button type="button" pButton icon="pi pi-check" class="p-button-text p-ml-4" label="OK"
            (click)="cdDownload.accept()"></button>
    </ng-template>
</p-confirmDialog>