import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { SharedModule } from '../../shared/shared.module';
import { InspectionEffects } from './state/inspection.effects';
import { inspectionReducer } from './state/inspection.reducer';
import { InspectionComponent } from './inspection.component';
import { ProjetModule } from '../projet/projet.module';
import { ProjetInspectionListComponent } from './components/projet-inspection-list/projet-inspection-list.component';
import { ProjetInspectionListMobileDialogComponent }
    from './components/projet-inspection-list/projet-inspection-list-mobile-dialog/projet-inspection-list-mobile-dialog.component';
import { RapportValidationDialogComponent } from './components/rapport-validation-dialog/rapport-validation-dialog.component';

@NgModule({
    declarations: [
        InspectionComponent,
        ProjetInspectionListComponent,
        ProjetInspectionListMobileDialogComponent,
        RapportValidationDialogComponent
    ],
    imports: [
        ProjetModule,
        SharedModule,
        StoreModule.forFeature('inspection', inspectionReducer),
        EffectsModule.forFeature([InspectionEffects])
    ],
    exports: [
        // PointAuditNonConformeDialogComponent,
        //AnomalieAuditNonConformeDialogComponent,
        //CreateProjetAuditDialogComponent
    ],
})
export class InspectionModule { }
