import { Component, OnDestroy, OnInit } from '@angular/core';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { filter, map, takeUntil } from 'rxjs';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state/app.state';
import { getProjetsAuditDownloaded } from '../../../../../features/offline/state/offline.selectors';
import { SelectItem } from 'primeng/api/selectitem';
import { DynamicDialogRef } from 'primeng/dynamicdialog';

@Component({
    selector: 'app-select-projet-audit-dialog',
    templateUrl: './select-projet-audit-dialog.component.html',
    styleUrls: ['./select-projet-audit-dialog.component.scss']
})
export class SelectProjetAuditDialogComponent extends BaseComponent implements OnInit, OnDestroy {

    public selectedProjet: string;
    public projetItems: SelectItem<string>[] = [];

    constructor(
        private store: Store<State>,
        public ref: DynamicDialogRef
    ) {
        super();
    }

    ngOnInit(): void {
        this.buildProjetsMenu();
    }

    private buildProjetsMenu() {
        this.store.select(getProjetsAuditDownloaded)
            .pipe(
                filter(projets => !!projets),
                map(projets => {
                    return projets!.map(projet => {
                        return {
                            label: projet.nom,
                            value: projet
                        } as SelectItem;
                    });
                }),
                takeUntil(this.destroyed)
            ).subscribe(projets => {
                this.projetItems = projets;
            });
    }

    public closeSelectProjetDialog() {
        this.ref.close(this.selectedProjet);
    }
}
