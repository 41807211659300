import { SharedEffects } from './shared/shared.effects';
import { sharedReducer } from './shared/shared.reducer';
import { SharedState } from './shared/shared.state';

export interface State {
    shared: SharedState;
}

export const appReducer = {
    shared: sharedReducer
};

export const appEffects = [
    SharedEffects
];

