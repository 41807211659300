import { Action, createReducer, on } from '@ngrx/store';
import * as OfflineActions from './offline.actions';
import { OfflineState } from './offline.state';

export const offlineInitialState: OfflineState = {
    getProjetsDownloaded: null,
    getProjetsDownloadedLoading: false,
    getProjetsDownloadedSuccess: false,
    getProjetsDownloadedError: null,
    getProjetsAuditDownloaded: null,
    getProjetsAuditDownloadedLoading: false,
    getProjetsAuditDownloadedSuccess: false,
    getProjetsAuditDownloadedError: null,
    addProjetToIndexedDb: null,
    addProjetToIndexedDbLoading: false,
    addProjetToIndexedDbSuccess: false,
    addProjetToIndexedDbError: null,
    addProjetAuditToIndexedDb: null,
    addProjetAuditToIndexedDbLoading: false,
    addProjetAuditToIndexedDbSuccess: false,
    addProjetAuditToIndexedDbError: null,
    updateProjetToIndexedDb: null,
    updateProjetToIndexedDbSuccess: false,
    updateProjetToIndexedDbError: null,
    updateProjetAuditToIndexedDb: null,
    updateProjetAuditToIndexedDbSuccess: false,
    updateProjetAuditToIndexedDbError: null
};

export const offlineReducer = createReducer<OfflineState>(
    offlineInitialState,
    on(OfflineActions.getProjetsDownloaded, (state) => {
        return {
            ...state,
            getProjetsDownloaded: null,
            getProjetsDownloadedLoading: true,
            getProjetsDownloadedSuccess: false,
            getProjetsDownloadedError: null
        };
    }),
    on(OfflineActions.getProjetsDownloadedSuccess, (state, action) => {
        return {
            ...state,
            getProjetsDownloaded: action.projets,
            getProjetsDownloadedLoading: false,
            getProjetsDownloadedSuccess: true,
            getProjetsDownloadedError: null
        };
    }),
    on(OfflineActions.getProjetsDownloadedError, (state, action) => {
        return {
            ...state,
            getProjetsDownloaded: null,
            getProjetsDownloadedLoading: false,
            getProjetsDownloadedSuccess: false,
            getProjetsDownloadedError: action.error
        };
    }),
    on(OfflineActions.getProjetsAuditDownloaded, (state) => {
        return {
            ...state,
            getProjetsAuditDownloaded: null,
            getProjetsAuditDownloadedLoading: true,
            getProjetsAuditDownloadedSuccess: false,
            getProjetsAuditDownloadedError: null
        };
    }),
    on(OfflineActions.getProjetsAuditDownloadedSuccess, (state, action) => {
        return {
            ...state,
            getProjetsAuditDownloaded: action.projetsAudit,
            getProjetsAuditDownloadedLoading: false,
            getProjetsAuditDownloadedSuccess: true,
            getProjetsAuditDownloadedError: null
        };
    }),
    on(OfflineActions.getProjetsAuditDownloadedError, (state, action) => {
        return {
            ...state,
            getProjetsAuditDownloaded: null,
            getProjetsAuditDownloadedLoading: false,
            getProjetsAuditDownloadedSuccess: false,
            getProjetsAuditDownloadedError: action.error
        };
    }),
    on(OfflineActions.addProjetToIndexedDb, (state) => {
        return {
            ...state,
            addProjetToIndexedDb: null,
            addProjetToIndexedDbLoading: true,
            addProjetToIndexedDbSuccess: false,
            addProjetToIndexedDbError: null
        };
    }),
    on(OfflineActions.addProjetToIndexedDbSuccess, (state, action) => {
        return {
            ...state,
            addProjetToIndexedDb: action.projet,
            addProjetToIndexedDbLoading: false,
            addProjetToIndexedDbSuccess: true,
            addProjetToIndexedDbError: null
        };
    }),
    on(OfflineActions.addProjetToIndexedDbError, (state, action) => {
        return {
            ...state,
            addProjetToIndexedDb: null,
            addProjetToIndexedDbLoading: false,
            addProjetToIndexedDbSuccess: false,
            addProjetToIndexedDbError: action.error
        };
    }),
    on(OfflineActions.addProjetAuditToIndexedDb, (state) => {
        return {
            ...state,
            addProjetAuditToIndexedDb: null,
            addProjetAuditToIndexedDbLoading: true,
            addProjetAuditToIndexedDbSuccess: false,
            addProjetAuditToIndexedDbError: null
        };
    }),
    on(OfflineActions.addProjetAuditToIndexedDbSuccess, (state, action) => {
        return {
            ...state,
            addProjetAuditToIndexedDb: action.projetAudit,
            addProjetAuditToIndexedDbLoading: false,
            addProjetAuditToIndexedDbSuccess: true,
            addProjetAuditToIndexedDbError: null
        };
    }),
    on(OfflineActions.addProjetAuditToIndexedDbError, (state, action) => {
        return {
            ...state,
            addProjetAuditToIndexedDb: null,
            addProjetAuditToIndexedDbLoading: false,
            addProjetAuditToIndexedDbSuccess: false,
            addProjetAuditToIndexedDbError: action.error
        };
    }),
    on(OfflineActions.createPointInspection, (state, action) => {
        return {
            ...state,
            createPointInspection: action.pointInspection,
            createPointInspectionSuccess: false,
            createPointInspectionError: null
        };
    }),
    on(OfflineActions.updateProjetToIndexedDbError, (state, action) => {
        return {
            ...state,
            updateProjetToIndexedDb: null,
            updateProjetToIndexedDbSuccess: false,
            updateProjetToIndexedDbError: action.error
        };
    }),
    on(OfflineActions.updateProjetAuditToIndexedDb, (state) => {
        return {
            ...state,
            updateProjetAuditToIndexedDb: null,
            updateProjetAuditToIndexedDbSuccess: false,
            updateProjetAuditToIndexedDbError: null
        };
    }),
    on(OfflineActions.updateProjetAuditToIndexedDbSuccess, (state, action) => {
        return {
            ...state,
            updateProjetAuditToIndexedDb: action.projetAudit,
            updateProjetAuditToIndexedDbSuccess: true,
            updateProjetAuditToIndexedDbError: null
        };
    }),
    on(OfflineActions.updateProjetAuditToIndexedDbError, (state, action) => {
        return {
            ...state,
            updateProjetAuditToIndexedDb: null,
            updateProjetAuditToIndexedDbSuccess: false,
            updateProjetAuditToIndexedDbError: action.error
        };
    }),
    on(OfflineActions.updateProjetToIndexedDb, (state) => {
        return {
            ...state,
            updateProjetToIndexedDb: null,
            updateProjetToIndexedDbSuccess: false,
            updateProjetToIndexedDbError: null
        };
    }),
    on(OfflineActions.updateProjetToIndexedDbSuccess, (state, action) => {
        return {
            ...state,
            updateProjetToIndexedDb: action.projet,
            updateProjetToIndexedDbSuccess: true,
            updateProjetToIndexedDbError: null
        };
    }),
    on(OfflineActions.updateProjetToIndexedDbError, (state, action) => {
        return {
            ...state,
            updateProjetToIndexedDb: null,
            updateProjetToIndexedDbSuccess: false,
            updateProjetToIndexedDbError: action.error
        };
    })
);

export function reducer(state: any, action: Action) {
    return offlineReducer(state, action);
}
