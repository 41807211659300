<div class="form-field-group p-field-group" [ngClass]="{
    'p-invalid': formControl.invalid && (formControl.dirty || formControl.touched),
    'p-field-disabled': formControl.disabled
}">
    <label *ngIf="label" [attr.for]="inputId">
        {{ label }}
        <span class="field-required" *ngIf="isRequired()">*</span>
        <app-form-info *ngIf="info" [message]="info"></app-form-info>
    </label>
    <app-form-field-error [control]="formControl"></app-form-field-error>
    <ng-content></ng-content>
</div>
