import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';

@Component({
    selector: 'pop-up-info-accordion-component',
    templateUrl: './pop-up-info-accordion.component.html',
    styleUrls: ['./pop-up-info-accordion.component.scss']
})
export class PopUpInfoAccordionComponent implements OnChanges {
    public disabledColor: string = '#d1d1d1';
    public isOpen: boolean = false;
    public displayContent: boolean = false;
    public displayChevrons: boolean = true;

    @Input() logo: string = '';
    @Input() logoColor: string = '#1b75bb';

    @Input() title: string = '';
    @Input() titleColor: string = '#1b75bb';
    @Input() titleTooltip: string = null;

    @Input() quantity: number | null = null;
    @Input() displayBorders: boolean = true;

    @Input() logoEnd: string = '';
    @Input() logoEndColor: string = '#1b75bb';
    @Input() quantityEnd: number | null = null;

    @Input() isAlwaysOpen: boolean = false;
    @Input() minHeight: boolean = false;

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.isAlwaysOpen) {
            this.setToOpen(changes.isAlwaysOpen.currentValue);
        }
    }

    private setToOpen(alwaysOpen: boolean | undefined) {
        this.displayChevrons = alwaysOpen ? !alwaysOpen : true;
        this.toggleHeader();
    }

    public toggleHeader() {
        if (this.isAlwaysOpen) {
            this.displayContent = true;
            this.isOpen = true;
        } else {
            this.isOpen = !this.isOpen;
            this.displayContent = !this.displayContent;
        }
    }
}
