import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { EquipementMineur } from '../../../../../features/anomalie/models/equipement-mineur.model';

@Component({
    selector: 'app-pop-up-info-equipements-mineurs',
    templateUrl: './pop-up-info-equipements-mineurs.component.html',
    styleUrls: ['./pop-up-info-equipements-mineurs.component.scss']
})
export class PopUpInfoEquipementsMineursComponent implements OnChanges {
    public data: { [name: string]: any } = [];

    @Input() equipementsMineurs: EquipementMineur;

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.equipementsMineurs && changes.equipementsMineurs) {
            this.setUpEquipmentMineur();
        }
    }

    private mappingData(value: any): string {
        return value ? 'Oui' : value === false ? 'Non' : '';
    }

    private getProperties(): string[] {
        return Object.keys(this.equipementsMineurs);
    }

    private prepareData() {
        this.getProperties().forEach(valueKey => {
            this.data[valueKey] = this.mappingData(this.equipementsMineurs[valueKey]);
        });
    }

    private setUpEquipmentMineur() {
        this.prepareData();
    }

}
