import { Injectable } from '@angular/core';
import { DialogService } from 'primeng/dynamicdialog';
import { SynchronisationDialogComponent } from '../components/synchronisation-dialog/synchronisation-dialog.component';
import { Observable, combineLatest, filter, take } from 'rxjs';
import { Requests } from '../models/requests.model';
import { StoreName } from '../../offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../offline/services/indexed-db.service';
import { ErreurSynchronisationService } from '../../../core/api/client/services';
import { SyncErrors } from '../models/sync-errors.model';
import * as AuditActions from '../../audit/state/audit.actions';
import * as InspectionActions from '../../inspection/state/inspection.actions';
import { State } from '../../../state/app.state';
import { Store } from '@ngrx/store';
import { getPointInspectionById, getProjetInspectionList } from '../../inspection/state/inspection.selectors';
import { getProjetsAuditDownloaded, getProjetsDownloaded } from '../../offline/state/offline.selectors';
import { PointInspectionDto, ProjetAuditDto, ProjetCompletDto } from '../../../core/api/client/models';
import { getProjetAuditList } from '../../audit/state/audit.selectors';
import { RapportErreurSynchronisationDialogComponent } from '../components/rapport-erreur-synchronisation-dialog/rapport-erreur-synchronisation-dialog.component';

@Injectable()
export class SynchronisationService {

    constructor(
        private store: Store<State>,
        private dialogService: DialogService,
        private indexedDbService: IndexedDbService,
        private erreurSynchronisationService: ErreurSynchronisationService,
    ) { }

    public getSyncErrors(): Observable<SyncErrors[]> {
        return this.indexedDbService.getAll<SyncErrors>(StoreName.SYNC_ERRORS);
    }

    public addSyncErrors(params: SyncErrors[]): Observable<number[]> {
        return this.indexedDbService.bulkAdd(StoreName.SYNC_ERRORS, params);
    }

    public deleteSyncErrors(): Observable<boolean> {
        return this.indexedDbService.clear(StoreName.SYNC_ERRORS);
    }

    public getRequests(): Observable<Requests[]> {
        return this.indexedDbService.getAll<Requests>(StoreName.REQUESTS);
    }

    public uploadRequests(params: ErreurSynchronisationService.PostApiV1ErreursynchronisationParams): Observable<null> {
        return this.erreurSynchronisationService.postApiV1Erreursynchronisation(params);
    }

    public deleteRequestFromStack(request: Requests) {
        return this.indexedDbService.delete(StoreName.REQUESTS, request.id);
    }

    public deleteRequests(): Observable<boolean> {
        return this.indexedDbService.clear(StoreName.REQUESTS);
    }

    public updateProjetsInspectionToIndexedDb(): ProjetCompletDto[] {
        const projetsInspectionList$ = this.store.select(getProjetInspectionList);
        const projetsInspectionDownloaded$ = this.store.select(getProjetsDownloaded);
        let projetsDownloaded;

        combineLatest([projetsInspectionList$, projetsInspectionDownloaded$])
            .pipe(
                take(1),
            ).subscribe(([projetsInspection, projetsInspectionDownloaded]) => {
                projetsDownloaded = projetsInspectionDownloaded;
                for (const projetInspection of projetsInspection) {
                    const filteredProjetInspection = projetsInspectionDownloaded.filter(projetDownloaded => projetDownloaded.id === projetInspection.id);
                    if (!!filteredProjetInspection.length) {
                        this.store.dispatch(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: projetInspection.id }));
                    }
                }
                this.store.dispatch(InspectionActions.getPointsActiveProjetInspection());
            });

        return projetsDownloaded;
    }

    public updateProjetsAuditToIndexedDb(): ProjetAuditDto[] {
        const projetAuditList$ = this.store.select(getProjetAuditList);
        const projetsAuditDownloaded$ = this.store.select(getProjetsAuditDownloaded);
        let projetsDownloaded;

        combineLatest([projetAuditList$, projetsAuditDownloaded$])
            .pipe(
                take(1),
            ).subscribe(([projetsAudit, projetsAuditDownloaded]) => {
                projetsDownloaded = projetsAuditDownloaded;
                for (const projetAudit of projetsAudit) {
                    const filteredProjetAudit = projetsAuditDownloaded.filter(projetDownloaded => projetDownloaded.id === projetAudit.id);
                    if (!!filteredProjetAudit.length) {
                        this.store.dispatch(AuditActions.updateProjetAuditToIndexedDb({ projetAuditId: projetAudit.id }));
                    }
                }
                this.store.dispatch(AuditActions.getPointsActiveProjetAudit());
            });

        return projetsDownloaded;
    }

    public openSynchronisationDialog() {
        this.dialogService.open(SynchronisationDialogComponent, {
            width: '40%',
            styleClass: 'min-height: 20%',
            closable: true
        });
    }

    public openRapportErreursSynchronisationDialog(requests: Requests[], projetId?: string, isAuditeur?: boolean) {
        this.dialogService.open(RapportErreurSynchronisationDialogComponent, {
            header: `Rapport d’erreurs de synchronisation`,
            width: '100%',
            height: '100%',
            modal: false,
            styleClass: 'mobile-dialog',
            data: {
                requests,
                projetId,
                isAuditeur
            }
        });
    }

    public getCodeABarres(pointInspectionId: string): string {
        let codeABarres;

        this.store.select(getPointInspectionById(pointInspectionId)).pipe(
            filter(success => !!success)
        ).subscribe((pointInspection: PointInspectionDto) => {
            codeABarres = pointInspection?.poteau.codeABarres;
        });

        return codeABarres;
    }
}
