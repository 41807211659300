/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { asyncScheduler, interval, of, throwError, timer } from 'rxjs';
import { catchError, concatMap, debounceTime, map, mergeMap, skipWhile, startWith, switchMap, takeUntil, withLatestFrom } from 'rxjs/operators';
import { getIsUserMobile } from '../../../core/store/selectors/user.selectors';
import { mapStyleConfig } from '../../../map/models/map-style-config.const';
import { PhotoService } from '../../../services/photo.service';
import { State } from '../../../state/app.state';
import { getIsAppOnline } from '../../../state/shared/shared.selectors';
import { AuditService } from '../services/audit.service';
import * as AuditActions from './audit.actions';
import * as InspectionActions from '../../inspection/state/inspection.actions';
import * as PhotosActions from '../../../shared/photos/state/photos.actions';
import * as OfflineActions from '../../offline/state/offline.actions';
import { getCurrentActiveProjetAudit } from './audit.selectors';

@Injectable()
export class AuditEffects {

    loadProjetAuditList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.loadProjetAuditList),
            withLatestFrom(this.store.select(getIsAppOnline)),
            mergeMap(([_, isAppOnline]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.loadProjetAuditList());
                } else {
                    return this.auditService.getProjetsAudit({ pageSize: 5000 }).pipe(
                        map((auditList) => AuditActions.loadProjetAuditListSuccess({ projetAuditList: auditList })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Liste des projets d'audit`,
                                        detail: `Une erreur est survenue lors de la récupération de la liste des projets d'audit.`
                                    }
                                );
                            }

                            return of(AuditActions.loadProjetAuditListError({ error }));
                        })
                    );
                }
            })
        );
    });

    loadProjetAuditHistory$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.loadProjetAuditHistory),
            mergeMap(action =>
                this.auditService.getProjetAuditHistory({}, action.projetAuditId)
                    .pipe(
                        map(auditHistory => AuditActions.loadProjetAuditHistorySuccess({ auditHistory })),
                        catchError((error: unknown) => of(AuditActions.loadProjetAuditHistoryError({ error })))
                    )));
    });

    startCreateProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.startCreateProjetAudit),
            mergeMap(action => this.auditService.createProjetAudit({ id: action.projetId, body: action.projetAudit })
                .pipe(
                    map(createdProjetAudit => AuditActions.startCreateProjetAuditSuccess({ createdProjetAudit })),
                    catchError((error: unknown) => of(AuditActions.startCreateProjetAuditError({ error })))
                )));
    });

    checkCreateProjetAuditCompleted$ = createEffect(() => ({ scheduler = asyncScheduler } = {}) => {
        return this.actions$.pipe(
            ofType(AuditActions.startCreateProjetAuditSuccess),
            //timeout 15min
            mergeMap(({ createdProjetAudit }) => timer(900000, scheduler).pipe(
                switchMap(() => throwError(() => ({ error: { erreurs: [`Le délais d'attente maximal est dépassé.`] } }))),
                startWith(null),
                switchMap(() => interval(5000, scheduler)),
                mergeMap(() => {
                    return this.auditService.getCreateStatus(createdProjetAudit.projetAudit.id);
                }),
                skipWhile(status => {
                    return status === createdProjetAudit.checksum;
                }),
                switchMap(() => {
                    return this.auditService.getProjetAudit(createdProjetAudit.projetAudit.id);
                }),
                map(projetAudit => {
                    return AuditActions.createProjetAuditSuccess({ createProjetAudit: projetAudit });
                })
            )),
            catchError((error: any) => {
                return of(AuditActions.createProjetAuditError({ error }));
            }),
            takeUntil(this.actions$.pipe(ofType(AuditActions.createProjetAuditSuccess)))
        );
    });

    assignProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.assignProjetAudit),
            mergeMap(action =>
                this.auditService.updateProjetAudit({ id: action.projetAuditId, body: action.assignData })
                    .pipe(
                        map(assignProjetAudit => AuditActions.assignProjetAuditSuccess({ assignProjetAudit })),
                        catchError((error: unknown) => of(AuditActions.assignProjetAuditError({ error })))
                    )));
    });

    activateProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.activateProjetAudit),
            mergeMap(action =>
                this.auditService.updateProjetAudit({ id: action.projetAuditId, body: action.activateData })
                    .pipe(
                        map(activateProjetAudit => AuditActions.activateProjetAuditSuccess({ activateProjetAudit })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Activation d'un projet d'audit`,
                                        detail: `${error.error.erreurs}` ?? `Une erreur est survenue lors de l'activation d'un projet.`
                                    }
                                );
                            }

                            return of(AuditActions.activateProjetAuditError({ error }));
                        })
                    )));
    });

    cancelProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.cancelProjetAudit),
            mergeMap(action =>
                this.auditService.updateProjetAudit({ id: action.projetAuditId, body: action.cancelData })
                    .pipe(
                        map(cancelProjetAudit => AuditActions.cancelProjetAuditSuccess({ cancelProjetAudit })),
                        catchError((error: unknown) => of(AuditActions.cancelProjetAuditError({ error })))
                    )));
    });

    rejectProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.rejectProjetAudit),
            mergeMap(action =>
                this.auditService.updateProjetAudit({ id: action.projetAuditId, body: action.rejectData })
                    .pipe(
                        map(projetAudit => AuditActions.rejectProjetAuditSuccess({ projetAudit })),
                        catchError((error: unknown) => of(AuditActions.rejectProjetAuditError({ error })))
                    )));
    });

    completeProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.completeProjetAudit),
            mergeMap(action =>
                this.auditService.updateProjetAudit({ id: action.projetAuditId, body: action.completeData })
                    .pipe(
                        map(projetAudit => AuditActions.completeProjetAuditSuccess({ projetAudit })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Compléter un projet d'audit`,
                                        detail: `Une erreur est survenue lors de la finalisation du projet d'audit.`
                                    }
                                );
                            }

                            return of(AuditActions.completeProjetAuditError({ error }));
                        })
                    )));
    });

    approveProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.approveProjetAudit),
            mergeMap(action =>
                this.auditService.updateProjetAudit({ id: action.projetAuditId, body: action.approveData })
                    .pipe(
                        map(projetAudit => AuditActions.approveProjetAuditSuccess({ projetAudit })),
                        catchError((error: unknown) => of(AuditActions.approveProjetAuditError({ error })))
                    )));
    });

    updatePointAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.updatePointAudit),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetAudit)
            ),
            concatMap(([action, isAppOnline, isUserMobile, currentActiveProjetAudit]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.updatePointAudit({ projetAudit: currentActiveProjetAudit, pointAudit: action.pointAudit }));
                } else {
                    return this.auditService.updatePointAudit({ pointAuditId: action.pointAudit.id, body: action.pointAudit })
                        .pipe(
                            mergeMap((pointAudit) => {
                                const actions: Action[] = [
                                    AuditActions.updatePointAuditSuccess({ pointAudit })
                                ];

                                if (isUserMobile) {
                                    actions.push(
                                        InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetAudit.projetId }),
                                        AuditActions.updateProjetAuditToIndexedDb({ projetAuditId: currentActiveProjetAudit.id })
                                    );
                                }

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Mise à jour de détails poteau`,
                                            detail: `Une erreur est survenue lors de la mise à jour du point d'audit.`
                                        }
                                    );
                                }

                                return of(AuditActions.updatePointAuditError({ error }));
                            })
                        );
                }
            })
        );
    });

    addPointAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.addPointAudit),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getCurrentActiveProjetAudit)
            ),
            mergeMap(([action, isAppOnline, currentActiveProjetAudit]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.addPointAudit(
                        {
                            projetAudit: currentActiveProjetAudit,
                            pointAudit: action.pointAudit
                        }));
                } else {
                    return this.auditService.addPointAudit(
                        {
                            id: action.pointAudit.projetAuditId,
                            pointInspectionId: action.pointAudit.pointInspectionId,
                            body: action.pointAudit
                        }
                    ).pipe(
                        mergeMap(() => {
                            return [
                                AuditActions.addPointAuditSuccess({ pointAudit: action.pointAudit }),
                                InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetAudit.projetId }),
                                AuditActions.updateProjetAuditToIndexedDb({ projetAuditId: currentActiveProjetAudit.id }),
                            ];
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Ajout d'un point d'audit`,
                                        detail: `Une erreur est survenue lors de l'ajout d'un point d'audit.`
                                    }
                                );
                            }

                            return of(AuditActions.addPointAuditError({ error }));
                        })
                    );
                }
            })
        );
    });

    createPointAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.createPointAudit),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getCurrentActiveProjetAudit)
            ),
            mergeMap(([action, isAppOnline, currentActiveProjetAudit]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.createPointAudit({ pointInspection: action.pointInspection }));
                } else {
                    return this.auditService.createPointAudit(action.pointInspection.pointsAudit[0]).pipe(
                        mergeMap((pointAudit) => {
                            return [
                                AuditActions.createPointAuditSuccess({ pointAudit }),
                                InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetAudit.projetId }),
                                AuditActions.updateProjetAuditToIndexedDb({ projetAuditId: currentActiveProjetAudit.id })
                            ];
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Création d'un point d'audit`,
                                        detail: `Une erreur est survenue lors de la création d'un point d'audit.`
                                    }
                                );
                            }

                            return of(AuditActions.createPointAuditError({ error }));
                        })
                    );
                }
            })
        );
    });

    addPointAuditPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.addPointAuditPhoto),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetAudit)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetAudit]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.addPointAuditPhoto({ pointAudit: action.pointAudit, photo: action.photo }));
                } else {
                    return this.auditService.addPointAuditPhoto(
                        {
                            pointAuditId: action.pointAudit.id,
                            Photo: this.photoService.blobToFile(action.photo.data, action.photo.nomOriginal),
                            NomOriginal: action.photo.nomOriginal,
                            Id: action.photo.id
                        }
                    ).pipe(
                        mergeMap((photo) => {
                            const actions: Action[] = [
                                AuditActions.addPointAuditPhotoSuccess({ pointAudit: action.pointAudit, photo }),
                                PhotosActions.loadUploadedPhoto({ photo: photo })
                            ];

                            if (isUserMobile) {
                                actions.push(AuditActions.updateProjetAuditToIndexedDb({ projetAuditId: currentActiveProjetAudit.id }));
                            }

                            return actions;
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Ajout de photo`,
                                        detail: `Une erreur est survenue lors de l'ajout de photo.`
                                    }
                                );
                            }

                            return of(AuditActions.addPointAuditPhotoError({ error }));
                        })
                    );
                }
            })
        );
    });

    addAnomalieAuditPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.addAnomalieAuditPhoto),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetAudit)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetAudit]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.addAnomalieAuditPhoto(
                        {
                            pointAudit: action.pointAudit,
                            anomalieAuditId: action.anomalieAuditId,
                            photo: action.photo
                        }
                    ));
                } else {
                    return this.auditService.addAnomalieAuditPhoto(
                        {
                            pointAuditId: action.pointAudit.id,
                            idAnomalieAudit: action.anomalieAuditId,
                            Photo: this.photoService.blobToFile(action.photo.data, action.photo.nomOriginal),
                            NomOriginal: action.photo.nomOriginal,
                            Id: action.photo.id
                        }
                    ).pipe(
                        mergeMap((photo) => {
                            const actions: Action[] = [
                                AuditActions.addAnomalieAuditPhotoSuccess({
                                    pointAuditId: action.pointAudit.id,
                                    anomalieAuditId: action.anomalieAuditId,
                                    photo
                                }),
                                PhotosActions.loadUploadedPhoto({ photo: photo })
                            ];

                            if (isUserMobile) {
                                actions.push(AuditActions.updateProjetAuditToIndexedDb({ projetAuditId: currentActiveProjetAudit.id }));
                            } else {
                                actions.push(AuditActions.getPointsAuditByProjetAuditId({ projetAuditId: action.pointAudit.projetAuditId }));
                            }

                            return actions;
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Ajout de photo`,
                                        detail: `Une erreur est survenue lors de l'ajout de photo.`
                                    }
                                );
                            }

                            return of(AuditActions.addAnomalieAuditPhotoError({ error }));
                        })
                    );
                }
            })
        );
    });

    deletePointAuditPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.deletePointAuditPhoto),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetAudit)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetAudit]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.deletePointAuditPhoto({ pointAudit: action.pointAudit, photo: action.photo }));
                } else {
                    return this.auditService.deletePointAuditPhoto({ pointAuditId: action.pointAudit.id, idPhoto: action.photo.id })
                        .pipe(
                            mergeMap(() => {
                                const actions: Action[] = [
                                    AuditActions.deletePointAuditPhotoSuccess({ pointAudit: action.pointAudit, photo: action.photo })
                                ];

                                if (isUserMobile) {
                                    actions.push(AuditActions.updateProjetAuditToIndexedDb({ projetAuditId: currentActiveProjetAudit.id }));
                                }

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Supression de photo`,
                                            detail: `Une erreur est survenue lors de la supression de photo.`
                                        }
                                    );
                                }

                                return of(AuditActions.deletePointAuditPhotoError({ error }));
                            })
                        );
                }
            })
        );
    });

    deleteAnomalieAuditPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.deleteAnomalieAuditPhoto),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetAudit)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetAudit]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.deleteAnomalieAuditPhoto(
                        {
                            pointAudit: action.pointAudit,
                            anomalieAuditId: action.anomalieAuditId,
                            photo: action.photo
                        }
                    ));
                } else {
                    return this.auditService.deleteAnomalieAuditPhoto(
                        {
                            pointAuditId: action.pointAudit.id,
                            idPhoto: action.photo.id,
                            idAnomalieAudit: action.anomalieAuditId
                        }
                    ).pipe(
                        mergeMap(() => {
                            const actions: Action[] = [
                                AuditActions.deleteAnomalieAuditPhotoSuccess({
                                    pointAudit: action.pointAudit,
                                    anomalieAuditId: action.anomalieAuditId,
                                    photo: action.photo
                                })
                            ];

                            if (isUserMobile) {
                                actions.push(AuditActions.updateProjetAuditToIndexedDb({ projetAuditId: currentActiveProjetAudit.id }));
                            } else {
                                actions.push(AuditActions.getPointsAuditByProjetAuditId({ projetAuditId: action.pointAudit.projetAuditId }));
                            }

                            return actions;
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Supression de photo`,
                                        detail: `Une erreur est survenue lors de la supression de photo.`
                                    }
                                );
                            }

                            return of(AuditActions.deleteAnomalieAuditPhotoError({ error }));
                        })
                    );
                }
            })
        );
    });

    loadPointsAuditBbox$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.loadPointsAuditBbox),
            withLatestFrom(this.store.select(getIsAppOnline)),
            debounceTime(200),
            switchMap(([action, isAppOnline]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.loadPointsAuditBbox({ bbox: action }));
                } else {
                    if (action.zoomLevel >= mapStyleConfig.poteau.minZoom) {
                        const { xmin, xmax, ymin, ymax } = action.bounds;
                        return this.auditService.getPointsAudit({ pageNumber: 1, pageSize: 5000, xmax, xmin, ymax, ymin })
                            .pipe(
                                map((pointsAudit) => AuditActions.loadPointsAuditBboxSuccess({ bbox: action, pointsAudit })),
                                catchError((error: unknown) => of(AuditActions.loadPointsAuditBboxError({ bbox: action, error })))
                            );
                    } else {
                        return [];
                    }
                }
            })
        );
    });

    getPointsActiveProjetAudit$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.getPointsActiveProjetAudit),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getCurrentActiveProjetAudit),
            ),
            switchMap(([_, isAppOnline, activeProjetAudit]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.getPointsActiveProjetAudit());
                } else {
                    return this.auditService.getProjetAudit(activeProjetAudit?.id).pipe(
                        map((projetAudit) => AuditActions.getPointsActiveProjetAuditSuccess({ pointsAudit: projetAudit.pointAudits! })),
                        catchError((error: unknown) => of(AuditActions.getPointsActiveProjetAuditError({ error })))
                    );
                }
            })
        );
    });

    getPointsAuditByProjetAuditId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.getPointsAuditByProjetAuditId),
            mergeMap((action) => {
                return this.auditService.getProjetAudit(action.projetAuditId).pipe(
                    map((projetAudit) => AuditActions.getPointsAuditByProjetAuditIdSuccess({ pointsAudit: projetAudit.pointAudits })),
                    catchError((error: unknown) => of(AuditActions.getPointsAuditByProjetAuditIdError({ error })))
                );
            })
        );
    });

    updateProjetAuditToIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AuditActions.updateProjetAuditToIndexedDb),
            mergeMap((action) => {
                return this.auditService.getProjetAudit(action.projetAuditId).pipe(
                    mergeMap(projetAudit => {
                        return [
                            AuditActions.getPointsActiveProjetAuditSuccess({ pointsAudit: projetAudit.pointAudits! }),
                            OfflineActions.updateProjetAuditToIndexedDb({ projetAudit })
                        ];
                    }),
                    catchError((error: unknown) => of(AuditActions.getPointsActiveProjetAuditError({ error })))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private auditService: AuditService,
        private messageService: MessageService,
        private photoService: PhotoService
    ) { }
}
