import { Injectable } from '@angular/core';
import {
    AnomalieAuditService
    , AnomalieService,
    InspectionService,
    PointsAuditService,
    ProjetAuditsService,
    ProjetService
} from '../../../core/api/client/services';
import { TaillePhoto } from '../models';
import { PhotoType } from '../models/photo-type.enum';

@Injectable({
    providedIn: 'root'
})
export class PhotosService {

    constructor(
        private projetService: ProjetService,
        private projetAuditService: ProjetAuditsService,
        private inspectionService: InspectionService,
        private anomalieService: AnomalieService,
        private pointAuditsService: PointsAuditService,
        private anomalieAuditService: AnomalieAuditService
    ) { }

    public getPointInspectionPhotosInfoByProjetId(projetId: string, PageNumber?: number, taille?: string) {
        const params: ProjetService.GetApiV1ProjetsIdPhotosParams = {
            id: projetId,
            PageNumber: PageNumber,
            type: PhotoType.INSPECTION,
            taille
        };
        return this.projetService.getApiV1ProjetsIdPhotos(params);
    }

    public getAnomaliesPhotosInfoByProjetId(projetId: string, PageNumber?: number, taille?: string) {
        const params: ProjetService.GetApiV1ProjetsIdPhotosParams = {
            id: projetId,
            PageNumber: PageNumber,
            type: PhotoType.ANOMALIE,
            taille
        };
        return this.projetService.getApiV1ProjetsIdPhotos(params);
    }

    public getPointAuditPhotosInfoByProjetAuditId(projetAuditId: string, PageNumber?: number, taille?: string) {
        const params: ProjetAuditsService.GetApiV1AuditsIdPhotosParams = {
            id: projetAuditId,
            PageNumber: PageNumber,
            type: PhotoType.POINT_AUDIT,
            taille
        };
        return this.projetAuditService.getApiV1AuditsIdPhotos(params);
    }

    public getAnomaliesAuditPhotosInfoByProjetAuditId(projetAuditId: string, PageNumber?: number, taille?: string) {
        const params: ProjetAuditsService.GetApiV1AuditsIdPhotosParams = {
            id: projetAuditId,
            PageNumber: PageNumber,
            type: PhotoType.ANOMALIE_AUDIT,
            taille
        };
        return this.projetAuditService.getApiV1AuditsIdPhotos(params);
    }

    public getPointInspectionPhotoById(inspectionId: string, photoId: string, size?: TaillePhoto) {
        const params: InspectionService.GetApiV1InspectionsInspectionIdPhotosIdentifiantPhotoParams = {
            inspectionId: inspectionId,
            identifiantPhoto: photoId
        };

        if (size) {
            params.taille = size;
        }

        return this.inspectionService.getApiV1InspectionsInspectionIdPhotosIdentifiantPhoto(params);
    }

    public getAnomaliePhotoById(anomalieId: string, photoId: string, size?: TaillePhoto) {
        const params: AnomalieService.GetApiV1AnomaliesAnomalieIdPhotosIdentifiantPhotoParams = {
            anomalieId: anomalieId,
            identifiantPhoto: photoId
        };

        if (size) {
            params.taille = size;
        }

        return this.anomalieService.getApiV1AnomaliesAnomalieIdPhotosIdentifiantPhoto(params);
    }

    public getPointAuditPhotoById(pointAuditId: string, photoId: string, size?: TaillePhoto) {
        const params: PointsAuditService.GetApiV1PointsauditPointAuditIdPhotosIdPhotoParams = {
            pointAuditId: pointAuditId,
            idPhoto: photoId
        };

        if (size) {
            params.taille = size;
        }

        return this.pointAuditsService.getApiV1PointsauditPointAuditIdPhotosIdPhoto(params);
    }

    public getAnomalieAuditPhotoById(anomalieAuditId: string, photoId: string, size?: TaillePhoto) {
        const params: AnomalieAuditService.GetApiV1AnomaliesauditIdPhotosIdPhotoParams = {
            id: anomalieAuditId,
            idPhoto: photoId
        };

        if (size) {
            params.taille = size;
        }

        return this.anomalieAuditService.getApiV1AnomaliesauditIdPhotosIdPhoto(params);
    }
}
