import { Observable } from 'rxjs';
import { PhotoEntity } from '../../core/api/client/models';
import { PhotoBackend } from '../models/photo-backend.model';
import { WorkBook, utils, write } from 'xlsx';
import { saveFile } from '../utils';

export function searchPhotoInArray(searchedPhoto: PhotoBackend, arrayPhotos: PhotoBackend[]) {
    // Comme le but est de savoir si la photo existe déjà, si on trouve on l'ajoute ici
    // Les propriétés à regarder dans l'ordre sont:
    //    id: Il y a un ID seulement une fois que la photo a été sync
    //    nom: Si le nom est différent du nom original, c'est qu'elle a été sync
    //    nomOriginal: le nomOriginal est une valeur obligatoire pour la synchro
    //    photo: La photo est également obligatoire pour la sync
    const foundPhoto: PhotoBackend[] = [];
    for (let index = 0; index < arrayPhotos.length; index++) {
        const aPhoto: PhotoBackend = arrayPhotos[index];

        // On cherche en premier par ID, car s'il y a un ID, c'est que ça a déjà été synchronisé.
        if (aPhoto.id) {
            if (searchedPhoto.id && aPhoto.id === searchedPhoto.id) {
                foundPhoto.push(aPhoto);
                break;
            }
        } else {
            if (aPhoto.nom) {
                // Il est possible que les noms soient identique si la photo n'a pas encore été sync
                if (aPhoto.nom !== aPhoto.nomOriginal) {
                    if (aPhoto.nom === searchedPhoto.nom) {
                        foundPhoto.push(aPhoto);
                        break;
                    }
                } else {
                    // Comme le nom et le nomOriginal sont identique, on va comparer la photo au format string
                    if (aPhoto.photo === searchedPhoto.photo) {
                        foundPhoto.push(aPhoto);
                        break;
                    }
                }
            } else {
                // Ici on sait que la photo n'a pas encore été sync
                // On compare les 2 noms originals car les photos pourraient avoir le même nom mais pas la même photo
                //  ex.: delete d'une photo avec le nom X et ajouter une photo avec le nom X mais c'est une photo différente,
                if (aPhoto.nomOriginal === searchedPhoto.nomOriginal) {
                    if (aPhoto.photo === searchedPhoto.photo) {
                        foundPhoto.push(aPhoto);
                        break;
                    }
                }
            }
        }
    }
    // On indique seulement que l'on a trouvé la photo de référence "searchedPhoto" dans la collection "arrayPhotos"
    return foundPhoto.length > 0;
}

export function getAddedPhotos(photos: PhotoEntity[], originalPhotos: PhotoEntity[], deletedPhotos: PhotoEntity[]) {
    const addedPhoto: PhotoEntity[] = [];
    // On regarde chacune des photos du array "photo"
    // Pour chacune des photos, on regarde si elle est dans le array de photo originale "originalPhotos" ou dans le array de photos supprimées "deletedPhotos
    // Si la photo n'est pas trouvé dans les photos originales ou les photos à supprimer, c'est qu'elle est une nouvelle photo
    photos?.forEach(photo => {
        if (!originalPhotos || (originalPhotos && !searchPhotoInArray(photo, originalPhotos) && !searchPhotoInArray(photo, deletedPhotos))) {
            addedPhoto.push(photo);
        }
    });

    // On retourne les nouvelles photos à ajouter.
    return addedPhoto;
}

export function getRemainingPhotos(photos: PhotoBackend[] | undefined, deletedPhotos: PhotoBackend[], newPhotos: PhotoBackend[]) {
    const remainingPhoto: PhotoBackend[] = [];
    // On regarde chacune des photos du array "photo"
    // Pour chacune des photos, on regarde si fait parti des photos supprimé "deletedPhoto" ou des nouvelles photos "newPhotos"
    // Si on ne trouve pas la photo c'est qu'elle existait et qu'elle reste associé.
    photos?.forEach((photo: PhotoBackend) => {
        if (!searchPhotoInArray(photo, deletedPhotos) && !searchPhotoInArray(photo, newPhotos)) {
            remainingPhoto.push(photo);
        }
    });

    // On retourne les photo restantes
    return remainingPhoto;
}

export function getDeletedPhotos(photos: PhotoEntity[], originalPhotos: PhotoEntity[]) {
    const photosToDelete: PhotoEntity[] = [];
    // On regarde chacune des photos originales.
    // Si on trouve la photo original dans le array de photos "photos", c'est qu'elle n'a pas été supprimée
    originalPhotos?.forEach((originalPhoto: PhotoBackend) => {
        if (photos && !searchPhotoInArray(originalPhoto, photos)) {
            photosToDelete.push(originalPhoto);
        }
    });

    // On retourne les photos à supprimer
    return photosToDelete;
}

// Fonction utile car quans on déconstruit un object, on ne peut pas avoir 2 fois la même constante
// Comme on a pas besoin des photos, on veut juste le reste.
export function removePhotoFromObject(object: any) {
    const { photos, ...autre } = object;
    return autre;
}

export function blobToBase64(blob: Blob): Observable<string> {
    return new Observable((observer) => {
        const reader = new FileReader();
        reader.onloadend = () => {
            observer.next(reader.result as string);
            observer.complete();
        };
        reader.onerror = (err) => observer.error(err);
        reader.readAsDataURL(blob);
    });
}

export function exportPhotosInErrorList(photos: PhotoEntity[]) {
    const worksheet = utils.json_to_sheet(photos);
    // eslint-disable-next-line @typescript-eslint/naming-convention
    const workbook: WorkBook = { Sheets: { 'liste des photos manquantes': worksheet }, SheetNames: ['liste des photos manquantes'] };
    const excelBuffer: any = write(workbook, { bookType: 'xlsx', type: 'array' });
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';

    saveFile(excelBuffer, 'Liste des photos manquantes', fileType, '.xlsx');

}
