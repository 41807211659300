export enum TaxonomieGroupeValue {
    AUDITEURS = 'auditeurs',
    CONTROLEUR_QUALITE = 'controleurQualite',
    EMPLOYES = 'employes',
    FIRME = 'firme',
    FIRME_AUDIT = `firmeAudit`,
    INSPECTEURS = 'inspecteurs',
};

export enum TaxonomieGroupe {
    AUDITEURS = 'auditeurs',
    CONTROLEUR_QUALITE = 'contrôleur qualité',
    EMPLOYES = 'employés',
    FIRME = 'firme',
    FIRME_AUDIT = `firme d'audit`,
    INSPECTEURS = 'inspecteurs',
};

export const taxonomieNames = new Map<TaxonomieGroupeValue, TaxonomieGroupe>([
    [TaxonomieGroupeValue.AUDITEURS, TaxonomieGroupe.AUDITEURS],
    [TaxonomieGroupeValue.CONTROLEUR_QUALITE, TaxonomieGroupe.CONTROLEUR_QUALITE],
    [TaxonomieGroupeValue.EMPLOYES, TaxonomieGroupe.EMPLOYES],
    [TaxonomieGroupeValue.FIRME, TaxonomieGroupe.FIRME],
    [TaxonomieGroupeValue.FIRME_AUDIT, TaxonomieGroupe.FIRME_AUDIT],
    [TaxonomieGroupeValue.INSPECTEURS, TaxonomieGroupe.INSPECTEURS],
]);
