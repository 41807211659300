<p-dialog [header]="headerText" [(visible)]="visible" [modal]=true [draggable]=false (onHide)="closeAssignDialog()">
    <div class="p-d-flex p-flex-column p-jc-center">
        <div class="p-mb-2 p-ai-end">{{ assignText }}
            <p-dropdown [options]="itemList" placeholder="Choisir un élément" [(ngModel)]="itemSelected"
                appendTo="body"></p-dropdown>
        </div>
    </div>
    <ng-template pTemplate="footer">
        <button pButton pRipple label="Annuler" icon="pi pi-times" class="p-button-text"
            (click)="closeAssignDialog()"></button>
        <button [disabled]="!itemSelected" class="p-button-text" pButton pRipple icon="pi pi-check" label="Enregistrer"
            (click)="onAssign()"></button>
    </ng-template>
</p-dialog>
