import { Injectable } from '@angular/core';
import { Observable, of, zip } from 'rxjs';
import { tap, map, mergeMap, switchMap } from 'rxjs/operators';
import { StoreName } from '../models/indexed-db-store-name.enum';
import { Projet } from '../../projet/models/projet.model';
import { IndexedDbService } from './indexed-db.service';
import { RequestType } from '../../synchronisation/models/request-type.enum';
import { UtilisateurService } from '../../../services/utilisateur.service';
import { TypeObjetSync } from '../../synchronisation/models/type-objet-sync.enum';
import { Requests } from '../../synchronisation/models/requests.model';
import { LocalStorageIndex } from '../../../shared/enums/local-storage-index.enum';
import { EsriAccessToken } from '../../../shared/models/esri-access-token.model';
import { AuditService } from '../../audit/services/audit.service';
import { ProjetService } from '../../projet/services/projet.service';
import { filterDeletedAnomalies } from '../../inspection/state/inspection.selectors';
import { State } from '../../../state/app.state';
import { Store } from '@ngrx/store';
import { ExtendedInspectionPhoto } from '../../../shared/models/extended-inspection-photo.model';
import { ExtendedAuditPhoto } from '../../../shared/models/extended-audit-photo.model';
import { min } from 'lodash';
import { StatutPointInspection, StatutPointInspectionNumberValue } from '../../inspection/models/statut-point-inspection.enum';
import { getAnomaliePriorite, getStatutPointInspection } from '../../../shared/utils';
import {
    AnomalieBaseDto,
    AnomaliePilotageDto,
    IncludePointAuditDto,
    PhotoEntity,
    PointAuditDto,
    PointInspectionDto,
    ProjetAuditDto,
    ProjetCompletDto
} from '../../../core/api/client/models';

@Injectable()
export class OfflineService {

    private offlineMode = false;

    public get isOfflineMode(): boolean {
        const navigatorOnline = navigator.onLine;
        if (!navigatorOnline && this.utilisateurService.isUserMobile) {
            //&& (!!this.indexedDbService.projetTelecharge || !!this.indexedDbService.projetAuditTelecharge)) {  TODO ANALYSE THIS
            this.offlineMode = true;
        }
        return this.offlineMode;
    }

    constructor(
        private store: Store<State>,
        private indexedDbService: IndexedDbService,
        private auditService: AuditService,
        private projetService: ProjetService,
        private utilisateurService: UtilisateurService
    ) {
        zip(
            this.indexedDbService.getAll<Projet>(StoreName.PROJETS),
            this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT),
            this.indexedDbService.getAll<Requests>(StoreName.REQUESTS),
        ).pipe(tap(results => {
            const projets = results[0];
            const projetsAudit = results[1];
            const requests = results[2];
            this.offlineMode = ((requests?.length || 0) > 0) && this.utilisateurService.isUserMobile && (!!projets[0] || !!projetsAudit[0]);
        })).subscribe();
    }

    public getProjetInspection(projetId: string): Observable<ProjetCompletDto> {
        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS)
            .pipe(
                map(projets => projets.find(projet => projet.id === projetId))
            );
    }

    public getProjetsAudit(): Observable<ProjetAuditDto[]> {
        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT);
    }

    public getProjetAudit(projetAuditId: string): Observable<ProjetAuditDto> {
        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT)
            .pipe(
                map(projets => projets.find(projet => projet.id === projetAuditId))
            );
    }

    public getPointsAudit(projetAuditId: string): Observable<PointAuditDto[]> {
        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT)
            .pipe(
                map(projets => projets.find(projet => projet.id === projetAuditId)?.pointAudits || [])
            );
    }

    public getPointsInspection(projetInspectionId: string): Observable<PointInspectionDto[]> {
        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS)
            .pipe(
                map(projets => projets.find(projet => projet.id === projetInspectionId)?.pointInspections || [])
            );
    }

    public updateProjetToIndexedDb(projet: ProjetCompletDto): Observable<ProjetCompletDto[]> {
        return this.updateIndexedDb(StoreName.PROJETS, projet);
    }

    public updateProjetAuditToIndexedDb(projetAudit: ProjetAuditDto): Observable<ProjetAuditDto[]> {
        return this.updateIndexedDb(StoreName.PROJETS_AUDIT, projetAudit);
    }

    public updatePointInspection(pointInspection: PointInspectionDto, poteauAction?: string): Observable<PointInspectionDto> {
        const request: Requests = {
            type: RequestType.PUT,
            route: poteauAction ? `/inspections/${pointInspection.id}?action=${poteauAction}` : `/inspections/${pointInspection.id}`,
            body: pointInspection,
            pointInspection: pointInspection
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.updatePointInspectionIndexedDb(pointInspection);
    }

    public updatePointInspectionIndexedDb(pointInspection: PointInspectionDto): Observable<PointInspectionDto> {

        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS)
            .pipe(
                mergeMap((projets) => {
                    const updatedProjetInspection = projets.find(projet => projet.id === pointInspection.projetId);
                    const index = updatedProjetInspection.pointInspections.findIndex(point => point.id === pointInspection.id);
                    updatedProjetInspection.pointInspections![index] = pointInspection;

                    return this.updateIndexedDb(StoreName.PROJETS, updatedProjetInspection);
                }),
                map(() => {
                    return pointInspection;
                })
            );
    }

    public updatePointAuditToProjetIndexedDb(projetAudit: ProjetAuditDto, pointAudit: PointAuditDto): Observable<ProjetCompletDto> {
        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS).pipe(
            mergeMap((projets) => {
                const updatedProjet = projets.find(projet => projet.id === projetAudit.projetId);
                const index = updatedProjet.pointInspections.findIndex(point => point.id === pointAudit.pointInspectionId);
                const indexPointAudit = updatedProjet.pointInspections![index].pointsAudit.findIndex(point => point.id === pointAudit.id);

                updatedProjet.pointInspections![index].pointsAudit[indexPointAudit] = pointAudit;

                return this.updateIndexedDb(StoreName.PROJETS, updatedProjet);
            }),
            map((projet) => {
                return projet;
            })
        );
    }

    public updatePointAudit(projetAudit: ProjetAuditDto, pointAudit: PointAuditDto): Observable<PointAuditDto> {
        const request: Requests = {
            type: RequestType.PUT,
            route: `/pointsaudit/${pointAudit.id}`,
            body: pointAudit,
            pointAudit: pointAudit
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        this.updatePointAuditToProjetIndexedDb(projetAudit, pointAudit).subscribe();

        return this.updatePointAuditIndexedDb(pointAudit);
    }

    public updatePointAuditIndexedDb(pointAudit: PointAuditDto): Observable<PointAuditDto> {

        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT)
            .pipe(
                mergeMap((projets) => {
                    const updatedProjetAudit = projets.find(projet => projet.id === pointAudit.projetAuditId);
                    const index = updatedProjetAudit.pointAudits.findIndex(point => point.id === pointAudit.id);
                    updatedProjetAudit.pointAudits![index] = pointAudit;

                    return this.updateIndexedDb(StoreName.PROJETS_AUDIT, updatedProjetAudit);
                }),
                map(() => {
                    return pointAudit;
                })
            );
    }

    public createPointInspection(pointInspection: PointInspectionDto): Observable<PointInspectionDto> {
        const request: Requests = {
            type: RequestType.POST,
            route: `/projets/${pointInspection.projetId}/inspections`,
            body: pointInspection,
            pointInspection: pointInspection
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.createPointToProjetIndexedDb(pointInspection);
    }

    public createPointAudit(pointInspection: PointInspectionDto): Observable<PointAuditDto> {
        const request: Requests = {
            type: RequestType.POST,
            route: `/pointsaudit`,
            body: pointInspection.pointsAudit[0],
            pointAudit: pointInspection.pointsAudit[0]
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        this.createPointToProjetIndexedDb(pointInspection).subscribe();

        return this.addPointAuditToProjetAuditIndexedDb(pointInspection.pointsAudit[0]);
    }

    public createPointToProjetIndexedDb(pointInspection: PointInspectionDto): Observable<PointInspectionDto> {

        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS)
            .pipe(
                mergeMap((projets) => {
                    const updatedProjet = projets.find(projet => projet.id === pointInspection.projetId);
                    updatedProjet.pointInspections.push(pointInspection);
                    updatedProjet.nombrePoteauxNonInspectes++;
                    updatedProjet.nombreTotalPoteaux++;

                    return this.updateIndexedDb(StoreName.PROJETS, updatedProjet);
                }),
                map(() => {
                    return pointInspection;
                })
            );
    }

    public createAnomalieInspection(
        pointInspection: PointInspectionDto,
        anomalie: AnomalieBaseDto,
        anomaliePilotage: AnomaliePilotageDto[]
    ): Observable<AnomalieBaseDto> {

        const request: Requests = {
            type: RequestType.PUT,
            route: `/projets/${pointInspection.projetId}/inspection/${pointInspection.id}/anomalies`,
            body: anomalie,
            pointInspection: pointInspection
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.createAnomalieInspectionIndexedDb(pointInspection, anomalie, anomaliePilotage);
    }

    public createAnomalieInspectionIndexedDb(
        pointInspection: PointInspectionDto,
        anomalie: AnomalieBaseDto,
        anomaliePilotage: AnomaliePilotageDto[]
    ): Observable<AnomalieBaseDto> {

        let updatedAnomalie: AnomalieBaseDto;

        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS)
            .pipe(
                mergeMap((projets) => {

                    updatedAnomalie = {
                        ...anomalie,
                        dateTransfertSap: 0,
                        priorite: getAnomaliePriorite(anomalie, anomaliePilotage),
                        inspectionId: pointInspection.id
                    };

                    const updatedProjetInspection = projets.find(projet => projet.id === pointInspection.projetId);
                    const index = updatedProjetInspection.pointInspections.findIndex(point => point.id === pointInspection.id);
                    updatedProjetInspection.pointInspections[index].anomalies.push(updatedAnomalie);

                    updatedProjetInspection.pointInspections[index].statut = getStatutPointInspection(updatedProjetInspection, index);

                    return this.updateIndexedDb(StoreName.PROJETS, updatedProjetInspection);
                }),
                map(() => {
                    return updatedAnomalie;
                })
            );
    }

    public updateAnomalieInspection(
        pointInspection: PointInspectionDto,
        anomalie: AnomalieBaseDto,
        anomaliePilotage: AnomaliePilotageDto[]
    ): Observable<AnomalieBaseDto> {
        const request: Requests = {
            type: RequestType.PUT,
            route: `/projets/${pointInspection.projetId}/inspection/${pointInspection.id}/anomalies`,
            body: anomalie,
            pointInspection: pointInspection
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.updateAnomalieInspectionIndexedDb(pointInspection, anomalie, anomaliePilotage);
    }

    public updateAnomalieInspectionIndexedDb(
        pointInspection: PointInspectionDto,
        anomalie: AnomalieBaseDto,
        anomaliePilotage: AnomaliePilotageDto[]): Observable<AnomalieBaseDto> {

        let updatedAnomalie: AnomalieBaseDto;

        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS)
            .pipe(
                mergeMap((projets) => {

                    updatedAnomalie = {
                        ...anomalie,
                        priorite: getAnomaliePriorite(anomalie, anomaliePilotage),
                        inspectionId: pointInspection.id
                    };

                    const updatedProjetInspection = projets.find(projet => projet.id === pointInspection.projetId);
                    const index = updatedProjetInspection.pointInspections.findIndex(point => point.id === pointInspection.id);
                    const indexAnomalie = updatedProjetInspection.pointInspections[index].anomalies
                        .findIndex(currentAnomalie => currentAnomalie.id === updatedAnomalie.id);

                    updatedProjetInspection.pointInspections[index].anomalies[indexAnomalie] = updatedAnomalie;

                    updatedProjetInspection.pointInspections[index].statut = getStatutPointInspection(updatedProjetInspection, index);

                    return this.updateIndexedDb(StoreName.PROJETS, updatedProjetInspection);
                }),
                map(() => {
                    return updatedAnomalie;
                })
            );
    }

    public addPointAudit(projetAudit: ProjetAuditDto, pointAudit: PointAuditDto): Observable<PointAuditDto> {

        let body: IncludePointAuditDto = { id: pointAudit.id };

        if (pointAudit.anomaliesAudit.length) {
            const nouveauxId = pointAudit.anomaliesAudit.reduce((obj: { [key: string]: string }, anomalie) => {
                return { ...obj, [anomalie.anomalieInspectionId]: anomalie.id };
            }, {});

            body = { ...body, nouveauxId };

        }
        const request: Requests = {
            type: RequestType.POST,
            route: `/audits/${pointAudit.projetAuditId}/inspections/${pointAudit.pointInspectionId}`,
            body
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        this.addPointAuditToProjetIndexedDb(projetAudit, pointAudit).subscribe();

        return this.addPointAuditToProjetAuditIndexedDb(pointAudit);
    }

    public addPointAuditToProjetAuditIndexedDb(pointAudit: PointAuditDto): Observable<PointAuditDto> {

        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT)
            .pipe(
                mergeMap((projets) => {
                    const updatedProjetAudit = projets.find(projet => projet.id === pointAudit.projetAuditId);
                    updatedProjetAudit.pointAudits.push(pointAudit);
                    updatedProjetAudit.nombreTotalDePoteaux++;

                    return this.updateIndexedDb(StoreName.PROJETS_AUDIT, updatedProjetAudit);
                }),
                map(() => {
                    return pointAudit;
                })
            );
    }

    public addPointAuditToProjetIndexedDb(projetAudit: ProjetAuditDto, pointAudit: PointAuditDto): Observable<ProjetCompletDto> {

        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS)
            .pipe(
                mergeMap((projets) => {
                    const updatedProjet = projets.find(projet => projet.id === projetAudit.projetId);
                    const index = updatedProjet.pointInspections.findIndex(point => point.id === pointAudit.pointInspectionId);
                    updatedProjet.pointInspections![index].pointsAudit!.push(pointAudit);

                    return this.updateIndexedDb(StoreName.PROJETS, updatedProjet);
                }),
                map((projet) => {
                    return projet;
                })
            );
    }

    public addPointAuditPhoto(pointAudit: PointAuditDto, photo: PhotoEntity, photoFile: File): Observable<PointAuditDto> {
        const request: Requests = {
            type: RequestType.POST,
            route: `/pointsaudit/${pointAudit.id}/photos`,
            body: { photo: photoFile, nomOriginal: photo.nomOriginal, id: photo.id },
            pointAudit: pointAudit,
            typeObjet: TypeObjetSync.PHOTO
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.addPointAuditPhotoIndexedDb(pointAudit, photo);
    }

    public addPointAuditPhotoIndexedDb(pointAudit: PointAuditDto, photo: PhotoEntity): Observable<PointAuditDto> {

        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT)
            .pipe(
                mergeMap((projets) => {
                    const updatedProjetAudit = projets.find(projet => projet.id === pointAudit.projetAuditId);
                    const index = updatedProjetAudit.pointAudits.findIndex(point => point.id === pointAudit.id);

                    const pointAuditPhoto = [...updatedProjetAudit.pointAudits![index].photos];
                    pointAuditPhoto.push(photo);

                    const updatedPointAudit: PointAuditDto = {
                        ...pointAudit,
                        photos: pointAuditPhoto
                    };

                    updatedProjetAudit.pointAudits[index] = updatedPointAudit;

                    return this.updateIndexedDb(StoreName.PROJETS_AUDIT, updatedProjetAudit);

                }),
                map(() => {
                    return pointAudit;
                })
            );
    }

    public addPointInspectionPhoto(pointInspection: PointInspectionDto, photoEntity: PhotoEntity, photoFile: File): Observable<PointInspectionDto> {
        const request: Requests = {
            type: RequestType.POST,
            route: `/inspections/${pointInspection.id}/photos`,
            body: { photo: photoFile, nomOriginal: photoEntity.nomOriginal, id: photoEntity.id },
            pointInspection: pointInspection,
            typeObjet: TypeObjetSync.PHOTO
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return of(pointInspection);
    }

    public addAnomalieAuditPhoto(pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity, photoFile: File): Observable<PointAuditDto> {
        const request: Requests = {
            type: RequestType.POST,
            route: `/pointsaudit/${pointAudit.id}/anomalies/${anomalieAuditId}/photos`,
            body: { photo: photoFile, nomOriginal: photo.nomOriginal, id: photo.id },
            pointAudit: pointAudit,
            typeObjet: TypeObjetSync.PHOTO
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.addAnomalieAuditPhotoIndexedDb(pointAudit, anomalieAuditId, photo);
    }

    public addAnomalieAuditPhotoIndexedDb(pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity): Observable<PointAuditDto> {

        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT)
            .pipe(
                mergeMap((projets) => {
                    const updatedProjetAudit = projets.find(projet => projet.id === pointAudit.projetAuditId);
                    const index = updatedProjetAudit.pointAudits.findIndex(point => point.id === pointAudit.id);
                    const indexAnomalie = updatedProjetAudit.pointAudits[index].anomaliesAudit.findIndex(anomalie => anomalie.id === anomalieAuditId);

                    const anomalieAuditPhoto = [...updatedProjetAudit.pointAudits![index].anomaliesAudit[indexAnomalie].photos];
                    anomalieAuditPhoto.push(photo);

                    const updatedPointAudit: PointAuditDto = {
                        ...pointAudit,
                        anomaliesAudit: pointAudit.anomaliesAudit.map(anomalie => anomalie.id === anomalieAuditId ? {
                            ...anomalie,
                            photos: anomalieAuditPhoto
                        } : anomalie)
                    };

                    updatedProjetAudit.pointAudits[index] = updatedPointAudit;

                    return this.updateIndexedDb(StoreName.PROJETS_AUDIT, updatedProjetAudit);

                }),
                map(() => {
                    return pointAudit;
                })
            );
    }

    public addAnomalieInspectionPhoto(
        pointInspection: PointInspectionDto,
        anomalieId: string,
        photo: PhotoEntity,
        photoFile: File
    ): Observable<PointInspectionDto> {
        const request: Requests = {
            type: RequestType.POST,
            route: `/anomalies/${anomalieId}/photos`,
            body: { id: photo.id, nomOriginal: photo.nomOriginal, photo: photoFile },
            pointInspection: pointInspection,
            typeObjet: TypeObjetSync.PHOTO
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return of(pointInspection);
    }

    public deletePointAuditPhoto(pointAudit: PointAuditDto, photo: PhotoEntity): Observable<PointAuditDto> {
        const request: Requests = {
            type: RequestType.DELETE,
            route: `/pointsaudit/${pointAudit.id}/photos/${photo.id}`,
            pointAudit: pointAudit,
            body: photo,
            typeObjet: TypeObjetSync.PHOTO
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.deletePointAuditPhotoIndexedDb(pointAudit, photo.id);
    }

    public deletePointAuditPhotoIndexedDb(pointAudit: PointAuditDto, photoId: string): Observable<PointAuditDto> {

        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT).pipe(
            mergeMap(projets => {
                const updatedProjetAudit = projets.find(projet => projet.id === pointAudit.projetAuditId);
                const index = updatedProjetAudit.pointAudits.findIndex(point => point.id === pointAudit.id);
                const updatedPhotos = updatedProjetAudit.pointAudits![index].photos.filter(photo => photo.id !== photoId);

                const updatedPointAudit: PointAuditDto = {
                    ...pointAudit,
                    photos: updatedPhotos
                };

                updatedProjetAudit.pointAudits![index] = updatedPointAudit;

                return this.updateIndexedDb(StoreName.PROJETS_AUDIT, updatedProjetAudit);
            }),
            map(() => {
                return pointAudit;
            })
        );
    }

    public deletePointInspectionPhoto(pointInspection: PointInspectionDto, photo: PhotoEntity): Observable<PointInspectionDto> {
        const request: Requests = {
            type: RequestType.DELETE,
            route: `/inspections/${pointInspection.id}/photos/${photo.nom}`,
            pointInspection: pointInspection,
            body: photo,
            typeObjet: TypeObjetSync.PHOTO
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.deletePointInspectionPhotoIndexedDb(pointInspection, photo.id);
    }

    public deletePointInspectionPhotoIndexedDb(pointInspection: PointInspectionDto, photoId: string): Observable<PointInspectionDto> {

        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS).pipe(
            mergeMap(projets => {
                const updatedProjetInspection = projets.find(projet => projet.id === pointInspection.projetId);
                const index = updatedProjetInspection.pointInspections.findIndex(point => point.id === pointInspection.id);
                const updatedPhotos = updatedProjetInspection.pointInspections![index].photos.filter(photo => photo.id !== photoId);

                const updatedPointInspection: PointInspectionDto = {
                    ...pointInspection,
                    photos: updatedPhotos
                };

                updatedProjetInspection.pointInspections![index] = updatedPointInspection;

                return this.updateIndexedDb(StoreName.PROJETS, updatedProjetInspection);
            }),
            map(() => {
                return pointInspection;
            })
        );
    }

    public deleteAnomalieAuditPhoto(pointAudit: PointAuditDto, anomalieAuditId: string, photo: PhotoEntity): Observable<PointAuditDto> {
        const request: Requests = {
            type: RequestType.DELETE,
            route: `/pointsaudit/${pointAudit.id}/anomalies/${anomalieAuditId}/photos/${photo.id}`,
            pointAudit: pointAudit,
            body: photo,
            typeObjet: TypeObjetSync.PHOTO
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.deleteAnomalieAuditPhotoIndexedDb(pointAudit, anomalieAuditId, photo.id);
    }

    public deleteAnomalieAuditPhotoIndexedDb(pointAudit: PointAuditDto, idAnomalieAudit: string, photoId: string): Observable<PointAuditDto> {

        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT).pipe(
            mergeMap(projets => {
                const updatedProjetAudit = projets.find(projet => projet.id === pointAudit.projetAuditId);
                const index = updatedProjetAudit.pointAudits.findIndex(point => point.id === pointAudit.id);
                const indexAnomalie = updatedProjetAudit.pointAudits[index].anomaliesAudit.findIndex(anomalie => anomalie.id === idAnomalieAudit);
                const updatedAnomaliePhotos = updatedProjetAudit.pointAudits![index].anomaliesAudit[indexAnomalie].photos.
                    filter(photo => photo.id !== photoId);

                const updatedPointAudit: PointAuditDto = {
                    ...pointAudit,
                    anomaliesAudit: pointAudit.anomaliesAudit.map(anomalie => anomalie.id === idAnomalieAudit ? {
                        ...anomalie,
                        photos: updatedAnomaliePhotos
                    } : anomalie)
                };

                updatedProjetAudit.pointAudits![index] = updatedPointAudit;

                return this.updateIndexedDb(StoreName.PROJETS_AUDIT, updatedProjetAudit);
            }),
            map(() => {
                return pointAudit;
            })
        );
    }

    public deleteAnomalieInspectionPhoto(pointInspection: PointInspectionDto, anomalieId: string, photo: PhotoEntity): Observable<PointInspectionDto> {
        const request: Requests = {
            type: RequestType.DELETE,
            route: `/anomalies/${anomalieId}/photos/${photo.nom}`,
            pointInspection: pointInspection,
            body: photo,
            typeObjet: TypeObjetSync.PHOTO
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.deleteAnomalieInspectionPhotoIndexedDb(pointInspection, anomalieId, photo.id);
    }

    public deleteAnomalieInspectionPhotoIndexedDb(pointInspection: PointInspectionDto, anomalieId: string, photoId: string): Observable<PointInspectionDto> {

        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS).pipe(
            mergeMap(projets => {
                const updatedProjetInspection = projets.find(projet => projet.id === pointInspection.projetId);
                const index = updatedProjetInspection.pointInspections.findIndex(point => point.id === pointInspection.id);
                const indexAnomalie = updatedProjetInspection.pointInspections[index].anomalies.findIndex(anomalie => anomalie.id === anomalieId);
                const updatedAnomaliePhotos = updatedProjetInspection.pointInspections![index].anomalies[indexAnomalie].photos.
                    filter(photo => photo.id !== photoId);

                const updatedPointInspection: PointInspectionDto = {
                    ...pointInspection,
                    anomalies: pointInspection.anomalies.map(anomalie => anomalie.id === anomalieId ? {
                        ...anomalie,
                        photos: updatedAnomaliePhotos
                    } : anomalie)
                };

                updatedProjetInspection.pointInspections![index] = updatedPointInspection;

                return this.updateIndexedDb(StoreName.PROJETS, updatedProjetInspection);
            }),
            map(() => {
                return pointInspection;
            })
        );
    }

    public deleteAnomalieInspection(pointInspection: PointInspectionDto, anomalieId: string): Observable<PointInspectionDto> {
        const request: Requests = {
            type: RequestType.DELETE,
            route: `/anomalies/${anomalieId}`,
            pointInspection: pointInspection
        };

        this.addToIndexedDb(StoreName.REQUESTS, request).subscribe();

        return this.deleteAnomalieInspectionIndexedDb(pointInspection, anomalieId);
    }

    public deleteAnomalieInspectionIndexedDb(pointInspection: PointInspectionDto, anomalieId: string): Observable<PointInspectionDto> {

        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS).pipe(
            mergeMap(projets => {

                const updatedProjetInspection = projets.find(projet => projet.id === pointInspection.projetId);
                const index = updatedProjetInspection.pointInspections.findIndex(point => point.id === pointInspection.id);
                const updatedAnomalies = updatedProjetInspection.pointInspections![index].anomalies.filter(anomalie => anomalie.id !== anomalieId);
                let updatedStatut: string;

                if (updatedAnomalies.length) {
                    const hasUrgence = updatedAnomalies.find(anomalie => anomalie.urgence);
                    const statutPriorite: number = min(updatedAnomalies.map(anomalie => StatutPointInspectionNumberValue[anomalie.priorite]));
                    updatedStatut = hasUrgence ? StatutPointInspection.urgence : StatutPointInspectionNumberValue[statutPriorite];
                } else {
                    updatedStatut = StatutPointInspection.nonInspecte;
                }

                const updatedPointInspection: PointInspectionDto = {
                    ...pointInspection,
                    anomalies: updatedAnomalies,
                    statut: updatedStatut
                };

                updatedProjetInspection.pointInspections![index] = updatedPointInspection;

                return this.updateIndexedDb(StoreName.PROJETS, updatedProjetInspection);
            }),
            map(() => {
                return pointInspection;
            })
        );
    }

    public addProjetToIndexedDb(projetInspectionId: string): Observable<ProjetCompletDto> {
        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS)
            .pipe(
                mergeMap(projets => {
                    if (!projets.find(projet => projet.id === projetInspectionId)) {
                        return this.projetService.getProjet({ id: projetInspectionId, expandEquipement: true })
                            .pipe(
                                switchMap(projet => this.store.select(filterDeletedAnomalies(projet.pointInspections))
                                    .pipe(map(pointInspections => ({ ...projet, pointInspections })))
                                ),
                                mergeMap(currentProjet => this.addToIndexedDb(StoreName.PROJETS, currentProjet)));
                    } else {
                        return of(null);
                    }
                })
            );
    }

    public addProjetAuditToIndexedDb(projetAudit: ProjetAuditDto): Observable<ProjetAuditDto> {
        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT)
            .pipe(
                mergeMap(projets => {
                    if (!projets.find(projet => projet.id === projetAudit.id)) {
                        return this.auditService.getProjetAudit(projetAudit.id)
                            .pipe(
                                mergeMap(currentProjetAudit => this.addToIndexedDb(StoreName.PROJETS_AUDIT, currentProjetAudit))
                            );
                    } else {
                        return of(null);
                    }
                })
            );
    }

    public deleteInspectionPhotosFromIndexedDb(projetId: string): Observable<number[]> {
        const photoIdsToDelete: string[] = [];
        return this.indexedDbService.getAll<ExtendedInspectionPhoto>(StoreName.PHOTOS)
            .pipe(
                mergeMap(photos => {
                    photos.forEach((photo) => {
                        if (photo.projetId === projetId) {
                            photoIdsToDelete.push(photo.id);
                        }
                    });

                    return this.indexedDbService.bulkDelete(StoreName.PHOTOS, photoIdsToDelete);
                })
            );
    }

    public deleteAuditPhotosFromIndexedDb(projetAuditId: string): Observable<number[]> {
        const photoIdsToDelete: string[] = [];
        return this.indexedDbService.getAll<ExtendedAuditPhoto>(StoreName.PHOTOS)
            .pipe(
                mergeMap(photos => {
                    photos.forEach((photo) => {
                        if (photo.projetAuditId === projetAuditId) {
                            photoIdsToDelete.push(photo.id);
                        }
                    });

                    return this.indexedDbService.bulkDelete(StoreName.PHOTOS, photoIdsToDelete);
                })
            );
    }

    public deleteProjetFromIndexedDb(storeName: StoreName, projetId: string): Observable<boolean> {
        return this.indexedDbService.deleteByKey(storeName, projetId);
    }

    public getProjetsDownloaded(): Observable<ProjetCompletDto[]> {
        return this.indexedDbService.getAll<ProjetCompletDto>(StoreName.PROJETS);
    }

    public getProjetsAuditDownloaded(): Observable<ProjetAuditDto[]> {
        return this.indexedDbService.getAll<ProjetAuditDto>(StoreName.PROJETS_AUDIT);
    }

    private addToIndexedDb(storeName: StoreName, value: any) {
        return this.indexedDbService.add(storeName, value);
    }

    private updateIndexedDb(storeName: StoreName, value: any) {
        return this.indexedDbService.update(storeName, value);
    }

    public getEsriAccessToken(): Observable<EsriAccessToken> {
        return of(JSON.parse(window.localStorage.getItem(LocalStorageIndex.ESRI_ACCESS_TOKEN)));
    }
}
