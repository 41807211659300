import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';

import { LogoutComponent } from './features/auth/logout/logout.component';
import { GlobalErrorComponent } from './core/errors';
import { MapComponent } from './map/map.component';
import { OpenIdAuthGuard } from '@ngxhq/security';
import { InvalidRoleComponent } from './invalid-role/invalid-role.component';

const routes: Routes = [
    {
        path: '',
        redirectTo: 'carte',
        pathMatch: 'full'
    },
    { path: 'carte', component: MapComponent, canActivate: [OpenIdAuthGuard] },
    { path: 'invalid-role', component: InvalidRoleComponent, canActivate: [OpenIdAuthGuard] },

    // Pages anonymes
    { path: 'deconnexion', component: LogoutComponent },
    { path: 'erreur', component: GlobalErrorComponent, data: { layout: 'fullscreen' } },
    { path: 'erreur/http/:httpCode', component: GlobalErrorComponent, data: { layout: 'fullscreen' } },

    // Défaut :
    { path: '**', redirectTo: 'carte' }
];

@NgModule({
    imports: [
        RouterModule,
        RouterModule.forRoot(routes, {
            scrollPositionRestoration: 'enabled',
        })
    ],
    exports: [
        RouterModule,
    ],
    declarations: [],
    providers: [],
})
export class AppRoutingModule { }
