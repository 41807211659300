import { Update } from '@ngrx/entity';
import { createAction, props } from '@ngrx/store';
import { ProjetAction } from '../../../inspection/models/projet-action.enum';
import { ActiverProjet, AssigneProjet, ModifierProjet } from '../../models';
import { Projet } from '../../models/projet.model';
import { ResultatTransfertAvisInspection } from '../../../../core/api/client/models';

export enum ProjetActions {
    FETCH_PROJETS = '[Projet] Fetch projets',
    FETCH_PROJETS_SUCCESS = '[Projet] Fetch projets success',
    FETCH_PROJETS_ERROR = '[Projet] Fetch projets error',
    CREATE_PROJET = '[Projet] Create projet',
    CREATE_PROJET_SUCCESS = '[Projet] Create projet success',
    CREATE_PROJET_ERROR = '[Projet] Create projet error',
    POST_PROJET_SUCCESS = '[Projet] Post projet success',
    ASSIGNE_PROJET = '[Projet] Assigner projet',
    ASSIGNE_PROJET_SUCCESS = '[Projet] Assigner projet success',
    ASSIGNE_PROJET_ERROR = '[Projet] Assigner projet error',
    ACTIVER_PROJET = '[Projet] Activer projet',
    ACTIVER_PROJET_SUCCESS = '[Projet] Activer projet success',
    ANNULER_PROJET = '[Projet] Annuler projet',
    ANNULER_PROJET_SUCCESS = '[Projet] Annuler projet success',
    SUPPRIMER_PROJET = '[Projet] Supprimer projet',
    SUPPRIMER_PROJET_SUCCESS = '[Projet] Supprimer projet success',
    ANNULER_PROJET_ERROR = '[Projet] Annuler projet error',
    MODIFIER_PROJET = '[Projet] Modifier projet',
    MODIFIER_PROJET_SUCCESS = '[Projet] Modifier projet success',
    MODIFIER_STATUT_PROJET = '[Projet] Modifier statut projet',
    MODIFIER_STATUT_PROJET_SUCCESS = '[Projet] Modifier statut projet success',
    MODIFIER_STATUT_PROJET_ERROR = '[Projet] Modifier statut projet erreur',
    VALIDER_PROJET = '[Projet] Valider projet',
    VALIDER_PROJET_SUCCESS = '[Projet] Valider projet success',
    APPROUVER_PROJET = '[Projet] Approuver projet',
    APPROUVER_PROJET_SUCCESS = '[Projet] Approuver projet success',
    APPROUVER_PROJET_ERROR = '[Projet] Approuver projet erreur',
    REJETER_PROJET = '[Projet] Rejeter projet',
    REJETER_PROJET_SUCCESS = '[Projet] Rejeter projet success',
    REJETER_PROJET_ERROR = '[Projet] Rejeter projet erreur',
    ENVOYER_AVIS_PROJET = '[Projet] Envoyer avis',
    NOTIFIER_RESULTAT_RAPPORT_AVIS_SAP = '[Projet] Notifier resultat rapport avis SAP',
    NOTIFIER_ERREUR_RAPPORT_AVIS_SAP = '[Projet] Notifier erreur rapport avis SAP',
    CLEAR_PROJET_DATA = '[Projet] Effacer les données',
    UPDATE_PROJET_TO_INDEXEDDB = '[Projet] Update Projet To IndexedDb',
}

export const fetchProjets = createAction(ProjetActions.FETCH_PROJETS);

export const fetchProjetsSuccess = createAction(ProjetActions.FETCH_PROJETS_SUCCESS, props<{ projets: Projet[] }>());

export const fetchProjetsError = createAction(ProjetActions.FETCH_PROJETS_ERROR);

export const createProjet = createAction(ProjetActions.CREATE_PROJET, props<{ projet: Projet }>());

export const createProjetSuccess = createAction(ProjetActions.CREATE_PROJET_SUCCESS, props<{ projet: Projet }>());

export const postProjetSuccess = createAction(ProjetActions.POST_PROJET_SUCCESS, props<{ projet: Projet }>());

export const createProjetError = createAction(ProjetActions.CREATE_PROJET_ERROR);

export const assigneProjet = createAction(ProjetActions.ASSIGNE_PROJET, props<{ projetId: string | undefined, assigneData: AssigneProjet }>());

export const assigneProjetSuccess = createAction(ProjetActions.ASSIGNE_PROJET_SUCCESS, props<{ projet: Projet }>());

export const assigneProjetError = createAction(ProjetActions.ASSIGNE_PROJET_ERROR, props<{ error: any }>());

export const activerProjet = createAction(ProjetActions.ACTIVER_PROJET, props<{ projetId: string | undefined, activerData: ActiverProjet }>());

export const activerProjetSuccess = createAction(ProjetActions.ACTIVER_PROJET_SUCCESS, props<{ projet: Projet }>());

export const annulerProjet = createAction(ProjetActions.ANNULER_PROJET, props<{ projet: Projet, modificationData: ModifierProjet }>());

export const annulerProjetSuccess = createAction(ProjetActions.ANNULER_PROJET_SUCCESS, props<{ projet: Update<Projet> }>());

export const annulerProjetError = createAction(ProjetActions.ANNULER_PROJET_ERROR, props<{ error: any }>());

export const rejeterProjet = createAction(ProjetActions.REJETER_PROJET, props<{ projet: Projet, rejeterData: ModifierProjet }>());

export const rejeterProjetSuccess = createAction(ProjetActions.REJETER_PROJET_SUCCESS, props<{ projet: Update<Projet> }>());

export const rejeterProjetError = createAction(ProjetActions.REJETER_PROJET_ERROR, props<{ error: any }>());

export const supprimerProjet = createAction(ProjetActions.SUPPRIMER_PROJET, props<{ projet: Projet }>());
// À utiliser seulement avec l'effect annulerProjet
export const supprimerProjetSuccess = createAction(ProjetActions.SUPPRIMER_PROJET_SUCCESS);

export const onModifierProjet = createAction(ProjetActions.MODIFIER_PROJET, props<{ projetId: string, modificationData: ModifierProjet }>());

export const onModifierProjetSuccess = createAction(ProjetActions.MODIFIER_PROJET_SUCCESS, props<{ projet: Update<Projet> }>());

export const onModifierStatutProjet = createAction(ProjetActions.MODIFIER_STATUT_PROJET, props<{ projet: Projet | undefined, statut: ModifierProjet }>());

export const onModifierStatutProjetSuccess = createAction(ProjetActions.MODIFIER_STATUT_PROJET_SUCCESS,
    props<{ projet: Update<Projet>, projetOriginal: Projet, askedStatut: ProjetAction }>());

export const onModifierStatutProjetError = createAction(ProjetActions.MODIFIER_STATUT_PROJET_ERROR,
    props<{ error: any, projetOriginal: Projet, askedStatut: ProjetAction }>());

export const onApprouveProjet = createAction(ProjetActions.APPROUVER_PROJET, props<{ projet: Projet, statut: ModifierProjet }>());

export const onApprouveProjetSuccess = createAction(ProjetActions.APPROUVER_PROJET_SUCCESS, props<{ projet: Update<Projet> }>());

export const onApprouveProjetError = createAction(ProjetActions.APPROUVER_PROJET_ERROR, props<{ error: any }>());

export const onEnvoyerAvisSAP = createAction(ProjetActions.ENVOYER_AVIS_PROJET, props<{ projetId: string | undefined }>());

export const onResultatRapportAvisSAPRecu = createAction(ProjetActions.NOTIFIER_RESULTAT_RAPPORT_AVIS_SAP, props<{ rapport: Array<ResultatTransfertAvisInspection> }>());

export const onErreurRapportAvisSAP = createAction(ProjetActions.NOTIFIER_ERREUR_RAPPORT_AVIS_SAP);

export const clearProjetData = createAction(ProjetActions.CLEAR_PROJET_DATA);

export const updateProjetToIndexedDb = createAction(ProjetActions.UPDATE_PROJET_TO_INDEXEDDB);
