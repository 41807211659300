import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import {
    EMPTY,
    Observable,
    catchError,
    concatMap,
    expand,
    finalize,
    from,
    map,
    mergeMap,
    of,
    scan,
    takeLast,
    throwError,
    toArray,
    withLatestFrom
} from 'rxjs';
import { PhotoEntity } from '../../../core/api/client/models';
import { StoreName } from '../../../features/offline/models/indexed-db-store-name.enum';
import { IndexedDbService } from '../../../features/offline/services/indexed-db.service';
import { /*blobToBase64,*/ exportPhotosInErrorList } from '../../utils/photo.utils';
import { PhotosService } from '../services/photos.service';
import * as PhotosActions from './photos.actions';
import {
    getNonCachedAnomaliesAuditPhotos,
    getNonCachedAnomaliesPhotos,
    getNonCachedPointAuditPhotos,
    getNonCachedPointInspectionPhotos
} from './photos.selectors';

@Injectable()
export class PhotosEffects {

    // Uploaded photos
    loadUploadedPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.loadUploadedPhoto),
            mergeMap((action) => {
                let photoObservable: Observable<Blob>;
                if (action.photo.pointInspectionId) {
                    photoObservable = this.photosService.getPointInspectionPhotoById(action.photo.pointInspectionId, action.photo.id);
                } else if (action.photo.anomalieInspectionId) {
                    photoObservable = this.photosService.getAnomaliePhotoById(action.photo.anomalieInspectionId, action.photo.id);
                } else if (action.photo.pointAuditId) {
                    photoObservable = this.photosService.getPointAuditPhotoById(action.photo.pointAuditId, action.photo.id);
                } else if (action.photo.anomalieAuditId) {
                    photoObservable = this.photosService.getAnomalieAuditPhotoById(action.photo.anomalieAuditId, action.photo.id);
                } else {
                    return throwError(() => 'Des informations sont manquantes pour télécharger la photo.');
                }

                return photoObservable.pipe(
                    map(blob => {
                        const photoEntityWithData: PhotoEntity = {
                            data: blob,
                            ...action.photo
                        };
                        return photoEntityWithData;
                    }),
                    mergeMap(photoEntity => {
                        return this.dbService.add(StoreName.PHOTOS, photoEntity).pipe(
                            catchError((error: any) => {
                                console.error(error);
                                throw (error);
                            })
                        );

                    })
                );
            }),
            map(() => PhotosActions.loadUploadedPhotoSuccess()),
            catchError((error: any) => {
                return of(PhotosActions.loadUploadedPhotoFailure({ error }));
            })
        );
    });

    loadPointInspectionPhotos = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.loadPointInspectionPhotos),
            mergeMap((action) => {
                return from(action.photos).pipe(
                    concatMap(photo => {
                        return this.photosService.getPointInspectionPhotoById(photo.pointInspectionId, photo.id).pipe(
                            map(blob => {
                                const photoEntityWithData: PhotoEntity = {
                                    data: blob,
                                    ...photo
                                };
                                return photoEntityWithData;
                            }),
                        );
                    }),
                    toArray(),
                    map((photosWithData) => PhotosActions.loadPointInspectionPhotosSuccess({ photos: photosWithData })),
                    catchError((error: any) => {
                        return of(PhotosActions.loadPointInspectionPhotosFailure({ error }));
                    })
                );
            })
        );
    });

    cachePointInspectionPhotos$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.cachePointInspectionPhotos),
            mergeMap((action) => {
                return this.photosService.getPointInspectionPhotosInfoByProjetId(action.projetId).pipe(
                    expand(page => page.pagination.pageNumber * page.pagination.pageSize < page.pagination.totalCount ?
                        this.photosService.getPointInspectionPhotosInfoByProjetId(action.projetId, page.pagination.pageNumber + 1) :
                        EMPTY),
                    map(page => page.items),
                    scan((acc: PhotoEntity[], data: PhotoEntity[]) => {
                        return [...acc, ...data];
                    }, []),
                    takeLast(1),
                    mergeMap(photos => {
                        if (photos.length > 0) {
                            return from(photos).pipe(
                                concatMap(photo => this.photosService.getPointInspectionPhotoById(photo.pointInspectionId, photo.id).pipe(
                                    map(blob => {
                                        const photoEntityWithData = {
                                            ...photo,
                                            data: blob,
                                            projetId: action.projetId

                                        };
                                        return photoEntityWithData;
                                    }),
                                    mergeMap(photoEntity => {
                                        return this.dbService.add(StoreName.PHOTOS, photoEntity).pipe(
                                            catchError((error: any) => {
                                                console.error(error);
                                                return throwError(() => error);
                                            })
                                        );
                                    }),
                                    catchError(() => {
                                        this.store.dispatch(PhotosActions.updateNonCachedPointInspectionPhotosList({ photo }));
                                        return of(null);
                                    }),
                                    finalize(() => {
                                        const progression = Math.round((photos.indexOf(photo) + 1) / photos.length * 100);
                                        this.store.dispatch(PhotosActions.updateCachingPointInspectionPhotosProgression({ progression }));
                                    })
                                )));
                        } else {
                            this.store.dispatch(PhotosActions.updateCachingPointInspectionPhotosProgression({ progression: 100 }));
                            return of(null);
                        }
                    }),
                    takeLast(1),
                    withLatestFrom(this.store.select(getNonCachedPointInspectionPhotos)),
                    map(([_, photosInError]) => {
                        if (photosInError.length === 0) {
                            this.messageService.add({
                                severity: 'success',
                                summary: `Téléchargement des photos d'inspection`,
                                detail: `Les photos d'inspection ont été téléchargées avec succès.`
                            });
                        } else {
                            exportPhotosInErrorList(photosInError);
                            this.messageService.add({
                                severity: 'warn',
                                summary: `Téléchargement des photos d'inspection`,
                                detail: `Certaines photos d'inspection n'ont pas pu être téléchargées ou sauvegardées en cache. La liste a été téléchargé.`
                            });
                        }
                        return PhotosActions.cachePointInspectionPhotosSuccess();
                    }),
                    catchError((error: any) => {
                        this.messageService.add(
                            {
                                severity: 'error',
                                summary: 'Téléchargement des photos',
                                detail: `Une erreur est survenue lors du téléchargement des informations des photos d'inspection.`
                            }
                        );
                        return of(PhotosActions.cachePointInspectionPhotosFailure({ error }));
                    }));
            })
        );
    });

    loadAnomaliesPhotos = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.loadAnomaliesPhotos),
            mergeMap((action) => {
                return from(action.photos).pipe(
                    concatMap(photo => {
                        return this.photosService.getAnomaliePhotoById(photo.anomalieInspectionId, photo.id).pipe(
                            map(blob => {
                                const photoEntityWithData: PhotoEntity = {
                                    data: blob,
                                    ...photo
                                };
                                return photoEntityWithData;
                            }),
                        );
                    }),
                    toArray(),
                    map((photosWithData) => PhotosActions.loadAnomaliesPhotosSuccess({ photos: photosWithData })),
                    catchError((error: any) => {
                        return of(PhotosActions.loadAnomaliesPhotosFailure({ error }));
                    })
                );
            })
        );
    });

    cacheAnomaliesPhotos$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.cacheAnomaliesPhotos),
            mergeMap((action) => {
                return this.photosService.getAnomaliesPhotosInfoByProjetId(action.projetId).pipe(
                    expand(page => page.pagination.pageNumber * page.pagination.pageSize < page.pagination.totalCount ?
                        this.photosService.getAnomaliesPhotosInfoByProjetId(action.projetId, page.pagination.pageNumber + 1) :
                        EMPTY),
                    map(page => page.items),
                    scan((acc: PhotoEntity[], data: PhotoEntity[]) => {
                        return [...acc, ...data];
                    }, []),
                    takeLast(1),
                    mergeMap(photos => {
                        if (photos.length > 0) {
                            return from(photos).pipe(
                                concatMap(photo => this.photosService.getAnomaliePhotoById(photo.anomalieInspectionId, photo.id).pipe(
                                    map(blob => {
                                        const photoEntityWithData = {
                                            ...photo,
                                            data: blob,
                                            projetId: action.projetId
                                        };
                                        return photoEntityWithData;
                                    }),
                                    mergeMap(photoEntity => {
                                        return this.dbService.add(StoreName.PHOTOS, photoEntity).pipe(
                                            catchError((error: any) => {
                                                console.error(error);
                                                return throwError(() => error);
                                            })
                                        );
                                    }),
                                    catchError(() => {
                                        this.store.dispatch(PhotosActions.updateNonCachedAnomaliesPhotosList({ photo }));
                                        return of(null);
                                    }),
                                    finalize(() => {
                                        const progression = Math.round((photos.indexOf(photo) + 1) / photos.length * 100);
                                        this.store.dispatch(PhotosActions.updateCachingAnomaliesPhotosProgression({ progression }));
                                    })
                                )));
                        } else {
                            this.store.dispatch(PhotosActions.updateCachingAnomaliesPhotosProgression({ progression: 100 }));
                            return of(null);
                        }
                    }),
                    takeLast(1),
                    withLatestFrom(this.store.select(getNonCachedAnomaliesPhotos)),
                    map(([_, photosInError]) => {
                        if (photosInError.length === 0) {
                            this.messageService.add({
                                severity: 'success',
                                summary: `Téléchargement des photos d'anomalies`,
                                detail: `Les photos d'anomalies ont été téléchargées avec succès.`
                            });
                        } else {
                            exportPhotosInErrorList(photosInError);
                            this.messageService.add({
                                severity: 'warn',
                                summary: `Téléchargement des photos d'anomalies`,
                                detail: `Certaines photos d'anomalies n'ont pas pu être téléchargées ou sauvegardées en cache. La liste a été téléchargée.`
                            });
                        }
                        return PhotosActions.cacheAnomaliesPhotosSuccess();
                    }),
                    catchError((error: any) => {
                        this.messageService.add(
                            {
                                severity: 'error',
                                summary: `Téléchargement des photos d'anomalies`,
                                detail: `Une erreur est survenue lors du téléchargement des informations des photos d'anomalies.`
                            }
                        );
                        return of(PhotosActions.cacheAnomaliesPhotosFailure({ error }));
                    }));
            })
        );
    });

    loadPointAuditPhotos = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.loadPointAuditPhotos),
            mergeMap((action) => {
                return from(action.photos).pipe(
                    concatMap(photo => {
                        return this.photosService.getPointAuditPhotoById(photo.pointAuditId, photo.id).pipe(
                            map(blob => {
                                const photoEntityWithData: PhotoEntity = {
                                    data: blob,
                                    ...photo
                                };
                                return photoEntityWithData;
                            }),
                        );
                    }),
                    toArray(),
                    map((photosWithData) => PhotosActions.loadPointAuditPhotosSuccess({ photos: photosWithData })),
                    catchError((error: any) => {
                        return of(PhotosActions.loadPointAuditPhotosFailure({ error }));
                    })
                );
            })
        );
    });

    cachePointAuditPhotos$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.cachePointAuditPhotos),
            mergeMap((action) => {
                return this.photosService.getPointAuditPhotosInfoByProjetAuditId(action.projetAuditId).pipe(
                    expand(page => page.pagination.pageNumber * page.pagination.pageSize < page.pagination.totalCount ?
                        this.photosService.getPointAuditPhotosInfoByProjetAuditId(action.projetAuditId, page.pagination.pageNumber + 1) :
                        EMPTY),
                    map(page => page.items),
                    scan((acc: PhotoEntity[], data: PhotoEntity[]) => {
                        return [...acc, ...data];
                    }, []),
                    takeLast(1),
                    mergeMap(photos => {
                        if (photos.length > 0) {
                            return from(photos).pipe(
                                concatMap(photo => this.photosService.getPointAuditPhotoById(photo.pointAuditId, photo.id).pipe(
                                    map(blob => {
                                        const photoEntityWithData = {
                                            ...photo,
                                            data: blob,
                                            projetAuditId: action.projetAuditId
                                        };
                                        return photoEntityWithData;
                                    }),
                                    mergeMap(photoEntity => {
                                        return this.dbService.add(StoreName.PHOTOS, photoEntity).pipe(
                                            catchError((error: any) => {
                                                console.error(error);
                                                return throwError(() => error);
                                            })
                                        );
                                    }),
                                    catchError(() => {
                                        this.store.dispatch(PhotosActions.updateNonCachedPointAuditPhotosList({ photo }));
                                        return of(null);
                                    }),
                                    finalize(() => {
                                        const progression = Math.round((photos.indexOf(photo) + 1) / photos.length * 100);
                                        this.store.dispatch(PhotosActions.updateCachingPointAuditPhotosProgression({ progression }));
                                    })
                                )));
                        } else {
                            this.store.dispatch(PhotosActions.updateCachingPointAuditPhotosProgression({ progression: 100 }));
                            return of(null);
                        }
                    }),
                    takeLast(1),
                    withLatestFrom(this.store.select(getNonCachedPointAuditPhotos)),
                    map(([_, photosInError]) => {
                        if (photosInError.length === 0) {
                            this.messageService.add({
                                severity: 'success',
                                summary: `Téléchargement des photos d'audit`,
                                detail: `Les photos d'audit ont été téléchargées avec succès.`
                            });
                        } else {
                            exportPhotosInErrorList(photosInError);
                            this.messageService.add({
                                severity: 'warn',
                                summary: `Téléchargement des photos d'audit`,
                                detail: `Certaines photos d'audit n'ont pas pu être téléchargées ou sauvegardées en cache. La liste a été téléchargée.`
                            });
                        }
                        return PhotosActions.cachePointAuditPhotosSuccess();
                    }),
                    catchError((error: any) => {
                        this.messageService.add(
                            {
                                severity: 'error',
                                summary: `Téléchargement des photos d'audit`,
                                detail: `Une erreur est survenue lors du téléchargement des informations des photos d'audit.`
                            }
                        );
                        return of(PhotosActions.cachePointAuditPhotosFailure({ error }));
                    }));
            })
        );
    });

    loadAnomaliesAuditPhotos = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.loadAnomaliesAuditPhotos),
            mergeMap((action) => {
                return from(action.photos).pipe(
                    concatMap(photo => {
                        return this.photosService.getAnomalieAuditPhotoById(photo.anomalieAuditId, photo.id).pipe(
                            map(blob => {
                                const photoEntityWithData: PhotoEntity = {
                                    data: blob,
                                    ...photo
                                };
                                return photoEntityWithData;
                            }),
                        );
                    }),
                    toArray(),
                    map((photosWithData) => PhotosActions.loadAnomaliesAuditPhotosSuccess({ photos: photosWithData })),
                    catchError((error: any) => {
                        return of(PhotosActions.loadAnomaliesAuditPhotosFailure({ error }));
                    })
                );
            })
        );
    });


    cacheAnomaliesAuditPhotos = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.cacheAnomaliesAuditPhotos),
            mergeMap((action) => {
                return this.photosService.getAnomaliesAuditPhotosInfoByProjetAuditId(action.projetAuditId).pipe(
                    expand(page => page.pagination.pageNumber * page.pagination.pageSize < page.pagination.totalCount ?
                        this.photosService.getAnomaliesAuditPhotosInfoByProjetAuditId(action.projetAuditId, page.pagination.pageNumber + 1) :
                        EMPTY),
                    map(page => page.items),
                    scan((acc: PhotoEntity[], data: PhotoEntity[]) => {
                        return [...acc, ...data];
                    }, []),
                    takeLast(1),
                    mergeMap(photos => {
                        if (photos.length > 0) {
                            return from(photos).pipe(
                                concatMap(photo => this.photosService.getAnomalieAuditPhotoById(photo.anomalieAuditId, photo.id).pipe(
                                    map(blob => {
                                        const photoEntityWithData = {
                                            ...photo,
                                            data: blob,
                                            projetAuditId: action.projetAuditId
                                        };
                                        return photoEntityWithData;
                                    }),
                                    mergeMap(photoEntity => {
                                        return this.dbService.add(StoreName.PHOTOS, photoEntity).pipe(
                                            catchError((error: any) => {
                                                console.error(error);
                                                return throwError(() => error);
                                            })
                                        );
                                    }),
                                    catchError(() => {
                                        this.store.dispatch(PhotosActions.updateNonCachedAnomaliesAuditPhotosList({ photo }));
                                        return of(null);
                                    }),
                                    finalize(() => {
                                        const progression = Math.round((photos.indexOf(photo) + 1) / photos.length * 100);
                                        this.store.dispatch(PhotosActions.updateCachingAnomaliesAuditPhotosProgression({ progression }));
                                    })
                                )));
                        } else {
                            this.store.dispatch(PhotosActions.updateCachingAnomaliesAuditPhotosProgression({ progression: 100 }));
                            return of(null);
                        }
                    }),
                    takeLast(1),
                    withLatestFrom(this.store.select(getNonCachedAnomaliesAuditPhotos)),
                    map(([_, photosInError]) => {
                        if (photosInError.length === 0) {
                            this.messageService.add({
                                severity: 'success',
                                summary: `Téléchargement des photos d'anomalies d'audit`,
                                detail: `Les photos d'anomalies d'audit ont été téléchargées avec succès.`
                            });
                        } else {
                            exportPhotosInErrorList(photosInError);
                            this.messageService.add({
                                severity: 'warn',
                                summary: `Téléchargement des photos d'anomalies d'audit`,
                                detail: `Certaines photos d'anomalies d'audit n'ont pas pu être téléchargées ou sauvegardées en cache. La liste a été téléchargée.`
                            });
                        }
                        return PhotosActions.cacheAnomaliesAuditPhotosSuccess();
                    }),
                    catchError((error: any) => {
                        this.messageService.add(
                            {
                                severity: 'error',
                                summary: `Téléchargement des photos d'anomalies d'audit`,
                                detail: `Une erreur est survenue lors du téléchargement des informations des photos d'anomalies d'audit.`
                            }
                        );
                        return of(PhotosActions.cacheAnomaliesAuditPhotosFailure({ error }));
                    }));
            })
        );
    });

    // Offline photos
    addPhotoToIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(PhotosActions.addPhotoToIndexedDb),
            mergeMap(action => this.dbService.add(StoreName.PHOTOS, action.photo)),
            map(() => PhotosActions.loadUploadedPhotoSuccess()),
            catchError((error: any) => {
                return of(PhotosActions.loadUploadedPhotoFailure({ error }));
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store,
        private photosService: PhotosService,
        private messageService: MessageService,
        private dbService: IndexedDbService
    ) { }

}

