import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { AnomalieService } from '../../services/anomalie.service';
import * as AnomalieListActions from './anomalie-list.action';
@Injectable()
export class AnomalieListEffects {

    loadAnomalieList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AnomalieListActions.loadAnomalieList),
            mergeMap(action =>
                this.anomalieService.getAnomalies({ id: action.projetId })
                    .pipe(
                        map(anomalieList => AnomalieListActions.loadAnomalieListSuccess({ anomalieList })),
                        catchError((error: unknown) => of(AnomalieListActions.loadAnomalieListError({ error })))
                    )));
    });

    loadStatutPhotoExtraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AnomalieListActions.loadStatutPhotoExtraction),
            mergeMap(action =>
                this.anomalieService.getStatutPhotoExtraction(action.projetId)
                    .pipe(
                        map(statutPhotoExtraction => AnomalieListActions.loadStatutPhotoExtractionSuccess({ statutPhotoExtraction })),
                        catchError((error: unknown) => of(AnomalieListActions.loadStatutPhotoExtractionError({ error })))
                    )));
    });

    createPhotoExtraction$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(AnomalieListActions.createPhotoExtraction),
            mergeMap(action =>
                this.anomalieService.createPhotoExtraction(action.projetId)
                    .pipe(
                        map(photoExtraction => AnomalieListActions.createPhotoExtractionSuccess({ photoExtraction })),
                        catchError((error: unknown) => of(AnomalieListActions.createPhotoExtractionError({ error })))
                    )));
    });

    constructor(
        private actions$: Actions,
        private anomalieService: AnomalieService
    ) { }
}
