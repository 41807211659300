import { Component } from '@angular/core';
import { ComponentBase, ComponentFeatures, InheritsBaseLifecycleHooks } from '@ngxhq/common-ui';
import { Router } from '@angular/router';

@Component({
    styleUrls: ['./logout.component.scss'],
    templateUrl: './logout.component.html',
})
@ComponentFeatures([
    InheritsBaseLifecycleHooks()
])
export class LogoutComponent extends ComponentBase {
    constructor(public router: Router) {
        super();
    }
    async redirect(): Promise<void> {
        await this.router.navigate(['/']);
    }
}
