import { Injectable } from '@angular/core';
import { Key, NgxIndexedDBService } from 'ngx-indexed-db';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { StoreName } from '../models/indexed-db-store-name.enum';

@Injectable()
export class IndexedDbService {

    public requestStackSize = 0;

    constructor(
        private readonly dbService: NgxIndexedDBService
    ) {
        this.dbService.getAll(StoreName.REQUESTS)
            .pipe(
                tap(requests => {
                    this.requestStackSize = requests?.length || 0;
                }))
            .subscribe();
    }

    public add<T>(storeName: StoreName, value: T, key?: any): Observable<T> {
        if (storeName === StoreName.REQUESTS) {
            this.requestStackSize++;
        }

        return this.dbService.add(storeName, value, key);
    }

    public bulkAdd(storeName: StoreName, key: any[]): Observable<number[]> {
        return this.dbService.bulkAdd(storeName, key);
    }

    public bulkGet<T>(storeName: StoreName, keys: string[]): Observable<T[]> {
        return this.dbService.bulkGet(storeName, keys);
    }

    public get<T>(storeName: StoreName, key: string): Observable<T> {
        return this.dbService.getByID(storeName, key);
    }

    public getAll<T>(storeName: StoreName): Observable<T[]> {
        return this.dbService.getAll(storeName);
    }

    public delete<T>(storeName: StoreName, key: Key): Observable<T[]> {
        if (storeName === StoreName.REQUESTS) {
            this.requestStackSize--;
        }

        return this.dbService.delete(storeName, key);
    }

    public deleteByKey(storeName: StoreName, key: Key): Observable<boolean> {
        return this.dbService.deleteByKey(storeName, key);
    }

    public bulkDelete(storeName: StoreName, key: Key[]): Observable<number[]> {
        return this.dbService.bulkDelete(storeName, key);
    }

    public update<T>(storeName: StoreName, value: T): Observable<T> {
        return this.dbService.update(storeName, value);
    }

    public clear(storeName: StoreName): Observable<boolean> {
        if (storeName === StoreName.REQUESTS) {
            this.requestStackSize = 0;
        }

        return this.dbService.clear(storeName);
    }
}
