<ng-container *ngIf="loading; else photosList">
    <p-skeleton width="150px" height="100px" [style]="{'margin-bottom': '10px', 'margin-right': '20px'}"></p-skeleton>
    <p-skeleton width="150px" height="90px" [style]="{'margin-bottom': '10px', 'margin-right': '20px'}"></p-skeleton>
    <p-skeleton width="150px" height="110px" [style]="{'margin-bottom': '10px', 'margin-right': '20px'}"></p-skeleton>
    <p-skeleton width="150px" height="100px" [style]="{'margin-bottom': '10px', 'margin-right': '20px'}"></p-skeleton>
</ng-container>
<ng-template #photosList>
    <div *ngFor="let photo of photos" class="photo">
        <div class="photo__info">
            <label class="word-break">
                {{photo.nomOriginal}}
            </label>
            <button *ngIf="canEdit" pButton icon="pi pi-times"
                class="p-button-rounded p-button-danger p-button-text delete-photo-button"
                (click)="remove(photo.id)"></button>
        </div>
        <span>
            <p-image [src]="photo.file" width="150" [title]="photo.nomOriginal" [alt]="photo.nomOriginal"
                [preview]="true" appendTo="body"></p-image>
        </span>
        <br>
    </div>
</ng-template>