import { createReducer, on } from '@ngrx/store';
import * as SharedActions from './shared.actions';
import { SharedState } from './shared.state';

export const sharedInitialState: SharedState = {
    isAppOnline: null,
    esriAccessToken: null,
    esriAccessTokenError: null,
    dataExtraction: null,
    dataExtractionError: null,
    startDataExtraction: null,
    startDataExtractionError: null,
    anomaliesPilotage: null,
    anomaliesPilotageError: null,
};

export const sharedReducer = createReducer<SharedState>(
    sharedInitialState,
    on(SharedActions.setAppOnlineStatut, (state, action) => {
        return {
            ...state,
            isAppOnline: action.online
        };
    }),
    on(SharedActions.getEsriAccessToken, (state) => {
        return {
            ...state,
            esriAccessToken: null,
            esriAccessTokenError: null,
        };
    }),
    on(SharedActions.getEsriAccessTokenSuccess, (state, action) => {
        return {
            ...state,
            esriAccessToken: action.esriAccessToken,
            esriAccessTokenError: null
        };
    }),
    on(SharedActions.getEsriAccessTokenFailure, (state, action) => {
        return {
            ...state,
            esriAccessToken: null,
            esriAccessTokenError: action.error
        };
    }),
    on(SharedActions.getDataExtraction, (state) => {
        return {
            ...state,
            dataExtraction: null,
            dataExtractionError: null,
        };
    }),
    on(SharedActions.getDataExtractionSuccess, (state, action) => {
        return {
            ...state,
            dataExtraction: action.dataExtraction,
            dataExtractionError: null
        };
    }),
    on(SharedActions.getDataExtractionFailure, (state, action) => {
        return {
            ...state,
            dataExtraction: null,
            dataExtractionError: action.error
        };
    }),
    on(SharedActions.startDataExtraction, (state) => {
        return {
            ...state,
            startDataExtraction: null,
            startDataExtractionError: null
        };
    }),
    on(SharedActions.startDataExtractionSuccess, (state, action) => {
        return {
            ...state,
            startDataExtraction: action.id,
            startDataExtractionError: null
        };
    }),
    on(SharedActions.startDataExtractionFailure, (state, action) => {
        return {
            ...state,
            startDataExtraction: null,
            startDataExtractionError: action.error
        };
    }),
    on(SharedActions.loadAnomaliesPilotage, (state) => {
        return {
            ...state,
            anomaliesPilotage: null,
            anomaliesPilotageError: null
        };
    }),
    on(SharedActions.loadAnomaliesPilotageSuccess, (state, action) => {
        return {
            ...state,
            anomaliesPilotage: action.anomaliesPilotage,
            anomaliesPilotageError: null
        };
    }),
    on(SharedActions.loadAnomaliesPilotageFailure, (state, action) => {
        return {
            ...state,
            anomaliesPilotage: null,
            anomaliesPilotageError: action.error
        };
    }),
);
