/* eslint-disable @typescript-eslint/naming-convention */
import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';

export const pointAuditLayer: AnyLayer = {
    id: MapLayersSources.POINT_AUDIT,
    type: 'symbol',
    source: MapLayersSources.POINT_AUDIT,
    paint: {
        'icon-color': '#FFFFFF',
        'text-color': 'green',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 1
    }
};
