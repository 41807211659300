import { Action, createReducer, on } from '@ngrx/store';
import { ProjetHistoryState } from './projet-history.state';
import * as ProjetHistoryActions from './projet-history.actions';


export const projetHistoryInitialState: ProjetHistoryState = {
    projetHistory: null,
    projetHistoryError: null
};

export const projetHistoryReducer = createReducer<ProjetHistoryState>(
    projetHistoryInitialState,
    on(ProjetHistoryActions.loadProjetHistory, (state) => {
        return {
            ...state,
            projetHistory: null,
            projetHistoryError: null,
        };
    }),
    on(ProjetHistoryActions.loadProjetHistorySuccess, (state, action) => {
        return {
            ...state,
            projetHistory: action.projetHistory,
            projetHistoryError: null
        };
    }),
    on(ProjetHistoryActions.loadProjetHistoryError, (state, action) => {
        return {
            ...state,
            projetHistory: null,
            projetHistoryError: action.error
        };
    }),
);

export function reducer(state: any, action: Action) {
    return projetHistoryReducer(state, action);
}
