export enum ProjetAuditProperties {
    assigneA = 'Assigné à',
    assigneLe = 'Assigné le',
    assignePar = 'Assigné par',
    firme = 'Firme',
    geometrie = 'Géometrie',
    id = 'id',
    nom = 'Nom',
    nombreTotalDePoteaux = 'Nombre total de poteaux',
    projetId = 'id projet',
    remarques = 'Remarques',
    seuilAudit = `Seuil d'audit`,
    statut = 'Statut',
    statutGlobal = 'Statut global',
    typeDeSeuil = 'Type de seuil'
}
