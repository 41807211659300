<div class="p-field-checkbox" [ngClass]="{
    'p-invalid': formControl.invalid && (formControl.dirty || formControl.touched),
    'p-field-disabled' : formControl.disabled
}">
    <ng-content></ng-content>
    <label *ngIf="label" for="{{inputId}}">
        {{label}}
        <span class="field-required" *ngIf="isRequired()">*</span>
        <app-form-info *ngIf="info" [message]="info"></app-form-info>
    </label>
</div>

<app-form-field-error [label]="label" [control]="formControl"></app-form-field-error>
