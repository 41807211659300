<div class="container" [style]="{'opacity': '0.'+opacity, 'position': position}">
    <div class="container__logo">
        <div class="logo__container">
            <p-progressSpinner
                class="container__loader"
                [style]="{'width': size+'px', 'height': size+'px'}"
            ></p-progressSpinner>
            <span *ngIf="displayLabel"
                class="container__label"
                [ngStyle]="{'font-size': labelFontSize+'px'}"
            >{{ label }}</span>
        </div>
    </div>
</div>
