import { SelectItem } from 'primeng/api';

export enum PrioriteAnalyse {
    prioritaire = 'Prioritaire',
    nonPrioritaire = 'Non prioritaire',
}

export enum PrioriteAnalyseValue {
    prioritaire = 'prioritaire',
    nonPrioritaire = '',
}

export const prioriteAnalyseOptions: SelectItem[] = [
    { label: PrioriteAnalyse.prioritaire, value: PrioriteAnalyseValue.prioritaire },
    { label: PrioriteAnalyse.nonPrioritaire, value: PrioriteAnalyseValue.nonPrioritaire }
];
