import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, ViewChildren } from '@angular/core';
import { Store } from '@ngrx/store';
import { State } from '../../../../../state/app.state';
import { filter, takeUntil } from 'rxjs/operators';
import { ConfirmationService, MenuItem } from 'primeng/api';
import { ContextMenu } from 'primeng/contextmenu';
import { DialogService, DynamicDialogRef } from 'primeng/dynamicdialog';
import { ProjetAuditDto, PatchProjetAuditDto, ActionProjetAudit } from '../../../../../core/api/client/models';
import * as AuditActions from '../../../state/audit.actions';
import * as InspectionActions from '../../../../inspection/state/inspection.actions';
import * as OfflineActions from '../../../../offline/state/offline.actions';
import { StatutProjetAudit, StatutProjetAuditValue } from '../../../models/statut-projet-audit.enum';
import { getActivateProjetAuditSuccess } from '../../../state/audit.selectors';
import { getProjetsAuditDownloaded, getProjetsAuditDownloadedLoading } from '../../../../offline/state/offline.selectors';
import { getCompleteProjetAuditPermission } from '../../../utils/audit-permissions.utils';
import { CompleteProjetAuditDialogComponent } from '../../complete-projet-audit-dialog/complete-projet-audit-dialog.component';
import { MapService } from '../../../../../map/services/map.service';
import { UiService } from '../../../../../services/ui.service';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { TableColumn } from '../../../../../shared/models/table-column.model';
import { MAX_ACTIVE_PROJET_AUDIT } from '../../../models/audit.const';
import { SyncErrors } from '../../../../synchronisation/models/sync-errors.model';
import { SynchronisationService } from '../../../../synchronisation/services/synchronisation.service';
import { getSyncErrors, getRequests } from '../../../../synchronisation/state/synchronisation.selectors';
import { Requests } from '../../../../synchronisation/models/requests.model';

@Component({
    selector: 'app-projet-audit-list-mobile-dialog',
    templateUrl: './projet-audit-list-mobile-dialog.component.html',
    styleUrls: ['./projet-audit-list-mobile-dialog.component.scss']
})
export class ProjetAuditListMobileDialogComponent extends BaseComponent implements AfterViewInit, OnInit {
    @ViewChildren('actionsButton') actionsButton: QueryList<ElementRef>;

    public projetsAuditDownloadedLoading = false;
    private _projetAuditList: ProjetAuditDto[] = [];
    public projetsAuditDownloaded: ProjetAuditDto[] = [];
    public selectedProjetAudit: ProjetAuditDto | null;
    private requests: Requests[] = [];
    private syncErrors: SyncErrors[] = [];

    @Input()
    set projetAuditList(value: ProjetAuditDto[]) {
        this._projetAuditList = value;
        this.selectedProjetAudit = null;
    }

    get projetAuditList() {
        return this._projetAuditList;
    }

    @Input() columns: TableColumn[];
    @Input() canCompleteProjetAudit: boolean;
    @Input() isOffline: boolean;

    menuItems: MenuItem[];
    statutProjetAuditValue = StatutProjetAuditValue;
    completeProjetAuditDialogVisible = false;

    constructor(
        private store: Store<State>,
        private syncService: SynchronisationService,
        private dialogService: DialogService,
        private confirmationService: ConfirmationService,
        public ref: DynamicDialogRef,
        private cdr: ChangeDetectorRef,
        private mapService: MapService,
        private uiService: UiService
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(OfflineActions.getProjetsAuditDownloaded());

        this.subscribeToProjetsAuditDownloaded();
        this.subscribeToProjetsAuditDownloadedLoading();
        this.subscribeToSyncErrors();
        this.subscribeToRequests();
    }

    initMenuItems(projetAudit: ProjetAuditDto) {
        this.menuItems = [
            {
                label: 'Zoom sur',
                icon: 'pi pi-fw pi-search-plus',
                command: () => {
                    this.ref.close(true);
                    this.mapService.zoomProjetAudit(projetAudit);
                }
            },
            {
                label: 'Auditer',
                icon: 'pi pi-fw pi-download',
                disabled: this.disableAuditerProjetAudit(projetAudit),
                command: () => this.activateProjetAudit(projetAudit)
            },
            {
                label: 'Télécharger de nouveau',
                icon: 'pi pi-fw pi-refresh',
                visible: this.canDownloadProjetAudit(projetAudit),
                disabled: this.disableDownloadProjetAudit(projetAudit),
                command: () => this.downloadProjetAuditConfirm(projetAudit)
            },
            {
                label: 'Compléter',
                icon: 'pi pi-flag',
                visible: this.canCompleteProjetAudit,
                disabled: this.disableCompleteProjetAudit(projetAudit),
                command: () => this.openCompleteProjetAuditDialog(projetAudit)
            },
            {
                label: `Rapport d’erreurs de synchronisation`,
                icon: 'fas fa-exclamation-circle',
                disabled: !this.syncErrors.find(syncError => syncError.pointAudit?.projetAuditId === projetAudit.id),
                command: () => {
                    this.ref.close(true);
                    this.syncService.openRapportErreursSynchronisationDialog(this.requests, projetAudit.id, true);
                }
            }
        ];
    }

    onRowSelect(projetAudit: ProjetAuditDto, contextMenu: ContextMenu) {
        this.store.dispatch(AuditActions.setCurrentActiveProjetAudit({ projetAudit }));
        this.store.dispatch(InspectionActions.setCurrentActiveProjetInspectionById({ projetInspectionId: projetAudit.projetId }));
        this.uiService.closeActionSheet(true);
        contextMenu.hide();
    }

    onRowUnselect(contextMenu: ContextMenu) {
        this.store.dispatch(AuditActions.setCurrentActiveProjetAudit({ projetAudit: null }));
        contextMenu.hide();
    }

    onActionsButtonClick(event: MouseEvent, contextMenu: ContextMenu, selectedProjetAudit: ProjetAuditDto) {
        this.initMenuItems(selectedProjetAudit);
        event.stopPropagation();
        event.preventDefault();
        contextMenu.show(event);
    }

    private activateProjetAudit(projetAudit: ProjetAuditDto) {
        const data: PatchProjetAuditDto = { action: ActionProjetAudit.activer };
        this.store.dispatch(AuditActions.activateProjetAudit({ projetAuditId: projetAudit.id!, activateData: data }));
        this.subscribeToActivateProjetAuditSuccess();
    }

    private subscribeToProjetsAuditDownloaded() {
        this.store.select(getProjetsAuditDownloaded)
            .pipe(
                filter(projetsAuditDownloaded => !!projetsAuditDownloaded),
                takeUntil(this.destroyed)
            )
            .subscribe(projetsAuditDownloaded => {
                this.projetsAuditDownloaded = projetsAuditDownloaded;
            });
    }

    private subscribeToProjetsAuditDownloadedLoading() {
        this.store.select(getProjetsAuditDownloadedLoading)
            .pipe(takeUntil(this.destroyed))
            .subscribe(projetsAuditDownloadedLoading => {
                this.projetsAuditDownloadedLoading = projetsAuditDownloadedLoading;
            });
    }

    private subscribeToActivateProjetAuditSuccess() {
        this.store.select(getActivateProjetAuditSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed)
            )
            .subscribe(activateResult => {
                if (activateResult.success) {
                    this.downloadProjetAudit(activateResult.projetAudit!);
                    this.ref.close(true);
                }
            });
    }

    private subscribeToRequests() {
        this.store.select(getRequests)
            .pipe(
                filter(requests => !!requests),
                takeUntil(this.destroyed)
            )
            .subscribe(requests => {
                this.requests = requests;
            });
    }

    private subscribeToSyncErrors() {
        this.store.select(getSyncErrors)
            .pipe(
                filter(syncErrors => !!syncErrors),
                takeUntil(this.destroyed)
            )
            .subscribe(syncErrors => {
                this.syncErrors = syncErrors;
            });
    }

    private canDownloadProjetAudit(projetAudit: ProjetAuditDto): boolean {
        return projetAudit.statut === StatutProjetAudit.enCours;
    }

    private disableDownloadProjetAudit(projetAudit: ProjetAuditDto): boolean {
        return this.isProjetAuditDownloaded(projetAudit);
    }

    private disableAuditerProjetAudit(projetAudit: ProjetAuditDto): boolean {
        return this._projetAuditList.filter(projet => projet.statut === StatutProjetAudit.enCours).length >= MAX_ACTIVE_PROJET_AUDIT
            || projetAudit.statut === StatutProjetAudit.enCours;
    }

    private disableCompleteProjetAudit(projetAudit: ProjetAuditDto): boolean {
        return getCompleteProjetAuditPermission(projetAudit, this.isProjetAuditDownloaded(projetAudit), this.isOffline);
    }

    public isProjetAuditDownloaded(projetAudit: ProjetAuditDto): boolean {
        return !!this.projetsAuditDownloaded?.find(projet => projet.id === projetAudit.id);
    }

    private downloadProjetAuditConfirm(projetAudit: ProjetAuditDto) {
        this.confirmationService.confirm({
            message: `Attention:
            Si vous travaillez sur le projet sur un autre appareil, il peut y avoir des erreurs de mise à jour.`,
            accept: () => {
                this.downloadProjetAudit(projetAudit);
                this.ref.close(true);
            },
            key: 'downloadProjetAuditConfirm',
        });
    }

    private downloadProjetAudit(projetAudit: ProjetAuditDto) {
        void this.mapService.loadTuilesProjet(JSON.parse(projetAudit.geometrie));
        this.store.dispatch(OfflineActions.addProjetAuditToIndexedDb({ projetAudit }));
        this.store.dispatch(OfflineActions.addProjetToIndexedDb({ projetId: projetAudit.projetId }));
    }

    private openCompleteProjetAuditDialog(projet: ProjetAuditDto) {
        this.ref = this.dialogService.open(CompleteProjetAuditDialogComponent,
            {
                header: `Compléter un projet d'audit`,
                modal: true,
                styleClass: 'mobile-dialog',
                data: { projet }
            });
    }

    ngAfterViewInit(): void {
        this.cdr.detectChanges();
    }
}
