<p-dialog header="Recherche" (onHide)="onCloseDialog(false)" [(visible)]="showRechercheDialog" position="bottom-right" [breakpoints]="$any({'960px': '75vw', '640px': '100vw'})" [style]="{width: '15vw'}">
    <div class="recherche__params">
        <form [formGroup]="rechercheForm">
            <div class="params__type">
                <div class="p-mb-4 p-d-inline-flex p-ai-end">
                    Recherche par&nbsp;:&nbsp;&nbsp;
                    <p-dropdown [options]="dropdownRechercheList" placeholder="Choisir un élément" appendTo="body" formControlName="layerSelect" class="p-ml-1"></p-dropdown>
                </div>
            </div>

            <div class="params__value">
                <div class="value__texte">
                    <div class="texte__input">
                        <span class="p-input-icon-left p-ml-auto" [style]="{width: '100%'}">
                            <i class="pi pi-search"></i>
                            <input pInputText type="text" [minlength]="inputMinMaxLength" [maxlength]="inputMinMaxLength" placeholder="Entrer une valeur" formControlName="queryString" (keyup.enter)="onRecherche()">
                        </span>
                    </div>
                    <div class="texte__aide">
                        <small class="aide__container">
                            <ng-container *ngIf="rechercheForm.controls.layerSelect.value === RECHERCHE_CONFIG.RECHERCHE_LCLCL">
                                <ng-container *ngIf="!displayError || (displayError && rechercheForm.controls.queryString.errors === null)">
                                    <span>Min. de {{totalCaracteres}} car.</span>
                                </ng-container>
                                <ng-container *ngIf="displayError && rechercheForm.controls.queryString.errors?.lclcl">
                                    <span class="error">LCLCL invalide</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="rechercheForm.controls.layerSelect.value === RECHERCHE_CONFIG.RECHERCHE_CODE_BARRES">
                                <ng-container *ngIf="!displayError || (displayError && rechercheForm.controls.queryString.errors === null)">
                                    <span>Min. de {{totalCaracteres}} car.</span>
                                </ng-container>
                                <ng-container *ngIf="displayError && rechercheForm.controls.queryString.errors?.codeabarres">
                                    <span class="error">Code à barres invalide</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="rechercheForm.controls.layerSelect.value === RECHERCHE_CONFIG.RECHERCHE_LIGNE">
                                <ng-container>
                                    <span>Saisir poste et/ou ligne</span>
                                </ng-container>
                            </ng-container>

                            <ng-container *ngIf="displayCount">
                                <span>{{rechercheForm.controls.queryString.value.length}}/{{totalCaracteres}}</span>
                            </ng-container>
                        </small>
                    </div>
                </div>
                <div class="value__checkbox">
                    <div *ngIf="!isUserMobile" class="p-field-checkbox checkbox__container">
                        <!-- <p-checkbox [formControl]="$any(rechercheForm).controls.includeSig" [binary]="true" [disabled]="!displaySig"></p-checkbox> -->
                        <p-checkbox [formControl]="$any(rechercheForm).controls.includeSig" [binary]="true"></p-checkbox>
                        <label [class.disable]="rechercheForm.controls.includeSig.disabled">Inclure SIG</label>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <div>
        <app-waiting *ngIf="rechercheIsLoading$ | async" [displayLabel]="true"></app-waiting>
        <div class="p-mt-3" *ngIf="rechercheForm.controls.layerSelect.value !== null">
            <!-- <div *ngIf="rechercheListe$ | async as liste"> -->
                <!-- <div *ngIf="liste.length > 0 || liste.hasOwnProperty('groupename')"> -->
                    <p-table *ngIf="showLine" [columns]="colonnes"  [value]="liste.groupename" [(first)]="premierePage"
                        responsiveLayout="scroll" selectionMode='single' [paginator]="liste.groupename.length > 0" [rows]="6"
                        [showCurrentPageReport]="false" (onRowSelect)="zoomFeature($event.data)">
                        <ng-template pTemplate="header" let-columns>
                            <tr *ngIf="liste.groupename.length > 0">
                                <th *ngFor="let col of columns " class="p-flex-column" >
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" *ngIf="showLine">
                            <tr [pSelectableRow]="rowData">
                                <td *ngFor="let col of columns" class="p-flex-column" >
                                    {{rowData[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns >
                            <tr>
                                <td [attr.colspan]="columns.length" *ngIf="showAucunResult">
                                    Aucun résultat ne correspond à votre recherche
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                    <p-table *ngIf="!showLine " [columns]="colonnes" [value]="liste" [(first)]="premierePage" responsiveLayout="scroll"
                    selectionMode='single' [paginator]="liste.length > 0" [rows]="6" [showCurrentPageReport]="false" (onRowSelect)="zoomFeature($event.data)">
                        <ng-template pTemplate="header" let-columns>
                            <tr *ngIf="liste.length > 0">
                                <th *ngFor="let col of columns " class="p-flex-column" >
                                    {{col.header}}
                                </th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-rowData let-columns="columns" >
                            <tr [pSelectableRow]="rowData"  >
                                <td *ngFor="let col of columns" class="p-flex-column" >
                                    {{rowData.properties[col.field]}}
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage" let-columns >
                            <tr>
                                <td [attr.colspan]="columns.length" *ngIf="showAucunResult">
                                    Aucun résultat ne correspond à votre recherche
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                <!-- </div> -->
            <!-- </div> -->
        </div>
    </div>
</p-dialog>
