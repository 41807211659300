import { CommonConfiguration } from '@ngxhq/config';

/**
 * Configuration applicative chargée au démarrage à partir du fichier app.json.
 * Les valeurs de la configuration peuvent être accédées seulement après qu'elles ont été chargées : lorsque les APP_INITIALIZER sont terminés ou après que
 * @see configurationAvailable de @see CommonConfigurationService<AppConfiguration> soit terminé.
 * @summary Configuration applicative chargée à partir du fichier app.json
 */
export class AppConfiguration implements CommonConfiguration {
    // IMPORTANT!!!
    // - INITIALISEZ les `= undefined as any` pour chaque propriétés racine pour que Internet Explorer fonctionne correctement.
    baseBackendUrl: string = undefined as any;
    baseEsriUrl: string = undefined as any;
    baseTileServiceUrl: string = undefined as any;
    mapbox: MapboxConfiguration = undefined as any;
    environment: string = undefined as any;
}

export class MapboxConfiguration {
    accessToken: string = undefined as any;
}
