import { ActionReducer } from '@ngrx/store';
import { environment } from '@app/environment';

export function logger(
    reducer: ActionReducer<any>
): ActionReducer<any> {
    return (state: any, action: any) => {
        if (environment.config.logStoreActions) {
            console.info('[Store] State', state);
            console.info('[Store] Action', action);
        }

        return reducer(state, action);
    };
}
