import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Point } from '@turf/turf';
import { Feature } from 'geojson';
import { GeoJSONSource } from 'mapbox-gl';
import { MapLayersSources } from '../../../models/map-layers-sources.enum';
import { filter, takeUntil } from 'rxjs/operators';
import { TypePosition } from '../../../../enums/typePosition';
import { MapService } from '../../../services/map.service';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { Store } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { UserInformation } from '../../../../shared/models/user-informations.model';
import { StatutPointInspection } from '../../../../features/inspection/models/statut-point-inspection.enum';
import { StatutPoteau } from '../../../../enums/statutPoteau';
import { PoteauAction } from '../../../models/poteau-action.enum';
import { InfoPoint } from '../../../models/info-point.model';
import { Guid } from 'guid-typescript';
import { PhotoEntity, PointAuditDto, PointInspectionDto, ProjetAuditDto, ProjetCompletDto } from '../../../../core/api/client/models';
import { getCurrentActiveProjetAudit } from '../../../../features/audit/state/audit.selectors';
import * as InspectionActions from '../../../../features/inspection/state/inspection.actions';
import { getCreatePointInspectionSuccess, getCurrentActiveProjetInspection } from '../../../../features/inspection/state/inspection.selectors';
import { PhotoService } from '../../../../services/photo.service';

@Component({
    selector: 'app-create-move-poteau-panel',
    templateUrl: './create-move-poteau-panel.component.html',
    styleUrls: ['./create-move-poteau-panel.component.scss']
})
export class CreateMovePoteauPanelComponent extends BaseComponent implements OnChanges, OnDestroy {

    @Input() infoPointInspection: InfoPoint;
    @Input() selectedPointInspection: PointInspectionDto;
    @Input() createPoteauInfo: PointInspectionDto | null;
    @Input() currentUserInfo: UserInformation;
    @Input() createPoteauSansAnomalie: boolean;
    @Input() createPoteauPointAudit: boolean;
    @Input() visible: boolean;
    @Output() createPoteauInfoChange = new EventEmitter<null>();
    @Output() createPoteauSansAnomalieChange = new EventEmitter<boolean>();
    @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() createPointAudit = new EventEmitter<PointAuditDto>();

    private pointAudit: PointAuditDto;
    private currentActiveProjetAudit: ProjetAuditDto;
    private currentActiveProjetInspection: ProjetCompletDto;
    private createPoteauHasPhotos = false;
    private photosWithData: PhotoEntity[] = [];
    private currentPointInspection: PointInspectionDto;

    constructor(
        private store: Store<State>,
        private mapService: MapService,
        private photoService: PhotoService
    ) {
        super();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.visible) {
            if (changes.visible.currentValue === true) {
                this.subscribeToCurrentActiveProjetInspection();
                this.subscribeToCurrentActiveProjetAudit();
            } else if (changes.visible.currentValue === false) {
                this.ngOnDestroy();
            }
        }
    }

    public get map(): mapboxgl.Map {
        return this.mapService.map as any;
    }

    private subscribeToCurrentActiveProjetAudit() {
        this.store.select(getCurrentActiveProjetAudit).pipe(
            filter(currentActiveProjetAudit => !!currentActiveProjetAudit),
            takeUntil(this.destroyed)
        ).subscribe(currentActiveProjetAudit => {
            this.currentActiveProjetAudit = currentActiveProjetAudit;
        });
    }

    private subscribeToCurrentActiveProjetInspection() {
        this.store.select(getCurrentActiveProjetInspection).pipe(
            filter(currentActiveProjetInspection => !!currentActiveProjetInspection),
            takeUntil(this.destroyed)
        ).subscribe(currentActiveProjetInspection => {
            this.currentActiveProjetInspection = currentActiveProjetInspection;
        });
    }

    public saveCreateMovePoteau() {
        if (this.createPoteauInfo) {
            this.currentPointInspection = {
                ...this.createPoteauInfo,
                anomalies: [],
                geometrie: JSON.stringify(this.getGeometrie()),
                projetId: this.currentActiveProjetInspection.id,
                poteau: {
                    ...this.createPoteauInfo.poteau,
                    typePositionDeplacement: this.mapService.trackingGPS ? TypePosition.gps : TypePosition.manuel,
                    geometrie: JSON.stringify(this.getGeometrie())
                }
            };
            this.saveCreatePointInspection();
            this.mapService.zoomPointInspection(this.currentPointInspection);
        } else {
            const updatedPointInspection: PointInspectionDto = {
                ...this.selectedPointInspection,
                geometrie: JSON.stringify(this.getGeometrie()),
                poteau: {
                    ...this.selectedPointInspection.poteau,
                    statut: StatutPoteau.modifie,
                    typePositionDeplacement: TypePosition.manuel,
                    geometrie: JSON.stringify(this.getGeometrie()),
                }
            };

            this.store.dispatch(InspectionActions.updatePointInspection({ pointInspection: updatedPointInspection, poteauAction: PoteauAction.DEPLACEMENT }));

            const pointInspection: Feature = { type: 'Feature', geometry: this.getGeometrie(), properties: [], };
            (this.map.getSource(MapLayersSources.POTEAU_SELECTED) as GeoJSONSource).setData(pointInspection);
        }

        if (!this.createPoteauHasPhotos) {
            this.closeCreateMovePoteauPanel();
        }
    }

    private subscribeToCreatePointInspectionSuccess() {
        this.store.select(getCreatePointInspectionSuccess)
            .pipe(
                filter(success => !!success),
                takeUntil(this.destroyed),
            ).subscribe(success => {
                if (success && this.photosWithData.length > 0) {
                    this.savePointInspectionPhoto(this.photosWithData);
                    this.closeCreateMovePoteauPanel();
                }
            });
    }

    private saveCreatePointInspection() {

        this.photosWithData = this.currentPointInspection.photos;

        this.currentPointInspection = {
            ...this.currentPointInspection,
            photos: this.photoService.removeDataFromPhotoArray(this.photosWithData)
        };

        this.store.dispatch(InspectionActions.createPointInspection({ pointInspection: this.currentPointInspection }));

        this.createPoteauHasPhotos = this.photosWithData.length > 0;

        if (this.createPoteauHasPhotos) {
            this.subscribeToCreatePointInspectionSuccess();
        }
    }

    public savePointInspectionPhoto(updatedPointInspectionPhotos: PhotoEntity[]) {
        updatedPointInspectionPhotos.forEach((addedPhoto: PhotoEntity) => {
            this.store.dispatch(InspectionActions.addPointInspectionPhoto({ pointInspection: this.currentPointInspection, photo: addedPhoto }));
        });
    }

    private saveCreatePoteauPointAudit() {
        this.pointAudit = {
            pointInspectionId: Guid.create().toString(),
            projetAuditId: this.currentActiveProjetAudit?.id,
            geometrie: JSON.stringify(this.getGeometrie())
        };

        this.closeCreatePointAudit();
    }

    public saveCreatePoteauSansAnomalie() {
        const pointInspection: PointInspectionDto = {
            id: Guid.create().toString(),
            projetId: this.currentActiveProjetInspection.id,
            photos: [],
            inspecteLe: new Date().getTime(),
            inspectePar: this.currentUserInfo.courriel,
            statut: StatutPointInspection.sansAnomalie,
            geometrie: JSON.stringify(this.getGeometrie()),
            poteau: {
                id: Guid.create().toString(),
                typePositionDeplacement: this.mapService.trackingGPS ? TypePosition.gps : TypePosition.manuel,
                geometrie: JSON.stringify(this.getGeometrie())
            },
        };

        this.store.dispatch(InspectionActions.createPointInspection({ pointInspection }));
        this.mapService.zoomPointInspection(pointInspection);

        this.closeCreateMovePoteauPanel();
    }

    private getGeometrie(): Point {
        const mapCenter = this.map.getCenter();
        return { type: 'Point', coordinates: [mapCenter.lng, mapCenter.lat] };
    }

    public onSaveCreateMovePoteau() {
        if (this.createPoteauSansAnomalie) {
            this.saveCreatePoteauSansAnomalie();
        } else if (this.createPoteauPointAudit) {
            this.saveCreatePoteauPointAudit();
        } else {
            this.saveCreateMovePoteau();
        }
    }

    public onCancelCreateMovePoteau() {
        if (this.createPoteauPointAudit) {
            this.closeCreatePointAudit();
        } else {
            this.closeCreateMovePoteauPanel();
        }
    }

    private closeCreatePointAudit() {
        this.createPointAudit.emit(this.pointAudit);
        this.close();
    }

    public closeCreateMovePoteauPanel() {
        this.createPoteauSansAnomalie = false;
        this.createPoteauSansAnomalieChange.emit(this.createPoteauSansAnomalie);
        this.close();
    }

    private close() {
        this.createPoteauInfoChange.emit(null);
        this.visible = false;
        this.visibleChange.emit(this.visible);
    }
}
