import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { min } from 'lodash';
import { PointInspection } from 'src/app/features/projet/models/point-inspection.model';
import { StatutPointInspection, StatutPointInspectionNumberValue } from '../../../features/inspection/models/statut-point-inspection.enum';
import { Anomalie } from '../../../features/anomalie/models/anomalie.model';
import {
    createAnomalieSuccess, createPointInspectionSuccess,
    deleteAnomalieSuccess,
    getPointsInspectionBboxSuccess,
    patchPointInspectionSuccess,
    setPointInspectionSansAnomalieSuccess,
    setPointsInspectionSuccess,
    updatePointInspectionSuccess
} from '../actions/pointInspection.action';

export interface PointInspectionState extends EntityState<PointInspection> { }

export const pointInspectionAdapter: EntityAdapter<PointInspection> = createEntityAdapter<PointInspection>({
    selectId: (pointInspection: PointInspection) => pointInspection.id
});

export const pointInspectionSelector = pointInspectionAdapter.getSelectors();

export const initialpointInspectionAdapter = pointInspectionAdapter.getInitialState();

export const pointInspectionReducer = createReducer(initialpointInspectionAdapter,
    on(getPointsInspectionBboxSuccess,
        (state, action) => pointInspectionAdapter.upsertMany(action.pointsInspection, state)),
    on(patchPointInspectionSuccess,
        (state, action) => pointInspectionAdapter.updateOne(action.pointInspection, state)),
    on(setPointInspectionSansAnomalieSuccess,
        (state, action) => pointInspectionAdapter.updateOne(action.pointInspection, state)),
    on(createAnomalieSuccess,
        (state, action) => {
            let newAnomalies: Anomalie[] = [];
            const currentAnomalies: Anomalie[] = [...state.entities[action.anomalie.inspectionId as string]?.anomalies || []];
            const index = currentAnomalies.findIndex((anomalie: Anomalie) => anomalie.id === action.anomalie.id);
            const anomalieExistante = currentAnomalies.find((anomalie: Anomalie) => anomalie.id === action.anomalie.id);
            if (anomalieExistante) {
                currentAnomalies[index] = action.anomalie;
                newAnomalies = [...currentAnomalies];
            } else {
                newAnomalies = [...currentAnomalies, action.anomalie];
            };
            const hasUrgence = newAnomalies.find((a: Anomalie) => a.urgence);
            const newAnomaliesMap = newAnomalies.map((a: Anomalie) => StatutPointInspectionNumberValue[a.priorite]);
            const statutPriorite: number = min(newAnomaliesMap);

            const newStatut = hasUrgence ? StatutPointInspection.urgence : StatutPointInspectionNumberValue[statutPriorite];

            return pointInspectionAdapter.updateOne({
                id: action.anomalie.inspectionId as string,
                changes: { ...state.entities[action.anomalie.inspectionId as string], anomalies: newAnomalies, statut: newStatut }
            }, state);
        }),
    on(deleteAnomalieSuccess,
        (state, action) => {
            const pointInpsection = state.entities[action.pointInspectionId as string];
            const anomalies = [...pointInpsection?.anomalies || []].filter(({ id }) => id !== action.anomalieId);

            let newStatut: string;
            if (anomalies.length) {
                const hasUrgence = anomalies.find(a => a.urgence);
                const statutPriorite: number = min(anomalies.map(a => StatutPointInspectionNumberValue[a.priorite]));

                newStatut = hasUrgence ? StatutPointInspection.urgence : StatutPointInspectionNumberValue[statutPriorite];
            } else {
                newStatut = StatutPointInspection.sansAnomalie;
            }

            return pointInspectionAdapter.updateOne({
                id: action.pointInspectionId as string,
                changes: { ...pointInpsection, anomalies, statut: newStatut }
            }, state);
        }),
    on(updatePointInspectionSuccess,
        (state, action) => pointInspectionAdapter.updateOne(action.pointInspection, state)
    ),
    on(createPointInspectionSuccess,
        (state, action) => {
            return pointInspectionAdapter.upsertOne(action.pointInspection, state);
        }
    ),
    on(setPointsInspectionSuccess, (state, action) => pointInspectionAdapter.setAll(action.pointsInspection, state)),
);
