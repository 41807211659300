import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { dateTimeChange } from '../../../shared/utils';
import { ProjetAuditHistory } from '../models/projet-audit-history.model';
import {
    ProjetService,
    ProjetAuditsService,
    PointsAuditService,
} from '../../../core/api/client/services';
import {
    ProjetAuditDto,
    PointAuditDto,
    SearchResult,
    PhotoEntity,
    CreatedProjetAuditDto
} from '../../../core/api/client/models';

@Injectable({
    providedIn: 'root'
})
export class AuditService {

    constructor(
        private projetApiService: ProjetService,
        private pointsAuditApiService: PointsAuditService,
        private projetAuditsApiService: ProjetAuditsService,
    ) { }

    public getProjetsAudit(params: ProjetAuditsService.GetApiV1AuditsParams): Observable<ProjetAuditDto[]> {
        return this.projetAuditsApiService.getApiV1Audits(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public getProjetAudit(projetAuditId: string): Observable<ProjetAuditDto> {
        return this.projetAuditsApiService.getApiV1AuditsId(projetAuditId);
    }

    public getProjetAuditHistory(params: ProjetAuditsService.GetApiV1AuditsParams, projetAuditId: string): Observable<ProjetAuditHistory[]> {
        return this.projetAuditsApiService.getApiV1Audits(params)
            .pipe(
                map(data => this.formatProjetAuditHistory(data, projetAuditId))
            );
    }

    public getPointsAudit(params: PointsAuditService.GetApiV1PointsauditParams): Observable<PointAuditDto[]> {
        return this.pointsAuditApiService.getApiV1Pointsaudit(params)
            .pipe(
                map(data => {
                    return data.items.map(item => {
                        return {
                            ...item
                        };
                    });
                })
            );
    }

    public createProjetAudit(params: ProjetService.PostApiV1ProjetsIdAuditsParams): Observable<CreatedProjetAuditDto> {
        return this.projetApiService.postApiV1ProjetsIdAudits(params);
    }

    public getCreateStatus(id: string) {
        return this.projetAuditsApiService.getApiV1AuditsIdStatut(id);
    }

    public updateProjetAudit(params: ProjetAuditsService.PatchApiV1AuditsIdParams): Observable<ProjetAuditDto> {
        return this.projetAuditsApiService.patchApiV1AuditsId(params);
    }

    public createPointAudit(params: PointAuditDto): Observable<PointAuditDto> {
        return this.pointsAuditApiService.postApiV1Pointsaudit(params);
    }

    public updatePointAudit(params: PointsAuditService.PutApiV1PointsauditPointAuditIdParams): Observable<PointAuditDto> {
        return this.pointsAuditApiService.putApiV1PointsauditPointAuditId(params);
    }

    public addPointAudit(params: ProjetAuditsService.PostApiV1AuditsIdInspectionsPointInspectionIdParams): Observable<ProjetAuditDto> {
        return this.projetAuditsApiService.postApiV1AuditsIdInspectionsPointInspectionId(params);
    }

    public addPointAuditPhoto(params: PointsAuditService.PostApiV1PointsauditPointAuditIdPhotosParams): Observable<PhotoEntity> {
        return this.pointsAuditApiService.postApiV1PointsauditPointAuditIdPhotos(params);
    }

    public addAnomalieAuditPhoto(params: PointsAuditService.PostApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosParams): Observable<PhotoEntity> {
        return this.pointsAuditApiService.postApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotos(params);
    }

    public deletePointAuditPhoto(params: PointsAuditService.DeleteApiV1PointsauditPointAuditIdPhotosIdPhotoParams): Observable<null> {
        return this.pointsAuditApiService.deleteApiV1PointsauditPointAuditIdPhotosIdPhoto(params);
    }

    public deleteAnomalieAuditPhoto(params: PointsAuditService.DeleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams): Observable<null> {
        return this.pointsAuditApiService.deleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhoto(params);
    }

    private formatProjetAuditHistory(data: SearchResult, projetAuditId: string): ProjetAuditHistory[] {
        const projetAuditHistory: ProjetAuditHistory[] = [];
        data.items!
            .filter(item => item.historique?.length)
            .map(item => {
                item.historique!
                    .filter((historique: ProjetAuditHistory) => historique.auditId === projetAuditId)
                    .map((historique: ProjetAuditHistory) => {
                        projetAuditHistory.push(
                            {
                                id: historique.id,
                                auditId: historique.auditId,
                                nom: item.nom,
                                statut: historique.statut,
                                creeLe: dateTimeChange(historique.creeLe?.toString(), 'YYYY-MM-dd'),
                                creePar: historique.creePar,
                                assigneLe: dateTimeChange(historique.assigneLe?.toString(), 'YYYY-MM-dd'),
                                assignePar: historique.assignePar,
                                assigneA: historique.assigneA,
                                activeLe: dateTimeChange(historique.activeLe?.toString(), 'YYYY-MM-dd'),
                                activePar: historique.activePar,
                                completeLe: dateTimeChange(historique.completeLe?.toString(), 'YYYY-MM-dd'),
                                completePar: historique.completePar,
                                approuveLe: dateTimeChange(historique.approuveLe?.toString(), 'YYYY-MM-dd'),
                                approuvePar: historique.approuvePar,
                                annuleLe: dateTimeChange(historique.annuleLe?.toString(), 'YYYY-MM-dd'),
                                annulePar: historique.annulePar
                            });
                    });
            });
        return projetAuditHistory;
    }
}
