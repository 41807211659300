
import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';
import { PointInspectionProperties } from '../../../enums/point-inspection-properties.enum';

export const codeABarres: AnyLayer = {
    id: MapLayersSources.CODEABARRES,
    type: 'symbol',
    source: MapLayersSources.CODEABARRES,
    minzoom: mapStyleConfig.codeABarres.minZoom,
    layout: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'text-field': ['get', PointInspectionProperties.codeABarres],
        'text-anchor': 'top',
        'text-allow-overlap': false,
        'text-offset': [
            0,
            1
        ],
        'text-optional': true,
        'text-font': [
            'Arial Normal'
        ]
        /* eslint-enable @typescript-eslint/naming-convention */
    },
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 1
        /* eslint-enable @typescript-eslint/naming-convention */
    }
};
