export enum PointAuditProperties {
    projetAuditId = 'projetAuditId',
    auditeLe = 'Audité le',
    auditePar = 'Audité par',
    remarque = 'Remarque',
    justification = 'Justification',
    statut = 'Statut',
    statutGlobal = 'StatutGlobal',
    containsAnomalieNonConforme = 'containsAnomalieNonConforme',
    type = 'Type',
    geometrie = 'geometrie',
    pointInspectionId = 'pointInspectionId',
    id = 'id',
    anomaliesAudit = 'anomaliesAudit',
    photos = 'photos',
}
