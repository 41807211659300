import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { createReducer, on } from '@ngrx/store';
import { AnomaliePilotage } from '../../../shared/models/anomalie-pilotage.model';
import { getAllAnomaliesPilotageSuccess } from '../actions/anomaliesPilotage.actions';

export interface AnomaliePilotageState extends EntityState<AnomaliePilotage> { }

export const anomaliePilotageAdapter: EntityAdapter<AnomaliePilotage> = createEntityAdapter<AnomaliePilotage>({
    selectId: (anomaliePilotage: AnomaliePilotage) => anomaliePilotage.id
});

export const anomaliePilotageSelector = anomaliePilotageAdapter.getSelectors();

export const initialAnomaliePilotageAdapter = anomaliePilotageAdapter.getInitialState();

export const anomaliePilotageReducer = createReducer(initialAnomaliePilotageAdapter,
    on(
        getAllAnomaliesPilotageSuccess,
        (state, action) => anomaliePilotageAdapter.upsertMany(action.anomaliesPilotage, state)
    )
);
