/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { TaxonomieDto } from '../models/taxonomie-dto';
import { TaxonomieBaseDto } from '../models/taxonomie-base-dto';
@Injectable({
  providedIn: 'root',
})
class TaxonomieService extends __BaseService {
  static readonly getApiV1TaxonomiesPath = '/api/v1/taxonomies';
  static readonly postApiV1TaxonomiesPath = '/api/v1/taxonomies';
  static readonly putApiV1TaxonomiesTaxonomieIdPath = '/api/v1/taxonomies/{taxonomieId}';
  static readonly deleteApiV1TaxonomiesTaxonomieIdPath = '/api/v1/taxonomies/{taxonomieId}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `TaxonomieService.GetApiV1TaxonomiesParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `orderBy`:
   *
   * - `groupe`:
   *
   * - `estActif`:
   *
   * - `code`:
   *
   * @return Success
   */
  getApiV1TaxonomiesResponse(params: TaxonomieService.GetApiV1TaxonomiesParams): __Observable<__StrictHttpResponse<Array<TaxonomieDto>>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.orderBy != null) __params = __params.set('orderBy', params.orderBy.toString());
    if (params.groupe != null) __params = __params.set('groupe', params.groupe.toString());
    if (params.estActif != null) __params = __params.set('estActif', params.estActif.toString());
    if (params.code != null) __params = __params.set('code', params.code.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/taxonomies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<Array<TaxonomieDto>>;
      })
    );
  }
  /**
   * @param params The `TaxonomieService.GetApiV1TaxonomiesParams` containing the following parameters:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `orderBy`:
   *
   * - `groupe`:
   *
   * - `estActif`:
   *
   * - `code`:
   *
   * @return Success
   */
  getApiV1Taxonomies(params: TaxonomieService.GetApiV1TaxonomiesParams): __Observable<Array<TaxonomieDto>> {
    return this.getApiV1TaxonomiesResponse(params).pipe(
      __map(_r => _r.body as Array<TaxonomieDto>)
    );
  }

  /**
   * @param body undefined
   * @return Created
   */
  postApiV1TaxonomiesResponse(body?: TaxonomieBaseDto): __Observable<__StrictHttpResponse<TaxonomieDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    __body = body;
    let req = new HttpRequest<any>(
      'POST',
      this.rootUrl + `/api/v1/taxonomies`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaxonomieDto>;
      })
    );
  }
  /**
   * @param body undefined
   * @return Created
   */
  postApiV1Taxonomies(body?: TaxonomieBaseDto): __Observable<TaxonomieDto> {
    return this.postApiV1TaxonomiesResponse(body).pipe(
      __map(_r => _r.body as TaxonomieDto)
    );
  }

  /**
   * @param params The `TaxonomieService.PutApiV1TaxonomiesTaxonomieIdParams` containing the following parameters:
   *
   * - `taxonomieId`:
   *
   * - `body`:
   *
   * @return Success
   */
  putApiV1TaxonomiesTaxonomieIdResponse(params: TaxonomieService.PutApiV1TaxonomiesTaxonomieIdParams): __Observable<__StrictHttpResponse<TaxonomieDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    __body = params.body;
    let req = new HttpRequest<any>(
      'PUT',
      this.rootUrl + `/api/v1/taxonomies/${encodeURIComponent(String(params.taxonomieId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<TaxonomieDto>;
      })
    );
  }
  /**
   * @param params The `TaxonomieService.PutApiV1TaxonomiesTaxonomieIdParams` containing the following parameters:
   *
   * - `taxonomieId`:
   *
   * - `body`:
   *
   * @return Success
   */
  putApiV1TaxonomiesTaxonomieId(params: TaxonomieService.PutApiV1TaxonomiesTaxonomieIdParams): __Observable<TaxonomieDto> {
    return this.putApiV1TaxonomiesTaxonomieIdResponse(params).pipe(
      __map(_r => _r.body as TaxonomieDto)
    );
  }

  /**
   * @param taxonomieId undefined
   */
  deleteApiV1TaxonomiesTaxonomieIdResponse(taxonomieId: string): __Observable<__StrictHttpResponse<null>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'DELETE',
      this.rootUrl + `/api/v1/taxonomies/${encodeURIComponent(String(taxonomieId))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<null>;
      })
    );
  }
  /**
   * @param taxonomieId undefined
   */
  deleteApiV1TaxonomiesTaxonomieId(taxonomieId: string): __Observable<null> {
    return this.deleteApiV1TaxonomiesTaxonomieIdResponse(taxonomieId).pipe(
      __map(_r => _r.body as null)
    );
  }
}

module TaxonomieService {

  /**
   * Parameters for getApiV1Taxonomies
   */
  export interface GetApiV1TaxonomiesParams {
    pageSize?: number;
    pageNumber?: number;
    orderBy?: string;
    groupe?: string;
    estActif?: boolean;
    code?: string;
  }

  /**
   * Parameters for putApiV1TaxonomiesTaxonomieId
   */
  export interface PutApiV1TaxonomiesTaxonomieIdParams {
    taxonomieId: string;
    body?: TaxonomieBaseDto;
  }
}

export { TaxonomieService }
