import { createAction, props } from '@ngrx/store';
import { SyncErrors } from '../models/sync-errors.model';
import { Requests } from '../models/requests.model';

export enum SyncActionType {
    GET_SYNC_ERRORS = '[Synchronisation] Get Sync Errors',
    GET_SYNC_ERRORS_SUCCESS = '[Synchronisation] Get Sync Errors Success',
    GET_SYNC_ERRORS_FAILURE = '[Synchronisation] Get Sync Errors Failure',
    ADD_SYNC_ERRORS = '[Synchronisation] Add Sync Errors',
    ADD_SYNC_ERRORS_SUCCESS = '[Synchronisation] Add Sync Errors Success',
    ADD_SYNC_ERRORS_FAILURE = '[Synchronisation] Add Sync Errors Failure',
    DELETE_SYNC_ERRORS = '[Synchronisation] Delete Sync Errors',
    DELETE_SYNC_ERRORS_SUCCESS = '[Synchronisation] Delete Sync Errors Success',
    DELETE_SYNC_ERRORS_FAILURE = '[Synchronisation] Delete Sync Errors Failure',
    GET_REQUESTS = '[Synchronisation] Get Requests',
    GET_REQUESTS_SUCCESS = '[Synchronisation] Get Requests Success',
    GET_REQUESTS_FAILURE = '[Synchronisation] Get Requests Failure',
    UPLOAD_REQUESTS = '[Synchronisation] Upload Requests To S3',
    UPDLOAD_REQUESTS_SUCCESS = '[Synchronisation] Upload Requests To S3 Success',
    UPLOAD_REQUESTS_FAILURE = '[Synchronisation] Upload Requests To S3 Failure',
    DELETE_REQUESTS = '[Synchronisation] Delete Requests',
    DELETE_REQUESTS_SUCCESS = '[Synchronisation] Delete Requests Success',
    DELETE_REQUESTS_FAILURE = '[Synchronisation] Delete Requests Failure'
}

export const getSyncErrors = createAction(SyncActionType.GET_SYNC_ERRORS);

export const getSyncErrorsSuccess = createAction(SyncActionType.GET_SYNC_ERRORS_SUCCESS, props<{ syncErrors: SyncErrors[] }>());

export const getSyncErrorsFailure = createAction(SyncActionType.GET_SYNC_ERRORS_FAILURE, props<{ error: any }>());

export const addSyncErrors = createAction(SyncActionType.ADD_SYNC_ERRORS, props<{ syncErrors: SyncErrors[] }>());

export const addSyncErrorsSuccess = createAction(SyncActionType.ADD_SYNC_ERRORS_SUCCESS, props<{ syncErrors: number[] }>());

export const addSyncErrorsFailure = createAction(SyncActionType.ADD_SYNC_ERRORS_FAILURE, props<{ error: any }>());

export const deleteSyncErrors = createAction(SyncActionType.DELETE_SYNC_ERRORS);

export const deleteSyncErrorsSuccess = createAction(SyncActionType.DELETE_SYNC_ERRORS_SUCCESS, props<{ success: boolean }>());

export const deleteSyncErrorsFailure = createAction(SyncActionType.DELETE_SYNC_ERRORS_FAILURE, props<{ error: any }>());

export const getRequests = createAction(SyncActionType.GET_REQUESTS);

export const getRequestsSuccess = createAction(SyncActionType.GET_REQUESTS_SUCCESS, props<{ requests: Requests[] }>());

export const getRequestsFailure = createAction(SyncActionType.GET_REQUESTS_FAILURE, props<{ error: any }>());

export const uploadRequests = createAction(SyncActionType.UPLOAD_REQUESTS,
    props<{ request: Requests[], numeroRequete: number, totalRequete: number, dateSauvegarde: number }>());

export const uploadRequestsSuccess = createAction(SyncActionType.UPDLOAD_REQUESTS_SUCCESS, props<{ request: Requests[] }>());

export const uploadRequestsFailure = createAction(SyncActionType.UPLOAD_REQUESTS_FAILURE, props<{ error: any }>());

export const deleteRequests = createAction(SyncActionType.DELETE_REQUESTS);

export const deleteRequestsSuccess = createAction(SyncActionType.DELETE_REQUESTS_SUCCESS, props<{ success: boolean }>());

export const deleteRequestsFailure = createAction(SyncActionType.DELETE_REQUESTS_FAILURE, props<{ error: any }>());

