import { createSelector } from '@ngrx/store';
import { CoreState, getCoreState } from '../reducers';
import { anomaliePilotageSelector } from '../reducers/anomaliesPilotage.reducer';


export const getAnomaliePilotageStore = createSelector(
    getCoreState,
    (state: CoreState) => state.anomaliesPilotage
);

export const getAllAnomaliesPilotageState = createSelector(
    getAnomaliePilotageStore,
    anomaliePilotageSelector.selectAll
);
