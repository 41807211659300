import { Projet, StatutProjet } from '../models';
import { UserRole } from '../../../shared/models/user-roles.model';
import { UserInformation } from '../../../shared/models/user-informations.model';

export function getAssigneProjetPermission(projet: Projet, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canAssigneProjetStatutList: string[] =
        [
            StatutProjet.aqAssigne,
            StatutProjet.aqEnCours,
            StatutProjet.aqCompletee,
            StatutProjet.inspectionAssigne,
            StatutProjet.inspectionEnCours,
            StatutProjet.inspectionCompletee,
            StatutProjet.nouveau
        ];

    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
        case UserRole.ADMIN_TIC:
        case UserRole.ADMIN_GCSP:
        case UserRole.PILOTE:
            return canAssigneProjetStatutList.includes(projet.statut!)
                && projet.firme === currentUserInfos.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return canAssigneProjetStatutList.includes(projet.statut!)
                && projet.firme === currentUserInfos.firme
                && projet.creePar?.toLowerCase() === currentUserInfos.courriel!.toLowerCase();
        default:
            return false;
    }
}

export function getAnnuleProjetPermission(projet: Projet, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canAnnuleProjetStatutList: string[] =
        [
            StatutProjet.inspectionAssigne,
            StatutProjet.nouveau,
            StatutProjet.enCreation,
            StatutProjet.erreur
        ];

    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
            return canAnnuleProjetStatutList.includes(projet.statut!)
                && projet.firme === currentUserInfos.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return canAnnuleProjetStatutList.includes(projet.statut!) &&
                projet.creePar?.toLowerCase() === currentUserInfos.courriel!.toLowerCase();
        case UserRole.ADMIN_TIC:
        case UserRole.PILOTE:
        case UserRole.ADMIN_GCSP:
            return canAnnuleProjetStatutList.includes(projet.statut!);
        default:
            return false;
    }
}

export function getModifierProjetPermission(projet: Projet, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canUpdateProjetStatutList: string[] =
        [
            StatutProjet.inspectionCompletee,
            StatutProjet.aqEnCours,
            StatutProjet.nouveau
        ];
    switch (currentUserRole) {
        case UserRole.ADMIN_EXTERNE:
            return projet.firme === currentUserInfos.firme;
        case UserRole.TECH_INGENIEURS_RESEAU:
            return canUpdateProjetStatutList.includes(projet.statut!);
        case UserRole.ADMIN_TIC:
        case UserRole.PILOTE:
        case UserRole.ADMIN_GCSP:
            return true;
        default:
            return false;
    }
}

export function getValiderProjetPermission(projet: Projet, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canValiderProjetStatutList: string[] = [StatutProjet.inspectionCompletee, StatutProjet.aqCompletee, StatutProjet.correctionCompletee];
    if (UserRole.ADMIN_EXTERNE.includes(currentUserRole)) {
        return canValiderProjetStatutList.includes(projet.statut!)
            && projet.firme === currentUserInfos.firme;
    } else {
        return canValiderProjetStatutList.includes(projet.statut!);
    }
}

export function getApprouveProjetPermission(projet: Projet): boolean {
    const canApprouveProjetStatutList = [StatutProjet.attenteApprobationHQ, StatutProjet.conforme, StatutProjet.audite];
    return canApprouveProjetStatutList.includes(projet.statut);
}

export function getRejeterProjetPermission(projet: Projet): boolean {
    const canRejeterProjetStatutList = [StatutProjet.attenteApprobationHQ, StatutProjet.audite];
    return canRejeterProjetStatutList.includes(projet.statut);
}

export function getEnvoyerAvisPermission(projet: Projet, currentUserRole: UserRole, currentUserInfos: UserInformation): boolean {
    const canEnvoyerAvisStatutList: string[] =
        [
            StatutProjet.inspectionAssigne,
            StatutProjet.inspectionEnCours,
            StatutProjet.inspectionCompletee,
            StatutProjet.aqAssigne,
            StatutProjet.aqEnCours,
            StatutProjet.aqCompletee
        ];
    if (UserRole.ADMIN_EXTERNE.includes(currentUserRole)) {
        return canEnvoyerAvisStatutList.includes(projet.statut!)
            && projet.firme === currentUserInfos.firme;
    } else {
        return projet.statut !== StatutProjet.approbationFinaleHQ;
    }
}

export function getCreateAuditPermission(projet: Projet): boolean {
    return projet.statut === StatutProjet.attenteApprobationHQ;
}

export function getAssigneProjetAQPermission(projet: Projet): boolean {
    return projet.statut === StatutProjet.inspectionCompletee ||
        projet.statut === StatutProjet.aqAssigne ||
        projet.statut === StatutProjet.aqEnCours ||
        projet.statut === StatutProjet.aqCompletee;
}

export function getRapportCreationAvisPermission(projet: Projet): boolean {
    return (projet.resultatTransferAvis || []).length > 0;
}

export function getAssigneFirmeProjetPermission(projet: Projet): boolean {
    return projet.firme === undefined || projet.firme === '';
}

export function getAssigneProjetPourCorrectionPermission(projet: Projet): boolean {
    return projet.statut === StatutProjet.nonConforme ||
        projet.statut === StatutProjet.correctionAssignee ||
        projet.statut === StatutProjet.correctionEnCours ||
        projet.statut === StatutProjet.correctionCompletee;
}
