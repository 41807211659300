import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';

export const anomalie: AnyLayer = {
    id: MapLayersSources.ANOMALIE,
    type: 'symbol',
    source: MapLayersSources.ANOMALIE,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'icon-color': '#FFFFFF',
        'text-color': 'green',
        'text-halo-color': '#FFFFFF',
        'text-halo-width': 1
        /* eslint-enable @typescript-eslint/naming-convention */
    }
};
