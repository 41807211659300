import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PhotoEntity } from '../../../core/api/client/models';
import { PhotoEntityFile } from '../models/photo-entity-file.model';

@Component({
    selector: 'app-photos-container',
    templateUrl: './photos-container.component.html',
    styleUrls: ['./photos-container.component.scss']
})
export class PhotosContainerComponent {
    private _photos: PhotoEntityFile[] = [];

    @Input() set photos(values: PhotoEntity[]) {
        this.formatePhotos(values);
    }

    public get photos(): PhotoEntityFile[] {
        return this._photos;
    }

    @Input() loading = false;
    @Input() canEdit = false;
    @Output() removePhoto = new EventEmitter<string>();

    public remove(photoId: string) {
        this.removePhoto.emit(photoId);
    }

    private formatePhotos(values: PhotoEntity[]) {
        this._photos = values.map(photo => {
            return {
                ...photo,
                file: URL.createObjectURL(photo.data)
            };
        });
    }
}
