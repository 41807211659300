<div class="gps__header">
    <p-tag class="gps__header__status" [severity]="gpsStatusSeverity" [value]="gpsStatusMessage"></p-tag>

    <button *ngIf="gpsPortConnectionState === gpsPortConnectionStateEnum.DISCONNECTED" pButton
        class="p-button-success  gps__header__button" (click)="connectGps()" label="Connexion"></button>
    <button *ngIf="gpsPortConnectionState === gpsPortConnectionStateEnum.CONNECTED" pButton
        class="p-button-danger gps__header__button" (click)="disconnectGps()" label="Déconnexion"></button>
</div>
<div class="gps__content">
    <div #terminal class="gps__content__terminal"></div>
</div>