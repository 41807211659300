import { createAction, props } from '@ngrx/store';
import { Taxonomie } from '../../../features/pilotage/models/taxonomie.model';
import { Severite } from '../../../enums/severite';

export enum TaxonomieActionTypes {
    GET_ALL_TAXONOMIE = '[Taxonomie] Get All Taxonomie',
    GET_ALL_TAXONOMIE_SUCCESS = '[Taxonomie] Get All Taxonomie Success',

    CREATE_ONE_TAXONOMIE = '[Taxonomie] Create One Taxonomie',
    CREATE_ONE_TAXONOMIE_SUCCESS = '[Taxonomie] Create One Taxonomie Success',
    CREATE_ONE_TAXONOMIE_ERROR = '[Taxonomie] Create One Taxonomie error',

    UPDATE_ONE_TAXONOMIE = '[Taxonomie] Update One Taxonomie',
    UPDATE_ONE_TAXONOMIE_SUCCESS = '[Taxonomie] Update One Taxonomie Success',
    UPDATE_ONE_TAXONOMIE_ERROR = '[Taxonomie] Update One Taxonomie error',

    DELETE_ONE_TAXONOMIE = '[Taxonomie] Delete One Taxonomie',
    DELETE_ONE_TAXONOMIE_SUCCESS = '[Taxonomie] Delete One Taxonomie Success',
    DELETE_ONE_TAXONOMIE_ERROR = '[Taxonomie] Delete One Taxonomie error',

    MESSAGE_TAXONOMIE = '[Taxonomie] Message Taxonomie',
}

///  GET ALL  ///
export const getAllTaxonomies = createAction(
    TaxonomieActionTypes.GET_ALL_TAXONOMIE,
);

export const getAllTaxonomiesSuccess = createAction(
    TaxonomieActionTypes.GET_ALL_TAXONOMIE_SUCCESS,
    props<{ taxonomies: Taxonomie[] }>()
);

///  Create One  ///
export const createOneTaxonomie = createAction(
    TaxonomieActionTypes.CREATE_ONE_TAXONOMIE,
    props<{ taxonomie: Taxonomie }>()
);

export const createOneTaxonomieSuccess = createAction(
    TaxonomieActionTypes.CREATE_ONE_TAXONOMIE_SUCCESS,
    props<{ taxonomie: Taxonomie }>()
);

export const createOneTaxonomieError = createAction(
    TaxonomieActionTypes.CREATE_ONE_TAXONOMIE_ERROR,
    props<{ error: any }>()
);

///  Update One  ///
export const updateOneTaxonomie = createAction(
    TaxonomieActionTypes.UPDATE_ONE_TAXONOMIE,
    props<{ taxonomie: Taxonomie }>()
);

export const updateOneTaxonomieSuccess = createAction(
    TaxonomieActionTypes.UPDATE_ONE_TAXONOMIE_SUCCESS,
    props<{ taxonomie: Taxonomie }>()
);

export const updateOneTaxonomieError = createAction(
    TaxonomieActionTypes.UPDATE_ONE_TAXONOMIE_ERROR,
    props<{ error: any }>()
);

///  Delete One  ///
export const deleteOneTaxonomie = createAction(
    TaxonomieActionTypes.DELETE_ONE_TAXONOMIE,
    props<{ taxonomie: Taxonomie }>()
);

export const deleteOneTaxonomieSuccess = createAction(
    TaxonomieActionTypes.DELETE_ONE_TAXONOMIE_SUCCESS,
    props<{ taxonomie: Taxonomie }>()
);

export const deleteOneTaxonomieError = createAction(
    TaxonomieActionTypes.DELETE_ONE_TAXONOMIE_ERROR,
    props<{ error: any }>()
);

/// MESSAGE ///
export const messageTaxonomie = createAction(
    TaxonomieActionTypes.MESSAGE_TAXONOMIE,
    props<{ severity: Severite, summary: string, detail: string }>()
);
