export enum ProjetAuditProprietesKey {
    assigneA = 'assigneA',
    assigneLe = 'assigneLe',
    assignePar = 'assignePar',
    creeLe = 'creeLe',
    firme = 'firme',
    geometrie = 'geometrie',
    historique = 'historique',
    id = 'id',
    nom = 'nom',
    nombreTotalDePoteaux = 'nombreTotalDePoteaux',
    pointAudits = 'pointAudits',
    projetId = 'projetId',
    remarques = 'remarques',
    seuilAudit = 'seuilAudit',
    statut = 'statut',
    statutGlobal = 'statutGlobal',
    typeDeSeuil = 'typeDeSeuil',
}

export enum ProjetAuditProprietesValue {
    assigneA = 'Assigné à',
    assigneLe = 'Assigné le',
    assignePar = 'Assigné par',
    creeLe = 'Créé le',
    firme = 'Firme',
    geometrie = 'Géometrie',
    historique = 'Historique',
    id = 'id',
    nom = 'Nom',
    nombreTotalDePoteaux = 'Nombre total de poteaux',
    pointAudits = `Liste de points d'audit`,
    projetId = `id projet d'inspection`,
    remarques = 'Remarques',
    seuilAudit = `Seuil d'audit`,
    statut = 'Statut',
    statutGlobal = 'Statut global',
    typeDeSeuil = 'Type de seuil',
}
