import { createReducer, on } from '@ngrx/store';
import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { UserInformation } from 'src/app/shared/models/user-informations.model';
import { getUserInformationSuccess } from '../actions/userInformation.action';

export interface UserInformationState extends EntityState<UserInformation> { }

export function selectedUserInformationId(): number {
    return 0;
}

export const userInformationAdapter: EntityAdapter<UserInformation> = createEntityAdapter<UserInformation>({
    selectId: selectedUserInformationId
});

export const userInformationInitialState = userInformationAdapter.getInitialState();

export const userInformationSelectors = userInformationAdapter.getSelectors();

export const userInformationReducer = createReducer(userInformationInitialState,
    on(getUserInformationSuccess,
        (state, action) => userInformationAdapter.upsertOne(action.userInformation, state)));
