/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { LogBaseDtoSearchResult } from '../models/log-base-dto-search-result';
import { LogBaseDto } from '../models/log-base-dto';
@Injectable({
  providedIn: 'root',
})
class LogService extends __BaseService {
  static readonly getApiV1LogsPath = '/api/v1/logs';
  static readonly getApiV1LogsIdPath = '/api/v1/logs/{id}';

  constructor(
    config: __Configuration,
    http: HttpClient
  ) {
    super(config, http);
  }

  /**
   * @param params The `LogService.GetApiV1LogsParams` containing the following parameters:
   *
   * - `timestampStartDate`:
   *
   * - `timestampEndDate`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `message_template`:
   *
   * - `message`:
   *
   * - `level`:
   *
   * - `exception`:
   *
   * - `Id`:
   *
   * @return Success
   */
  getApiV1LogsResponse(params: LogService.GetApiV1LogsParams): __Observable<__StrictHttpResponse<LogBaseDtoSearchResult>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;
    if (params.timestampStartDate != null) __params = __params.set('timestampStartDate', params.timestampStartDate.toString());
    if (params.timestampEndDate != null) __params = __params.set('timestampEndDate', params.timestampEndDate.toString());
    if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
    if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
    if (params.messageTemplate != null) __params = __params.set('message_template', params.messageTemplate.toString());
    if (params.message != null) __params = __params.set('message', params.message.toString());
    if (params.level != null) __params = __params.set('level', params.level.toString());
    if (params.exception != null) __params = __params.set('exception', params.exception.toString());
    if (params.Id != null) __params = __params.set('Id', params.Id.toString());
    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/logs`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LogBaseDtoSearchResult>;
      })
    );
  }
  /**
   * @param params The `LogService.GetApiV1LogsParams` containing the following parameters:
   *
   * - `timestampStartDate`:
   *
   * - `timestampEndDate`:
   *
   * - `pageSize`:
   *
   * - `pageNumber`:
   *
   * - `message_template`:
   *
   * - `message`:
   *
   * - `level`:
   *
   * - `exception`:
   *
   * - `Id`:
   *
   * @return Success
   */
  getApiV1Logs(params: LogService.GetApiV1LogsParams): __Observable<LogBaseDtoSearchResult> {
    return this.getApiV1LogsResponse(params).pipe(
      __map(_r => _r.body as LogBaseDtoSearchResult)
    );
  }

  /**
   * @param id undefined
   * @return Success
   */
  getApiV1LogsIdResponse(id: number): __Observable<__StrictHttpResponse<LogBaseDto>> {
    let __params = this.newParams();
    let __headers = new HttpHeaders();
    let __body: any = null;

    let req = new HttpRequest<any>(
      'GET',
      this.rootUrl + `/api/v1/logs/${encodeURIComponent(String(id))}`,
      __body,
      {
        headers: __headers,
        params: __params,
        responseType: 'json'
      });

    return this.http.request<any>(req).pipe(
      __filter(_r => _r instanceof HttpResponse),
      __map((_r) => {
        return _r as __StrictHttpResponse<LogBaseDto>;
      })
    );
  }
  /**
   * @param id undefined
   * @return Success
   */
  getApiV1LogsId(id: number): __Observable<LogBaseDto> {
    return this.getApiV1LogsIdResponse(id).pipe(
      __map(_r => _r.body as LogBaseDto)
    );
  }
}

module LogService {

  /**
   * Parameters for getApiV1Logs
   */
  export interface GetApiV1LogsParams {
    timestampStartDate?: string;
    timestampEndDate?: string;
    pageSize?: number;
    pageNumber?: number;
    messageTemplate?: string;
    message?: string;
    level?: string;
    exception?: string;
    Id?: number;
  }
}

export { LogService }
