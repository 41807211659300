import { Action, createReducer, on } from '@ngrx/store';
import { AnomalieListState } from './anomalie-list.state';
import * as AnomalieListActions from './anomalie-list.action';


export const anomalieListInitialState: AnomalieListState = {
    anomalieList: [],
    anomalieListLoading: false,
    loadAnomalieListError: null,
    updateAnomalieListSuccess: false,
    photoExtraction: null,
    photoExtractionLoading: false,
    photoExtractionError: null,
    statutPhotoExtraction: null,
    statutPhotoExtractionLoading: false,
    statutPhotoExtractionError: null
};

export const anomalieListReducer = createReducer<AnomalieListState>(
    anomalieListInitialState,
    on(AnomalieListActions.loadAnomalieList, (state) => {
        return {
            ...state,
            anomalieList: [],
            anomalieListLoading: true,
            loadAnomalieListError: null,
            updateAnomalieListSuccess: false,
        };
    }),
    on(AnomalieListActions.loadAnomalieListSuccess, (state, action) => {
        return {
            ...state,
            anomalieList: action.anomalieList,
            anomalieListLoading: false,
            loadAnomalieListError: null
        };
    }),
    on(AnomalieListActions.loadAnomalieListError, (state, action) => {
        return {
            ...state,
            anomalieList: [],
            anomalieListLoading: false,
            loadAnomalieListError: action.error,
        };
    }),
    on(AnomalieListActions.loadStatutPhotoExtraction, (state) => {
        return {
            ...state,
            statutPhotoExtraction: null,
            statutPhotoExtractionLoading: true,
            statutPhotoExtractionError: null,
        };
    }),
    on(AnomalieListActions.loadStatutPhotoExtractionSuccess, (state, action) => {
        return {
            ...state,
            statutPhotoExtraction: action.statutPhotoExtraction,
            statutPhotoExtractionLoading: false,
            statutPhotoExtractionError: null,
        };
    }),
    on(AnomalieListActions.loadStatutPhotoExtractionError, (state, action) => {
        return {
            ...state,
            statutPhotoExtraction: null,
            statutPhotoExtractionLoading: false,
            statutPhotoExtractionError: action.error,
        };
    }),
    on(AnomalieListActions.createPhotoExtraction, (state) => {
        return {
            ...state,
            photoExtraction: null,
            photoExtractionLoading: true,
            photoExtractionError: null,
        };
    }),
    on(AnomalieListActions.createPhotoExtractionSuccess, (state, action) => {
        return {
            ...state,
            photoExtraction: action.photoExtraction,
            photoExtractionLoading: false,
            photoExtractionError: null,
        };
    }),
    on(AnomalieListActions.createPhotoExtractionError, (state, action) => {
        return {
            ...state,
            photoExtraction: null,
            photoExtractionLoading: false,
            photoExtractionError: action.error,
        };
    }),
    on(AnomalieListActions.updateAnomalieListSuccess, (state) => {
        return {
            ...state,
            updateAnomalieListSuccess: true,
        };
    }),
);

export function reducer(state: any, action: Action) {
    return anomalieListReducer(state, action);
}
