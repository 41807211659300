<div>
    <div class="main">
        <div class="main__section">
            <p class="section__para">Capture est une solution mobile, permettant aux équipes d'Hydro&#x2011;Québec
                et à celles des firmes partenaires, d'exécuter des inspections de lignes
                sur le réseau de distribution.</p>
        </div>
        <hr class="main__division">
        <div class="main__section">
            <p class="section__version"><b>Version API:</b>&emsp;{{ backendVersion }}</p>
            <p class="section__version"><b>Version Interface:</b>&emsp;{{ frontendVersion }}</p>
        </div>
        <div class="main__section alignRight">
            <p class="section__para">{{ copyright }}</p>
        </div>
    </div>
</div>

