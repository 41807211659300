import { createFeatureSelector, createSelector } from '@ngrx/store';
import { OfflineState } from './offline.state';

const getOfflineFeatureState = createFeatureSelector<OfflineState>('offline');

export const getProjetsDownloaded = createSelector(
    getOfflineFeatureState,
    state => state.getProjetsDownloaded
);

export const getProjetsDownloadedLoading = createSelector(
    getOfflineFeatureState,
    state => state.getProjetsDownloadedLoading
);

export const getProjetsAuditDownloaded = createSelector(
    getOfflineFeatureState,
    state => state.getProjetsAuditDownloaded
);

export const getProjetsAuditDownloadedLoading = createSelector(
    getOfflineFeatureState,
    state => state.getProjetsAuditDownloadedLoading
);

export const getProjetAddedToIndexedDb = createSelector(
    getOfflineFeatureState,
    state => state.addProjetToIndexedDb
);

export const getProjetAddedToIndexedDbSuccess = createSelector(
    getOfflineFeatureState,
    state => state.addProjetToIndexedDbSuccess
);

export const getProjetAuditAddedToIndexedDb = createSelector(
    getOfflineFeatureState,
    state => state.addProjetAuditToIndexedDb
);

export const getProjetAuditAddedToIndexedDbSuccess = createSelector(
    getOfflineFeatureState,
    state => state.addProjetAuditToIndexedDbSuccess
);

export const getProjetAddedToIndexedDbLoading = createSelector(
    getOfflineFeatureState,
    state => state.addProjetToIndexedDbLoading
);

export const getProjetAuditAddedToIndexedDbLoading = createSelector(
    getOfflineFeatureState,
    state => state.addProjetAuditToIndexedDbLoading
);
