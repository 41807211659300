/* tslint:disable */
import { Injectable } from '@angular/core';
import { HttpClient, HttpRequest, HttpResponse, HttpHeaders } from '@angular/common/http';
import { BaseService as __BaseService } from '../base-service';
import { ApiConfiguration as __Configuration } from '../api-configuration';
import { StrictHttpResponse as __StrictHttpResponse } from '../strict-http-response';
import { Observable as __Observable } from 'rxjs';
import { map as __map, filter as __filter } from 'rxjs/operators';

import { PointAuditDto } from '../models/point-audit-dto';
import { PointAuditDtoSearchResult } from '../models/point-audit-dto-search-result';
import { PhotoEntity } from '../models/photo-entity';
@Injectable({
    providedIn: 'root',
})
class PointsAuditService extends __BaseService {
    static readonly putApiV1PointsauditPointAuditIdPath = '/api/v1/pointsaudit/{pointAuditId}';
    static readonly postApiV1PointsauditPath = '/api/v1/pointsaudit';
    static readonly getApiV1PointsauditPath = '/api/v1/pointsaudit';
    static readonly postApiV1PointsauditPointAuditIdPhotosPath = '/api/v1/pointsaudit/{pointAuditId}/photos';
    static readonly deleteApiV1PointsauditPointAuditIdPhotosIdPhotoPath = '/api/v1/pointsaudit/{pointAuditId}/photos/{idPhoto}';
    static readonly getApiV1PointsauditPointAuditIdPhotosIdPhotoPath = '/api/v1/pointsaudit/{pointAuditId}/photos/{idPhoto}';
    static readonly postApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosPath = '/api/v1/pointsaudit/{pointAuditId}/anomalies/{idAnomalieAudit}/photos';
    static readonly deleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoPath = '/api/v1/pointsaudit/{pointAuditId}/anomalies/{idAnomalieAudit}/photos/{idPhoto}';
    static readonly getApiV1PointsauditIdPhotosIdPhotoThumbnailsPath = '/api/v1/pointsaudit/{id}/photos/{idPhoto}/thumbnails';
    static readonly getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoPath = '/api/v1/pointsaudit/{id}/anomalies/{idAnomalieAudit}/photos/{idPhoto}';
    static readonly getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnailsPath = '/api/v1/pointsaudit/{id}/anomalies/{idAnomalieAudit}/photos/{idPhoto}/thumbnails';

    constructor(
        config: __Configuration,
        http: HttpClient
    ) {
        super(config, http);
    }

    /**
     * @param params The `PointsAuditService.PutApiV1PointsauditPointAuditIdParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `body`:
     *
     * @return Success
     */
    putApiV1PointsauditPointAuditIdResponse(params: PointsAuditService.PutApiV1PointsauditPointAuditIdParams): __Observable<__StrictHttpResponse<PointAuditDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;

        __body = params.body;
        let req = new HttpRequest<any>(
            'PUT',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.pointAuditId))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PointAuditDto>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.PutApiV1PointsauditPointAuditIdParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `body`:
     *
     * @return Success
     */
    putApiV1PointsauditPointAuditId(params: PointsAuditService.PutApiV1PointsauditPointAuditIdParams): __Observable<PointAuditDto> {
        return this.putApiV1PointsauditPointAuditIdResponse(params).pipe(
            __map(_r => _r.body as PointAuditDto)
        );
    }

    /**
     * @param body undefined
     * @return Success
     */
    postApiV1PointsauditResponse(body?: PointAuditDto): __Observable<__StrictHttpResponse<PointAuditDto>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        __body = body;
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/pointsaudit`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PointAuditDto>;
            })
        );
    }
    /**
     * @param body undefined
     * @return Success
     */
    postApiV1Pointsaudit(body?: PointAuditDto): __Observable<PointAuditDto> {
        return this.postApiV1PointsauditResponse(body).pipe(
            __map(_r => _r.body as PointAuditDto)
        );
    }

    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditParams` containing the following parameters:
     *
     * - `ymin`:
     *
     * - `ymax`:
     *
     * - `xmin`:
     *
     * - `xmax`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * @return Success
     */
    getApiV1PointsauditResponse(params: PointsAuditService.GetApiV1PointsauditParams): __Observable<__StrictHttpResponse<PointAuditDtoSearchResult>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        if (params.ymin != null) __params = __params.set('ymin', params.ymin.toString());
        if (params.ymax != null) __params = __params.set('ymax', params.ymax.toString());
        if (params.xmin != null) __params = __params.set('xmin', params.xmin.toString());
        if (params.xmax != null) __params = __params.set('xmax', params.xmax.toString());
        if (params.pageSize != null) __params = __params.set('pageSize', params.pageSize.toString());
        if (params.pageNumber != null) __params = __params.set('pageNumber', params.pageNumber.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/pointsaudit`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PointAuditDtoSearchResult>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditParams` containing the following parameters:
     *
     * - `ymin`:
     *
     * - `ymax`:
     *
     * - `xmin`:
     *
     * - `xmax`:
     *
     * - `pageSize`:
     *
     * - `pageNumber`:
     *
     * @return Success
     */
    getApiV1Pointsaudit(params: PointsAuditService.GetApiV1PointsauditParams): __Observable<PointAuditDtoSearchResult> {
        return this.getApiV1PointsauditResponse(params).pipe(
            __map(_r => _r.body as PointAuditDtoSearchResult)
        );
    }

    /**
     * @param params The `PointsAuditService.PostApiV1PointsauditPointAuditIdPhotosParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `Photo`:
     *
     * - `NomOriginal`:
     *
     * - `Nom`:
     *
     * - `Id`:
     *
     * @return Created
     */
    postApiV1PointsauditPointAuditIdPhotosResponse(params: PointsAuditService.PostApiV1PointsauditPointAuditIdPhotosParams): __Observable<__StrictHttpResponse<PhotoEntity>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;

        if (params.Photo != null) { __formData.append('Photo', params.Photo as string | Blob); }
        if (params.NomOriginal != null) { __formData.append('NomOriginal', params.NomOriginal as string | Blob); }
        if (params.Nom != null) { __formData.append('Nom', params.Nom as string | Blob); }
        if (params.Id != null) { __formData.append('Id', params.Id as string | Blob); }
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.pointAuditId))}/photos`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PhotoEntity>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.PostApiV1PointsauditPointAuditIdPhotosParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `Photo`:
     *
     * - `NomOriginal`:
     *
     * - `Nom`:
     *
     * - `Id`:
     *
     * @return Created
     */
    postApiV1PointsauditPointAuditIdPhotos(params: PointsAuditService.PostApiV1PointsauditPointAuditIdPhotosParams): __Observable<PhotoEntity> {
        return this.postApiV1PointsauditPointAuditIdPhotosResponse(params).pipe(
            __map(_r => _r.body as PhotoEntity)
        );
    }

    /**
     * @param params The `PointsAuditService.DeleteApiV1PointsauditPointAuditIdPhotosIdPhotoParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `idPhoto`:
     */
    deleteApiV1PointsauditPointAuditIdPhotosIdPhotoResponse(params: PointsAuditService.DeleteApiV1PointsauditPointAuditIdPhotosIdPhotoParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.pointAuditId))}/photos/${encodeURIComponent(String(params.idPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.DeleteApiV1PointsauditPointAuditIdPhotosIdPhotoParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `idPhoto`:
     */
    deleteApiV1PointsauditPointAuditIdPhotosIdPhoto(params: PointsAuditService.DeleteApiV1PointsauditPointAuditIdPhotosIdPhotoParams): __Observable<null> {
        return this.deleteApiV1PointsauditPointAuditIdPhotosIdPhotoResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditPointAuditIdPhotosIdPhotoParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `idPhoto`:
     *
     * - `taille`:
     */
    getApiV1PointsauditPointAuditIdPhotosIdPhotoResponse(params: PointsAuditService.GetApiV1PointsauditPointAuditIdPhotosIdPhotoParams): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        if (params.taille != null) __params = __params.set('taille', params.taille.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.pointAuditId))}/photos/${encodeURIComponent(String(params.idPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'blob'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditPointAuditIdPhotosIdPhotoParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `idPhoto`:
     *
     * - `taille`:
     */
    getApiV1PointsauditPointAuditIdPhotosIdPhoto(params: PointsAuditService.GetApiV1PointsauditPointAuditIdPhotosIdPhotoParams): __Observable<Blob> {
        return this.getApiV1PointsauditPointAuditIdPhotosIdPhotoResponse(params).pipe(
            __map(_r => _r.body as Blob)
        );
    }

    /**
     * @param params The `PointsAuditService.PostApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `idAnomalieAudit`:
     *
     * - `Photo`:
     *
     * - `NomOriginal`:
     *
     * - `Nom`:
     *
     * - `Id`:
     *
     * @return Created
     */
    postApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosResponse(params: PointsAuditService.PostApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosParams): __Observable<__StrictHttpResponse<PhotoEntity>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;
        let __formData = new FormData();
        __body = __formData;


        if (params.Photo != null) { __formData.append('Photo', params.Photo as string | Blob); }
        if (params.NomOriginal != null) { __formData.append('NomOriginal', params.NomOriginal as string | Blob); }
        if (params.Nom != null) { __formData.append('Nom', params.Nom as string | Blob); }
        if (params.Id != null) { __formData.append('Id', params.Id as string | Blob); }
        let req = new HttpRequest<any>(
            'POST',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.pointAuditId))}/anomalies/${encodeURIComponent(String(params.idAnomalieAudit))}/photos`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<PhotoEntity>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.PostApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `idAnomalieAudit`:
     *
     * - `Photo`:
     *
     * - `NomOriginal`:
     *
     * - `Nom`:
     *
     * - `Id`:
     *
     * @return Created
     */
    postApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotos(params: PointsAuditService.PostApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosParams): __Observable<PhotoEntity> {
        return this.postApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosResponse(params).pipe(
            __map(_r => _r.body as PhotoEntity)
        );
    }

    /**
     * @param params The `PointsAuditService.DeleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `idPhoto`:
     *
     * - `idAnomalieAudit`:
     */
    deleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoResponse(params: PointsAuditService.DeleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;



        let req = new HttpRequest<any>(
            'DELETE',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.pointAuditId))}/anomalies/${encodeURIComponent(String(params.idAnomalieAudit))}/photos/${encodeURIComponent(String(params.idPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.DeleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams` containing the following parameters:
     *
     * - `pointAuditId`:
     *
     * - `idPhoto`:
     *
     * - `idAnomalieAudit`:
     */
    deleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhoto(params: PointsAuditService.DeleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams): __Observable<null> {
        return this.deleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditIdPhotosIdPhotoThumbnailsParams` containing the following parameters:
     *
     * - `idPhoto`:
     *
     * - `id`:
     */
    getApiV1PointsauditIdPhotosIdPhotoThumbnailsResponse(params: PointsAuditService.GetApiV1PointsauditIdPhotosIdPhotoThumbnailsParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;


        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.id))}/photos/${encodeURIComponent(String(params.idPhoto))}/thumbnails`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditIdPhotosIdPhotoThumbnailsParams` containing the following parameters:
     *
     * - `idPhoto`:
     *
     * - `id`:
     */
    getApiV1PointsauditIdPhotosIdPhotoThumbnails(params: PointsAuditService.GetApiV1PointsauditIdPhotosIdPhotoThumbnailsParams): __Observable<null> {
        return this.getApiV1PointsauditIdPhotosIdPhotoThumbnailsResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }

    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams` containing the following parameters:
     *
     * - `idPhoto`:
     *
     * - `idAnomalieAudit`:
     *
     * - `id`:
     *
     * - `taille`:
     */
    getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoResponse(params: PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams): __Observable<__StrictHttpResponse<Blob>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;



        if (params.taille != null) __params = __params.set('taille', params.taille.toString());
        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.id))}/anomalies/${encodeURIComponent(String(params.idAnomalieAudit))}/photos/${encodeURIComponent(String(params.idPhoto))}`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'blob'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<Blob>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams` containing the following parameters:
     *
     * - `idPhoto`:
     *
     * - `idAnomalieAudit`:
     *
     * - `id`:
     *
     * - `taille`:
     */
    getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhoto(params: PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams): __Observable<Blob> {
        return this.getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoResponse(params).pipe(
            __map(_r => _r.body as Blob)
        );
    }

    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnailsParams` containing the following parameters:
     *
     * - `idPhoto`:
     *
     * - `idAnomalieAudit`:
     *
     * - `id`:
     */
    getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnailsResponse(params: PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnailsParams): __Observable<__StrictHttpResponse<null>> {
        let __params = this.newParams();
        let __headers = new HttpHeaders();
        let __body: any = null;



        let req = new HttpRequest<any>(
            'GET',
            this.rootUrl + `/api/v1/pointsaudit/${encodeURIComponent(String(params.id))}/anomalies/${encodeURIComponent(String(params.idAnomalieAudit))}/photos/${encodeURIComponent(String(params.idPhoto))}/thumbnails`,
            __body,
            {
                headers: __headers,
                params: __params,
                responseType: 'json'
            });

        return this.http.request<any>(req).pipe(
            __filter(_r => _r instanceof HttpResponse),
            __map((_r) => {
                return _r as __StrictHttpResponse<null>;
            })
        );
    }
    /**
     * @param params The `PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnailsParams` containing the following parameters:
     *
     * - `idPhoto`:
     *
     * - `idAnomalieAudit`:
     *
     * - `id`:
     */
    getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnails(params: PointsAuditService.GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnailsParams): __Observable<null> {
        return this.getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnailsResponse(params).pipe(
            __map(_r => _r.body as null)
        );
    }
}

module PointsAuditService {

    /**
     * Parameters for putApiV1PointsauditPointAuditId
     */
    export interface PutApiV1PointsauditPointAuditIdParams {
        pointAuditId: string;
        body?: PointAuditDto;
    }

    /**
     * Parameters for getApiV1Pointsaudit
     */
    export interface GetApiV1PointsauditParams {
        ymin?: number;
        ymax?: number;
        xmin?: number;
        xmax?: number;
        pageSize?: number;
        pageNumber?: number;
    }

    /**
     * Parameters for postApiV1PointsauditPointAuditIdPhotos
     */
    export interface PostApiV1PointsauditPointAuditIdPhotosParams {
        pointAuditId: string;
        Photo?: Blob;
        NomOriginal?: string;
        Nom?: string;
        Id?: string;
    }

    /**
     * Parameters for deleteApiV1PointsauditPointAuditIdPhotosIdPhoto
     */
    export interface DeleteApiV1PointsauditPointAuditIdPhotosIdPhotoParams {
        pointAuditId: string;
        idPhoto: string;
    }

    /**
     * Parameters for getApiV1PointsauditPointAuditIdPhotosIdPhoto
     */
    export interface GetApiV1PointsauditPointAuditIdPhotosIdPhotoParams {
        pointAuditId: string;
        idPhoto: string;
        taille?: any;
    }

    /**
     * Parameters for postApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotos
     */
    export interface PostApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosParams {
        pointAuditId: string;
        idAnomalieAudit: string;
        Photo?: Blob;
        NomOriginal?: string;
        Nom?: string;
        Id?: string;
    }

    /**
     * Parameters for deleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhoto
     */
    export interface DeleteApiV1PointsauditPointAuditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams {
        pointAuditId: string;
        idPhoto: string;
        idAnomalieAudit: string;
    }

    /**
     * Parameters for getApiV1PointsauditIdPhotosIdPhotoThumbnails
     */
    export interface GetApiV1PointsauditIdPhotosIdPhotoThumbnailsParams {
        idPhoto: string;
        id: string;
    }

    /**
     * Parameters for getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhoto
     */
    export interface GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoParams {
        idPhoto: string;
        idAnomalieAudit: string;
        id: string;
        taille?: any;
    }

    /**
     * Parameters for getApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnails
     */
    export interface GetApiV1PointsauditIdAnomaliesIdAnomalieAuditPhotosIdPhotoThumbnailsParams {
        idPhoto: string;
        idAnomalieAudit: string;
        id: string;
    }
}

export { PointsAuditService }
