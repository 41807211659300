import { AnyLayer } from 'mapbox-gl';
import { MapLayersSources } from '../map-layers-sources.enum';
import { mapStyleConfig } from '../map-style-config.const';

export const projetHighlight: AnyLayer = {
    id: MapLayersSources.POLYGONE_PROJET_HIGHLIGHTED,
    type: 'fill',
    source: MapLayersSources.POLYGONE_PROJET_HIGHLIGHTED,
    paint: {
        /* eslint-disable @typescript-eslint/naming-convention */
        'fill-color': mapStyleConfig.colors.transparent,
        'fill-opacity': mapStyleConfig.projetHighlight.opacity,
        /* eslint-enable @typescript-eslint/naming-convention */
    },
    filter: ['==', ['geometry-type'], 'Polygon']
};

