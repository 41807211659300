import { createAction, props } from '@ngrx/store';
import { AnomaliePilotage } from '../../../shared/models/anomalie-pilotage.model';

export enum AnomaliePilotageActionTypes {
    GET_ANOMALIES_PILOTAGE = '[anomaliePilotage] Get ALL Anomalies Pilotage From Backend',
    GET_ANOMALIES_PILOTAGE_SUCCESS = '[anomaliePilotage] Get ALL Anomalies Pilotage From Backend Success'
}

export const getAllAnomaliesPilotage = createAction(
    AnomaliePilotageActionTypes.GET_ANOMALIES_PILOTAGE,
);

export const getAllAnomaliesPilotageSuccess = createAction(
    AnomaliePilotageActionTypes.GET_ANOMALIES_PILOTAGE_SUCCESS,
    props<{ anomaliesPilotage: AnomaliePilotage[] }>()
);
