import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';
import { projetReducer, ProjetsState } from './projet/projet.reducers';
import { projetHistoryReducer } from './projet-history/projet-history.reducer';
import { ProjetHistoryState } from './projet-history/projet-history.state';
import { ProjetHistoryEffects } from './projet-history/projet-history.effects';
import { ProjetEffects } from './projet/projet.effects';

export const getProjetState = createFeatureSelector<ProjetState>('projet');

export interface ProjetState {
    projets: ProjetsState,
    projetHistory: ProjetHistoryState,
}

export const projetReducers: ActionReducerMap<any> = {
    projets: projetReducer,
    projetHistory: projetHistoryReducer,
};

export const projetsStoreEffects = [
    ProjetHistoryEffects,
    ProjetEffects,
];
