<p-dialog header="Veuillez renseigner la non-conformité" [(visible)]="visible" [modal]=true [draggable]=false
    (onHide)="closeAnomalieAuditNonConformeDialog()" appendTo="body">
    <div *ngIf="form" class="audit-non-conforme-dialog">
        <form [formGroup]="form">
            <div class="audit-non-conforme-dialog__content">
                <app-form-field class="app-form-field app-form-field--error-absolute" [control]="form.controls.remarque"
                    label="Note :">
                    <input pInputText type="text" formControlName="remarque">
                </app-form-field>

                <div class="audit-non-conforme-dialog__content__photo">
                    <div class="photos">
                        <span class="p-column-title photos__title">Photos *</span>
                        <div class="photos__list">
                            <app-photos-container (removePhoto)="removePhoto($event)"
                                [loading]="photosLoading || (photosUploading$ | async)" [canEdit]="true"
                                [photos]="photosWithData"></app-photos-container>
                        </div>
                        <div class="photos__upload">
                            <app-photos-upload [currentPhotos]="photosWithData" (upload)="onUploadPhotos($event)"
                                [maxPhotos]="maxPhotoAnomalieAuditNonConforme">
                            </app-photos-upload>
                        </div>
                    </div>

                </div>
            </div>
        </form>
    </div>
    <div class="audit-non-conforme-dialog__footer">
        <button pButton type="button" label="Annuler" class="btn-annuler"
            (click)="closeAnomalieAuditNonConformeDialog()"></button>
        <button pButton type="button" label="Enregistrer" class="btn-enregistrer"
            (click)="saveAnomalieAuditNonConforme()" [disabled]="form?.valid !== true || uploadedPhotoLoading"></button>
    </div>
</p-dialog>