import { Component, EventEmitter, Input, Output } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { BaseComponent } from '../../../shared/components/abstract-base-component';

@Component({
    selector: 'app-action-sheet',
    templateUrl: './action-sheet.component.html',
    styleUrls: ['./action-sheet.component.scss'],
    animations: [
        trigger('openClose', [
            state('true', style({ height: '*' })),
            state('false', style({ height: '32px' })),
            transition('false => true', animate('0.2s ease-out')),
            transition('true => false', animate('0.1s ease-in'))
        ]),
        trigger('rotate', [
            state('default', style({ transform: '*' })),
            state('rotated', style({ transform: 'rotate(-180deg)' })),
            transition('default => rotated', animate(200)),
            transition('rotated => default', animate(100))
        ]),
    ],
})
export class ActionSheetComponent extends BaseComponent {
    public actionSheetOpen: boolean = true;
    public iconState: string = 'default';

    @Input() displaySubContent: boolean = false;
    @Input() overGridLabel: string = '';

    @Input() topLeftLabel: string = 'Détails';
    @Input() topLeftIcon: boolean = false;
    @Input() topLeftBGColor: string = '#1b75bb';
    @Input() topLeftDisabled: boolean = false;

    @Input() bottomLeftLabel: string = 'Anomalie';
    @Input() bottomLeftIcon: boolean = false;
    @Input() bottomLeftBGColor: string = '#e60c0c';
    @Input() bottomLeftDisabled: boolean = false;

    @Input() topRightLabel: string = 'Déplacer';
    @Input() topRightBGColor: string = '#1b75bb';
    @Input() topRightDisabled: boolean = false;

    @Input() bottomRightLabel: string = 'Ignorer';
    @Input() bottomRightBGColor: string = '#656565';
    @Input() bottomRightDisabled: boolean = false;

    @Input() centerLabel: string = 'Aucune Anomalie';
    @Input() centerBGColor: string = '#00AC37';
    @Input() centerDisable: boolean = false;
    @Input() centerDisplay: boolean = true;

    @Output() topLeft: EventEmitter<void> = new EventEmitter();
    @Output() bottomLeft: EventEmitter<void> = new EventEmitter();
    @Output() topRight: EventEmitter<void> = new EventEmitter();
    @Output() bottomRight: EventEmitter<void> = new EventEmitter();
    @Output() center: EventEmitter<void> = new EventEmitter();

    public topLeftClicked() {
        if (!this.topLeftDisabled) {
            this.topLeft.emit();
        }
    }

    public bottomLeftClicked() {
        if (!this.bottomLeftDisabled) {
            this.bottomLeft.emit();
        }
    }

    public topRightClicked() {
        if (!this.topRightDisabled) {
            this.topRight.emit();
        }
    }

    public bottomRightClicked() {
        if (!this.bottomRightDisabled) {
            this.bottomRight.emit();
        }
    }

    public centerClicked() {
        if (!this.centerDisable) {
            this.center.emit();
        }
    }

    public changeDrawerStatus() {
        this.actionSheetOpen = !this.actionSheetOpen;
        this.iconState = this.iconState === 'default' ? 'rotated' : 'default';
    }
}
