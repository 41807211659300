import { createSelector } from '@ngrx/store';
import { ExtendedResultatTransfertAvis } from '../../../features/projet/models/extended-resultat-transfert-avis';
import { PointInspection } from '../../../features/projet/models/point-inspection.model';
import { CoreState, getCoreState } from '../reducers';
import { pointInspectionSelector } from '../reducers/pointInspection.reducer';
import { getIsControleurQualite, getIsInspecteur } from './user.selectors';

export const getPointInspectionStore = createSelector(
    getCoreState,
    (state: CoreState) => state.pointInspection
);

export const getAllPointsInspectionState = createSelector(
    getPointInspectionStore,
    pointInspectionSelector.selectAll
);

export const getPointInspectionAnomalies = (pointInspectionId: string) => createSelector(
    getAllPointsInspectionState,
    (pointInspections: PointInspection[]) => pointInspections?.find(p => p.id === pointInspectionId)?.anomalies || []
);

export const getPointInspectionById = (pointInspectionId: string) => createSelector(
    getAllPointsInspectionState,
    (pointInspections: PointInspection[]) => pointInspections?.find(p => p.id === pointInspectionId) as PointInspection
);

export const getPointsInspectionInvalides = (pointsInspectionListe: PointInspection[]) => createSelector(
    getAllPointsInspectionState,
    (_: PointInspection[],) => {
        const pointsInspectionInvalideListe: PointInspection[] = [];

        for (const pi of pointsInspectionListe) {
            if (pi.anomalies && pi.anomalies.length > 0 && (!pi.photos || pi.photos?.length === 0)) {
                pointsInspectionInvalideListe.push(pi);
            } else if (pi.anomalies && pi.anomalies.length > 0) {
                for (const ano of pi.anomalies) {
                    if (ano.photos?.length === 0) {
                        pointsInspectionInvalideListe.push(pi);
                        break;
                    }
                }
            }
        }

        return pointsInspectionInvalideListe;
    }
);

export const filterDeletedAnomalies = (pointsInspection: PointInspection[] = []) => createSelector(
    getIsInspecteur,
    getIsControleurQualite,
    (_isInspecteur, _isControlleurQualite) => {
        if (_isInspecteur || _isControlleurQualite) {
            const retour = pointsInspection.map(pointInspection => {
                pointInspection.anomalies = pointInspection.anomalies?.filter(anomalie => !anomalie.statut);
                return pointInspection;
            });

            return retour;
        }

        return pointsInspection;
    }
);

export const getAllPi = (idProjet: string, listeIdPointsInspection: ExtendedResultatTransfertAvis[]) => createSelector(
    getAllPointsInspectionState,
    (pointInspections: PointInspection[]) => {
        const pointsInspectionInvalideListe: [PointInspection, string[]][] = [];

        if (pointInspections && pointInspections.length > 0) {

            const foundPointInspections = pointInspections
                .filter(pi => listeIdPointsInspection
                    .map(avis => avis.id).includes(pi.id) && pi.projetId === idProjet);

            for (const pi of foundPointInspections) {
                const currentPI = listeIdPointsInspection.find(c => c.id === pi.id);

                const anomaliesErrors = currentPI.resultatsAnomalies.reduce((aggregatedErrors: string[], anomalie) => {
                    return aggregatedErrors.concat(anomalie.erreurs);
                }, []);

                const listeErreurs = (listeIdPointsInspection.find(c => c.id === pi.id))?.erreurs || [];
                listeErreurs.push(...anomaliesErrors);

                pointsInspectionInvalideListe.push([pi, listeErreurs]);
            }
        }

        return pointsInspectionInvalideListe;
    }
);

