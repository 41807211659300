import { createSelector } from '@ngrx/store';
import { UserRole } from 'src/app/shared/models/user-roles.model';
import { User, userSelectors } from '../reducers/user.reducer';
import { CoreState, getCoreState } from '../reducers';
import { OpenIdClaims, OpenIdUserInfo } from '@ngxhq/security';
import { getAllTaxonomieState } from './taxonomie.selectors';

export const getUserState = createSelector(
    getCoreState,
    (state: CoreState) => state && state.user
);

export const getAllUserstate = createSelector(
    getUserState,
    userSelectors.selectAll
);

export const getUserInfos = createSelector(
    getAllUserstate,
    (state: User[]) => {
        if (state[0]) {
            return state && state[0].user as Partial<OpenIdClaims & OpenIdUserInfo & { roles: UserRole[]; }>;
        } else {
            return {};
        };
    }
);

export const getFirmeUser = createSelector(
    getUserInfos,
    getAllTaxonomieState,
    (userState, taxonomieState) => {
        const userId = (userState?.email || userState?.upn?.toString())?.toLowerCase();
        const userTaxonomie = taxonomieState.find(taxonomie => taxonomie.code.toLowerCase() === userId);

        if (userTaxonomie?.proprietes) {
            const { firmeID } = JSON.parse(userTaxonomie.proprietes);
            const firme = taxonomieState.find(x => x.id.toLowerCase() === firmeID.toLowerCase());

            if (firme) {
                return firme;
            }
        }

        return null;
    }
);

export const getUserRoles = createSelector(
    getUserInfos,
    (state: Partial<OpenIdClaims & OpenIdUserInfo & { roles: UserRole[]; }>) => {
        const userRoles: UserRole[] = state && state.roles as UserRole[];
        if (state.roles) {
            return userRoles || [];
        } else {
            return [];
        };
    }
);

export const getIsUserMobile = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.INSPECTEUR_EXTERNE) ||
            state.includes(UserRole.INSPECTEUR_HQD) ||
            state.includes(UserRole.CONTROLEUR_QUALITE_EXTERNE) ||
            state.includes(UserRole.AUDITEUR_HQD) ||
            state.includes(UserRole.AUDITEUR_EXTERNE);
    }
);

export const getIsInspecteur = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.INSPECTEUR_HQD) ||
            state.includes(UserRole.INSPECTEUR_EXTERNE);
    }
);

export const getIsAuditeur = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.AUDITEUR_HQD) ||
            state.includes(UserRole.AUDITEUR_EXTERNE);
    }
);

export const getIsControleurQualite = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.CONTROLEUR_QUALITE_EXTERNE);
    }
);

export const getIsUserPilotage = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.ADMIN_GCSP);
    }
);

export const getIsUserAdmin = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.ADMIN_GCSP);
    }
);

export const canInspecterProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.INSPECTEUR_HQD) ||
            state.includes(UserRole.INSPECTEUR_EXTERNE) ||
            state.includes(UserRole.CONTROLEUR_QUALITE_EXTERNE);
    }
);

export const canCompleteProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.INSPECTEUR_HQD) ||
            state.includes(UserRole.INSPECTEUR_EXTERNE);
    }
);

export const canOpenCreateProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU) ||
            state.includes(UserRole.ADMIN_GCSP);
    }
);

export const canCreerProjetSansFirme = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU);
    }
);

export const canChooseFirmeOption = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP);
    }
);

export const canAssignerProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU) ||
            state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.ADMIN_GCSP);
    }
);

export const canAnnulerProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU) ||
            state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.ADMIN_GCSP);
    }
);

export const canModifierProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU) ||
            state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.ADMIN_GCSP);
    }
);

export const canValiderProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_TIC);
    }
);

export const canApprouverProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.TECH_INGENIEURS_RESEAU) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC);
    }
);

export const canEnvoyerAvis = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU);
    }
);

export const canRapportCreationAvis = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU) ||
            state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.GESTION) ||
            state.includes(UserRole.ADMIN_TIC);
    }
);

export const canRapportValidation = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.CONTROLEUR_QUALITE_EXTERNE) ||
            state.includes(UserRole.GESTION) ||
            state.includes(UserRole.INSPECTEUR_EXTERNE) ||
            state.includes(UserRole.INSPECTEUR_HQD) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU);
    }
);

export const canOpenProjetList = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.CONTROLEUR_QUALITE_EXTERNE) ||
            state.includes(UserRole.GESTION) ||
            state.includes(UserRole.INSPECTEUR_EXTERNE) ||
            state.includes(UserRole.INSPECTEUR_HQD) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU);
    }
);

export const canLoadProjetAuditList = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_EXTERNE) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.GESTION) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU) ||
            state.includes(UserRole.AUDITEUR_HQD) ||
            state.includes(UserRole.AUDITEUR_EXTERNE);
    }
);

export const canOpenProjetAuditList = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.GESTION) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU) ||
            state.includes(UserRole.AUDITEUR_HQD) ||
            state.includes(UserRole.AUDITEUR_EXTERNE);
    }
);

export const canCreateProjetAudit = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP);
    }
);

export const canAssignProjetAudit = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.PILOTE);
    }
);

export const canCancelProjetAudit = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.PILOTE);
    }
);

export const canZoomProjetAudit = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU);
    }
);

export const canApproveProjetAudit = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.PILOTE);
    }
);

export const canRejectProjetAudit = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC);
    }
);

export const canCompleteProjetAudit = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.AUDITEUR_HQD) ||
            state.includes(UserRole.AUDITEUR_EXTERNE);
    }
);

export const canAssignerProjetAQ = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_EXTERNE);
    }
);

export const canInspecterProjetAQ = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.CONTROLEUR_QUALITE_EXTERNE);
    }
);

export const canCompleteProjetAQ = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.CONTROLEUR_QUALITE_EXTERNE);
    }
);

export const canAssignerFirmeProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP);
    }
);

export const canExtractAuditHistory = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC) ||
            state.includes(UserRole.PILOTE) ||
            state.includes(UserRole.GESTION) ||
            state.includes(UserRole.TECH_INGENIEURS_RESEAU);
    }
);

export const canOpenCreatePoteauSansAnomalie = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.INSPECTEUR_EXTERNE) ||
            state.includes(UserRole.INSPECTEUR_HQD) ||
            state.includes(UserRole.CONTROLEUR_QUALITE_EXTERNE);
    }
);

export const canAssignerProjetPourCorrection = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_EXTERNE);
    }
);

export const canRejeterProjet = createSelector(
    getUserRoles,
    (state: UserRole[]) => {
        return state.includes(UserRole.ADMIN_GCSP) ||
            state.includes(UserRole.ADMIN_TIC);
    }
);



