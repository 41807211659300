<p-menu #menuExtract [popup]="true" [model]="extractMenuItems" appendTo="body"></p-menu>

<p-table #dt [value]="projetAuditList" [columns]="columns" [rowHover]="true" [paginator]="true" [rows]="10"
    [rowsPerPageOptions]="[10,25,50,100,200]" responsiveLayout="scroll">
    <ng-template pTemplate="caption">
        <div class="audit-table__header">
            <div class="search">
                <i class="fas fa-search search__icon"></i>
                <input type="text" class="search__input" pInputText size="30" placeholder="Rechercher"
                    aria-placeholder="Rechercher" (input)="dt.filterGlobal($any($event.target).value, 'contains')">
            </div>
            <div class="header__action">
                <div class="action__export">
                    <button type="button" pButton pRipple icon="far fa-file-archive" pTooltip="Extraire les données"
                        (click)="menuExtract.toggle($event)" tooltipPosition="bottom" class="data-extraction"
                        [loading]="dataExtractionLoading"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-o" pTooltip="CSV" (click)="export('csv')"
                        tooltipPosition="bottom"></button>
                    <button type="button" pButton pRipple icon="pi pi-file-excel" class="p-button-success"
                        pTooltip="XLS" (click)="export('xlsx')" tooltipPosition="bottom"></button>
                </div>
            </div>
        </div>
    </ng-template>
    <ng-template pTemplate="header">
        <tr>
            <th scope="col" *ngFor="let col of columns" [style.width]="col.width" [pSortableColumn]="col.field">
                {{col.header}}
                <p-sortIcon *ngIf="col.sortable" [field]="col.field" ariaLabel="Trier"
                    aria-label-desc="Trier en ordre descendant" aria-label-asc="Trier en ordre ascendant"></p-sortIcon>
            </th>
        </tr>
        <tr>
            <th scope="col" *ngFor="let col of columns" [ngSwitch]="col.field">
                <ng-container *ngIf="col.filterable">
                    <input *ngSwitchDefault style="background:none;" pInputText type="text" pInputText
                        placeholder="Filtrer" (input)="globalFilter($event, col.field, 'contains')"
                        [class.dateField]="col.isDate" [value]="$any(dt.filters[col.field])?.value">
                    <p-multiSelect *ngSwitchCase="'statut'" [appendTo]="'body'" defaultLabel="Sélectionner un statut"
                        [options]="statutFiltersItems" optionLabel="name" [maxSelectedLabels]="1" [showHeader]="true"
                        [filterBy]="'name, code'" [filterPlaceHolder]="'Rechercher statut'"
                        [selectedItemsLabel]="'{0} éléments'" class="p-column-filter"
                        (onChange)="onFilterChange($event.value, col.field)">
                    </p-multiSelect>
                    <p-multiSelect *ngSwitchCase="'statutGlobal'" [appendTo]="'body'"
                        defaultLabel="Sélectionner un statut" [options]="statutGlobalFiltersItems" optionLabel="name"
                        [maxSelectedLabels]="1" [showHeader]="true" [filterBy]="'name, code'"
                        [filterPlaceHolder]="'Rechercher statut'" [selectedItemsLabel]="'{0} éléments'"
                        class="p-column-filter" (onChange)="onFilterChange($event.value, col.field)">
                    </p-multiSelect>
                </ng-container>
            </th>
        </tr>
    </ng-template>
    <ng-template pTemplate="body" let-rowData>
        <tr [pContextMenuRow]="rowData">
            <td *ngFor="let col of columns">
                <ng-container [ngSwitch]="col.field">
                    <span *ngSwitchCase="'statut'">{{statutProjetAudit[rowData.statut]}}</span>
                    <span *ngSwitchCase="'statutGlobal'">{{statutPointAudit[rowData.statutGlobal]}}</span>
                    <span *ngSwitchCase="'actions'" class="sticky-column">
                        <div class="sticky-column__container">
                            <button #actionsButton pButton pRipple icon="pi pi-ellipsis-v"
                                class="p-button-rounded p-button-secondary assigne-button"
                                (click)="onActionsButtonClick($event, menu, rowData)"></button>
                        </div>
                    </span>
                    <ng-container *ngSwitchDefault>
                        <span *ngIf="col.isDate; else default">
                            {{(rowData[col.field] > 0) ? (rowData[col.field] | date: 'YYYY-MM-dd') : ''}}
                        </span>
                        <ng-template #default>{{rowData[col.field]}}</ng-template>
                    </ng-container>
                </ng-container>
            </td>
        </tr>
    </ng-template>
</p-table>

<p-contextMenu #menu [model]="menuItems" appendTo="body" triggerEvent="click" [target]="actionsButton">
</p-contextMenu>

<app-assign-projet-audit-dialog [projetAudit]="selectedProjetAudit" [(visible)]="assignProjetAuditDialogVisible"
    (close)="closeAssignProjetAuditDialog()">
</app-assign-projet-audit-dialog>