import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators';
import { RechercheListeService } from '../services/recherche-liste.service';
import * as RechercheAction from './recherche.actions';
import { RechercheConfig, rechercheBackendUrlValue } from '../models/recherche.enum';

@Injectable()
export class RechercheEffects {
    recherche$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RechercheAction.recherche),
            mergeMap(action => this.rechercheListeService.getRecherche(
                {
                    inclureSig: action.includeSig,
                    valeur: action.value,
                    type: action.name
                }
            ).pipe(
                map((rechercheList: any) => {
                    return RechercheAction.rechercheSuccess({ rechercheList });
                }),
                catchError((error: unknown) => of(RechercheAction.rechercheFailure({ error })))
            ))
        );
    });

    rechercheZoom$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(RechercheAction.rechercheZoom),
            switchMap(action => this.rechercheListeService.getRechercheZoom(
                {
                    inclureSig: true,
                    valeur: action.value.value,
                    type: rechercheBackendUrlValue.get(RechercheConfig.RECHERCHE_LIGNE),
                    ced: action.value.territoire,
                }
            ).pipe(
                map((zoom: any) => {
                    return RechercheAction.rechercheZoomSuccess({ zoom });
                }),
                catchError((error: any) => of(RechercheAction.rechercheZoomFailure({ error })))
            ))
        );
    });

    constructor(
        private actions$: Actions,
        private rechercheListeService: RechercheListeService
    ) { }
}
