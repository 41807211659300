import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { DynamicDialogConfig, DynamicDialogRef } from 'primeng/dynamicdialog';
import { filter, mergeMap, takeUntil } from 'rxjs';
import { PointInspectionDto, ProjetCompletDto, ResultatTransfertAvisInspection } from '../../../../../core/api/client/models';
import { MapService } from '../../../../../map/services/map.service';
import { UiService } from '../../../../../services/ui.service';
import { BaseComponent } from '../../../../../shared/components/abstract-base-component';
import { TableColumn } from '../../../../../shared/models/table-column.model';
import { State } from '../../../../../state/app.state';
import { getPointsByProjetInspectionId } from '../../../../inspection/state/inspection.actions';
import {
    getPointsActiveProjetInspectionSuccess,
    getResultatTransfertAvisErrors,
    getResultatTransfertAvisErrorsLoading
} from '../../../../inspection/state/inspection.selectors';

@Component({
    selector: 'app-rapport-creation-avis-dialog',
    templateUrl: './rapport-creation-avis-dialog.component.html',
    styleUrls: ['./rapport-creation-avis-dialog.component.scss']
})

export class RapportCreationAvisDialogComponent extends BaseComponent implements OnInit {

    public projet: ProjetCompletDto = this.config.data.projet;
    public resultatTransfertAvis: ResultatTransfertAvisInspection[] = this.config.data.projet.resultatTransferAvis || [];

    public resultatTransfertAvisErrorsLoading$ = this.store.select(getResultatTransfertAvisErrorsLoading);

    columns: TableColumn[] = [
        {
            field: 'codeABarres',
            header: 'Code à barres',
            textAlign: 'left'
        },
        {
            field: 'codeErreur',
            header: 'Message',
            textAlign: 'left'
        },
        {
            field: 'action',
            header: '',
            textAlign: 'left'
        }
    ];

    public resultatTransfertAvisErrors: [PointInspectionDto, string[]][] = [];

    constructor(
        public ref: DynamicDialogRef,
        public config: DynamicDialogConfig,
        private store: Store<State>,
        private mapService: MapService,
        private uiService: UiService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.store.dispatch(getPointsByProjetInspectionId({ projetId: this.projet.id }));

        this.store.select(getPointsActiveProjetInspectionSuccess)
            .pipe(
                filter(points => points.length > 0),
                mergeMap(() => this.store.select(getResultatTransfertAvisErrors(this.projet.id, this.resultatTransfertAvis))),
                takeUntil(this.destroyed)
            )
            .subscribe((resultatTransfertAvisErrors) => {
                this.resultatTransfertAvisErrors = resultatTransfertAvisErrors;
            });
    }

    public onZoomPointInspection(pointInspection: PointInspectionDto) {
        this.uiService.openProjetListModal(false);
        this.mapService.zoomPointInspection(pointInspection);
        this.ref.close(true);
    }
}
