import { Component, EventEmitter, Input, OnChanges, OnDestroy, Output, SimpleChanges } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { State } from '../../../../state/app.state';
import { takeUntil, filter, map, mergeMap } from 'rxjs';
import { BaseComponent } from '../../../../shared/components/abstract-base-component';
import { AnomalieAuditDto, PointAuditDto, PointInspectionDto, ProjetAuditDto, ProjetCompletDto } from '../../../../core/api/client/models';
import { getPointAuditById, getProjetAuditById } from '../../../../features/audit/state/audit.selectors';
import { flatMap } from 'lodash';
import { statutAuditName, StatutPointAudit } from '../../../../features/audit/models/statut-point-audit.enum';
import { dateTimeChange, generatePhotosAnomalieAudit, generatePhotosFromParameters } from '../../../../shared/utils';
import { PhotoService } from '../../../../services/photo.service';
import { ObjectType } from '../../../../shared/enums/photo-object-type.enum';
import { getPointInspectionById, getProjetInspectionById } from '../../../../features/inspection/state/inspection.selectors';
import { UserRole } from '../../../../shared/models/user-roles.model';
import { PopUpInfoCloseEvent } from '../../../models/pop-up-info-close-event.model';
import { MapPermissionsService } from '../../../services/map-permissions.service';
import { getIsAppOnline } from '../../../../state/shared/shared.selectors';

@Component({
    selector: 'app-info-point-audit',
    templateUrl: './info-point-audit.component.html',
    styleUrls: ['./info-point-audit.component.scss']
})
export class InfoPointAuditComponent extends BaseComponent implements OnDestroy, OnChanges {
    public subTitle: string = `Point d'audit`;
    public nbPhotos: number = 0;
    public data: { [name: string]: any } = {};
    public pointAuditHtmlPhotos: HTMLImageElement[] = [];
    public userGroups: string[] = [];

    public projetInspection: ProjetCompletDto | undefined = undefined;
    public pointAudit: PointAuditDto | undefined = undefined;
    public pointInspection: PointInspectionDto | undefined = undefined;
    public projetAudit: ProjetAuditDto | undefined = undefined;
    public anomaliesNonConformes: AnomalieAuditDto[] | undefined = undefined;

    public displayedProperties = {
        statut: 'Statut',
        auditePar: 'Audité par',
        auditeLe: 'Audité le',
        typeNonConformite: 'Type de non-conformité',
        noteAuditeur: 'Note auditeur'
    };

    public verticalProperties: string[] = [this.displayedProperties.noteAuditeur];
    public showEditButton: boolean = false;
    public isOffline: boolean = false;
    public offlineMessage = 'Connectez-vous à internet pour modifier';
    // private projetAUditList: ProjetAuditDto[] | undefined = undefined;
    private auditFeatureReceived: mapboxgl.MapboxGeoJSONFeature;
    private pointInspectionFeatureReceived: mapboxgl.MapboxGeoJSONFeature;

    @Input() currentUserGroups: string[] = [];
    @Input() pointAuditId: string;
    @Input() feature: mapboxgl.MapboxGeoJSONFeature;
    @Input() auditFeature: mapboxgl.MapboxGeoJSONFeature;
    @Output() closed: EventEmitter<PopUpInfoCloseEvent> = new EventEmitter<PopUpInfoCloseEvent>();

    constructor(
        private store: Store<State>,
        private mapPermissionsService: MapPermissionsService,
        private photoService: PhotoService
    ) {
        super();
        // TODO: Déplacer vers une place plus global. Genre faire les dispatchs (projet audit et projet inspection) quand on recoit les claims du user
        // this.auditStore.dispatch();

        this.subscribeToIsAppOffline();
    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes?.currentUserGroups?.currentValue) {
            this.userGroups = changes.currentUserGroups.currentValue;
        }

        if (changes?.feature?.currentValue) {
            this.pointInspectionFeatureReceived = changes.feature.currentValue;
        }

        if (changes?.auditFeature?.currentValue) {
            this.auditFeatureReceived = changes.auditFeature.currentValue;
        }

        if (changes?.pointAuditId?.currentValue) {
            this.subscribeToPointAudit(changes.pointAuditId.currentValue);
        }
    }

    private subscribeToPointAudit(pointAuditId: string) {
        this.store
            .pipe(
                select(getPointAuditById(pointAuditId)),
                filter(pointsAudit => {
                    return !!pointsAudit.length;
                }),
                map(pointsAudits => pointsAudits[0]),
                mergeMap(pointAudit =>
                    this.store.select(getPointInspectionById(pointAudit.pointInspectionId))
                        .pipe(map(pointInspection => ({ pointInspection, pointAudit })))
                ),
                mergeMap(data =>
                    this.store.select(getProjetInspectionById(data.pointInspection.projetId))
                        .pipe(map(projetInspection => ({ ...data, projetInspection })))
                ),
                mergeMap(data =>
                    this.store.select(getProjetAuditById(data.pointAudit.projetAuditId))
                        .pipe(map(projetAudit => ({ ...data, projetAudit })))
                ),
                takeUntil(this.destroyed)
            )
            .subscribe(({ pointAudit, pointInspection, projetInspection, projetAudit }) => {
                this.pointAudit = pointAudit;
                this.pointInspection = pointInspection;
                this.projetInspection = projetInspection;
                this.projetAudit = projetAudit;
                this.anomaliesNonConformes = pointAudit.anomaliesAudit.filter(({ statut }) => statut === StatutPointAudit.nonConforme);
                this.showEditButton = this.mapPermissionsService.canEditPointAuditByProject(this.projetAudit);
                this.setHtmlPhotos();
                this.setHeaderData();
                this.setBodyData();
            });
    }

    private setHtmlPhotos() {
        const convertedPointAuditPhotos = generatePhotosFromParameters(this.pointAudit.photos, {
            getParentId: () => this.pointAudit.id,
            getObjectType: () => ObjectType.POINTS_AUDIT,
            getPhotoId: photo => photo.id,
            alt: 'PointsAudit'
        });

        const convertedNonConformitesPhotos = generatePhotosAnomalieAudit(this.anomaliesNonConformes);
        const pointAuditPhotoContainers = this.photoService.convertToPhotoContainer(convertedPointAuditPhotos);
        const nonConformitesPhotoContainers = this.photoService.convertToPhotoContainer(convertedNonConformitesPhotos);

        this.photoService.setCarousselPhotos([...pointAuditPhotoContainers, ...nonConformitesPhotoContainers]);

        this.pointAuditHtmlPhotos = this.photoService.convertToPhotoHtml(pointAuditPhotoContainers);
    }

    private setHeaderData() {
        const photosNonConformites = flatMap(this.anomaliesNonConformes, x => x.photos);
        this.nbPhotos = this.pointAudit.photos.length + photosNonConformites.length;
    }

    private setBodyData() {
        this.data = {
            [this.displayedProperties.statut]: this.getStatut(),
            [this.displayedProperties.auditeLe]: dateTimeChange(this.pointAudit.auditeLe?.toString(), 'dd/MM/YYYY'),
        };

        if (!this.userGroups.includes(UserRole.ADMIN_EXTERNE)) {
            this.data[this.displayedProperties.auditePar] = this.pointAudit.auditePar;
        }

        this.data = {
            ...this.data,
            [this.displayedProperties.typeNonConformite]: this.getTypeNonConformite(),
            [this.displayedProperties.noteAuditeur]: this.pointAudit.remarque
        };
    }

    private getStatut() {
        const isAudite = [StatutPointAudit.conforme, StatutPointAudit.nonConforme]
            .map(statut => statut.toString())
            .includes(this.pointAudit.statutGlobal.toString());

        return `${isAudite ? 'Audité,' : ''} ${statutAuditName(this.pointAudit.statutGlobal)}`.trim();
    }

    private getTypeNonConformite() {
        const typesNonConformites: string[] = [];

        if (this.pointAudit?.statut === StatutPointAudit.nonConforme) {
            typesNonConformites.push('Détails');
        }

        if (this.anomaliesNonConformes?.length) {
            typesNonConformites.push('Anomalies');
        }

        return typesNonConformites.join(' + ');
    }

    public close() {
        this.closed.emit({ closed: true });
        this.ngOnDestroy();
    }

    public edit() {
        this.closed.emit({
            closed: true,
            pointAuditId: this.pointAudit.id,
            pointAuditFeature: this.auditFeatureReceived,
            pointInspectionFeature: this.pointInspectionFeatureReceived
        });
        this.ngOnDestroy();
    }

    private subscribeToIsAppOffline() {
        this.store.select(getIsAppOnline).pipe(
            takeUntil(this.destroyed)
        ).subscribe(_isAppOnline => {
            this.isOffline = !_isAppOnline;
        });
    }
}
