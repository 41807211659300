import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { SharedModule } from '../../shared/shared.module';
import { synchronisationReducer } from './state/synchronisation.reducer';
import { SynchronisationEffects } from './state/synchronisation.effects';
import { SynchronisationService } from './services/synchronisation.service';
import { SynchronisationDialogComponent } from './components/synchronisation-dialog/synchronisation-dialog.component';
import { RapportErreurSynchronisationDialogComponent } from './components/rapport-erreur-synchronisation-dialog/rapport-erreur-synchronisation-dialog.component';

@NgModule({
    imports: [
        SharedModule,
        StoreModule.forFeature('synchronisation', synchronisationReducer),
        EffectsModule.forFeature([SynchronisationEffects]),
    ],
    declarations: [
        SynchronisationDialogComponent,
        RapportErreurSynchronisationDialogComponent
    ],
    providers: [
        SynchronisationService
    ],
    exports: []
})
export class SynchronisationModule { }
