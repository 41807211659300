export enum StoreName {
    ANOMALIE_PILOTAGE = 'anomaliePilotage',
    PILE_REQUETE = 'pileRequete', // TODO DELETE WHEN PDL-655 IS DONE
    PROJETS = 'projets',
    PROJETS_AUDIT = 'projetsAudit',
    REQUESTS = 'requests',
    SYNC_ERRORS = 'syncErrors',
    TAXONOMIE = 'taxonomie',
    PHOTOS = 'photos',
}
