/* eslint-disable @typescript-eslint/naming-convention */
import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { of } from 'rxjs';
import { catchError, concatMap, debounceTime, map, mergeMap, switchMap, withLatestFrom } from 'rxjs/operators';
import { PointInspectionDto, ProjetCompletDto } from '../../../core/api/client/models';
import { getIsUserAdmin, getIsUserMobile } from '../../../core/store/selectors/user.selectors';
import { mapStyleConfig } from '../../../map/models/map-style-config.const';
import { PhotoService } from '../../../services/photo.service';
import * as PhotosActions from '../../../shared/photos/state/photos.actions';
import { State } from '../../../state/app.state';
import { getIsAppOnline } from '../../../state/shared/shared.selectors';
import * as OfflineActions from '../../offline/state/offline.actions';
import { InspectionService } from '../services/inspection.service';
import * as InspectionActions from './inspection.actions';
import { filterDeletedAnomalies, getCurrentActiveProjetInspection } from './inspection.selectors';

@Injectable()
export class InspectionEffects {

    loadProjetInspectionList$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.loadProjetInspectionList),
            withLatestFrom(this.store.select(getIsAppOnline)),
            mergeMap(([_, isAppOnline]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.loadProjetInspectionList());
                } else {
                    return this.inspectionService.getProjetsInspection({ pageSize: 5000 })
                        .pipe(
                            map(projetInspectionList => InspectionActions.loadProjetInspectionListSuccess({ projetList: projetInspectionList })),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Liste des projets`,
                                            detail: `Une erreur est survenue lors de la récupération de la liste des projets.`
                                        }
                                    );
                                }

                                return of(InspectionActions.loadProjetInspectionListError({ error }));
                            })
                        );
                }
            })
        );
    });

    // loadProjetInspectionHistory$ = createEffect(() => { // TODO
    //     return this.actions$.pipe(
    //         ofType(InspectionActions.loadProjetInspectionHistory),
    //         mergeMap(action =>
    //             this.inspectionService.getProjetInspectionHistory(action.projetInspectionId)
    //                 .pipe(
    //                     map(projetHistory => InspectionActions.loadProjetInspectionHistorySuccess({ projetHistory })),
    //                     catchError((error: unknown) => of(InspectionActions.loadProjetInspectionHistoryError({ error })))
    //                 )));
    // });

    // startCreateProjetInspection$ = createEffect(() => { // TODO
    //     return this.actions$.pipe(
    //         ofType(InspectionActions.startCreateProjetInspection),
    //         mergeMap(action => this.inspectionService.createProjetInspection({ id: action.projetId, body: action.projetInspection })
    //             .pipe(
    //                 map(createdProjetInspection => InspectionActions.startCreateProjetInspectionSuccess({ createdProjetInspection })),
    //                 catchError((error: unknown) => of(InspectionActions.startCreateProjetInspectionError({ error })))
    //             )));
    // });

    // checkCreateProjetInspectionCompleted$ = createEffect(() => ({ scheduler = asyncScheduler } = {}) => { // TODO
    //     return this.actions$.pipe(
    //         ofType(InspectionActions.startCreateProjetInspectionSuccess),
    //         //timeout 15min
    //         mergeMap(({ createdProjetInspection }) => timer(900000, scheduler).pipe(
    //             switchMap(() => throwError(() => ({ error: { erreurs: [`Le délais d'attente maximal est dépassé.`] } }))),
    //             startWith(null),
    //             switchMap(() => interval(5000, scheduler)),
    //             mergeMap(() => {
    //                 return this.inspectionService.getCreateStatus(createdProjetInspection.projetInspection.id);
    //             }),
    //             skipWhile(status => {
    //                 return status === createdProjetInspection.checksum;
    //             }),
    //             switchMap(() => {
    //                 return this.inspectionService.getProjetInspection(createdProjetInspection.projetInspection.id);
    //             }),
    //             map(projetInspection => {
    //                 return InspectionActions.createProjetInspectionSuccess({ createProjetInspection: projetInspection });
    //             })
    //         )),
    //         catchError((error: any) => {
    //             return of(InspectionActions.createProjetInspectionError({ error }));
    //         }),
    //         takeUntil(this.actions$.pipe(ofType(InspectionActions.createProjetInspectionSuccess)))
    //     );
    // });

    assignProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.assignProjetInspection),
            mergeMap(action =>
                this.inspectionService.updateProjetInspection({ id: action.projetId, body: action.assignData })
                    .pipe(
                        map(assignProjetInspection => InspectionActions.assignProjetInspectionSuccess({ assignProjetInspection })),
                        catchError((error: unknown) => of(InspectionActions.assignProjetInspectionError({ error })))
                    )));
    });

    activateProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.activateProjetInspection),
            mergeMap(action =>
                this.inspectionService.updateProjetInspection({ id: action.projetInspectionId, body: action.activateData })
                    .pipe(
                        map(activateProjetInspection => InspectionActions.activateProjetInspectionSuccess({ activateProjetInspection })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Activer un projet`,
                                        detail: `Une erreur est survenue lors de l'activation du projet.`
                                    }
                                );
                            }

                            return of(InspectionActions.activateProjetInspectionError({ error }));
                        })
                    )));
    });

    cancelProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.cancelProjetInspection),
            mergeMap(action =>
                this.inspectionService.updateProjetInspection({ id: action.projetId, body: action.cancelData })
                    .pipe(
                        map(cancelProjetInspection => InspectionActions.cancelProjetInspectionSuccess({ cancelProjetInspection })),
                        catchError((error: unknown) => of(InspectionActions.cancelProjetInspectionError({ error })))
                    )));
    });

    rejectProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.rejectProjetInspection),
            mergeMap(action =>
                this.inspectionService.updateProjetInspection({ id: action.projetId, body: action.rejectData })
                    .pipe(
                        map(projetInspection => InspectionActions.rejectProjetInspectionSuccess({ projetInspection })),
                        catchError((error: unknown) => of(InspectionActions.rejectProjetInspectionError({ error })))
                    )));
    });

    completeProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.completeProjetInspection),
            mergeMap(action =>
                this.inspectionService.updateProjetInspection({ id: action.projetId, body: action.completeData })
                    .pipe(
                        map(projetInspection => InspectionActions.completeProjetInspectionSuccess({ projetInspection })),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Compléter un projet`,
                                        detail: `Une erreur est survenue lors de la finalisation du projet.`
                                    }
                                );
                            }

                            return of(InspectionActions.completeProjetInspectionError({ error }));
                        })
                    )));
    });

    approveProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.approveProjetInspection),
            mergeMap(action =>
                this.inspectionService.updateProjetInspection({ id: action.projetId, body: action.approveData })
                    .pipe(
                        map(projetInspection => InspectionActions.approveProjetInspectionSuccess({ projetInspection })),
                        catchError((error: unknown) => of(InspectionActions.approveProjetInspectionError({ error })))
                    )));
    });

    updatePointInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.updatePointInspection),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserAdmin),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            concatMap(([action, isAppOnline, isUserAdmin, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.updatePointInspection({ pointInspection: action.pointInspection, poteauAction: action.poteauAction }));
                } else {
                    return this.inspectionService.updatePointInspection(
                        {
                            id: action.pointInspection.id,
                            body: action.pointInspection,
                            action: action.poteauAction
                        }
                    )
                        .pipe(
                            //mergeMap(pointInspection => this.filterDeletedAnomaliesPointInspection(pointInspection)), // TODO REFACTOR
                            mergeMap(pointInspection => {
                                const actions: Action[] = [
                                    InspectionActions.updatePointInspectionSuccess({ pointInspection })
                                ];

                                if (!isUserAdmin) {
                                    actions.push(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }));
                                }

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Mise à jour de détails poteau`,
                                            detail: `Une erreur est survenue lors de la mise à jour du poteau.`
                                        }
                                    );
                                }

                                return of(InspectionActions.updatePointInspectionError({ error }));
                            })
                        );
                }
            })
        );
    });

    createPointInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.createPointInspection),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            mergeMap(([action, isAppOnline, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.createPointInspection({ pointInspection: action.pointInspection }));
                } else {
                    return this.inspectionService.createPointInspection({ id: action.pointInspection.projetId, body: action.pointInspection })
                        .pipe(
                            //mergeMap(pointInspection => this.filterDeletedAnomaliesPointInspection(pointInspection)),
                            mergeMap((pointInspection) => {
                                const actions: Action[] = [
                                    InspectionActions.createPointInspectionSuccess({ pointInspection }),
                                    InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }),
                                ];

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Création de poteau`,
                                            detail: `Une erreur est survenue lors de la création de poteau.`
                                        }
                                    );
                                }

                                return of(InspectionActions.createPointInspectionError({ error }));
                            })
                        );
                }
            })
        );
    });

    createAnomalieInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.createAnomalieInspection),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.createAnomalieInspection({ pointInspection: action.pointInspection, anomalie: action.anomalie }));
                } else {
                    return this.inspectionService.updateAnomalieInspection(
                        {
                            inspectionId: action.pointInspection.id,
                            id: action.pointInspection.projetId,
                            body: action.anomalie
                        }
                    )
                        .pipe(
                            concatMap((anomalie) => {
                                const actions: Action[] = [];

                                actions.push(InspectionActions.createAnomalieInspectionSuccess({ anomalie, success: true }));
                                actions.push(InspectionActions.getPointsByProjetInspectionId({ projetId: action.pointInspection.projetId }));

                                if (isUserMobile) {
                                    actions.push(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }));
                                }

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Création d'anomalie`,
                                            detail: `Une erreur est survenue lors de la création d'anomalie.`
                                        }
                                    );
                                }

                                return of(InspectionActions.createAnomalieInspectionError({ error }));
                            })
                        );
                }
            })
        );
    });

    updateAnomalieInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.updateAnomalieInspection),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.updateAnomalieInspection({ pointInspection: action.pointInspection, anomalie: action.anomalie }));
                } else {
                    return this.inspectionService.updateAnomalieInspection(
                        {
                            inspectionId: action.pointInspection.id,
                            id: action.pointInspection.projetId,
                            body: action.anomalie
                        }
                    )
                        .pipe(
                            mergeMap((anomalie) => {
                                const actions: Action[] = [
                                    InspectionActions.updateAnomalieInspectionSuccess({ anomalie, success: true }),
                                    InspectionActions.getPointsByProjetInspectionId({ projetId: action.pointInspection.projetId })
                                ];

                                if (isUserMobile) {
                                    actions.push(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }));
                                }

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Modification d'anomalie`,
                                            detail: `Une erreur est survenue lors de la modification d'anomalie.`
                                        }
                                    );
                                }

                                return of(InspectionActions.updateAnomalieInspectionError({ error }));
                            })
                        );
                }
            })
        );
    });

    deleteAnomalieInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.deleteAnomalieInspection),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.deleteAnomalieInspection({ pointInspection: action.pointInspection, anomalieId: action.anomalieId }));
                } else {
                    return this.inspectionService.deleteAnomalieInspection(action.anomalieId)
                        .pipe(
                            mergeMap(() => {
                                const actions: Action[] = [
                                    InspectionActions.deleteAnomalieInspectionSuccess({ success: true }),
                                    InspectionActions.getPointsByProjetInspectionId({ projetId: action.pointInspection.projetId })
                                ];

                                if (isUserMobile) {
                                    actions.push(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }));
                                }

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Suppression d'anomalie`,
                                            detail: `Une erreur est survenue lors de la suppression de l'anomalie.`
                                        }
                                    );
                                }

                                return of(InspectionActions.deleteAnomalieInspectionError({ error }));
                            })
                        );
                }
            })
        );
    });

    addPointInspectionPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.addPointInspectionPhoto),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.addPointInspectionPhoto({ pointInspection: action.pointInspection, photo: action.photo }));
                } else {

                    return this.inspectionService.addPointInspectionPhoto(
                        {
                            inspectionId: action.pointInspection.id,
                            Photo: this.photoService.blobToFile(action.photo.data, action.photo.nomOriginal),
                            NomOriginal: action.photo.nomOriginal,
                            Id: action.photo.id
                        }
                    ).pipe(
                        mergeMap((photo) => {
                            const updatedPointInspection: PointInspectionDto = {
                                ...action.pointInspection,
                                photos: action.pointInspection.photos.map((pointPhoto) => pointPhoto.id === photo.id ? photo : pointPhoto)
                            };

                            const actions: Action[] = [
                                InspectionActions.addPointInspectionPhotoSuccess({ pointInspection: updatedPointInspection }),
                                PhotosActions.loadUploadedPhoto({ photo: photo })
                            ];

                            if (isUserMobile) {
                                actions.push(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }));
                            }

                            return actions;
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Ajout de photo`,
                                        detail: `Une erreur est survenue lors de l'ajout de photo.`
                                    }
                                );
                            }

                            return of(InspectionActions.addPointInspectionPhotoError({ error }));
                        })
                    );
                }
            })
        );
    });

    addAnomalieInspectionPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.addAnomalieInspectionPhoto),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.addAnomalieInspectionPhoto(
                        {
                            pointInspection: action.pointInspection,
                            anomalieId: action.anomalieId,
                            photo: action.photo
                        }
                    ));
                } else {
                    return this.inspectionService.addAnomalieInspectionPhoto(
                        {
                            anomalieId: action.anomalieId,
                            Photo: this.photoService.blobToFile(action.photo.data, action.photo.nomOriginal),
                            NomOriginal: action.photo.nomOriginal,
                            Id: action.photo.id
                        }
                    ).pipe(
                        mergeMap((photo) => {
                            const actions: Action[] = [
                                InspectionActions.addAnomalieInspectionPhotoSuccess({
                                    pointInspectionId: action.pointInspection.id,
                                    anomalieId: action.anomalieId,
                                    photo
                                }),
                                PhotosActions.loadUploadedPhoto({ photo: photo })
                            ];

                            if (isUserMobile) {
                                actions.push(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }));
                            }

                            return actions;
                        }),
                        catchError((error: any) => {
                            if (error.status !== 404) {
                                this.messageService.add(
                                    {
                                        severity: 'error',
                                        summary: `Ajout de photo`,
                                        detail: `Une erreur est survenue lors de l'ajout de photo.`
                                    }
                                );
                            }

                            return of(InspectionActions.addAnomalieInspectionPhotoError({ error }));
                        })
                    );
                }
            })
        );
    });

    deletePointInspectionPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.deletePointInspectionPhoto),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.deletePointInspectionPhoto({ pointInspection: action.pointInspection, photo: action.photo }));
                } else {
                    return this.inspectionService.deletePointInspectionPhoto({ inspectionId: action.pointInspection.id, nomPhoto: action.photo.nom })
                        .pipe(
                            mergeMap(() => {
                                const actions: Action[] = [
                                    InspectionActions.deletePointInspectionPhotoSuccess({ pointInspection: action.pointInspection })
                                ];

                                if (isUserMobile) {
                                    actions.push(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }));
                                }

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Supression de photo`,
                                            detail: `Une erreur est survenue lors de la supression de photo.`
                                        }
                                    );
                                }

                                return of(InspectionActions.deletePointInspectionPhotoError({ error }));
                            })
                        );
                }
            })
        );
    });

    deleteAnomalieInspectionPhoto$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.deleteAnomalieInspectionPhoto),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile),
                this.store.select(getCurrentActiveProjetInspection)
            ),
            mergeMap(([action, isAppOnline, isUserMobile, currentActiveProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.deleteAnomalieInspectionPhoto(
                        {
                            pointInspection: action.pointInspection,
                            anomalieId: action.anomalieId,
                            photo: action.photo
                        }
                    ));
                } else {
                    return this.inspectionService.deleteAnomalieInspectionPhoto({ anomalieId: action.anomalieId, nomPhoto: action.photo.nom })
                        .pipe(
                            mergeMap(() => {
                                const actions: Action[] = [
                                    InspectionActions.deleteAnomalieInspectionPhotoSuccess()
                                ];

                                if (isUserMobile) {
                                    actions.push(InspectionActions.updateProjetInspectionToIndexedDb({ projetId: currentActiveProjetInspection.id }));
                                }

                                return actions;
                            }),
                            catchError((error: any) => {
                                if (error.status !== 404) {
                                    this.messageService.add(
                                        {
                                            severity: 'error',
                                            summary: `Supression de photo`,
                                            detail: `Une erreur est survenue lors de la supression de photo.`
                                        }
                                    );
                                }

                                return of(InspectionActions.deleteAnomalieInspectionPhotoError({ error }));
                            })
                        );
                }
            })
        );
    });

    loadPointsInspectionBbox$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.loadPointsInspectionBbox),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getIsUserMobile)
            ),
            debounceTime(200),
            switchMap(([action, isAppOnline, isUserMobile]) => {
                if (!isAppOnline || isUserMobile) {
                    return of(OfflineActions.loadPointsInspectionBbox({ bbox: action }));
                } else {
                    if (action.zoomLevel >= mapStyleConfig.poteau.minZoom) {
                        const { xmin, xmax, ymin, ymax } = action.bounds;
                        return this.inspectionService.getPointsInspection({ pageNumber: 1, pageSize: 5000, xmax, xmin, ymax, ymin })
                            .pipe(
                                mergeMap(pointsInspection => this.filterDeletedAnomaliesPointsInspection(pointsInspection)), // TODO REFACTOR
                                map((pointsInspection) => InspectionActions.loadPointsInspectionBboxSuccess({ bbox: action, pointsInspection })),
                                catchError((error: unknown) => of(InspectionActions.loadPointsInspectionBboxError({ bbox: action, error })))
                            );
                    } else {
                        return [];
                    }
                }
            })
        );
    });

    getPointsActiveProjetInspection$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.getPointsActiveProjetInspection),
            withLatestFrom(
                this.store.select(getIsAppOnline),
                this.store.select(getCurrentActiveProjetInspection),
            ),
            switchMap(([_, isAppOnline, activeProjetInspection]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.getPointsActiveProjetInspection());
                } else {
                    return this.inspectionService.getProjetInspection({ id: activeProjetInspection?.id }).pipe(
                        mergeMap(projetInspection => this.filterDeletedAnomaliesProjetInspection(projetInspection)), // TODO REFACTOR
                        map((projetInspection) => InspectionActions.getPointsActiveProjetInspectionSuccess({ pointsInspection: projetInspection.pointInspections! })),
                        catchError((error: unknown) => of(InspectionActions.getPointsActiveProjetInspectionError({ error })))
                    );
                }
            })
        );
    });

    getPointsByProjetInspectionId$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.getPointsByProjetInspectionId),
            switchMap((action) => {
                return this.inspectionService.getProjetInspection({ id: action.projetId }).pipe(
                    //mergeMap(projetInspection => this.filterDeletedAnomaliesProjetInspection(projetInspection)), // TODO REFACTOR
                    map((projetInspection) => InspectionActions.getPointsByProjetInspectionIdSuccess({ pointsInspection: projetInspection.pointInspections! })),
                    catchError((error: unknown) => of(InspectionActions.getPointsByProjetInspectionIdError({ error })))
                );
            })
        );
    });

    getProjetInspectionById$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.getProjetInspectionById),
            withLatestFrom(this.store.select(getIsAppOnline)),
            switchMap(([action, isAppOnline]) => {
                if (!isAppOnline) {
                    return of(OfflineActions.getProjetInspectionById({ projetId: action.projetId }));
                } else {
                    return this.inspectionService.getProjetInspection({ id: action.projetId }).pipe(
                        map((projetInspection) => InspectionActions.getProjetInspectionByIdSuccess({ projetInspection })),
                        catchError((error: unknown) => of(InspectionActions.getProjetInspectionByIdError({ error })))
                    );
                }
            })
        );
    });


    updateProjetInspectionToIndexedDb$ = createEffect(() => {
        return this.actions$.pipe(
            ofType(InspectionActions.updateProjetInspectionToIndexedDb),
            mergeMap((action) => {
                return this.inspectionService.getProjetInspection({ id: action.projetId }).pipe(
                    mergeMap(projet => {
                        return [
                            InspectionActions.getPointsActiveProjetInspectionSuccess({ pointsInspection: projet.pointInspections! }),
                            OfflineActions.updateProjetToIndexedDb({ projet })
                        ];
                    }),
                    catchError((error: unknown) => of(InspectionActions.getPointsActiveProjetInspectionError({ error })))
                );
            })
        );
    });

    constructor(
        private actions$: Actions,
        private store: Store<State>,
        private inspectionService: InspectionService,
        private messageService: MessageService,
        private photoService: PhotoService
    ) { }

    // private filterDeletedAnomaliesPointInspection(pointInspection: PointInspectionDto) { TODO REFACTOR
    //     return this.filterDeletedAnomaliesPointsInspection([pointInspection])
    //         .pipe(map(pointsInspection => pointsInspection[0]));
    // }

    private filterDeletedAnomaliesPointsInspection(pointsInspection: PointInspectionDto[]) {
        return this.store.select(filterDeletedAnomalies(pointsInspection));
    }

    private filterDeletedAnomaliesProjetInspection(projet: ProjetCompletDto) {
        return this.filterDeletedAnomaliesPointsInspection(projet?.pointInspections || [])
            .pipe(map(pointInspections => (!projet ? projet : { ...projet, pointInspections })));
    }
}
