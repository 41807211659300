import { Component, Input } from '@angular/core';

@Component({
    selector: 'app-form-info',
    templateUrl: './form-info.component.html'
})
export class FormInfoComponent {
    @Input() icon = 'pi pi-question-circle';
    @Input() message: string;
    @Input() typeInfo = 'label';
}
