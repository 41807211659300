<hq-header image="{{imagePath}}capture_blanc_46x130.png" (expandedChange)='expanded=$event'>
    <hq-header-menu>
        <hq-header-menu-section>
            <hq-header-menu-item-group icon="fas fa-bars" label="Menu">
                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container>
                        <div>
                            <p-menu [model]="utilisateurService.isUserMobile ? menuMobile : menuWeb" appendTo="body"
                                [class.disableClick]="isPoteauDetailsDialogOpened">
                            </p-menu>
                        </div>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>
            </hq-header-menu-item-group>
        </hq-header-menu-section>
        <hq-header-menu-section #userInfoMenu>
            <hq-header-menu-item-group [icon]="user ? 'appicons ic-user' : 'appicons ic-sign-in-alt'" label="Profile">
                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container>
                        <div class='hq-header-menu-profile'>
                            <div class='hq-header-menu-profile__image'>
                                <img *ngIf="user?.profileImage" [src]='user?.profileImage' alt='' />
                                <div class='hq-header-menu-profile__initials' *ngIf="!user?.profileImage">
                                    {{user?.initials | uppercase}}
                                </div>
                            </div>
                            <div class='hq-header-menu-profile__description'>
                                <div class='hq-header-menu-profile__user-fullname'>{{user?.fullname}}</div>
                            </div>
                        </div>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>
                <hq-header-menu-section *ngIf="!user">
                    <hq-header-menu-item-container>
                        <div class="hq-header-menu__user-connection">
                            <button pButton type="button" label="Se connecter" icon='appicons ic-sign-in-alt'
                                [hqClickCommand]="login">
                            </button>
                        </div>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>
                <hq-header-menu-section *ngIf="user">
                    <hq-header-menu-item-container *ngIf="userInformation">
                        <span class="p-text-bold">Rôle: </span> {{userInformation.groupes.join(', ')}}
                    </hq-header-menu-item-container>
                    <hq-header-menu-item-container *ngIf="userInformation">
                        <span class="p-text-bold">Firme: </span> {{firmeName}}
                    </hq-header-menu-item-container>
                    <hq-header-menu-item-container *ngIf="environmentName !== undefined && environmentName !== ''">
                        <span class="p-text-bold">Environnement: </span> {{environmentName}}
                    </hq-header-menu-item-container>
                    <hq-header-menu-item-container>
                        <button pButton type="button" label="Se déconnecter" icon='appicons ic-sign-out-alt'
                            [hqClickCommand]="logout">
                        </button>
                    </hq-header-menu-item-container>
                </hq-header-menu-section>
            </hq-header-menu-item-group>
        </hq-header-menu-section>
    </hq-header-menu>
</hq-header>
