import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SharedState } from './shared.state';

const getSharedFeatureState = createFeatureSelector<SharedState>('shared');

export const getIsAppOnline = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state?.isAppOnline
);

export const getEsriAccessToken = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.esriAccessToken
);

export const getDataExtraction = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.dataExtraction
);

export const getDataExtractionError = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.dataExtractionError
);

export const getStartDataExtraction = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.startDataExtraction
);

export const getStartDataExtractionError = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.startDataExtractionError
);

export const getAnomaliesPilotage = createSelector(
    getSharedFeatureState,
    (state: SharedState) => state.anomaliesPilotage
);
