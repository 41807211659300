import { createAction, props } from '@ngrx/store';
import { RechercheBackendValue } from '../models/recherche.enum';
import { RechercheListe, FinalRecherche } from '../models/recherche-liste.model';

enum RechercheActionType {
    RECHERCHE = '[Recherche] ',
    RECHERCHE_SUCCESS = '[Recherche], success',
    RECHERCHE_FAILURE = '[Recherche], échec',
    RECHERCHE_ZOOM = '[Recherche] Zoom pour ligne',
    RECHERCHE_ZOOM_SUCCESS = '[Recherche] par LCLCL, success',
    RECHERCHE_ZOOM_FAILURE = '[Recherche] par LCLCL, échec',
}

export const recherche = createAction(RechercheActionType.RECHERCHE, props<{ name: RechercheBackendValue, value: string, includeSig: boolean }>());
export const rechercheSuccess = createAction(RechercheActionType.RECHERCHE_SUCCESS, props<{ rechercheList: RechercheListe }>());
export const rechercheFailure = createAction(RechercheActionType.RECHERCHE_FAILURE, props<{ error: any }>());

export const rechercheZoom = createAction(RechercheActionType.RECHERCHE_ZOOM, props<{ value: FinalRecherche }>());
export const rechercheZoomSuccess = createAction(RechercheActionType.RECHERCHE_ZOOM_SUCCESS, props<{ zoom: any }>());
export const rechercheZoomFailure = createAction(RechercheActionType.RECHERCHE_ZOOM_FAILURE, props<{ error: any }>());

